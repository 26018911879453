import { useContext } from "react";
import Context from "../Context/Context";
import { STATIC_ACCOUNT_EMAIL } from "../Constants";

export const IsGuestUser = () => {
	const context = useContext(Context);

	const isGuestUser = () => {
		return (
			!!!context.user ||
			!!!context.user?.subscription ||
			context.user?.email === STATIC_ACCOUNT_EMAIL
		);
	};

	return { isGuestUser };
};
