import { makeStyles, Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { DrawerProps } from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { getDatabase, ref, update } from "firebase/database";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import { useLocation, useParams } from "react-router-dom";
import SplitPane from "react-split-pane";
import BriaConfirmationDialog from "../../Components/BriaConfirmationDialog";
import ActionIcons from "../../Components/Tools/ActionIcons";
import { defaultSelectedColor, defaultSlidersState } from "../../Components/Tools/Mood/data/sliders";
import ToolHeader from "../../Components/Tools/ToolHeader";
import ToolsAction from "../../Components/Tools/ToolsAction";
import ToolsImage from "../../Components/Tools/ToolsImage";
import { BriaObjectType, SelectedPointInterface } from "../../Components/Tools/utilities";
import RegisterDetails from "../../Components/UI/RegisterDetails";
import Context from "../../Context/Context";
import { getUserBrands_getUserBrands } from "../../GraphQL/types/getUserBrands";
import { startDownload } from "../../Helpers/images";
import BriaAPI from "../../sdk/resources/briaAPI";
import { useImageEditor } from "./useImageEditor";
import DotBackground from "../../assets/svgs/dot_bg.svg";
import { IsIframe } from "../../Helpers/iframe";
import { FeedbackChat } from "../../Components/Tools/FeedBackChat";
import { IsGuestUser } from "../../Helpers/isGuest";

export const ImageEditorContext = createContext({
	generalLoading: false,
	setGeneralLoading: (loading: boolean) => {},
	setSelectedImageUrl: (url: string) => {},
	selectedImageUrl: "",
	downloadBtnProps: {},
	updateResetActions: (newAction: VoidFunction) => {},
	moodState: {
		slidersGeneralState: {},
		setSlidersGeneralState: (state: any) => {},
		selectedColor: "",
		setSelectedColor: (color: string) => {},
		moodInstance: {},
	},
	undoRedoSetter: (undoRedoState: any = {}) => {},
	getPlaygroundUserMoods: () => Promise.resolve([] as getUserBrands_getUserBrands[]),
	setOperationPlayGroundError: (value: boolean) => {},
});

function ImageEditor() {
	const context = useContext(Context);
	const { visual_hash: vhash } = useParams<any>();
	const { t } = useTranslation();

	const [slidersGeneralState, setSlidersGeneralState] = useState<any>(defaultSlidersState);
	const [selectedColor, setSelectedColor] = useState<string>(defaultSelectedColor);
	const moodInstance: any = BriaAPI.getInstance(vhash);

	const moodState = {
		slidersGeneralState,
		setSlidersGeneralState,
		selectedColor,
		setSelectedColor,
		moodInstance,
	};

	const {
		backgroundOracle,
		imageRef,
		visual_hash,
		loading,
		proccessing,
		selectedPoint,
		setSelectedVideo,
		selectedVideo,
		disableDownloadButton,
		selectedImageUrl,
		backgroundString,
		toggleBackground,
		onChangeClickedIncreaseResolutionButton,
		clickedIncreaseResButton,
		increaseResolution,
		showResolutionBeforeDownloadDialog,
		setShowResolutionBeforeDownloadDialog,
		accessoriesToRemove,
		objectsList,
		pointOfInterests,
		imageDetails,
		setSelectedPoint,
		onObjectHover,
		clearObjectHover,
		objectOnHover,
		personalizerValues,
		onChangePersonalizer,

		//Personalizer
		toDisplayThumbnails,
		onAddSemanticCombination,
		onThumbnailClick,

		//Extreme Expressions
		createExtremeExpression,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		selectedExpressionValue,
		selectedExpressionName,
		resetExtremeExpression,
		resetLastExtremeExpression,
		validExpressions,
		isLoadingExpression,

		canUndo,
		canRedo,
		onRedo,
		onUndo,

		canCompare,

		layersURL,

		selectedEthnicity,
		selectedEthnicityValue,
		onRemoveObject,
		onRemoveObjectByCoordinates,
		onShowObject,
		onChangeEthnicity,
		handleResetActions,
		updateResetActions,
		getPlaygroundUserMoods,
		pipelineSettings,
		handleChangePipelineSettings,
		generatingImageMetric,
		fullscreenMode,
		onFullscreenChange,
		resetCameraMotionVideo,
		fetchCameraVideo,
		cameraMotionVideo,
		isCameraMovementTabSelected,
		setIsCameraMovementTabSelected,
		setIsTextEditorTabSelected,
		setIsLogoTabSelected,
		setIsReplaceBackgroundTabSelected,
		isReplaceBackgroundTabSelected,
		preCameraMovementActionsConfig,
		setPreCameraMovementActionsConfig,
		isFashionMenTabSelected,
		setIsFashionSelected,

		openCreateVideoPopup,
		setOpenCreateVideoPopup,
		openVideoReadyPopup,
		setOpenVideoReadyPopup,

		setShouldResetCheckboxes,
		shouldResetCheckboxes,

		//Config
		toolsLoading,
		toolsConfigTabs,

		toolsConfigSuggestions,
		toolsConfigSliders,
		toolsConfigThumbnails,
		setImageScore,
		originalImage,
		originalImageWithWatermark,
		disableRemoveBGSwitch,
		expressionsSliders,
		isImageMode,
		setIsImageMode,
		videoStatus,
		setSelectedImageUrl,
		isImageLoading,
		setIsImageLoading,
		changePersonalizerSlider,
		setGeneralLoading,
		downloadBtnProps,
		undoRedoSetter,
		disabledTabs,
		faceDevInfo,
		setTextOnImage,
		textOnImage,
		isTextEditorTabSelected,
		isLogoTabSelected,
		isObjectsTabSelected,
		setIsObjectsTabSelected,
		objectsTabCanvasRef,
		brushConfig,
		setBrushConfig,
		resetManualBrushTab,
		setResetManualBrushTab,
		isObjectsAutomaticTabSelected,
		setIsObjectsAutomaticTabSelected,
		isObjectsManualBrushTabSelected,
		setIsObjectsManualBrushTabSelected,
		onPresentersStyleThumbnailClick,
		onDoPresentersStyle,
		objectsPanopticImage,
		objectsMasks,
		presentersStyleText,
		presentersStyleThumbnails,
		onImageReplaceBackground,
		onReplaceBgThumbnailClick,
		replaceBgText,
		replaceBgThumbnails,
		toggleBackgroundBlur,
		backgroundBlur,
		replaceBgLoading,
		isLoadingPersonInfo,
		isLoadingObjectsInfo,
		isLoadingCropConfig,
		setOperationPlayGroundError,
		disableObjectsTab,
		removeObjectMutationLoading,
		setPointOfInterests,
		resetPointsOfInterests,
		originalImageDimensions,
		presentersStyleLoading,
		getProcessingOperation,
		setReplaceBgThumbsLoading,
		imageDetailsLoading,
		replaceBgThumbsLoading,
		hideImageUrlLoader,
		onUncrop,
		onUncropThumbnailClick,
		setUncropThumbsLoading,
		uncropLoading,
		uncropThumbnails,
		uncropThumbsLoading,
		uncropValues,
		resetUncrop,
		resetCrop,
		isUncropApplied,
		setIsUncropApplied,
		cropZoom,
		setCropZoom,
		draftZoom,
		setDraftZoom,
		cropName,
		setCropName,
		draftCropName,
		setDraftCropName,
		previewImage,
		setPreviewImage,
		draftCroppedArea,
		setDraftCroppedArea,
		croppedArea,
		setCroppedArea,
		selectedTab,
		ethicalError,
		setEthicalError,
		setSelectedTab,
		activeCropPreset,
		setActiveCropPreset,
		activeCropSizebtn,
		setActiveCropSizeBtn,
		rotation,
		setRotation,
		cropImageUrl,
		setCropImageUrl,
		cropWidth,
		setCropWidth,
		cropHeight,
		setCropHeight,
		zoomPersantage,
		setZoomPersantage,
		naturalSize,
		setNaturalSize,
		croppedAreaPixels,
		setCroppedAreaPixels,
		croppedAreaPercentage,
		setCroppedAreaPercentage,
		CropTabselected,
		setCropTabSeleced,
		croppedImage,
		setCroppedImage,
		cropImageFunction,
		minZoom,
		maxZoom,
		draftCroppedAreaPixels,
		setDraftCroppedAreaPixels,
		draftCroppedAreaPercentage,
		setDraftCroppedAreaPercentage,
		draftRotation,
		setDraftRotation,
		createCroppedImage,
		expandUncropThumbnails,
		expandBackgroundTabSelected,
		setExpandBackgroundTabSelected,
		imageElement,
		showObjectsFollowUpTooltip,
		setShowObjectsFollowUpTooltip,
		setObjectsTooltipPosition,
		objectsTooltipPosition,
		onRestoreAllObjects,
		lockMasks,
		setLockMasks,
		mainObjectData,
		setMainObjectData,
		cropOnlyImageUrl,
		showCropSavePopUp,
		setShowCropSavePopUp,
		showCropSaveFirstTime,
		setShowCropSaveFirstTime,
		applySketchImageStyle,
		imageTransparencyPercentage,
	} = useImageEditor();
	const { isGuestUser } = IsGuestUser();

	const [drawerAnchor, setDrawerAnchor] = useState<DrawerProps["anchor"]>(
		// context?.preferences?.toolsActionDirections ??
		"left"
	);
	const [openCameraMovementModal, setOpenCameraMovementModal] = useState<boolean>(false);
	const [newSelectedPoint, setNewSelectedPoint] = useState<SelectedPointInterface | any | null>(null);

	const [isCompareMode, setIsCompareMode] = useState<boolean>(false);
	const setDrawerAnchorHander = (value: DrawerProps["anchor"] | undefined) => {
		setDrawerAnchor(value);
		const db = getDatabase();
		const settingsRef = ref(db, `users/${context.user?.uid}/settings`);
		update(settingsRef, { toolsActionDirections: value });
	};

	const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const closeHandler = () => {
		setShowPopup(false);
	};
	const [downloadSaveLoading, setDownloadSaveLoading] = useState(false);

	const [isUserHasIncreaseResolutionCredits, setIsUserHasIncreaseResolutionCredits] = useState(false);
	const [showIncreaseResolutionPopUp, setShowIncreaseResolutionPopUp] = useState<boolean>(false);
	// const numberOfChanges = localStorage.getItem("numberOfChanges") || 0;
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const openUploadPopup = searchParams.get("openUploadPopup") === "true";

	// useEffect(() => {
	// 	if (numberOfChanges === "3") {
	// 		// setShowPopup(true);
	// 	}
	// }, [numberOfChanges]);

	useEffect(() => {
		if (openUploadPopup) {
			if (!isGuestUser()) {
				context.setUploadState(true, undefined);
			} else {
				context.setAuthDialogSource("Upload image");
				context.setAuthDialogState(true);
			}
		}
	}, []);

	useEffect(() => {
		if (!isGuestUser()) {
			if (context.authDialogSource === "Upload image") {
				context.setAuthDialogSource("");
				context.setAuthDialogState(false);
				context.setUploadState(true, undefined);
			}
		}
	}, [context.user, context.user?.updated]);

	const doDownloadVideo = async (url?: string) => {
		const videoSrc = url ?? selectedVideo ?? "";
		const videoDstType = "mp4";
		await startDownload(videoSrc, "download_video", "", undefined, true);
	};

	const handleSelectedPointChange = (value: any) => {
		setNewSelectedPoint(value);

		if (isCameraMovementTabSelected && cameraMotionVideo && value?.objectType !== BriaObjectType.camera) {
			setOpenCameraMovementModal(true);
		} else {
			setSelectedPoint(value);
			setNewSelectedPoint(null);
		}
	};

	const handleCameraModalContinue = () => {
		setSelectedPoint(newSelectedPoint);
		setNewSelectedPoint(null);
		setOpenCameraMovementModal(false);
		resetCameraMotionVideo();
	};
	const [imageOnLoadError, setImageOnLoadError] = useState<boolean>(false);
	const setImageOnLoadErrorOn = () => {
		setImageOnLoadError(true);
	};

	const children = [
		<ToolsImage
			cropOnlyImageUrl={cropOnlyImageUrl}
			lockMasks={lockMasks}
			expandBackgroundTabSelected={expandBackgroundTabSelected}
			objectsTooltipPosition={objectsTooltipPosition}
			showObjectsFollowUpTooltip={showObjectsFollowUpTooltip}
			createCroppedImage={createCroppedImage}
			minZoom={minZoom}
			maxZoom={maxZoom}
			draftCroppedArea={draftCroppedArea}
			setDownloadSaveLoading={setDownloadSaveLoading}
			downloadSaveLoading={downloadSaveLoading}
			showIncreaseResolutionPopUp={showIncreaseResolutionPopUp}
			setShowIncreaseResolutionPopUp={setShowIncreaseResolutionPopUp}
			isUserHasIncreaseResolutionCredits={isUserHasIncreaseResolutionCredits}
			setIsUserHasIncreaseResolutionCredits={setIsUserHasIncreaseResolutionCredits}
			draftCropName={draftCropName}
			imageOnLoadError={imageOnLoadError}
			setImageOnLoadError={setImageOnLoadError}
			cropWidth={cropWidth}
			cropHeight={cropHeight}
			zoomPersantage={zoomPersantage}
			naturalSize={naturalSize}
			setCropWidth={setCropWidth}
			setCropHeight={setCropHeight}
			setZoomPersantage={setZoomPersantage}
			setNaturalSize={setNaturalSize}
			croppedImage={croppedImage}
			setCroppedImage={setCroppedImage}
			setCropImageUrl={setCropImageUrl}
			cropImageUrl={cropImageUrl}
			previewImage={previewImage}
			setPreviewImage={setPreviewImage}
			draftRotation={draftRotation}
			draftCroppedAreaPercentage={draftCroppedAreaPercentage}
			draftCroppedAreaPixels={draftCroppedAreaPixels}
			setDraftCroppedAreaPixels={setDraftCroppedAreaPixels}
			setDraftCroppedAreaPercentage={setDraftCroppedAreaPercentage}
			setDraftRotation={setDraftRotation}
			onResetActions={() => handleResetActions(isFashionMenTabSelected)}
			canUndo={canUndo}
			canRedo={canRedo}
			onRedo={onRedo}
			onUndo={onUndo}
			canCompare={canCompare}
			isCompareMode={isCompareMode}
			setIsCompareMode={setIsCompareMode}
			key="tools-image-key"
			isUncropApplied={isUncropApplied}
			hideImageUrlLoader={hideImageUrlLoader}
			setDraftZoom={setDraftZoom}
			draftZoom={draftZoom}
			activeCropSizeBtn={activeCropSizebtn}
			setActiveCropSizeBtn={setActiveCropSizeBtn}
			rotation={rotation}
			setCropName={setCropName}
			setRotation={setRotation}
			setCroppedAreaPixels={setCroppedAreaPixels}
			croppedAreaPixels={croppedAreaPixels}
			setCroppedAreaPercentage={setCroppedAreaPercentage}
			croppedAreaPercentage={croppedAreaPercentage}
			getProcessingOperation={getProcessingOperation}
			originalImageDimensions={originalImageDimensions}
			resetPointsOfInterests={resetPointsOfInterests}
			setPointOfInterests={setPointOfInterests}
			textOnImage={textOnImage}
			onObjectHover={onObjectHover}
			onChangeClickedIncreaseResolutionButton={onChangeClickedIncreaseResolutionButton}
			clickedIncreaseResButton={clickedIncreaseResButton}
			clearObjectHover={clearObjectHover}
			objectOnHover={objectOnHover}
			isTextEditorTabSelected={isTextEditorTabSelected}
			increaseResolution={increaseResolution}
			isLogoTabSelected={isLogoTabSelected}
			isObjectsTabSelected={isObjectsTabSelected}
			objectsTabCanvasRef={objectsTabCanvasRef}
			brushConfig={brushConfig}
			isObjectsAutomaticTabSelected={isObjectsAutomaticTabSelected}
			isObjectsManualBrushTabSelected={isObjectsManualBrushTabSelected}
			loading={loading}
			imageRef={imageRef}
			disableDownloadButton={disableDownloadButton}
			originalImage={originalImage}
			originalImageWithWatermark={originalImageWithWatermark}
			proccessing={proccessing}
			imageUrl={selectedImageUrl}
			backgroundString={backgroundString}
			imageVH={visual_hash}
			videoUrl={selectedVideo}
			drawerDirection={drawerAnchor}
			selectedPoint={selectedPoint}
			pointOfInterests={pointOfInterests}
			setSelectedPoint={handleSelectedPointChange}
			generatingImageMetric={generatingImageMetric}
			fullscreen={fullscreenMode}
			onFullscreenChange={onFullscreenChange}
			setImageScore={setImageScore}
			layersURL={layersURL}
			fetchCameraVideo={fetchCameraVideo}
			cameraMotionVideo={cameraMotionVideo}
			openCreateVideoPopup={openCreateVideoPopup}
			setOpenCreateVideoPopup={setOpenCreateVideoPopup}
			openVideoReadyPopup={openVideoReadyPopup}
			setOpenVideoReadyPopup={setOpenVideoReadyPopup}
			isImageMode={isImageMode}
			cameraVideoStatus={videoStatus}
			isCameraMovementTabSelected={isCameraMovementTabSelected}
			isImageLoading={isImageLoading}
			setIsImageLoading={setIsImageLoading}
			doDownloadVideo={doDownloadVideo}
			ChangeImageOnErrorOnLoad={setImageOnLoadErrorOn}
			croppedArea={croppedArea}
			cropImageFunction={cropImageFunction}
			CropTabselected={CropTabselected}
			cropZoom={cropZoom}
			setCropZoom={setCropZoom}
			cropName={cropName}
		/>,
		<ToolsAction
			imageTransparencyPercentage={imageTransparencyPercentage}
			rotation={rotation}
			applySketchImageStyle={applySketchImageStyle}
			setShowCropSaveFirstTime={setShowCropSaveFirstTime}
			setSelectedPoint={setSelectedPoint}
			showCropSavePopUp={showCropSavePopUp}
			setShowCropSavePopUp={setShowCropSavePopUp}
			setMainObjectData={setMainObjectData}
			mainObjectData={mainObjectData}
			lockMasks={lockMasks}
			setLockMasks={setLockMasks}
			expandBackgroundTabSelected={expandBackgroundTabSelected}
			setExpandBackgroundTabSelected={setExpandBackgroundTabSelected}
			setObjectsTooltipPosition={setObjectsTooltipPosition}
			setShowObjectsFollowUpTooltip={setShowObjectsFollowUpTooltip}
			imageElement={imageElement}
			onRemoveObjectByCoordinates={onRemoveObjectByCoordinates}
			objectsPanopticImage={objectsPanopticImage}
			objectsMasks={objectsMasks}
			naturalSize={naturalSize}
			cropImageUrl={cropImageUrl}
			setCropImageUrl={setCropImageUrl}
			expandUncropThumbnails={expandUncropThumbnails}
			resetCrop={resetCrop}
			setGeneralLoading={setGeneralLoading}
			createCroppedImage={createCroppedImage}
			disableObjectsTab={disableObjectsTab}
			cropTabSeleced={CropTabselected}
			isUncropApplied={isUncropApplied}
			minZoom={minZoom}
			maxZoom={maxZoom}
			setDraftCroppedArea={setDraftCroppedArea}
			draftCropName={draftCropName}
			setDraftCropName={setDraftCropName}
			croppedImage={croppedImage}
			selectedTab={selectedTab}
			ethicalError={ethicalError}
			setEthicalError={setEthicalError}
			setSelectedTab={setSelectedTab}
			croppedArea={croppedArea}
			previewImage={previewImage}
			draftRotation={draftRotation}
			draftCroppedAreaPercentage={draftCroppedAreaPercentage}
			draftCroppedAreaPixels={draftCroppedAreaPixels}
			resetPointsOfInterests={resetPointsOfInterests}
			setCroppedAreaPixels={setCroppedAreaPixels}
			setCroppedAreaPercentage={setCroppedAreaPercentage}
			setDraftCroppedAreaPixels={setDraftCroppedAreaPixels}
			setDraftCroppedAreaPercentage={setDraftCroppedAreaPercentage}
			setRotation={setRotation}
			resetUncrop={resetUncrop}
			croppedAreaPixels={croppedAreaPixels}
			replaceBgThumbsLoading={replaceBgThumbsLoading}
			uncropThumbsLoading={uncropThumbsLoading}
			setDraftZoom={setDraftZoom}
			draftZoom={draftZoom}
			activeCropPreset={activeCropPreset}
			setActiveCropPreset={setActiveCropPreset}
			activeCropSizebtn={activeCropSizebtn}
			setActiveCropSizeBtn={setActiveCropSizeBtn}
			originalImageDimensions={originalImageDimensions}
			setReplaceBgThumbsLoading={setReplaceBgThumbsLoading}
			setUncropThumbsLoading={setUncropThumbsLoading}
			presentersStyleLoading={presentersStyleLoading}
			removeObjectMutationLoading={removeObjectMutationLoading}
			backgroundOracle={backgroundOracle}
			replaceBgLoading={replaceBgLoading}
			uncropLoading={uncropLoading}
			setIsReplaceBackgroundTabSelected={setIsReplaceBackgroundTabSelected}
			onObjectHover={onObjectHover}
			clearObjectHover={clearObjectHover}
			objectOnHover={objectOnHover}
			isReplaceBackgroundTabSelected={isReplaceBackgroundTabSelected}
			onReplaceBgThumbnailClick={onReplaceBgThumbnailClick}
			onUncropThumbnailClick={onUncropThumbnailClick}
			onImageReplaceBackground={onImageReplaceBackground}
			onDoPresentersStyle={onDoPresentersStyle}
			onPresentersStyleThumbnailClick={onPresentersStyleThumbnailClick}
			presentersStyleThumbnails={presentersStyleThumbnails}
			presentersStyleText={presentersStyleText}
			onUncrop={onUncrop}
			uncropThumbnails={uncropThumbnails}
			replaceBgThumbnails={replaceBgThumbnails}
			replaceBgText={replaceBgText}
			uncropValues={uncropValues}
			originalImage={originalImage}
			visualHash={visual_hash}
			faceDevInfo={faceDevInfo}
			isImageLoading={isImageLoading}
			processing={proccessing}
			cameraVideoStatus={videoStatus}
			imageVH={visual_hash}
			openCameraMovementModal={openCameraMovementModal}
			setOpenCameraMovementModal={setOpenCameraMovementModal}
			doDownloadVideo={doDownloadVideo}
			handleCameraModalContinue={handleCameraModalContinue}
			resetCameraMotionVideo={resetCameraMotionVideo}
			backgroundString={backgroundString}
			toggleBackgroundBlur={toggleBackgroundBlur}
			backgroundBlur={backgroundBlur}
			toggleBackground={toggleBackground}
			disableRemoveBGSwitch={disableRemoveBGSwitch}
			accessoriesToRemove={accessoriesToRemove}
			disabledTabs={disabledTabs}
			expressionsSliders={expressionsSliders}
			objectsList={objectsList}
			pointOfInterests={pointOfInterests}
			key={`tools-action-key-${selectedPoint?.index}`}
			imageUrl={selectedImageUrl}
			imageRef={imageRef}
			disabled={proccessing}
			selectedPoint={selectedPoint}
			selectedEthnicity={selectedEthnicity}
			selectedEthnicityValue={selectedEthnicityValue}
			onRemoveObject={onRemoveObject}
			onShowObject={onShowObject}
			onChangeEthnicity={onChangeEthnicity}
			personalizerValues={personalizerValues}
			onChangePersonalizer={onChangePersonalizer}
			onAddSemanticCombination={onAddSemanticCombination}
			toDisplayThumbnails={toDisplayThumbnails}
			onThumbnailClick={onThumbnailClick}
			drawerAnchor={drawerAnchor}
			fullscreen={fullscreenMode}
			selectedVideo={selectedVideo}
			setSelectedVideo={setSelectedVideo}
			handleResetActions={handleResetActions}
			setShouldResetCheckboxes={setShouldResetCheckboxes}
			shouldResetCheckboxes={shouldResetCheckboxes}
			configLoading={toolsLoading}
			createExtremeExpression={createExtremeExpression}
			createExtremeExpressionMutationLoading={createExtremeExpressionMutationLoading}
			createExtremeExpressionMutationError={createExtremeExpressionMutationError}
			selectedExpressionValue={selectedExpressionValue}
			selectedExpressionName={selectedExpressionName}
			resetExtremeExpression={resetExtremeExpression}
			resetLastExtremeExpression={resetLastExtremeExpression}
			isLoadingExpression={isLoadingExpression}
			toolConfigurations={toolsConfigTabs}
			currentSliders={toolsConfigSliders}
			toolsConfigThumbnails={toolsConfigThumbnails}
			toolsConfigSuggestions={toolsConfigSuggestions}
			fetchCameraVideo={fetchCameraVideo}
			cameraMotionVideo={cameraMotionVideo}
			preCameraMovementActionsConfig={preCameraMovementActionsConfig}
			setPreCameraMovementActionsConfig={setPreCameraMovementActionsConfig}
			setIsImageMode={setIsImageMode}
			setIsCameraMovementTabSelected={setIsCameraMovementTabSelected}
			setIsTextEditorTabSelected={setIsTextEditorTabSelected}
			setIsLogoTabSelected={setIsLogoTabSelected}
			setIsObjectsTabSelected={setIsObjectsTabSelected}
			isObjectsTabSelected={isObjectsTabSelected}
			objectsTabCanvasRef={objectsTabCanvasRef}
			resetManualBrushTab={resetManualBrushTab}
			setResetManualBrushTab={setResetManualBrushTab}
			setIsObjectsAutomaticTabSelected={setIsObjectsAutomaticTabSelected}
			isObjectsManualBrushTabSelected={isObjectsManualBrushTabSelected}
			setIsObjectsManualBrushTabSelected={setIsObjectsManualBrushTabSelected}
			brushConfig={brushConfig}
			setBrushConfig={setBrushConfig}
			isCameraMovementTabSelected={isCameraMovementTabSelected}
			setIsFashionMenSelected={setIsFashionSelected}
			setIsImageLoading={setIsImageLoading}
			openVideoReadyPopup={openVideoReadyPopup}
			setOpenVideoReadyPopup={setOpenVideoReadyPopup}
			setSelectedImageUrl={setSelectedImageUrl}
			setTextOnImage={setTextOnImage}
			OnloadError={imageOnLoadError}
			cropImageFunction={cropImageFunction}
			setCropTabSeleced={setCropTabSeleced}
			setCropName={setCropName}
			onRestoreAllObjects={onRestoreAllObjects}
		>
			<Grid>
				<ActionIcons
					originalImage={originalImage}
					cropImageUrl={cropImageUrl}
					croppedImage={croppedImage}
					setShowCropSaveFirstTime={setShowCropSaveFirstTime}
					showCropSaveFirstTime={showCropSaveFirstTime}
					setShowCropSavePopUp={setShowCropSavePopUp}
					imageDetailsLoading={imageDetailsLoading}
					disableObjectsTab={disableObjectsTab}
					isLoadingPersonInfo={isLoadingPersonInfo}
					isLoadingObjectsInfo={isLoadingObjectsInfo}
					isLoadingCropConfig={isLoadingCropConfig}
					pointOfInterests={pointOfInterests}
					canUndo={canUndo}
					setIsUncropApplied={setIsUncropApplied}
					canRedo={canRedo}
					onRedo={onRedo}
					onUndo={onUndo}
					isCompareMode={isCompareMode}
					setIsCompareMode={setIsCompareMode}
					layersURL={layersURL}
					selectedVideo={selectedVideo}
					selectedImageUrl={selectedImageUrl}
					selectedPoint={selectedPoint}
					setSelectedPoint={handleSelectedPointChange}
					setSelectedVideo={setSelectedVideo}
					onResetActions={() => handleResetActions(isFashionMenTabSelected)}
					drawerAnchor={drawerAnchor}
					setDrawerAnchor={setDrawerAnchorHander}
					onOpenInfoDialog={() => setOpenInfoDialog(true)}
					onLoadError={imageOnLoadError}
				/>
			</Grid>
		</ToolsAction>,
	];

	const contextValue = {
		generalLoading: proccessing,
		setGeneralLoading,
		setSelectedImageUrl,
		downloadBtnProps,
		selectedImageUrl,
		moodState,
		undoRedoSetter,
		updateResetActions,
		getPlaygroundUserMoods,
		setOperationPlayGroundError,
	};
	const classes = useStyles();
	const { isIframe } = IsIframe();

	return (
		<ImageEditorContext.Provider value={contextValue}>
			{/*<BriaConfirmationDialog*/}
			{/*	title={"Upgrade to Pro and get 30 days free"}*/}
			{/*	description={*/}
			{/*		<Typography style={{ marginBottom: 100 }}>*/}
			{/*			This particular feature requires a subscription upgrade.*/}
			{/*			<br></br>*/}
			{/*			<Typography style={{ marginTop: "0.5rem" }}>*/}
			{/*				Upgrade and get a one-month free coupon that will automatically apply to your account.*/}
			{/*			</Typography>*/}
			{/*		</Typography>*/}
			{/*	}*/}
			{/*	width="600px"*/}
			{/*	buttonExtraStyle={{ width: 340, bottom: 30 }}*/}
			{/*	buttonText={"Upgrade"}*/}
			{/*	showCancelButton={true}*/}
			{/*	cancelButtonText={"Not yet"}*/}
			{/*	onButtonClick={() => {*/}
			{/*		Analytics.logEvent(ANALYTICS_EVENTS.UPGRADE_POPUP_UPGRADE_BTN_CLICK);*/}
			{/*		window.open(*/}
			{/*			`${RouterConstants.WEB_PLANS.path}?subscription=pro&coupon=1MonthFreeUpgrade`,*/}
			{/*			"_blank"*/}
			{/*		);*/}
			{/*		context.setDisplayUpgradeWebSubscriptionPopup(false);*/}
			{/*	}}*/}
			{/*	onCloseModal={() => {*/}
			{/*		context.setDisplayUpgradeWebSubscriptionPopup(false);*/}
			{/*	}}*/}
			{/*	shouldOpen={context.displayUpgradeWebSubscriptionPopup}*/}
			{/*/>*/}
			<BriaConfirmationDialog
				title={"Image resized to original resolution"}
				description={
					<Typography style={{ marginBottom: 100 }}>
						Before downloading the image, press the 'Increase Resolution' button to access the higher
						resolution.
					</Typography>
				}
				width="600px"
				buttonExtraStyle={{ width: 150, bottom: 30 }}
				buttonText={"Got It"}
				showDontShowAgainCheckbox={true}
				onButtonClick={(dontShowAgainFlag: boolean) => {
					context.user?.updateShowResolutionBeforeDownloadDialogSettings(dontShowAgainFlag, isIframe());
					setShowResolutionBeforeDownloadDialog(false);
				}}
				onCloseModal={(dontShowAgainFlag: boolean) => {
					context.user?.updateShowResolutionBeforeDownloadDialogSettings(dontShowAgainFlag, isIframe());
					setShowResolutionBeforeDownloadDialog(false);
				}}
				shouldOpen={
					!context.user?.getShowResolutionBeforeDownloadDialogSettings(isIframe()) &&
					showResolutionBeforeDownloadDialog
				}
			/>
			{showPopup && <RegisterDetails onClick={closeHandler} />}
			<Box>
				<ToolHeader
					croppedAreaPixels={croppedAreaPixels}
					croppedArea={croppedArea}
					rotation={rotation}
					isUncropApplied={isUncropApplied}
					downloadSaveLoading={downloadSaveLoading}
					setDownloadSaveLoading={setDownloadSaveLoading}
					imageOnLoadError={imageOnLoadError}
					isUserHasIncreaseResolutionCredits={isUserHasIncreaseResolutionCredits}
					setShowIncreaseResolutionPopUp={setShowIncreaseResolutionPopUp}
					imageRef={imageRef}
					imageName={visual_hash}
					isImageEditor={true}
					pipelineSettings={pipelineSettings}
					handleChangePipelineSettings={handleChangePipelineSettings}
					imageCropped={croppedImage}
					cameraVideoStatus={videoStatus}
					clickedIncreaseResButton={clickedIncreaseResButton}
					isImageLoading={isImageLoading}
					setIsImageLoading={(value) => {
						setIsImageLoading(value);
					}}
					imageUrl={selectedImageUrl}
				/>
			</Box>
			<Box
				className={classes?.editorOuterContainer}
				width="100%"
				height={"calc(100vh - 100px)"}
				position="relative"
			>
				<SplitPane
					split={drawerAnchor !== "bottom" ? "vertical" : "horizontal"}
					key={drawerAnchor === "left" ? "first" : "second"}
					size={drawerAnchor === "bottom" ? 395 : 600}
					primary={drawerAnchor === "left" ? "first" : "second"}
					allowResize={drawerAnchor !== "bottom"}
					className={classes?.editorContenter}
					style={{
						overflow: "unset",
						position: "unset",
					}}
				>
					{drawerAnchor === "left" ? children.reverse() : children}
				</SplitPane>
				<FeedbackChat />
			</Box>

			<Dialog onClose={() => setOpenInfoDialog(false)} open={openInfoDialog}>
				<DialogTitle id="simple-dialog-title">{t<string>("info")}</DialogTitle>
				<Box
					style={{
						height: 400,
						width: 600,
						overflow: "scroll",
					}}
				>
					<Divider />
					<ReactJson src={imageDetails?.vdrObject ?? {}} style={{ margin: 10 }} />
				</Box>
			</Dialog>
		</ImageEditorContext.Provider>
	);
}

interface StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	editorContenter: {
		maxWidth: 1872,
		width: "100%",
		"@media (min-width:1920px)": {
			maxWidth: "100%",
		},
	},
	editorOuterContainer: {
		display: "flex",
		justifyContent: "center",
		paddingInline: "1.7vw",
		backgroundImage: `url(${DotBackground})`,
		"@media (min-width:1920px)": {
			paddingInline: 32,
		},
	},
	chatCirculeContainer: {
		backgroundColor: "#FFFFFF",
		borderRadius: 100,
		width: 64,
		height: 64,
		boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08);",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#E7E7E7",
			boxShadow: "0px 2px 16px rgba(36, 0, 88, 0.08)",
		},
		"&:focus": {
			backgroundColor: "#F1EDF7",
			boxShadow: "0px 2px 16px rgba(36, 0, 88, 0.08)",
		},
	},
	chatContainer: {
		position: "absolute",
		bottom: 8,
		right: 46,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		zIndex: 1000,
		cursor: "pointer",
	},
	chatTextStyle: {
		marginTop: 8,
		color: "#1A0638",
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "16px",
	},
	scaleContainer: {
		position: "absolute",
		bottom: 23,
		left: 21,
		zIndex: 10,
	},
	scaleThisFeature: {
		fontSize: 14,
		fontWeight: 400,
		color: "#000000",
		lineHeight: "17px",
	},
	link: {
		fontSize: 14,
		// fontWeight: theme.typography.fontWeightMedium,
		cursor: "pointer",
		textDecoration: "underline",
		"&:hover": {
			fontWeight: theme.typography.fontWeightBold,
		},
	},
}));
export default ImageEditor;
