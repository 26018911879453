import { LanguagesEnum, PagesEnum, ToolConfigTabsTypes } from "../Models/ToolsConfig";

class IframeConstants {
	public static readonly IFRAME_LANGUAGES_MAP: { [key: string]: LanguagesEnum } = {
		"1": LanguagesEnum.English,
		"2": LanguagesEnum.Japanese,
	};

	public static readonly IFRAME_PAGES_MAP: { [key: string]: PagesEnum } = {
		"1": PagesEnum.Gallery,
		"2": PagesEnum.Playground,
		"3": PagesEnum.Assets,
		"4": PagesEnum.Campaign,
	};

	public static readonly IFRAME_FEATURES: { [key: string]: ToolConfigTabsTypes } = {
		"1": ToolConfigTabsTypes.Expression,
		"2": ToolConfigTabsTypes.Diversity,
		"3": ToolConfigTabsTypes.Appearance,
		"4": ToolConfigTabsTypes.CameraMovement,
		"5": ToolConfigTabsTypes.Logo,
		"6": ToolConfigTabsTypes.FineTuning,
		"7": ToolConfigTabsTypes.Mood,
		"8": ToolConfigTabsTypes.Remove,
		"9": ToolConfigTabsTypes.Blur,
		"10": ToolConfigTabsTypes.Paint,
		"11": ToolConfigTabsTypes.Replace,
		"12": ToolConfigTabsTypes.background,
		"13": ToolConfigTabsTypes.Objects,
		"14": ToolConfigTabsTypes.TextEditor,
		"15": ToolConfigTabsTypes.crop,
		"16": ToolConfigTabsTypes.PsdDownload,
		"17": ToolConfigTabsTypes.SuperResolution,
		"18": ToolConfigTabsTypes.ExpandBackground,
		"19": ToolConfigTabsTypes.ImageStyle,
		"20": ToolConfigTabsTypes.createCampaign,
		"21": ToolConfigTabsTypes.AutoObjIdentification,
	};
}

export enum IframePostMessageTypes {
	ImageSave = "Bria_ImageSave",
	iframePageNavigation = "Bria_IframePageNavigation",
	ImageSavePSD = "Bria_ImageSavePSD",
	ImageDownload = "Bria_ImageDownload",
	ApplyChange = "Bria_ApplyChange",
	InitialLoadComplete = "Bria_InitialLoadComplete",
	GalleryImageOpen = "Bria_GalleryImageOpen",
	CampaignOpen = "Bria_CampaignOpen",
	GalleryOpen = "Bria_GalleryOpen",
	AssetsOpen = "Bria_AssetsOpen",
	Auth = "Bria_Auth",
	AuthInit = "Bria_AuthInit",
	CloseClicked = "Bria_CloseClicked",
}

export enum IframeApplyChangePostMessageTypes {
	Expression = "expression",
	Diversity = "diversity",
	RemoveObject = "remove_object",
	IncreaseResolution = "increase_resolution",
	RemoveBackground = "remove_background",
	BlurBackground = "Blur_background",
	ImageStyle = "Image_Style",
	Mood = "mood",
	CameraMotion = "camera_motion",
	ReplaceBackground = "replace_background",
	Uncrop = "uncrop",
	PresentersStyle = "presenters_style",
}

export default IframeConstants;
