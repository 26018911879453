import { Box, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { GENERAL_ORG_ID } from "../../../../../Constants";
import Context from "../../../../../Context/Context";
import { IsIframe } from "../../../../../Helpers/iframe";
import BriaAPI from "../../../../../sdk/resources/briaAPI";
import { ApiActions } from "../../../../../sdk/resources/briaAPIConstants";
import { filterAllowedColor } from "../../../Mood/data/colors";
import { defaultSlidersState } from "../../../Mood/data/sliders";
import { BrandContext } from "../../BrandPage/BrandPage";
import {
	GridImgProps,
	mainImgInfo,
	SELECTED_IMG_INFO,
} from "../../BrandPage/ImgViewer/ImgViewer";
import {
	SELECTED_PREDEFINED_MOOD,
	SELECTED_PREDEFINED_MOOD_URL,
	XMP_FILE_IMAGE_RES,
} from "../../ColorPallete/ColorPallete";

export interface ISingleMood {
	name: string;
	moodId: string;
	id?: string;
	imageRes: string;
	onClick: () => void;
	moodParameters?: any;
	selfApply?: boolean;
	loading?: boolean;
}

const getLowerCase = (elem: string = "") => elem.toLowerCase();

export const validateColorMixer = (preColorMixer: any[] = []) =>
	preColorMixer
		.filter((colorObj: any) => colorObj.color)
		.map((colorObj: any) => ({
			...colorObj,
			color: getLowerCase(colorObj.color),
		}));

export const validateMoodParameters = (moodParams: any) => ({
	...defaultSlidersState,
	...moodParams,
	colorMixer: validateColorMixer(
		moodParams?.colorMixer || moodParams?.color_mixer
	),
});
export default function SingleMood({
	name,
	imageRes,
	onClick = () => {},
	moodParameters = {},
	selfApply,
	moodId,
	loading,
	id,
}: ISingleMood) {
	const classes = useStyles();
	const { generalState, updateGeneralState } = useContext(BrandContext);
	const [processedImgRes, setProcessedImgRes] = useState("");
	const [isProcessing, setIsProcessing] = useState("");

	const moodPassedId = id || moodId;

	const selectedPredefinedMood = (generalState as any)?.[
		SELECTED_PREDEFINED_MOOD
	];
	const selectedPredefinedMoodUrl = (generalState as any)?.[
		SELECTED_PREDEFINED_MOOD_URL
	];
	const processedXmpFileImage = (generalState as any)?.[XMP_FILE_IMAGE_RES];

	const isSelectedMood = moodPassedId === selectedPredefinedMood;

	const borderClass =
		clsx({ [classes.filledBorder]: isSelectedMood }) ||
		(!loading && classes.emptyBorder);

	const selectedImg: GridImgProps =
		(generalState as any)?.[SELECTED_IMG_INFO] || mainImgInfo;
	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const instance = BriaAPI.getInstance(selectedImg.vhash);

	const handleXmpValuesCall = async (): Promise<string | undefined> => {
		try {
			const { isIframe } = IsIframe();
			BriaAPI.resetInstance(instance.visualId);
			const res =
				(await (instance as any).callApi(
					ApiActions.APPLY_MOOD,
					{
						changes: {
							...moodParameters,
							...{
								color_mixer: validateColorMixer(
									moodParameters.color_mixer
								),
							},
						},
					},
					true,
					organizationUid
				)) || {};
			const {
				data: { image_res },
			} = res;
			// if there's no selected moods auto-select xmp generated thumbnail
			if (!selectedPredefinedMoodUrl)
				updateGeneralState("", "", {
					[SELECTED_PREDEFINED_MOOD_URL]: image_res,
					[XMP_FILE_IMAGE_RES]: {
						src: image_res,
						vhash: selectedImg.vhash,
					},
				});
			setProcessedImgRes(image_res);
			return image_res || "";
		} catch (error) {}
	};

	useEffect(() => {
		if (selfApply) {
			if (processedXmpFileImage?.vhash !== selectedImg.vhash)
				handleXmpValuesCall();
			else setProcessedImgRes(processedXmpFileImage.src);
		} // some moods will only have parameters without imgSrc => fetch imgSrc by apply brand request
	}, [selectedImg.vhash, processedXmpFileImage?.vhash]);

	const imgSrc = imageRes || processedImgRes;

	return (
		<Box className={classes.singleMoodWrapper} onClick={onClick}>
			<Box className={clsx(classes.singleMood, borderClass)}>
				{!!imgSrc && !loading && (
					<img className={classes.img} src={imgSrc} alt=" " />
				)}
			</Box>
			<Typography>{name}</Typography>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	singleMood: {
		aspectRatio: "1/1",
		borderRadius: 4,
		backgroundColor: "#ddd",
		overflow: "hidden",
		cursor: "pointer",
		maxWidth: "100%",
		maxHeight: "90%",
		width: "100%",
		height: "100%",
	},
	emptyBorder: {
		border: `4px solid transparent`,
		backgroundColor: "transparent",
	},
	filledBorder: {
		border: `4px solid ${theme.palette.primary.main}`,
		borderRadius: 8,
	},
	singleMoodWrapper: {
		overflow: "hidden",
		maxWidth: "165px",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		"& p": {
			fontSize: 12,
			textAlign: "center",
			color: "#495057",
			marginTop: 7,
		},
	},
	img: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		borderRadius: 5,
	},
}));
