import { makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ErrorIcon from "@material-ui/icons/Error";
import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import Context from "../../Context/Context";
import { IsIframe } from "../../Helpers/iframe";
import { ThumbnailImage, ToolConfigTabsTypes } from "../../Models/ToolsConfig";
import BriaAPI from "../../sdk/resources/briaAPI";
import LoadingAnimation from "../Common/LoadingAnimation/LoadingAnimation";
import ShowAt from "../Common/Wrappers/ShowAt";
import Personalizer from "../Tools/Personalizer";
import { ToolsBackGroundBlurMode, ToolsBackGroundMode } from "../Tools/utilities";
import BriaButton, { BUTTONTYPES } from "./BriaButton";
import BriaSwitch from "./BriaSwitch";
import CustomizedTooltip from "./Tooltip";
import { BackgroundReplacmentTexts, getRandomText } from "../../Constants/RandomTextGenerator";
import SurpriseMeButton from "../shared/SurpriseButton";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { DISABLE_ENTER_LANGUAGES } from "../../Constants";
import BriaCheckbox from "./BriaCheckbox";
import Info from "../../assets/svgs/info-circle-purple.svg";
import Tooltip from "@material-ui/core/Tooltip";

interface StyleProps {
	expanded: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: (props) => ({
		display: props.expanded ? "block" : "flex",
		justifyContent: "flex-start",
		height: "100%",

		flexDirection: "column",
		paddingRight: 19,
	}),
	textField: {
		"& .MuiInput-underline, ": {
			marginTop: 20,

			"&::placeholder": {
				opacity: 1,
			},
		},

		"& .MuiInputBase-root": {
			borderRadius: 8,
			// padding: "19px 7px 84px 17px !important",
			// border: "1px solid rgba(91, 91, 91, 0.5) !important",
			boxShadow: "0 0 0 1px rgba(91, 91, 91, 0.5)",

			"&:hover": {
				// border: " 1px solid #1A0638 !important",
				boxShadow: "0 0 0 1px #1A0638",
			},
			"&.Mui-focused": {
				// border: " 3px solid #5300C9 !important",
				boxShadow: `0 0 0 3px ${theme.palette.primary.main} !important`,
			},
		},
		"& .MuiInput-underline:before ": {
			border: "none !important",
		},

		"& .MuiInputBase-input": {
			color: "#5B5B5B !important",
		},
		marginTop: "8px",
	},
	errorTextField: {
		"& .MuiInputBase-root": {
			boxShadow: "none",
			"&:hover": {
				boxShadow: "none",
			},
			"&.Mui-focused": {
				boxShadow: `none !important`,
			},
		},
	},
	hide: {
		display: "none",
	},
	switchRoot: {
		marginRight: 0,
	},
	switchBase: {
		"&.Mui-checked + .MuiSwitch-track": {
			backgroundColor: theme.palette.cards.main,
		},
	},
	switchTrack: {
		"&.Mui-checked + .MuiSwitch-track": {
			backgroundColor: theme.palette.primary.main,
		},
	},
	supriseButtonStyle: {
		color: "#495057",
		fontSize: 14,
		marginLeft: 8,
		fontWeight: 600,
		width: 138,
		height: 40,
		"&:hover": {
			color: "#495057",
			backgroundColor: "#E7E7E7",
			opacity: "1 !important",
			borderRadius: 4,
		},
		"&:focus": {
			color: "#495057 !important",
		},
	},
	buttonsContainer: {
		marginTop: 20,
		marginBottom: "4px",
		display: "flex",
		justifyContent: "flex-end",
	},
	switchLable: {
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		textShadow: "0px 0px 0px #495057",
	},
	errorLabel: {
		color: "#DC3545",
		fontSize: "12px",
		fontWeight: 600,
		lineHeight: " 18px ",
	},
	switchBox: {
		paddingBottom: 34,
		paddingTop: 35,
		borderBottom: "1px solid #E9ECEF",
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	fastVersionCheckboxContainer: {
		marginTop: "8px",
	},
	fastVersionCheckbox: {
		display: "inline-block",
		padding: 0,
		"& span": {
			marginLeft: 0,
		},
	},
	fastVersionCheckboxLabel: {
		display: "inline-block",
		fontSize: "14px",
		fontWeight: 500,
		lineHeight: "17px",
		color: "#1A0638",
		cursor: "pointer",
	},
	tooltipStyle: {
		backgroundColor: "#FFFFFF",
		boxShadow: "0px 2px 8px rgba(36, 0, 88, 0.08)",
		borderRadius: 8,
		color: "#5B5B5B",
		fontSize: 14,
	},
	tooltipArrowStyle: {
		left: "35% !important",
		"&:before": {
			backgroundColor: "#FFFFFF",
		},
	},
	tooltipIcon: {
		verticalAlign: "middle",
		marginLeft: "4px",
	},
	tooltipDesc: {
		color: "#5B5B5B",
		fontSize: "14px",
		fontWeight: 500,
		lineHeight: "20px",
		padding: "6px 8px",
	},
}));

interface IProps {
	processing: boolean;
	backgroundOracle: any;
	replaceBgLoading: boolean;
	replaceBgThumbsLoading: boolean;
	selectedPoint: any;
	visual_hash: string;
	imageRef: RefObject<HTMLImageElement>;
	setReplaceBgThumbsLoading: (value: boolean) => void;
	onReplaceBgThumbnailClick: (thumbnailImage: ThumbnailImage) => void;
	onImageReplaceBackground: (
		fast: boolean,
		bg_prompt?: string,
		prefix?: string,
		num_results?: number,
		append?: boolean
	) => Promise<void>;
	originalImage: string;
	backgroundString: ToolsBackGroundMode;
	toggleBackground: (fireRequest?: boolean) => void;
	disabled: boolean;
	toggleBackgroundBlur: (fireRequest?: boolean) => void;
	backgroundBlur: ToolsBackGroundBlurMode;
	replaceBgText: { fast: boolean; text: string; force?: boolean; prefix?: string };
	replaceBgThumbnails: ThumbnailImage[];
	OnloadError: boolean;
	isEngineOverloaded?: boolean;
	originalImageDimensions: { width: number; height: number };
	ethicalError: boolean;
	setEthicalError: (value: boolean) => void;
}

const SceneBackground: React.FC<IProps> = ({
	processing,
	originalImageDimensions,
	setReplaceBgThumbsLoading,
	backgroundOracle,
	replaceBgLoading,
	replaceBgThumbsLoading,
	selectedPoint,
	visual_hash,
	imageRef,
	onReplaceBgThumbnailClick,
	onImageReplaceBackground,
	originalImage,
	toggleBackground,
	toggleBackgroundBlur,
	backgroundBlur,
	backgroundString,
	disabled,
	replaceBgText,
	replaceBgThumbnails,
	OnloadError = false,
	ethicalError,
	setEthicalError,
}) => {
	const { isIframe } = IsIframe();
	const { t } = useTranslation();
	const [retryingImageAbortController, setRetryingImageAbortController] = useState<AbortController>(
		new AbortController()
	);
	const verticalThumbConfig = [130, 200, 3, 3];
	const horizontalThumbConfig = [150, 130, 2, 3];
	const [isLoadMoreClicked, setIsLoadMoreClicked] = useState<boolean>(false);
	const [isFastVersionEnabled, setIsFastVersionEnabled] = useState<boolean>(replaceBgText.fast);
	const [thumbnailConfig, setThumbnailsConfig] = useState<number[]>(
		originalImageDimensions.height > originalImageDimensions.width ? verticalThumbConfig : horizontalThumbConfig
	);
	const [textValue, setTextValue] = useState<string>(replaceBgText.text ? replaceBgText.text : "");

	const [textRows, setTextRows] = useState<number>(4);
	const [isEngineOverloaded, setIsEngineOverloaded] = useState<boolean>(false);

	const context = useContext(Context);
	const initialContainerRef = useRef<null | HTMLDivElement>(null);
	const replaceContainerRef = useRef<null | HTMLDivElement>(null);
	const classes = useStyles({
		expanded: replaceBgLoading || replaceBgThumbnails.length !== 0,
	});

	const TooltipBtns = () => {
		return (
			<CustomizedTooltip
				arrow
				multiLine={true}
				title={<>{<div>{t("theSystemDetectedLowerQuality")}</div>}</>}
				placement="right"
			>
				<ErrorIcon
					style={{
						fontSize: 16,
						marginLeft: "5px",
						color: "#FFC107",
						marginBottom: "2.5px",
					}}
				/>
			</CustomizedTooltip>
		);
	};

	const loadingStrings = [
		t("replaceBgLoadingText1"),
		t("replaceBgLoadingText2"),
		t("replaceBgLoadingText3"),
		t("replaceBgLoadingText4"),
		t("replaceBgLoadingText6"),
		t("replaceBgLoadingText7"),
		t("replaceBgLoadingText8"),
		t("replaceBgLoadingText9"),
		t("replaceBgLoadingText10"),
		t("replaceBgLoadingText11"),
		t("replaceBgLoadingText12"),
		t("replaceBgLoadingText13"),
		t("replaceBgLoadingText14"),
		t("replaceBgLoadingText15"),
		t("replaceBgLoadingText16"),
		t("replaceBgLoadingText17"),
	];

	const callReplaceBg = async (bg_prompt: string, num_results: number, append?: boolean) => {
		setIsLoadMoreClicked(append ?? false);
		setIsEngineOverloaded(false);
		try {
			await onImageReplaceBackground(isFastVersionEnabled, bg_prompt, "", num_results, append);
		} catch (err: any) {
			if (!append && err.response && err.response.status === 512) {
				setIsEngineOverloaded(true);
			}
		}
	};

	const callImageReplaceBackground = async (value: string) => {
		retryingImageAbortController?.abort();
		setRetryingImageAbortController(new AbortController());
		await callReplaceBg(value, thumbnailConfig[3]);
		replaceContainerRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	const setThumbnailImages = async () => {
		await callImageReplaceBackground(textValue);
	};
	const handleChange = ({ target: { value } }: any) => {
		if (!value) {
			setEthicalError(false);
		}
		setTextValue(value.replace("\n", "") ?? "");
	};

	useEffect(() => {
		initialContainerRef.current?.scrollIntoView(true);
	}, [visual_hash]);

	useEffect(() => {
		setTextValue(replaceBgText.text);
		if (replaceBgText.force && replaceBgText.text) {
			callImageReplaceBackground(replaceBgText.text);
		}
	}, [replaceBgText.text, replaceBgText.prefix]);

	useEffect(() => {
		if (originalImageDimensions) {
			setThumbnailsConfig(
				originalImageDimensions.height > originalImageDimensions.width
					? verticalThumbConfig
					: horizontalThumbConfig
			);
		}
	}, [originalImageDimensions, originalImageDimensions.width, originalImageDimensions.height]);

	const handleRemoveBtnOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked && backgroundBlur === ToolsBackGroundBlurMode.Blur) {
			toggleBackgroundBlur(false);
		}
		toggleBackground();
	};
	const handleBlurBtnOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked && backgroundString === ToolsBackGroundMode.noBg) {
			toggleBackground(false);
		}

		toggleBackgroundBlur();
	};
	const handleKeyUp = (event: any) => {
		event.preventDefault();

		if (!DISABLE_ENTER_LANGUAGES.includes(i18n.language) && event.key === "Enter" && textValue.trim()) {
			setThumbnailImages();
			setTextRows(0);
		}
	};
	const generateRandomText = (openGallery?: boolean) => {
		let enteredText = getRandomText(BackgroundReplacmentTexts);
		setTextValue(enteredText);
	};
	const inputProps = {
		disableUnderline: true,
		style: { fontSize: 14, fontWeight: 500 },
	};

	const handleFastCheckboxChange = (value: boolean) => {
		setIsFastVersionEnabled(value);
	};

	return (
		<Box className={classes.root}>
			<div ref={initialContainerRef}>
				<ShowAt
					showAlways
					condition={
						!isIframe() ||
						(isIframe() &&
							context.iframeConfig?.enabledFeatures.some(
								(feature: string) => ToolConfigTabsTypes.Remove.toString() === feature
							))
					}
				>
					<Box className={classes.switchBox} style={{ paddingTop: 0 }}>
						<Box>
							<span className={classes.switchLable}>{t("backgroundRemoval")}</span>
							{backgroundOracle && !backgroundOracle.remove && <TooltipBtns />}
						</Box>
						<BriaSwitch
							color="default"
							classes={{
								root: classes.switchRoot,
								track: classes.switchTrack,
								switchBase: classes.switchBase,
							}}
							checked={backgroundString === ToolsBackGroundMode.noBg}
							onChange={handleRemoveBtnOnChange}
							disabled={processing || disabled || OnloadError}
						/>
					</Box>
				</ShowAt>
			</div>
			<ShowAt
				showAlways
				condition={
					!isIframe() ||
					(isIframe() &&
						context.iframeConfig?.enabledFeatures.some(
							(feature: string) => ToolConfigTabsTypes.Blur.toString() === feature
						))
				}
			>
				<Box className={classes.switchBox}>
					<Box>
						<span className={classes.switchLable}>{t("backgroundBlur")}</span>
						{backgroundOracle && !backgroundOracle.blur && <TooltipBtns />}
					</Box>
					<BriaSwitch
						color="default"
						classes={{
							root: classes.switchRoot,
							track: classes.switchTrack,
							switchBase: classes.switchBase,
						}}
						checked={backgroundBlur === ToolsBackGroundBlurMode.Blur}
						onChange={handleBlurBtnOnChange}
						disabled={processing || disabled || OnloadError}
					/>
				</Box>
			</ShowAt>
			<ShowAt
				showAlways
				condition={
					!isIframe() ||
					(isIframe() &&
						context.iframeConfig?.enabledFeatures.some(
							(feature: string) => ToolConfigTabsTypes.Replace.toString() === feature
						))
				}
			>
				<>
					<div ref={replaceContainerRef} style={{ marginTop: 36 }}>
						<span className={classes.switchLable}>{t("backgroundGeneration")}</span>
						{backgroundOracle && !backgroundOracle.replace && <TooltipBtns />}
						<TextField
							autoFocus
							value={textValue}
							fullWidth
							onChange={handleChange}
							// onClick={() => {
							// 	// setTextRows(4);
							// }}
							// onBlur={() => {
							// 	setTimeout(() => {
							// 		setTextRows(0);
							// 	}, 300);
							// }}
							placeholder={t("describeTheBackground")}
							multiline
							className={`${classes.textField} ${ethicalError && classes.errorTextField}`}
							minRows={7}
							maxRows={7}
							onKeyUp={handleKeyUp}
							disabled={processing || OnloadError}
							InputProps={inputProps}
							variant="outlined"
							error={ethicalError}
						/>
						{ethicalError && !replaceBgLoading && !processing && (
							<span className={classes.errorLabel}>{t("ethicalError")}</span>
						)}
						<Box className={classes.fastVersionCheckboxContainer}>
							<BriaCheckbox
								className={classes.fastVersionCheckbox}
								checked={isFastVersionEnabled}
								value={isFastVersionEnabled}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									handleFastCheckboxChange(event.target.checked);
								}}
							/>
							<Typography
								className={classes.fastVersionCheckboxLabel}
								onClick={() => handleFastCheckboxChange(!isFastVersionEnabled)}
							>
								{t("fastVersion")}
							</Typography>
							<Tooltip
								placement="top"
								classes={{
									tooltip: classes.tooltipStyle,
									arrow: classes.tooltipArrowStyle,
								}}
								title={
									<Typography className={classes.tooltipDesc}>{t("fastVersionTooltip")}</Typography>
								}
							>
								<img className={classes.tooltipIcon} src={Info} alt="" />
							</Tooltip>
						</Box>
						<Box className={classes.buttonsContainer}>
							{/* this need to be refacored into GenerateButton, fix tooltip issue first */}
							<SurpriseMeButton
								disabled={processing || replaceBgLoading || OnloadError}
								list={BackgroundReplacmentTexts}
								onClick={(text: any) => setTextValue(text)}
								style={{
									height: 34,
								}}
							/>
							<BriaButton
								id="GenrateBtn"
								disabled={!textValue || processing || replaceBgLoading || OnloadError}
								buttonType={BUTTONTYPES.PRIMARYSMALL}
								onClick={(e) => {
									setThumbnailImages();
								}}
								style={{
									width: 113,
									alignSelf: "flex-end",
								}}
							>
								<Typography>{t("generate")}</Typography>
							</BriaButton>
						</Box>
					</div>
					{(replaceBgThumbsLoading || replaceBgLoading) && !isLoadMoreClicked && (
						<Box
							mt={"25px"}
							height="20vh"
							width="100%"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<LoadingAnimation
								color="#ededed"
								loadingStringsPosition="top"
								progressBarTime={15}
								loading={true}
								showLoadingStrings={true}
								loadingStringsTimeout={5000}
								loadingStrings={loadingStrings}
							/>
						</Box>
					)}
					{(!replaceBgLoading || (replaceBgLoading && isLoadMoreClicked)) && (
						<Box
							style={{
								width: "100%",
								height: "",
								minHeight:
									((!replaceBgThumbsLoading && !replaceBgLoading) || isLoadMoreClicked) &&
									replaceBgThumbnails.length > 0
										? 500
										: 0,
								flexDirection: "column",
								visibility:
									((!replaceBgThumbsLoading && !replaceBgLoading) || isLoadMoreClicked) &&
									replaceBgThumbnails.length > 0
										? "visible"
										: "hidden",
							}}
							className={classes.thumbnail}
						>
							<Personalizer
								selectedBackgroundReplaceSeed={BriaAPI.getInstance(
									visual_hash
								).getSelectedBackgroundReplaceSeed()}
								isEngineOverloaded={isEngineOverloaded}
								onLoadMoreClick={async () => {
									await callReplaceBg(textValue, 4, true);
								}}
								setReplaceBgThumbsLoading={setReplaceBgThumbsLoading}
								retryingImageAbortController={retryingImageAbortController}
								isReplaceBackground={true}
								selectedPoint={selectedPoint}
								visualHash={visual_hash}
								originalImage={originalImage}
								imageRef={imageRef}
								key="replace-bg"
								feature="replace-bg"
								personalizerKey="suggestion"
								personalizerValues={{}}
								disabled={processing}
								isSuggestion={true}
								sliders={null}
								user={context.user}
								systemView={context.systemView}
								onChangePersonalizer={async (sliders, key) => {}}
								drawerAnchor={"left"}
								objectType={selectedPoint?.objectType}
								isLoadingExpression={false}
								thumbnailHeight={thumbnailConfig[1]}
								thumbnailWidth={thumbnailConfig[0]}
								thumbnailsPerCol={thumbnailConfig[2]}
								thumbnailsCount={12}
								thumbnailImages={replaceBgThumbnails}
								onAddSemanticCombination={async () => {
									return "";
								}}
								onReplaceBgThumbnailClick={(thumbnailImage: ThumbnailImage) => {
									backgroundString === ToolsBackGroundMode.noBg && toggleBackground(false);
									backgroundBlur === ToolsBackGroundBlurMode.Blur && toggleBackgroundBlur(false);
									onReplaceBgThumbnailClick(thumbnailImage);
								}}
							/>
						</Box>
					)}
				</>
			</ShowAt>
		</Box>
	);
};

export default SceneBackground;
