import React, { Dispatch, SetStateAction } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
	createStyles,
	Grid,
	Link,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import Analytics, {
	ANALYTICS_EVENTS,
	HUBSPOT_EVENTS,
} from "../../Models/Analytics";
import { toast } from "react-toastify";

interface IProps {
	openCameraMovementModal: boolean;
	setOpenCameraMovementModal: Dispatch<SetStateAction<boolean>>;
	doDownloadVideo: () => void;
	imageVH: string;
	onContinue: () => void;
}

const CameraMovementModal = ({
	openCameraMovementModal,
	setOpenCameraMovementModal,
	doDownloadVideo,
	imageVH,
	onContinue,
}: IProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleCancel = () => {
		setOpenCameraMovementModal(false);
	};

	return (
		<Modal open={openCameraMovementModal} onClose={handleCancel}>
			<Box className={classes.cameraMovementModalBody}>
				<Typography className={classes.cameraModalHeader}>
					{t("beforeYouGo")}
				</Typography>
				<Typography className={classes.cameraModalSubHeader}>
					{t("downloadVideoFirst")}
				</Typography>
				<Box>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<Link
								className={classes.link}
								onClick={handleCancel}
							>
								{t("cancel")}
							</Link>
						</Grid>
						<Grid item>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
							>
								<Grid item>
									<BriaButton
										className={classes.cameraModalButton}
										buttonType={BUTTONTYPES.PRIMARY}
										onClick={async () => {
											doDownloadVideo();
											Analytics.logEvent(
												ANALYTICS_EVENTS.DOWNLOAD_VIDEO,
												{ image_vh: imageVH }
											);
											await Analytics.pushHubspotEvent(
												HUBSPOT_EVENTS.ASSET_DOWNLOAD
											);
											toast.success(
												<Typography>
													Video was{" "}
													<strong>downloaded</strong>
												</Typography>
											);
										}}
									>
										{t("download")}
									</BriaButton>
								</Grid>
								<Grid item>
									<BriaButton
										buttonType={BUTTONTYPES.SECONDARY}
										className={classes.cameraModalButton}
										onClick={onContinue}
									>
										{t("continue")}
									</BriaButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cameraMovementModalBody: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: 620,
			height: 200,
			position: "absolute",
			backgroundColor: "white",
			borderRadius: 10,
			padding: theme.spacing(4),
			button: {
				width: 160,
			},
		},
		cameraModalHeader: {
			fontWeight: 700,
			fontSize: 24,
			marginBottom: 16,
			color: "rgba(73, 80, 87, 1)",
		},
		cameraModalSubHeader: {
			fontSize: 14,
			marginBottom: 24,
			color: "rgba(73, 80, 87, 1)",
		},
		link: {
			cursor: "pointer",
			color: "#D80067",
		},
		cameraModalButton: {
			width: 160,
			marginLeft: 15,
		},
	})
);

export default CameraMovementModal;
