import { useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ToolHeader from "../../ToolHeader";
import Brand from "../Brand";
import {
	allImgsHashes,
	GridImgProps,
	imgArray,
	mainImgInfo,
	SELECTED_IMG_INFO,
} from "./ImgViewer/ImgViewer";
import { brandSteps } from "../steps";
import { createContext } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Context from "../../../../Context/Context";
import {
	EXTRA_SUGGESTION,
	SELECTED_PREDEFINED_MOOD,
	XMP_FILE,
	XMP_FILE_INFO,
} from "../ColorPallete/ColorPallete";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import { ApiActions } from "../../../../sdk/resources/briaAPIConstants";
import { validateColorMixer } from "../Moods/SingleMood/SingleMood";
import { useGeneratedMoodImgs } from "../../../../hooks/useGeneratedMoodImgs";
import { GENERAL_ORG_ID } from "../../../../Constants";
import { ShouldClearWatermark } from "../../../../Helpers/clearWatermark";

export const PRE_GENERATED_URL_MOODS = [
	"Dream",
	"Cozy",
	"Nostalgic",
	"Fitness",
	"Night Life",
	"Nostalgic 2",
	"Fashion Elegant",
	"Retro",
	"Tech 1",
	"Tech 2",
	"Dreamy",
	"Vacation",
];
interface ILight {
	brightness: number;
	contrast: number;
}
interface IColor {
	vibrance: number;
	saturation: number;
}

interface IColorMixer {
	color: string;
	hue: number;
	luminance: number;
	saturation: number;
}
export interface ISliderState {
	light: ILight;
	color: IColor;
	color_mixer: (IColorMixer | any)[];
}

export const BrandContext = createContext({
	brandState: {},
	updateBrandState: (state: any, key?: string) => {},
	next: (skipHidden?: boolean) => {},
	prev: (skipHidden?: boolean) => {},
	resultKey: "",
	currentIndex: 0,
	defaultStepValue: null,
	navigate: (page: number) => {},
	changeId: 0,
	setChangeId: (c: any) => {},
	updateGeneralState: (key: string, value: any, directUpdate?: any) => {},
	generalState: {},
	imgsState: {
		setTotalImgs: (imgs: GridImgProps[]) => {},
		totalImgs: null,
	},
	silentImgUpdate: (
		imgUr: string,
		predefinedMood?: any,
		index?: number
	) => {},
	updateFineTunningInitialSlidersState: (newState: any) => {},
	fineTunningInitialSlidersState: {},
});

export default function BrandPage() {
	const classes = useStyles();
	const steps = brandSteps();
	const context = useContext(Context);
	const { user } = context;
	const { shouldClearWatermark } = ShouldClearWatermark();
	const organizationUid = user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const windowObj = window as any;

	const [totalImgs, setTotalImgs] = useState<GridImgProps[]>(imgArray);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [brandState, setBrandState] = useState({});
	const [fineTunningInitialSlidersState, setFineTunningInitialSlidersState] =
		useState({});
	const [generalState, setGeneralState] = useState({
		SELECTED_PREDEFINED_MOOD: "default",
	});
	const [changeId, setChangeId] = useState(0);

	const _generalState = generalState as any;
	const xmpFileInfo = _generalState?.[XMP_FILE_INFO];
	const selectedPredefinedMood = _generalState?.[SELECTED_PREDEFINED_MOOD];
	const savedMoodsValues = _generalState?.savedMoodsValues;
	const savedMoodsLoading = _generalState?.savedMoodsLoading;

	const selectedImg: GridImgProps =
		_generalState?.[SELECTED_IMG_INFO] || mainImgInfo;

	const getImgHash = (index: number) =>
		allImgsHashes.find((elem, _index) => _index === index);

	const [generatedMoods, generatedMoodsLoading] = useGeneratedMoodImgs(
		selectedImg.vhash,
		selectedImg.original || "",
		{
			includeGalleries: ["brand"],
			includeUserGallery: false,
		},
		true
	);

	const silentImgUpdate = (
		newUrl: string,
		predefinedMood: any = {},
		imgIndex?: number
	) => {
		const { brandName, name } = predefinedMood;
		const renderName = brandName || name;

		const moodsSupportsPreGenerated =
			PRE_GENERATED_URL_MOODS.includes(renderName);

		const generateUrlFunc = (index: number) =>
			`https://labs-assets.bria.ai/predefined_moods_results/${renderName}_${getImgHash(
				index
			)}.JPEG`;
		const preGenerated = moodsSupportsPreGenerated
			? generateUrlFunc
			: () => null;

		// in case you uploaded a xmp, the processed img should be applied directly to the main img
		// => only if no mood was previously selected or xmp mood was already selected
		// if (selectedPredefinedMood === XMP_FILE || !selectedPredefinedMood) {
		const newTotalImgs = totalImgs.map((img, index) => {
			if (img.vhash === selectedImg.vhash)
				return {
					src: preGenerated(index) || newUrl,
					vhash: img.vhash,
					original: img.original,
				};
			if (preGenerated) {
				return {
					src: preGenerated(index) || img.src,
					vhash: img.vhash,
					original: img.original,
				};
			} else return img;
		});
		setTotalImgs(newTotalImgs);
		// setChangeId((c) => c + 1);
		// }
	};

	const getNewStepIndex = (
		expectedIndex: number,
		isPrev: boolean,
		hidden?: boolean,
		skipHidden?: boolean
	) => {
		if (hidden) {
			if (skipHidden) return expectedIndex;
			if (isPrev) return expectedIndex - 1;
			else return expectedIndex + 1;
		}
		return expectedIndex;
	};
	const next = (skipHidden: boolean = false) => {
		const expectedNewStepIndex = currentIndex + 1;
		const { hidden = false } = steps[expectedNewStepIndex] || {};

		// normal behaviour is skipping the hidden tabs
		const newStepIndex = getNewStepIndex(
			expectedNewStepIndex,
			false,
			hidden,
			skipHidden
		);
		setCurrentIndex(newStepIndex);
	};

	const prev = (skipHidden: boolean = false) => {
		const expectedNewStepIndex = currentIndex - 1;
		const { hidden = false } = steps[expectedNewStepIndex] || {};

		// normal behaviour is skipping the hidden tabs
		const newStepIndex = getNewStepIndex(
			expectedNewStepIndex,
			true,
			hidden,
			skipHidden
		);
		setCurrentIndex(newStepIndex);
	};
	const navigate = (step: number) => setCurrentIndex(step);

	const updateGeneralState = (
		key: string,
		newState: any,
		directUpdate?: any
	) => {
		let newChange = { ...generalState, [key]: newState };
		if (directUpdate) newChange = { ...generalState, ...directUpdate }; // with no key or value
		setGeneralState(newChange); // default key to current step key
	};

	const handleXmpValuesCall = async (
		moodParameters: any
	): Promise<string | undefined> => {
		BriaAPI.resetInstance(selectedImg.vhash);
		const instance = BriaAPI.getInstance(selectedImg.vhash);
		try {
			const res =
				(await (instance as any).callApi(
					ApiActions.APPLY_MOOD,
					{
						changes: {
							...moodParameters,
							...{
								color_mixer: validateColorMixer(
									moodParameters.color_mixer
								),
							},
						},
					},
					shouldClearWatermark(),
					organizationUid
				)) || {};
			const {
				data: { image_res },
			} = res;
			// if there's no selected moods auto-select xmp generated thumbnail
			return image_res || "";
		} catch (error) {}
	};

	const onXmpChange = async (
		prevMoods: any[] = windowObj?.applyUserMoods
	) => {
		updateGeneralState("", null, {
			savedMoodsLoading: true,
		});
		const { moodParameters, name } = xmpFileInfo;
		const xmpImageRes = (await handleXmpValuesCall(moodParameters)) || "";
		silentImgUpdate(xmpImageRes, xmpFileInfo);
		const xmpFilePayload = {
			id: XMP_FILE,
			moodId: XMP_FILE,
			name,
			moodParameters,
			imageRes: xmpImageRes,
		};
		// TODO: update img array => target selected img and change it's url
		updateGeneralState("", "", {
			[XMP_FILE]: name, // to store xmp info for color pallet step
			[XMP_FILE_INFO]: xmpFilePayload, // to store xmp info for color pallet step
			[EXTRA_SUGGESTION]: [xmpFilePayload],
			[SELECTED_PREDEFINED_MOOD]: XMP_FILE, // to default selected predefined mood to xmp file
			savedMoodsLoading: false,
			savedMoodsValues: prevMoods,
		});
		setChangeId((c) => c + 1);
	};

	const selectedStepInfo = brandSteps()?.[currentIndex];
	const { resultKey: stepKey } = selectedStepInfo ?? "";

	const clearPendingProcesses = () => (windowObj.pending = null);

	const executePendingProcess = async () => {
		const pending = windowObj.pending;
		if (!!pending) {
			try {
				pending?.().then(clearPendingProcesses);
			} catch (error) {}
		}
	};

	const updateBrandState = (newState: any, key?: string) => {
		const newChange = { ...brandState, [key || stepKey]: newState };
		setBrandState(newChange); // default key to current step key
	};

	const updateFineTunningInitialSlidersState = (newState: ISliderState) => {
		const newStateLight: ILight = {
			brightness: newState.light?.brightness ?? 0,
			contrast: newState.light?.contrast ?? 0,
		};
		const newStateColor: IColor = {
			vibrance: newState.color?.vibrance ?? 0,
			saturation: newState.color?.saturation ?? 0,
		};
		let newStateColorMixer: any[] = [];
		if (newState.color_mixer) {
			const stateColorMixer: IColorMixer[] = newState.color_mixer.map(
				(colorMixer: IColorMixer) => {
					const colorMixerItem = {
						color: colorMixer.color,
						hue: colorMixer.hue,
						luminance: colorMixer.luminance,
						saturation: colorMixer.saturation,
					};
					return colorMixerItem;
				}
			);
			newStateColorMixer = stateColorMixer;
		}
		const newSlidersState: ISliderState = {
			light: newStateLight,
			color: newStateColor,
			color_mixer: newStateColorMixer,
		};
		setFineTunningInitialSlidersState(newSlidersState);
	};

	useEffect(() => {
		if (!user?.uid || !selectedImg.vhash) return;
		updateGeneralState("", null, {
			savedMoodsLoading: true,
		});

		// getSuggestions().then(executePendingProcess);
	}, [selectedImg.vhash]);

	useEffect(() => {
		updateGeneralState("", null, {
			savedMoodsLoading: generatedMoodsLoading,
		});
		if (generatedMoodsLoading) return;
		updateGeneralState("", null, {
			savedMoodsValues: generatedMoods,
			savedMoodsLoading: false,
		});
	}, [generatedMoodsLoading]);

	const defaultStepValue = (brandState as any)?.[stepKey];

	useEffect(() => {
		if (!xmpFileInfo) return;
		// in case user had requested predefined moods (in progress), and he uploaded new xmp file
		if (savedMoodsLoading) {
			// window object is used cuz state update is async process so it's not updating at the right timing
			if (windowObj.pending) return;
			windowObj.pending = () => onXmpChange(savedMoodsValues);
			return;
		}
		onXmpChange(savedMoodsValues);
	}, [xmpFileInfo?.name]);

	const contextValue = {
		brandState,
		updateBrandState,
		updateGeneralState,
		generalState,
		next,
		prev,
		resultKey: stepKey,
		defaultStepValue,
		navigate,
		currentIndex,
		changeId,
		setChangeId,
		imgsState: {
			setTotalImgs,
			totalImgs,
		},
		silentImgUpdate,
		updateFineTunningInitialSlidersState,
		fineTunningInitialSlidersState,
	};
	return (
		<BrandContext.Provider value={contextValue as any}>
			<Box className={classes.fullWidth}>
				<ToolHeader />
				<Box className={classes.wholeWrapper}>
					<Box className={classes.stepperContainer}>
						<Brand />
					</Box>
					{/* TODO: move into results step*/}
					{/*<Box className={classes.imgViwerSide}>*/}
					{/*	<ImgViewer />*/}
					{/*</Box>*/}
				</Box>
			</Box>
		</BrandContext.Provider>
	);
}

const useStyles = makeStyles(() => ({
	fullWidth: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	wholeWrapper: {
		display: "flex",
		flex: 1,
		// height: "100%",
	},
	stepperContainer: {
		flex: 1,
	},
	imgViwerSide: {
		flex: 3,
		backgroundColor: "#f8f8f9",
	},
}));
