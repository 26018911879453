import * as Sentry from "@sentry/react";
import {
	browserLocalPersistence,
	createUserWithEmailAndPassword,
	getAuth,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
} from "firebase/auth";
import { getDatabase, onValue, ref, set, update } from "firebase/database";
import { Component } from "react";
import smartlookClient from "smartlook-client";
import {
	fetchOrganizations,
	IframeGenModelKeys,
	SelectedPointInterface,
	ToolsViewerMode,
} from "../Components/Tools/utilities";
import { MslOptions } from "../Components/UI/Header_Elements/MslSwitch";
import { ValidFacesOptions } from "../Components/UI/Header_Elements/ValidFacesSwitch";
import { isDevelopment, isProd } from "../Config/Env";
import app from "../Config/Firebase";
import { STATIC_ACCOUNT_EMAIL, STATIC_ACCOUNT_PASSWORD } from "../Constants";
import IframeConstants, { IframePostMessageTypes } from "../Constants/IframeConstants";
import client from "../GraphQL/client";
import { setPostRegistrationConfigs } from "../GraphQL/mutations";
import { GET_IFRAME_CONFIG, getOrganization } from "../GraphQL/queries";
import { allOrganizations_allOrganizations } from "../GraphQL/types/allOrganizations";
import { getIframeConfig as getIframeConfigResponse, getIframeConfigVariables } from "../GraphQL/types/getIframeConfig";
import { getUserWithUID } from "../Helpers/firebase";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";
import SelectedPage from "../Models/SelectedPage";
import { SystemViewTypes } from "../Models/SystemView";
import User from "../Models/User";
import { ROLES } from "../Routes";
import { IframeConfig } from "../sdk/resources/element";
import AppContextInterface from "./AppContextInterface";
import AppContext from "./Context";
import { CustomAuthProviders } from "../Constants/AuthConstants";
import { toast } from "react-toastify";
import { LanguageCodesEnum, LanguagesEnum, PagesEnum, ToolConfigTabsTypes } from "../Models/ToolsConfig";
import { sendPostMessage } from "../Helpers/iframe";
import i18n from "i18next";
import { IframeDestinationEnum } from "../types/graphql-global-types";
import { getSelectedOrganizationFromLocalStorage } from "../Helpers/localStorage";
import { getTailoredModels_getTailoredModels } from "../GraphQL/types/getTailoredModels";
import { AESCipher } from "../utils/encryption";

interface IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;
}

export class Folder implements IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;

	constructor(path: string, name: string, children: IFolder[], parent: IFolder | null) {
		this.path = path;
		this.name = name;
		this.children = children;
		this.parent = parent;
		this.isExpanded = false;
		this.isChecked = false;
	}
}

const firebaseHeartbeat: string = "services_hart_bit";
const auth = getAuth(app);

declare global {
	interface Window {
		defaultRemoteConfig: any;
	}
}

const customAuthProvider = CustomAuthProviders.find((authProvider) =>
	authProvider.domains.includes(window.location.host)
);

class AppContextProvider extends Component {
	state: AppContextInterface = {
		customAuthProvider: customAuthProvider,
		similarSearchFlag: false,
		setSimilarSearchFlag: () => {},
		setLoadingBrands: () => {},
		loadingBrands: true,
		setLoadingChannels: () => {},
		setLoadingTemplates: () => {},
		loadingTemplates: true,
		loadingChannels: true,
		setLoadingPlacements: () => {},
		loadingPlacements: true,

		selectedClientEmails: [],
		setCampaignName: (campaignName: string) => {},
		setCurrentCampainVhash: (currentCampainVhash: string) => {},
		campaignName: "",
		currentCampainVhash: "",
		setSelectedTemplate: (selectedTemplate: string) => {},
		selectedTemplate: "",
		setSelectedDropDownValue: (selectedDropdownValue: {}) => {},
		selectedDropdownValue: {},
		setSelectedChannelValues: (selectedChannelValues: string[]) => {},
		selectedChannelValues: [],
		setSelectedPlacementsValues: (selectedPlacementsValues: string[]) => {},
		selectedPlacementsValues: [],
		setOrgChannels: (orgChannels: []) => {},
		orgChannels: [],
		setOrgPlacements: (orgPlacements: []) => {},
		orgPlacements: [],
		setOrgBrands: (orgBrands: []) => {},
		orgBrands: [],

		orgFonts: [],
		orgTemplates: [],
		updateSelectedClientEmails: (newSelectedClientEmails: (string | null)[]) => {},
		initialUploadDate: 0,
		updateInitialUploadDate: (newInitialUploadDate: number) => {},
		finalUploadDate: 0,
		updateFinalUploadDate: (newFinalUploadDate: number) => {},
		uploadImagePurpose: "",
		updateUploadImagePurpose: (newUploadImagePurpose: string) => {},
		objectsToRemoveArray: [],
		updateObjectsToRemoveArray: (newObjectsToRemoveArray: string[]) => {},
		updateOrgFonts: (orgFonts: string[]) => {},
		updateOrgTemplates: (orgTemplates: string[]) => {},
		showBugReportForm: false,
		updateShowBugReportForm: () => {},
		showShowroomScoring: false,
		updateShowShowroomScoring: () => {},
		selectedTab: {} as SelectedPointInterface,
		setSelectedTab: (value: SelectedPointInterface) => {},
		randomize: false,
		updateRandomizeToggle: (newRandomizeToggle: boolean) => {},
		validFacesString: ValidFacesOptions.both,
		updateValidFacesString: (newValidFacesString: ValidFacesOptions) => {},
		mslString: MslOptions.both,
		updateMslString: (newMslString: MslOptions) => {},
		mslToggle: false,
		updateMslToggle: (newMslToggle: boolean) => {},
		curFolder: new Folder("", "Root", [], null),
		updateCurFolder: (newCurFolder: Folder) => {},
		updateCurrExpandedFolders: (newExpandedFolders: string[] | []) => {},
		currExpandedFolders: [],
		changeallConfigPersonalizerSwitchCheck: (switchState: boolean) => {},
		updatePresentedImagesArray: () => {},
		updateSelectedText: (newText: string) => {},
		selectedText: "",
		allConfigPersonalizerSwitchCheck: false,
		authenticationWasVerified: false,
		user: null,
		organizationGalleryMap: {},
		setOrganizationGalleryMap: (value: { [index: string]: number | null | undefined }) => {},
		heartbeatRefs: [],
		presentedImagesArray: [],
		imagesPerPage: 50,
		imageHeight: 150,
		previouslySelectedLabels: [],
		previouslySelectedPage: 1,
		updateSelectedPage: () => {},
		updateSelectedLabels: (newSelectedLabels: string[]) => {},
		updateImageHeight: (newImageHeight: number) => {},
		updateImagesPerPage: (newImagesPerPage: number) => {},
		registerViaAuthProvider: (provider: any, viewer?: boolean) => Promise.resolve(undefined),
		updateUserInfo: (username: string, company?: string, role?: string) => Promise.resolve(),
		registerWithEmail: (
			username: string,
			email: string,
			password: string,
			company?: string,
			role?: string,
			feedback?: string,
			viewer?: boolean
		) => Promise.resolve(),
		logOut: () => {},
		pageMenuWidth: 72,
		preferences: null,
		displayUpload: false,
		authDialogStep: "Register",
		showLoginForm: true,
		authDialogSource: "",
		shapeEditingItems: [],
		shapeResultedImage: "",
		embeddedLogoAspectRatio: 1,
		displayAuthDialog: false,
		displayFeedbackDialog: false,
		displayCancelationPopup: false,
		displayDemoApplicationDialog: false,
		displayUpgradeWebSubscriptionPopup: false,
		displayHighLoadDialog: false,
		displayBriaConfirmationDialog: false,
		uploadDialogExpandFlag: true,
		generatedImagesState: [],
		setGeneratedImagesState: () => {},
		setUploadDialogExpandFlag: () => {},
		setUploadState: () => {},
		setAuthDialogStep: () => {},
		setAuthDialogState: () => {},
		setAuthDialogSource: () => {},
		setShapeEditingItems: () => {},
		setShapeResultedImage: () => {},
		setEmbeddedLogoAspectRatio: () => {},
		setShowLoginForm: () => {},
		setDisplayFeedbackDialogState: () => {},
		setDisplayCancelationPopupState: () => {},
		setDisplayBriaConfirmationDialogState: () => {},
		setDisplayDemoApplicationDialogState: () => {},
		setDisplayUpgradeWebSubscriptionPopup: () => {},
		setdisplayHighLoadDialog: () => {},
		isTesting: isDevelopment,
		setIsTesting: () => {},
		systemView: this.getSystemView(),
		setSystemView: () => {},
		iframeId: new URLSearchParams(window.location.search).get("iframeId"),
		iframeConfig: null,
		subscriptionConfig: null,
		cropConfig: [],
		setCropConfig: () => {},
		allOrganizations: [],
		selectedOrganization: getSelectedOrganizationFromLocalStorage(),
		isOrganizationsLoading: false,
		tailoredModels: undefined,
		setTailoredModels: (models: getTailoredModels_getTailoredModels[]) => {},
		setAllOrganizations: () => {},
		setSelectedOrganization: () => {},
		setIsOrganizationsLoading: () => {},
		diverseSearch: true,
		setDiverseSearch: () => {},
	};

	getSystemView() {
		switch (sessionStorage.getItem("systemView")) {
			case "admin":
				return SystemViewTypes.Admin;
			case "viewer":
				return SystemViewTypes.Viewer;
			case "external":
				return SystemViewTypes.External;
			case "testing":
				return SystemViewTypes.Testing;
			default:
				return SystemViewTypes.Admin;
		}
	}

	// TECH DEBT - TODO: Split the queryParams code to only preview
	async getIframeConfig(iframeId: string): Promise<IframeConfig | null> {
		let iframeConfig: IframeConfig | null = null;
		try {
			if (iframeId !== null) {
				window.iframeId = iframeId;
				const res = await client.query<getIframeConfigResponse, getIframeConfigVariables>({
					query: GET_IFRAME_CONFIG,
					variables: {
						iframeId,
					},
				});
				iframeConfig = res.data.getIframeConfig as unknown as IframeConfig;
				iframeConfig.encryptor = new AESCipher(iframeConfig.organization);

				await signInWithEmailAndPassword(
					auth,
					iframeConfig.encryptor.decrypt(iframeConfig.authUsername),
					iframeConfig.encryptor.decrypt(iframeConfig.authPassword)
				);

				const queryParams = new URLSearchParams(window ? window.location.search : {});
				iframeConfig.passedQueryParams = Object.fromEntries(queryParams);
				if (iframeId === "preview") {
					const orgId = queryParams.get("orgId");
					if (orgId) {
						iframeConfig.organization = orgId;
						const orgRes = await getOrganization(orgId, iframeId);
						this.setSelectedOrganization(orgRes as allOrganizations_allOrganizations);
					}
					iframeConfig.whiteLabel = !(queryParams.get("whiteLabel") === "false");
					iframeConfig.OrgIdForGallery = queryParams.get("orgIdForGallery");
					iframeConfig.usePublicGallery = !iframeConfig.OrgIdForGallery;
					iframeConfig.genModels = Object.values(IframeGenModelKeys).reduce(
						(genModels, key) => ({
							...genModels,
							...(queryParams.has(key) && { [key]: queryParams.get(key) }),
						}),
						{}
					);
					iframeConfig.customStyle = {
						logo: queryParams.get("logo"),
						primaryColor: queryParams.get("color"),
					};
					iframeConfig.name = queryParams.get("name");
					iframeConfig.defaultGenAspectRatio = queryParams.get("defaultGenAspectRatio");
					iframeConfig.description = queryParams.get("description");
					iframeConfig.enableDownload = queryParams.get("enableDownload") === "true";
					iframeConfig.disableUpload = queryParams.get("disableUpload") === "true";
					iframeConfig.hideStockImages = queryParams.get("hideStockImages") === "true";
					iframeConfig.embeddedIframe = !(queryParams.get("embeddedIframe") === "false");
					iframeConfig.disableDownloadPsd = queryParams.get("disableDownloadPsd") === "true";
					iframeConfig.disableSave = queryParams.get("disableSave") === "true";
					iframeConfig.disableSavePsd = queryParams.get("disableSavePsd") === "true";
					iframeConfig.saveLabel = queryParams.get("saveLabel");
					iframeConfig.watermarkPath = queryParams.get("watermarkPath");
					iframeConfig.disableDownloadImage = queryParams.get("disableDownloadImage") === "true";
					iframeConfig.enableCloseButton = queryParams.get("enableCloseButton") === "true";
					iframeConfig.target = queryParams.get("target");
					iframeConfig.saveDestination = (queryParams.get("saveDestination") ??
						IframeDestinationEnum.ORGANIZATION) as IframeDestinationEnum;
					iframeConfig.uploadDestination = (queryParams.get("uploadDestination") ??
						IframeDestinationEnum.ORGANIZATION) as IframeDestinationEnum;
					const enabledPagesStr = queryParams.get("enabledPages") || "";
					iframeConfig.enabledPages = Object.fromEntries(
						(enabledPagesStr ? enabledPagesStr.split(",") : []).map((pageId) => [
							IframeConstants.IFRAME_PAGES_MAP[pageId],
							true,
						])
					);
					const enabledLanguagesStr = queryParams.get("enabledLanguages") || "";
					iframeConfig.enabledLanguages = Object.fromEntries(
						(enabledLanguagesStr ? enabledLanguagesStr.split(",") : []).map((lng) => [
							IframeConstants.IFRAME_LANGUAGES_MAP[lng],
							true,
						])
					);
				} else {
					if (iframeConfig.organization) {
						const orgRes = await getOrganization(iframeConfig.organization, iframeId);
						this.setSelectedOrganization(orgRes as allOrganizations_allOrganizations);
					}
					iframeConfig.enabledPages = Object.fromEntries(
						Object.entries(iframeConfig.enabledPages).filter(
							([page]) => (iframeConfig?.allowedPages || {})[page as PagesEnum]
						)
					);
					iframeConfig.enabledLanguages = Object.fromEntries(
						Object.entries(iframeConfig.enabledLanguages ?? []).filter(
							([lng]) => (iframeConfig?.allowedLanguages || {})[lng as LanguagesEnum]
						)
					);
					iframeConfig.whiteLabel = !(
						iframeConfig.whiteLabel === false || queryParams.get("whiteLabel") === "false"
					);
					iframeConfig.genModels = Object.values(IframeGenModelKeys).reduce((genModels, key) => {
						const modelValue = iframeConfig?.genModels?.[key];
						return modelValue ? { ...genModels, [key]: modelValue } : genModels;
					}, {});
					iframeConfig.OrgIdForGallery = iframeConfig.usePublicGallery ? null : iframeConfig.organization;
					// Delete completely once clients use the Platform
					iframeConfig.defaultGenAspectRatio =
						iframeConfig.defaultGenAspectRatio || queryParams.get("defaultGenAspectRatio");
					iframeConfig.name = iframeConfig.name || queryParams.get("name");
					iframeConfig.description = iframeConfig.description || queryParams.get("name");
					iframeConfig.enableDownload =
						iframeConfig.enableDownload || queryParams.get("enableDownload") === "true";
					iframeConfig.disableUpload =
						iframeConfig.disableUpload || queryParams.get("disableUpload") === "true";
					iframeConfig.hideStockImages =
						iframeConfig.hideStockImages || queryParams.get("hideStockImages") === "true";
					iframeConfig.embeddedIframe = !(
						iframeConfig.embeddedIframe === false || queryParams.get("embeddedIframe") === "false"
					);
					iframeConfig.disableDownloadPsd =
						iframeConfig.disableDownloadPsd || queryParams.get("disableDownloadPsd") === "true";
					iframeConfig.disableSave = iframeConfig.disableSave || queryParams.get("disableSave") === "true";
					iframeConfig.disableSavePsd =
						iframeConfig.disableSavePsd || queryParams.get("disableSavePsd") === "true";
					iframeConfig.saveLabel = iframeConfig.saveLabel || queryParams.get("saveLabel");
					iframeConfig.watermarkPath = iframeConfig.watermarkPath || queryParams.get("watermarkPath");
					iframeConfig.disableDownloadImage =
						iframeConfig.disableDownloadImage || queryParams.get("disableDownloadImage") === "true";
					iframeConfig.enableCloseButton =
						iframeConfig.enableCloseButton || queryParams.get("enableCloseButton") === "true";
					iframeConfig.target = iframeConfig.target || queryParams.get("target");
					iframeConfig.saveDestination =
						iframeConfig.saveDestination ||
						((queryParams.get("saveDestination") ??
							IframeDestinationEnum.ORGANIZATION) as IframeDestinationEnum);
					iframeConfig.uploadDestination =
						iframeConfig.uploadDestination ||
						((queryParams.get("uploadDestination") ??
							IframeDestinationEnum.ORGANIZATION) as IframeDestinationEnum);
				}
				iframeConfig.enabledTgModels =
					iframeConfig.enabledTgModels || (queryParams.get("enabledTgModels") ?? "").split(",");
				iframeConfig.usageText = queryParams.get("usageText");
				iframeConfig.userId = queryParams.get("userId");
				iframeConfig.sessionId = queryParams.get("sessionId");
				iframeConfig.isPlaytikaIframe = iframeId === "db6040b7-c30e-4b3c-a967-4c8df4f10c51-1";
				iframeConfig.saveToAssets = queryParams.get("saveToAssets") === "true";
				iframeConfig.alwaysEnableSave = queryParams.get("alwaysEnableSave") === "true";
				iframeConfig.editorSelectedTab = queryParams.get("selectedTab");
				iframeConfig.disableNav = queryParams.get("disableNav") === "true";
				iframeConfig.authUser = iframeConfig.target === PagesEnum.Assets || iframeConfig.saveToAssets;
				if (!iframeConfig.authUser) {
					iframeConfig.saveDestination = IframeDestinationEnum.ORGANIZATION;
					iframeConfig.uploadDestination = IframeDestinationEnum.ORGANIZATION;
				}

				// only single language should be selected
				const enabledLanguages = Object.entries(iframeConfig.enabledLanguages)
					.filter(([key, value]) => value)
					.map(([key, value]) => key);
				const enabledLanguage = enabledLanguages.length > 0 ? enabledLanguages[0] : LanguagesEnum.English;
				await i18n.changeLanguage(LanguageCodesEnum[enabledLanguage as LanguagesEnum]);
				iframeConfig.enableTranslation = !iframeConfig.enabledLanguages.english;

				if (queryParams.get("initUpload") === "true") {
					this.setUploadState(true, undefined);
				}

				let sourceDomain = queryParams.get("sourceDomain");
				if (sourceDomain) {
					try {
						sourceDomain = sourceDomain.trim();
						if (!/^https?:\/\//.test(sourceDomain)) {
							sourceDomain = "https://" + sourceDomain;
						}
						const sourceUrl = new URL(sourceDomain);
						iframeConfig.sourceDomain = `${sourceUrl.protocol}//${sourceUrl.host}`;
					} catch (e) {
						console.log("Invalid sourceDomain: " + sourceDomain);
					}
				}

				// TECH DEBT
				// Checking if has the enabled_features from the firebase, to maintain the current enabledFeatures logic
				if (iframeConfig.enabledFeatures) {
					const filteredEnabledFeatures = Object.fromEntries(
						Object.entries(iframeConfig.enabledFeatures).filter(
							([key, value]) =>
								(value as unknown as boolean) === true &&
								iframeConfig?.allowedFeatures[key as ToolConfigTabsTypes]
						)
					);

					iframeConfig.enabledFeatures = Object.keys(filteredEnabledFeatures);
					iframeConfig.enabledFeaturesIds = Object.entries(IframeConstants.IFRAME_FEATURES)
						.filter(([id, feature]) => filteredEnabledFeatures[feature as ToolConfigTabsTypes])
						.map(([id]) => id);
				} else {
					// Should be moved up to if(iframeId="preview") once clients use the Platform
					let enabledFeaturesIds: string[] = [];
					const enabledFeaturesStr = queryParams.get("enabledFeatures") ?? "";
					if (enabledFeaturesStr.toLowerCase() === "all") {
						enabledFeaturesIds = Object.keys(IframeConstants.IFRAME_FEATURES);
					} else if (enabledFeaturesStr.toLowerCase() === "none") {
						enabledFeaturesIds = [];
					} else {
						enabledFeaturesIds = enabledFeaturesStr.split(",");
					}

					// TECH DEBT
					// Checking if the iframe in Firebase hasn't been migrated from string[] to [feature]: boolean object
					let allowedFeatures: string[] = [];
					if (!Array.isArray(iframeConfig.allowedFeatures)) {
						allowedFeatures = Object.entries(IframeConstants.IFRAME_FEATURES)
							.filter(([id, feature]) => iframeConfig?.allowedFeatures[feature as ToolConfigTabsTypes])
							.map(([id]) => id);
					} else {
						allowedFeatures = iframeConfig.allowedFeatures;
					}

					iframeConfig.enabledFeaturesIds = enabledFeaturesIds.filter(
						(feature: string) => allowedFeatures.indexOf(feature) !== -1
					);

					iframeConfig.enabledFeatures = iframeConfig.enabledFeaturesIds.map(
						(featureId) => IframeConstants.IFRAME_FEATURES[`${featureId}`]
					);
				}
			}
		} catch (error) {
			console.log("error while loading iframe config", error);
		}
		return iframeConfig;
	}

	async componentDidMount() {
		window.iframeId = undefined;
		window.iframeAuth = undefined;
		if (this.state.iframeId) {
			const iframeConfig = await this.getIframeConfig(this.state.iframeId);
			if (iframeConfig) {
				if (!isProd) {
					console.log("iframeConfig", iframeConfig);
				}
				this.setState({ iframeConfig });
				if (iframeConfig.authUser) {
					window.addEventListener(
						"message",
						(event) => {
							if (
								event.data &&
								event.data["type"] === IframePostMessageTypes.Auth &&
								event.origin === iframeConfig.sourceDomain
							) {
								if (!isProd) {
									console.log(
										"Iframe: received Bria_Auth message",
										event.origin,
										JSON.stringify(event.data)
									);
								}
								window.iframeAuth = event.data["message"];
							}
						},
						false
					);
					sendPostMessage(IframePostMessageTypes.AuthInit, {}, "", iframeConfig);
				}
			}
		} else {
			this.setState({ iframeConfig: null });
			if (!localStorage.getItem("authorization")) {
				await signInWithEmailAndPassword(auth, STATIC_ACCOUNT_EMAIL, STATIC_ACCOUNT_PASSWORD);
			}
		}
		this.verifyUser();
		window.addEventListener("storage", async (e) => {
			if (
				(e.key === "client_uid" || e.key === "selected_org") &&
				e.newValue !== e.oldValue &&
				!this.state.iframeId
			) {
				// to solve auth in multi-tabs
				window.location.reload();
			}
		});
	}

	componentWillUnmount() {
		this.state.heartbeatRefs?.forEach((ref: any) => {
			ref.off("value");
		});
	}

	changeallConfigPersonalizerSwitchCheck(switchState: boolean) {
		this.setState({ allConfigPersonalizerSwitchCheck: switchState });
	}

	setOrganizationGalleryMap(value: { [index: string]: number | null | undefined }) {
		this.setState({ organizationGalleryMap: value });
	}

	updatePresentedImagesArray(presentedImagesMongoIdArray: string[]) {
		this.setState({ presentedImagesArray: presentedImagesMongoIdArray });
	}

	updateSelectedLabels(newSelectedLabels: string[]) {
		this.setState({ previouslySelectedLabels: newSelectedLabels });
	}

	updateCurFolder(newCurFolder: Folder) {
		this.setState({ curFolder: newCurFolder });
	}

	updateSelectedPage(newSelectedPage: number) {
		this.setState({ previouslySelectedPage: newSelectedPage });
	}

	updateImagesPerPage(newImagesPerPage: number) {
		this.setState({ imagesPerPage: newImagesPerPage });
	}

	updateImageHeight(newImageHeight: number) {
		this.setState({ imageHeight: newImageHeight });
	}

	updateSelectedText(newSelectedText: string) {
		this.setState({ selectedText: newSelectedText });
	}

	updateCurrExpandedFolders(newExpandedFolders: string[] | []) {
		this.setState({ currExpandedFolders: newExpandedFolders });
	}

	updateMslString(newMslString: MslOptions) {
		this.setState({ mslString: newMslString });
	}

	updateValidFacesString(newValidFacesString: ValidFacesOptions) {
		this.setState({ validFacesString: newValidFacesString });
	}

	updateMslToggle(newMslToggle: boolean) {
		this.setState({ mslToggle: newMslToggle });
	}

	updateShowShowroomScoring() {
		this.setState({ showShowroomScoring: !this.state.showShowroomScoring });
	}

	updateShowBugReportForm() {
		this.setState({ showBugReportForm: !this.state.showBugReportForm });
	}

	updateRandomizeToggle(newRandomizeToggle: boolean) {
		this.setState({ randomize: newRandomizeToggle });
	}

	updateObjectsToRemoveArray(newObjectsToRemoveArray: string[]) {
		this.setState({ objectsToRemoveArray: newObjectsToRemoveArray });
	}

	updateOrgFonts(newOrgFonts: string[]) {
		this.setState({ orgFonts: newOrgFonts });
	}

	updateOrgTemplates(newOrgTemplates: string[]) {
		this.setState({ orgTemplates: newOrgTemplates });
	}

	setCampaignName(campaignName: string) {
		this.setState({ campaignName: campaignName });
	}

	setCurrentCampainVhash(currentCampainVhash: string) {
		this.setState({ currentCampainVhash: currentCampainVhash });
	}

	setSelectedTemplate(selectedTemplate: string) {
		this.setState({ selectedTemplate: selectedTemplate });
	}

	setSelectedDropDownValue(selectedDropdownValue: {}) {
		this.setState({ selectedDropdownValue: selectedDropdownValue });
	}

	setSelectedChannelValues(selectedChannelValues: string[]) {
		this.setState({ selectedChannelValues: selectedChannelValues });
	}

	setSelectedPlacementsValues(selectedPlacementsValues: string[]) {
		this.setState({ selectedPlacementsValues: selectedPlacementsValues });
	}

	setOrgChannels(orgChannels: {}) {
		this.setState({ orgChannels: orgChannels });
	}

	setOrgPlacements(orgPlacements: {}) {
		this.setState({ orgPlacements: orgPlacements });
	}

	setOrgBrands(orgBrands: {}) {
		this.setState({ orgBrands: orgBrands });
	}

	updateUploadImagePurpose(newUploadImagePurpose: string) {
		this.setState({ uploadImagePurpose: newUploadImagePurpose });
	}

	updateInitialUploadDate(newInitialUploadDate: number) {
		this.setState({ initialUploadDate: newInitialUploadDate });
	}

	updateFinalUploadDate(newFinalUploadDate: number) {
		this.setState({ finalUploadDate: newFinalUploadDate });
	}

	updateSelectedClientEmails(newSelectedClientEmails: (string | null)[]) {
		this.setState({ selectedClientEmails: newSelectedClientEmails });
	}

	render() {
		return (
			<AppContext.Provider
				value={{
					uploadDialogExpandFlag: this.state.uploadDialogExpandFlag,
					displayBriaConfirmationDialog: this.state.displayBriaConfirmationDialog,
					organizationGalleryMap: this.state.organizationGalleryMap,
					selectedClientEmails: this.state.selectedClientEmails,
					orgFonts: this.state.orgFonts,
					orgTemplates: this.state.orgTemplates,
					campaignName: this.state.campaignName,
					currentCampainVhash: this.state.currentCampainVhash,
					selectedTemplate: this.state.selectedTemplate,
					selectedDropdownValue: this.state.selectedDropdownValue,
					selectedChannelValues: this.state.selectedChannelValues,
					selectedPlacementsValues: this.state.selectedPlacementsValues,
					orgChannels: this.state.orgChannels,
					orgPlacements: this.state.orgPlacements,
					orgBrands: this.state.orgBrands,
					updateSelectedClientEmails: this.updateSelectedClientEmails.bind(this),
					initialUploadDate: this.state.initialUploadDate,
					updateInitialUploadDate: this.updateInitialUploadDate.bind(this),
					finalUploadDate: this.state.finalUploadDate,
					updateFinalUploadDate: this.updateFinalUploadDate.bind(this),
					uploadImagePurpose: this.state.uploadImagePurpose,
					updateUploadImagePurpose: this.updateUploadImagePurpose.bind(this),
					objectsToRemoveArray: this.state.objectsToRemoveArray,
					updateObjectsToRemoveArray: this.updateObjectsToRemoveArray.bind(this),
					updateOrgFonts: this.updateOrgFonts.bind(this),
					updateOrgTemplates: this.updateOrgTemplates.bind(this),
					setCampaignName: this.setCampaignName.bind(this),
					setCurrentCampainVhash: this.setCurrentCampainVhash.bind(this),
					setSelectedTemplate: this.setSelectedTemplate.bind(this),
					setSelectedDropDownValue: this.setSelectedDropDownValue.bind(this),
					setSelectedChannelValues: this.setSelectedChannelValues.bind(this),
					setSelectedPlacementsValues: this.setSelectedPlacementsValues.bind(this),
					setOrgChannels: this.setOrgChannels.bind(this),
					setOrgPlacements: this.setOrgPlacements.bind(this),
					setOrgBrands: this.setOrgBrands.bind(this),
					showBugReportForm: this.state.showBugReportForm,
					updateShowBugReportForm: this.updateShowBugReportForm.bind(this),
					showShowroomScoring: this.state.showShowroomScoring,
					updateShowShowroomScoring: this.updateShowShowroomScoring.bind(this),
					randomize: this.state.randomize,
					updateRandomizeToggle: this.updateRandomizeToggle.bind(this),
					validFacesString: this.state.validFacesString,
					updateValidFacesString: this.updateValidFacesString.bind(this),
					mslString: this.state.mslString,
					updateMslString: this.updateMslString.bind(this),
					mslToggle: this.state.mslToggle,
					updateMslToggle: this.updateMslToggle.bind(this),
					curFolder: this.state.curFolder,
					updateCurFolder: this.updateCurFolder.bind(this),
					setDisplayBriaConfirmationDialogState: this.setDisplayBriaConfirmationDialogState.bind(this),
					updateCurrExpandedFolders: this.updateCurrExpandedFolders.bind(this),
					generatedImagesState: this.state.generatedImagesState,
					setGeneratedImagesState: this.setGeneratedImagesState.bind(this),
					currExpandedFolders: this.state.currExpandedFolders,
					showLoginForm: this.state.showLoginForm,
					changeallConfigPersonalizerSwitchCheck: this.changeallConfigPersonalizerSwitchCheck.bind(this),
					setOrganizationGalleryMap: this.setOrganizationGalleryMap.bind(this),
					allConfigPersonalizerSwitchCheck: this.state.allConfigPersonalizerSwitchCheck,
					heartbeatRefs: this.state.heartbeatRefs,
					user: this.state.user,
					authenticationWasVerified: this.state.authenticationWasVerified,
					presentedImagesArray: this.state.presentedImagesArray,
					imagesPerPage: this.state.imagesPerPage,
					imageHeight: this.state.imageHeight,
					previouslySelectedLabels: this.state.previouslySelectedLabels,
					previouslySelectedPage: this.state.previouslySelectedPage,
					updateImagesPerPage: this.updateImagesPerPage.bind(this),
					updateSelectedPage: this.updateSelectedPage.bind(this),
					updateImageHeight: this.updateImageHeight.bind(this),
					updateSelectedLabels: this.updateSelectedLabels.bind(this),
					updatePresentedImagesArray: this.updatePresentedImagesArray.bind(this),
					updateSelectedText: this.updateSelectedText.bind(this),
					selectedText: this.state.selectedText,
					registerViaAuthProvider: this.registerViaAuthProvider,
					updateUserInfo: this.updateUserInfo,
					registerWithEmail: this.registerWithEmail,
					logOut: this.logOut,
					pageMenuWidth: this.state.pageMenuWidth,
					preferences: this.state.preferences,
					displayUpload: this.state.displayUpload,
					displayAuthDialog: this.state.displayAuthDialog,
					similarSearchFlag: this.state.similarSearchFlag,
					loadingBrands: this.state.loadingBrands,
					loadingChannels: this.state.loadingChannels,
					loadingTemplates: this.state.loadingTemplates,
					loadingPlacements: this.state.loadingPlacements,
					authDialogStep: this.state.authDialogStep,
					selectedTab: this.state.selectedTab,
					setSelectedTab: this.setSelectedTab.bind(this),
					authDialogSource: this.state.authDialogSource,
					shapeEditingItems: this.state.shapeEditingItems,
					shapeResultedImage: this.state.shapeResultedImage,
					embeddedLogoAspectRatio: this.state.embeddedLogoAspectRatio,
					displayFeedbackDialog: this.state.displayFeedbackDialog,
					displayCancelationPopup: this.state.displayCancelationPopup,
					setDisplayCancelationPopupState: this.setDisplayCancelationPopupState.bind(this),
					displayDemoApplicationDialog: this.state.displayDemoApplicationDialog,
					displayUpgradeWebSubscriptionPopup: this.state.displayUpgradeWebSubscriptionPopup,
					setUploadDialogExpandFlag: this.setUploadDialogExpandFlag.bind(this),
					displayHighLoadDialog: this.state.displayHighLoadDialog,
					setUploadState: this.setUploadState.bind(this),
					setAuthDialogState: this.setAuthDialogState.bind(this),
					setSimilarSearchFlag: this.setSimilarSearchFlag.bind(this),
					setloadingBrands: this.setLoadingBrands.bind(this),
					setLoadingChannels: this.setLoadingChannels.bind(this),
					setLoadingTemplates: this.setLoadingTemplates.bind(this),
					setLoadingPlacements: this.setLoadingPlacements.bind(this),
					setAuthDialogStep: this.setAuthDialogStep.bind(this),
					setAuthDialogSource: this.setAuthDialogSource.bind(this),
					setShapeEditingItems: this.setShapeEditingItems.bind(this),
					setShowLoginForm: this.setShowLoginForm.bind(this),
					setShapeResultedImage: this.setShapeResultedImage.bind(this),
					setEmbeddedLogoAspectRatio: this.setEmbeddedLogoAspectRatio.bind(this),
					setDisplayFeedbackDialogState: this.setDisplayFeedbackDialogState.bind(this),
					setDisplayDemoApplicationDialogState: this.setDisplayDemoApplicationDialogState.bind(this),
					setCropConfig: this.setCropConfig.bind(this),
					setDisplayUpgradeWebSubscriptionPopup: this.setDisplayUpgradeWebSubscriptionPopup.bind(this),
					setdisplayHighLoadDialog: this.setdisplayHighLoadDialog.bind(this),
					isTesting: this.state.isTesting,
					setIsTesting: this.setIsTesting.bind(this),
					systemView: this.state.systemView,
					setSystemView: this.setSystemView.bind(this),
					iframeId: this.state.iframeId,
					iframeConfig: this.state.iframeConfig,
					subscriptionConfig: this.state.subscriptionConfig,
					cropConfig: this.state.cropConfig,
					isOrganizationsLoading: this.state.isOrganizationsLoading,
					tailoredModels: this.state.tailoredModels,
					allOrganizations: this.state.allOrganizations,
					selectedOrganization: this.state.selectedOrganization,
					setTailoredModels: this.setTailoredModels.bind(this),
					setIsOrganizationsLoading: this.setIsOrganizationsLoading.bind(this),
					setAllOrganizations: this.setAllOrganizations.bind(this),
					setSelectedOrganization: this.setSelectedOrganization.bind(this),
					diverseSearch: this.state.diverseSearch,
					setDiverseSearch: this.setDiverseSearch.bind(this),
					customAuthProvider: this.state.customAuthProvider,
				}}
			>
				{this.props.children}
			</AppContext.Provider>
		);
	}

	// Context Actions
	setSelectedPage = (selectedPage: SelectedPage) => {
		this.setState({ selectedPage: selectedPage });
	};

	logOut = async () => {
		try {
			signOut(auth);
			localStorage.clear();
			this.setState({ user: null, iframeId: null, iframeConfig: null });
			// reload to reset HubSpot
			window.location.reload();
		} catch {}
	};

	registerViaAuthProvider = async (provider: any, viewer?: boolean) => {
		try {
			await setPersistence(auth, browserLocalPersistence);
			var result = await signInWithPopup(auth, provider);
			var user = await result.user;
			if (user !== null) {
				var exsistringUser: User | null = null;
				try {
					exsistringUser = await getUserWithUID(user.uid);
					const jwt = await user?.getIdToken();
					localStorage.setItem("authorization", jwt);
				} catch {}
				if (!exsistringUser) {
					var database = getDatabase(app);
					const userRole = viewer ? ROLES.USER : ROLES.EXTERNAL;
					await set(ref(database, "users/" + user.uid), {
						user_name: user.displayName ?? "",
						uid: user.uid,
						email: user.email,
						profile_picture: user.photoURL ?? "",
						role: userRole,
						company: "",
						userRole: "",
						getInfo: false,
						// manuallyManaged: false,
					});
					await setPostRegistrationConfigs();
					await Analytics.setUser(
						{
							userName: user.displayName ?? "",
							uid: user.uid,
							email: user.email ?? "",
							role: userRole,
							company: "",
							isSso: !!customAuthProvider,
						},
						true
					);
				}
				localStorage.setItem("client_uid", user.uid);
				this.verifyUser();
				return Promise.resolve(exsistringUser?.getInfo);
			}
			return Promise.reject("Invalid user");
		} catch (error: any) {
			if (error.code === "auth/account-exists-with-different-credential") {
				toast.error("User exists with a different credential");
				return Promise.reject("User exists with a different credential");
			}
			return Promise.reject(error);
		}
	};

	updateUserInfo = async (username: string, company?: string, role?: string) => {
		try {
			var user = this.state.user;
			if (user !== null) {
				var database = getDatabase(app);
				await update(ref(database, "users/" + user.uid), {
					user_name: username ?? "",
					company: company ?? "",
					userRole: role ?? "",
					getInfo: true,
					// manuallyManaged: false,
				});
				this.verifyUser();
				this.updateUser(false);
				return Promise.resolve();
			}
			return Promise.reject("Invalid user");
		} catch (error) {
			return Promise.reject(error);
		}
	};

	registerWithEmail = async (
		username: string,
		email: string,
		password: string,
		company?: string,
		role?: string,
		feedback?: string,
		viewer?: boolean
	) => {
		try {
			await setPersistence(auth, browserLocalPersistence);
			var result = await createUserWithEmailAndPassword(auth, email, password);
			var user = await result.user;
			if (user !== null) {
				var exsistringUser: User | null = null;
				try {
					exsistringUser = await getUserWithUID(user.uid);
					const jwt = await user?.getIdToken();
					localStorage.setItem("authorization", jwt);
				} catch {}
				const userRole = exsistringUser?.role ?? (viewer ? ROLES.USER : ROLES.EXTERNAL);
				var database = getDatabase(app);
				await set(ref(database, "users/" + user.uid), {
					user_name: user.displayName ?? username,
					uid: user.uid,
					email: user.email,
					profile_picture: user.photoURL ?? "",
					role: userRole,
					company: company ?? "",
					userRole: role ?? "",
					getInfo: true,
					// manuallyManaged: false,
				});
				await setPostRegistrationConfigs();
				await Analytics.setUser(
					{
						userName: user.displayName ?? username,
						uid: user.uid,
						email: user.email ?? "",
						role: userRole,
						company: company ?? "",
						isSso: !!customAuthProvider,
					},
					true
				);
				localStorage.setItem("client_uid", user.uid);
				this.verifyUser();
				return Promise.resolve();
			}
			return Promise.reject("Invalid user");
		} catch (error) {
			return Promise.reject(error);
		}
	};

	// helpers
	parseSnapshot(snapshot: any): number {
		const data = snapshot.val();
		if (data) {
			if ("last_seen" in data) {
				return data["last_seen"] as number;
			}
		}
		return 0;
	}

	async verifyUser() {
		onAuthStateChanged(auth, async (currentUser) => {
			try {
				if (currentUser) {
					if (currentUser.email) {
						Sentry.setUser({ email: currentUser.email });
						smartlookClient.identify(currentUser.uid, {
							name: currentUser.displayName ?? "",
							email: currentUser.email,
						});
					}
					const token = await currentUser.getIdToken();
					let user = await getUserWithUID(currentUser.uid);
					if (!user) {
						await new Promise((r) => setTimeout(r, 500));
						user = await getUserWithUID(currentUser.uid);
					}
					if (user) {
						await Analytics.setUser({
							userName: user.userName,
							uid: user.uid,
							email: user.email ?? "",
							role: user.role,
							company: user.company ?? "",
							organizations: user.organizations,
							subscription: user.subscription,
							manuallyManaged: user.manuallyManaged,
							isSso: !!customAuthProvider,
						});
					}
					localStorage.setItem("client_uid", currentUser.uid);
					localStorage.setItem("authorization", token);
					if (user) {
						fetchOrganizations(this).then((allOrgs) => {
							if (allOrgs && allOrgs.length > 0) {
								const selectedOrg = getSelectedOrganizationFromLocalStorage();
								if (!selectedOrg) {
									this.setSelectedOrganization(allOrgs[0]);
								} else {
									const foundOrg = allOrgs.find((org) => org.uid === selectedOrg.uid);
									if (foundOrg) {
										this.setSelectedOrganization(foundOrg);
									} else if (!user?.isAdmin()) {
										this.setSelectedOrganization(allOrgs[0]);
									}
								}
							}
						});
					}
					const settingsRef = ref(getDatabase(), `users/${user?.uid}/settings`);
					onValue(settingsRef, (snapshot) => {
						this.setState({
							preferences: snapshot.val(),
						});
						if (snapshot.val() === null) {
							this.setState({
								preferences: {
									withLabels: true,
									style: ToolsViewerMode.sliders,
								},
							});
						}
					});
					this.setState({ user: user }, () => {
						this.observeUserRoleChange();
					});
				}
			} catch {
				this.setState({ user: null });
			}
			this.setState({ authenticationWasVerified: true });
		});
	}

	observeUserRoleChange() {
		var starCountRef = ref(getDatabase(), "users/" + this.state.user?.uid ?? "" + "/role");
		onValue(starCountRef, (snapshot) => {
			this.updateUser(true);
		});
	}

	async updateUser(fetchSubscriptionConfig: boolean = false) {
		const user = await getUserWithUID(this.state.user?.uid ?? "");
		if (
			fetchSubscriptionConfig &&
			user &&
			(!this.state.subscriptionConfig ||
				user.subscription.web_subscription_type !== this.state.subscriptionConfig.subscriptionType)
		) {
			this.setState({
				subscriptionConfig: {
					subscriptionType: user.subscription.web_subscription_type,
					subscriptionConfig: user.subscription.web_subscription_type,
				},
			});
		}
		this.setState({
			user,
		});
	}

	setUploadDialogExpandFlag(value: boolean) {
		this.setState({ uploadDialogExpandFlag: value });
	}

	async setUploadState(value: boolean, history: any) {
		if (value) {
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.UPLOAD
			// );
			// if (!hasCredits) {
			// 	return;
			// }
			this.setUploadDialogExpandFlag(true);
		}
		this.setState({ displayUpload: value });
	}

	async setAuthDialogState(value: boolean) {
		this.setState({ displayAuthDialog: value });
	}

	async setSimilarSearchFlag(value: any) {
		this.setState({ similarSearchFlag: value });
	}

	async setLoadingPlacements(value: any) {
		this.setState({ loadingPlacements: value });
	}

	async setLoadingChannels(value: any) {
		this.setState({ loadingChannels: value });
	}

	async setLoadingTemplates(value: any) {
		this.setState({ loadingTemplates: value });
	}

	async setLoadingBrands(value: any) {
		this.setState({ loadingBrands: value });
	}

	async setSelectedTab(value: SelectedPointInterface) {
		this.setState({ selectedTab: value });
	}

	async setAuthDialogStep(value: string) {
		this.setState({ authDialogStep: value });
	}

	async setAuthDialogSource(value: any) {
		this.setState({ authDialogSource: value });
	}

	async setShapeEditingItems(value: any[]) {
		this.setState({ shapeEditingItems: value });
	}

	async setShowLoginForm(value: boolean) {
		this.setState({ showLoginForm: value });
	}

	async setShapeResultedImage(value: string) {
		this.setState({ shapeResultedImage: value });
	}

	async setEmbeddedLogoAspectRatio(value: number) {
		this.setState({ embeddedLogoAspectRatio: value });
	}

	setDisplayFeedbackDialogState(value: boolean) {
		if (value === true) {
			Analytics.logEvent(ANALYTICS_EVENTS.FEEDBACK_DIALOG_OPENED);
		}
		this.setState({ displayFeedbackDialog: value });
	}

	setDisplayCancelationPopupState(value: boolean) {
		if (value === true) {
			Analytics.logEvent(ANALYTICS_EVENTS.CANCELATION_POPUP_OPENED);
		}
		this.setState({ displayCancelationPopup: value });
	}

	setDisplayBriaConfirmationDialogState(value: boolean) {
		this.setState({ displayBriaConfirmationDialogPopup: value });
	}

	setGeneratedImagesState(value: any) {
		this.setState({ generatedImagesState: value });
	}

	setDisplayDemoApplicationDialogState(value: boolean) {
		this.setState({ displayDemoApplicationDialog: value });
	}

	setCropConfig(value: any[]) {
		this.setState({ cropConfig: value });
	}

	setDisplayUpgradeWebSubscriptionPopup(value: boolean) {
		this.setState({ displayUpgradeWebSubscriptionPopup: value });
	}

	setdisplayHighLoadDialog(value: boolean) {
		if (value) {
			Analytics.logEvent(ANALYTICS_EVENTS.HIGH_LOAD_DIALOG_OPEN);
		}
		this.setState({ displayHighLoadDialog: value });
	}

	setIsTesting(value: boolean) {
		this.setState({ isTesting: value });
	}

	setSystemView(value: SystemViewTypes) {
		sessionStorage.setItem("systemView", value);
		this.setState({ systemView: value });
	}

	setAllOrganizations(value: allOrganizations_allOrganizations[]) {
		this.setState({ allOrganizations: value });
	}

	setSelectedOrganization(value: allOrganizations_allOrganizations) {
		localStorage.setItem("selected_org", JSON.stringify(value));
		this.setState({ selectedOrganization: value });
	}

	setIsOrganizationsLoading(value: boolean) {
		this.setState({ isOrganizationsLoading: value });
	}

	setTailoredModels(models: getTailoredModels_getTailoredModels[]) {
		this.setState({ tailoredModels: models });
	}

	setDiverseSearch(value: boolean) {
		this.setState({ diverseSearch: value });
	}
}

export default AppContextProvider;
