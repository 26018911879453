import { useContext, useEffect } from "react";
import {
	MutationFunctionOptions,
	OperationVariables,
	useMutation,
} from "@apollo/client";
import { useFormik } from "formik";
import { useState } from "react";
import { ADD_OBJECT_TYPE_SEMANTICS } from "../../GraphQL/mutations";
import { AddSemanticsVariables } from "../../GraphQL/types/addSemantics";
import {
	ChangeObjectTypeSemantics,
	ChangeObjectTypeSemanticsVariables,
} from "../../GraphQL/types/ChangeObjectTypeSemantics";
import { ToolsSlider } from "../../Models/ToolsConfig";
import BriaAPI from "../../sdk/resources/briaAPI";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import Context from "../../Context/Context";
import { GENERAL_ORG_ID } from "../../Constants";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";

const usePersonalizer = (personalizerSliders: ToolsSlider[]) => {
	const context = useContext(Context);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [initialValues, setInitialValues] = useState<{
		[key: string]: number;
	}>({});

	const [semanticsList, setSemanticsList] =
		useState<ToolsSlider[]>(personalizerSliders);

	useEffect(() => {
		if (Object.keys(initialValues).length === 0) {
			setPersonalizerInitialValues(personalizerSliders);
		}
		setSemanticsList(personalizerSliders);
	}, [personalizerSliders]);

	const formik = useFormik({
		enableReinitialize: Object.keys(initialValues).length !== 0,
		initialValues: initialValues,
		onSubmit: () => {},
	});

	const setPersonalizerInitialValues = (
		personalizerSliders: ToolsSlider[]
	) => {
		const initialValues: { [key: string]: number } = {};

		personalizerSliders.forEach(({ id, defaultInitialValue }) => {
			initialValues[id] = defaultInitialValue;
		});
		setInitialValues(initialValues);
	};

	const [toDisplayThumbnails, setToDisplayThumbnails] =
		useState<{ url: string }[] | null>(null);

	const [addSemanticsMutationLoading, setAddSemanticsMutationLoading] =
		useState<boolean>(false);

	const addSemanticsMutation = async (
		faceId: string,
		options: MutationFunctionOptions<AddSemanticsVariables>,
		apiAction: ApiActions = ApiActions.FACE_MANIPULATIONS,
		ignoreEmpty: boolean = true
	) => {
		if (!options.variables) {
			throw new Error("variables not provided");
		}

		setAddSemanticsMutationLoading(true);
		const bria = BriaAPI.getInstance(options.variables.visualHash);

		const actions = bria.mapPipelineInputToAPI(
			options.variables as AddSemanticsVariables
		);
		const apiPipelineSettings = bria.mapPipelineSettingsToApi(
			options.variables.pipelineSettings
		);
		try {
			const res = await bria.callApi(
				apiAction,
				{
					changes: [
						{
							id: faceId,
							actions,
							change_skin: true,
						},
					],
					image_url: options.variables.inputImage,
					apiPipelineSettings,
				},
				shouldClearWatermark(),
				organizationUid,
				ignoreEmpty
			);
			setAddSemanticsMutationLoading(false);
			return {
				data: {
					addSemantics: {
						newImage: {
							url: res.data.image_res,
							__typename: "NewImage",
						},
						layersUrl: res.data.layers_url,
						__typename: "AddSemantics",
					},
				},
			};
		} catch (e) {
			console.log(e);
			setAddSemanticsMutationLoading(false);
			throw e;
		}
	};

	// const [addSemanticsMutation, { loading: addSemanticsMutationLoading }] =
	// 	useMutation<AddSemantics, AddSemanticsVariables>(ADD_SEMANTICS);

	const [
		addObjectTypeSemanticsMutation,
		{ loading: addObjectTypeSemanticsMutationLoading },
	] = useMutation<
		ChangeObjectTypeSemantics,
		ChangeObjectTypeSemanticsVariables
	>(ADD_OBJECT_TYPE_SEMANTICS);

	const addSemanticCombinationMutationHandler = async (
		params: OperationVariables
	) => {
		// controllerRef.current && controllerRef.current.abort();
		// controllerRef.current = new window.AbortController();
		const operationVariables = {
			// context: {
			// 	fetchOptions: {
			// 		signal: controllerRef.current.signal,
			// 	},
			// },
			...params,
		};
		let bria = BriaAPI.getInstance(params.variables.visualHash);
		return bria.addSemanticCombination(operationVariables.variables);
	};

	const handleToDisplayThumbnailsChange = (
		thumbnails: { url: string }[] | null
	) => {
		setToDisplayThumbnails([...(thumbnails ?? [])]);
	};

	const semantics: ToolsSlider[] = semanticsList.map((el) => ({
		...el,
		value: formik.values[el.id],
	}));

	return {
		semantics,
		addSemanticsMutationLoading,
		setAddSemanticsMutationLoading,
		addObjectTypeSemanticsMutationLoading,
		personalizerValues: formik.values,
		toDisplayThumbnails,
		setPersonalizerInitialValues,
		selectedSemantics: semantics.filter(
			(el: ToolsSlider) => el.value !== 0
		),
		setSemanticsValues: formik.setValues,
		changePersonalizerSlider: formik.setFieldValue,
		addSemanticsMutation,
		addSemanticCombinationMutation: addSemanticCombinationMutationHandler,
		addObjectTypeSemanticsMutation,
		handleToDisplayThumbnailsChange,
		resetSemantics: formik.resetForm,
	};
};

export default usePersonalizer;
