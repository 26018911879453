import { MutationFunctionOptions } from "@apollo/client";
import { ChangeEthnicityVariables } from "../../GraphQL/types/ChangeEthnicity";
import { useContext, useState } from "react";
import BriaAPI from "../../sdk/resources/briaAPI";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import Context from "../../Context/Context";
import { sendPostMessage } from "../../Helpers/iframe";
import { IframeApplyChangePostMessageTypes, IframePostMessageTypes } from "../../Constants/IframeConstants";
import { GENERAL_ORG_ID } from "../../Constants";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";
import { SystemViewTypes } from "../../Models/SystemView";
import useErrorPopup from "../../hooks/useErrorPopup";
import { useTranslation } from "react-i18next";

const useObjectRemover = () => {
	const { t } = useTranslation();
	const useErrorPopupVar: any = useErrorPopup();
	const context = useContext(Context);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [removeObjectMutationLoading, setremoveObjectMutationLoading] = useState<boolean>(false);

	const removeObjectMutation = async (
		objectId: string,
		options: MutationFunctionOptions<ChangeEthnicityVariables>
	) => {
		if (!options.variables) {
			throw new Error("variables not provided");
		}

		setremoveObjectMutationLoading(true);
		const bria = BriaAPI.getInstance(options.variables.visualHash);
		try {
			const res = await bria.callApi(
				ApiActions.REMOVE_OBJECT,
				{
					objectId,
				},
				shouldClearWatermark(),
				organizationUid,
				true,
				false,
				false,
				context.systemView === SystemViewTypes.Admin
			);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.RemoveObject,
					action: objectId,
				},
				options.variables.visualHash,
				context.iframeConfig
			);
			setremoveObjectMutationLoading(false);
			return res.data;
		} catch (e) {
			console.log(e);
			setremoveObjectMutationLoading(false);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			throw e;
		}
	};

	const removeObjectMutationByCoordinates = async (options: MutationFunctionOptions<ChangeEthnicityVariables>) => {
		if (!options.variables) {
			throw new Error("variables not provided");
		}
		setremoveObjectMutationLoading(true);
		const bria = BriaAPI.getInstance(options.variables.visualHash);
		try {
			const res = await bria.callApi(
				ApiActions.REMOVE_AUTO_OBJECT,
				{ changes: options.variables.changes },
				shouldClearWatermark(),
				organizationUid,
				true,
				false,
				false,
				context.systemView === SystemViewTypes.Admin
			);
			setremoveObjectMutationLoading(false);
			return res.data;
		} catch (e) {
			console.log(e);
			setremoveObjectMutationLoading(false);
			throw e;
		}
	};

	return {
		removeObjectMutationLoading,
		removeObjectMutation,
		setremoveObjectMutationLoading,
		removeObjectMutationByCoordinates,
	};
};

export default useObjectRemover;
