import client, { IframeClient } from "../GraphQL/client";
import { ApolloClient, ApolloQueryResult } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import {
	repositoryProjects,
	repositoryProjects_repositoryProjects as Project,
	repositoryProjectsVariables,
} from "./types/repositoryProjects";
import { repositoryTags, repositoryTags_repositoryTags as Tag, repositoryTagsVariables } from "./types/repositoryTags";
import { startImageOnboarding, startImageOnboardingVariables } from "./types/startImageOnboarding";
import { addItemsToDataset, addItemsToDatasetVariables } from "./types/addItemsToDataset";
import { images, images_images_images as Image, imagesVariables } from "./types/images";
import { folders, folders_folders as Folder, foldersVariables } from "./types/folders";
import {
	awsInstanceMetadata,
	awsInstanceMetadata_awsInstanceMetadata as InstanceMetadata,
} from "./types/awsInstanceMetadata";
import { gcpInstanceMetadata } from "./types/gcpInstanceMetadata";
import {
	labelsByPathPrefix,
	labelsByPathPrefix_labelsByPathPrefix as Label,
	labelsByPathPrefixVariables,
} from "./types/labelsByPathPrefix";
import { labelsByMongoId, labelsByMongoIdVariables } from "./types/labelsByMongoId";
import { DBName } from "../types/graphql-global-types";
import gql from "graphql-tag";
import { vdrObjectRawJson, vdrObjectRawJsonVariables } from "./types/vdrObjectRawJson";
import {
	getPostUrlsForUpload,
	getPostUrlsForUpload_getPostUrlsForUpload as PresignedPostUrl,
	getPostUrlsForUploadVariables,
} from "./types/getPostUrlsForUpload";
import { ValidFacesOptions } from "../Components/UI/Header_Elements/ValidFacesSwitch";
import { openMondayTask } from "./types/openMondayTask";
import { sendImageToDataloop, sendImageToDataloopVariables } from "./types/sendImageToDataloop";
import { sendEarlyAccessEmail, sendEarlyAccessEmailVariables } from "./types/sendEarlyAccessEmail";
import { sendFeedbackEmail, sendFeedbackEmailVariables } from "./types/sendFeedbackEmail";
import { sendDemoApplicationEmail, sendDemoApplicationEmailVariables } from "./types/sendDemoApplicationEmail";
import { showroomImages, showroomImagesVariables } from "./types/showroomImages";
import {
	clientBillingRequestsPerAction,
	clientBillingRequestsPerAction_clientBillingRequestsPerAction,
	clientBillingRequestsPerActionVariables,
} from "./types/clientBillingRequestsPerAction";
import { images_with_tags } from "./types/images_with_tags";
import {
	exportImagesToCsv,
	exportImagesToCsv_exportImagesToCsv,
	exportImagesToCsvVariables,
} from "./types/exportImagesToCsv";
import { clientEmails, clientEmailsVariables } from "./types/clientEmails";
import { getUserImages as getUserImagesInterface, getUserImagesVariables } from "./types/getUserImages";
import { ApiSubscriptionTypes, SubscriptionPeriods, SubscriptionTypes } from "../Constants/SubscriptionConstants";
import {
	subscriptionConfig as subscriptionConfigInterface,
	subscriptionConfig_subscriptionConfig,
	subscriptionConfigVariables,
} from "./types/subscriptionConfig";
import {
	apiSubscriptionConfig as apiSubscriptionConfigInterface,
	apiSubscriptionConfig_apiSubscriptionConfig,
	apiSubscriptionConfigVariables,
} from "./types/apiSubscriptionConfig";
import {
	getPaymentBuyNowUrl as getPaymentBuyNowUrlInterface,
	getPaymentBuyNowUrlVariables,
} from "./types/getPaymentBuyNowUrl";
import {
	getPaymentInfo as getPaymentInfoInterface,
	getPaymentInfo_getPaymentInfo,
	getPaymentInfoVariables,
} from "./types/getPaymentInfo";
import {
	sendDemoApplicationUserFeedback,
	sendDemoApplicationUserFeedbackVariables,
} from "./types/sendDemoApplicationUserFeedback";
import {
	getOrganization as getOrganizationResult,
	getOrganization_getOrganization,
	getOrganizationVariables,
} from "./types/getOrganization";
import {
	prepareImageForExport as prepareImageForExportResult,
	prepareImageForExportVariables,
} from "./types/prepareImageForExport";
import { AESCipher } from "../utils/encryption";
import {
	getTailoredModels as getTailoredModelsResult,
	getTailoredModels_getTailoredModels,
	getTailoredModelsVariables,
} from "./types/getTailoredModels";

export async function getLiveVideos(imagesUrls: string[]): Promise<any> {
	try {
		const res = await client.query<string>({
			query: gql`
				query liveVideos($imagesUrls: [String!]!) {
					liveVideos(imagesUrls: $imagesUrls) {
						videos {
							imageUrl
							videoUrl
						}
					}
				}
			`,
			variables: {
				imagesUrls: imagesUrls,
			},
		});
		return Promise.resolve(res.data);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function vdrObjectRawJsonString(
	mongoId: string,
	dbName: DBName,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		const res = await localClient.query<vdrObjectRawJson, vdrObjectRawJsonVariables>({
			query: gql`
				query vdrObjectRawJson($mongoId: String!, $dbName: DBName!) {
					vdrObjectRawJson(mongoId: $mongoId, dbName: $dbName) {
						jsonString
					}
				}
			`,
			variables: {
				mongoId: mongoId,
				dbName: dbName,
			},
		});
		return Promise.resolve(res.data.vdrObjectRawJson.jsonString);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getRepositoryProjects(
	dbName: DBName = DBName.VDR,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Project[]> {
	try {
		const res = await localClient.query<repositoryProjects, repositoryProjectsVariables>({
			query: gql`
				query repositoryProjects($dbName: DBName!) {
					repositoryProjects(dbName: $dbName) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
			},
		});
		return Promise.resolve(res.data.repositoryProjects ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getAddItemsToDataset(
	visualHashesToUpload: string[],
	dataset: string,
	project: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<addItemsToDataset> {
	try {
		const res = await localClient.query<addItemsToDataset, addItemsToDatasetVariables>({
			query: gql`
				query addItemsToDataset($visualHashesToUpload: [String!]!, $dataset: String!, $project: String!) {
					addItemsToDataset(visualHashesToUpload: $visualHashesToUpload, dataset: $dataset, project: $project)
				}
			`,
			variables: {
				dataset: dataset,
				project: project,
				visualHashesToUpload: visualHashesToUpload,
			},
		});
		return Promise.resolve(res.data);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getRepositoryTags(
	dbName: DBName = DBName.VDR,
	projectList: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Tag[]> {
	try {
		const res = await localClient.query<repositoryTags, repositoryTagsVariables>({
			query: gql`
				query repositoryTags($dbName: DBName!, $projectList: [String!]!) {
					repositoryTags(dbName: $dbName, projectList: $projectList) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				projectList: projectList,
			},
		});
		return Promise.resolve(res.data.repositoryTags ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getSendDemoApplicationEmail(
	userUid: string,
	interests: string[],
	feedbackText: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<sendDemoApplicationEmail> {
	try {
		const res = await localClient.query<sendDemoApplicationEmail, sendDemoApplicationEmailVariables>({
			query: gql`
				query sendDemoApplicationEmail($userUid: String!, $interests: [String!]!, $feedbackText: String!) {
					sendDemoApplicationEmail(userUid: $userUid, interests: $interests, feedbackText: $feedbackText)
				}
			`,
			variables: {
				userUid: userUid,
				interests: interests,
				feedbackText: feedbackText,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getSendDemoApplicationUserFeedback(
	userUid: string,
	feedbackText: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<sendDemoApplicationUserFeedback> {
	try {
		const res = await localClient.query<sendDemoApplicationUserFeedback, sendDemoApplicationUserFeedbackVariables>({
			query: gql`
				query sendDemoApplicationUserFeedback($userUid: String!, $feedbackText: String!) {
					sendDemoApplicationUserFeedback(userUid: $userUid, feedbackText: $feedbackText)
				}
			`,
			variables: {
				userUid: userUid,
				feedbackText: feedbackText,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getClientsEmails(
	dbName: DBName,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<clientEmails> {
	try {
		const res = await localClient.query<clientEmails, clientEmailsVariables>({
			query: gql`
				query clientEmails($dbName: DBName!) {
					clientEmails(dbName: $dbName) {
						clientEmails
					}
				}
			`,
			variables: {
				dbName: dbName,
			},
		});
		return Promise.resolve(res.data ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getSendEarlyAccessEmail(
	userUid: string,
	enteredEmail: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<sendEarlyAccessEmail> {
	try {
		const res = await localClient.query<sendEarlyAccessEmail, sendEarlyAccessEmailVariables>({
			query: gql`
				query sendEarlyAccessEmail($enteredEmail: String!, $userUid: String!) {
					sendEarlyAccessEmail(enteredEmail: $enteredEmail, userUid: $userUid)
				}
			`,
			variables: {
				userUid: userUid,
				enteredEmail: enteredEmail,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getSendFeedbackEmail(
	feedbackText: string,
	userUid: string,
	userEmail: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<sendFeedbackEmail> {
	try {
		const res = await localClient.query<sendFeedbackEmail, sendFeedbackEmailVariables>({
			query: gql`
				query sendFeedbackEmail($feedbackText: String!, $userUid: String!, $userEmail: String!) {
					sendFeedbackEmail(feedbackText: $feedbackText, userUid: $userUid, userEmail: $userEmail)
				}
			`,
			variables: {
				feedbackText: feedbackText,
				userUid: userUid,
				userEmail: userEmail,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getVDRImages(
	selectedClientEmails: (string | null)[],
	initialUploadDate: number,
	finalUploadDate: number,
	isAdmin: boolean,
	pageNumber: number,
	numberOfImagesInPage: number,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<ApolloQueryResult<images_with_tags>> {
	let customQueryJson: any = isAdmin
		? {
				$and: [
					{
						$or: [{ score: { $gt: 0 } }, { score: { $exists: false } }, { score: null }],
					},
				],
		  }
		: {
				$and: [{ "source.tags": { $in: ["viewer"] } }],
		  };

	try {
		const res = await localClient.query<images_with_tags>({
			query: GET_IMAGES,
			variables: {
				imagesPerPageForImageNavigation: 0,
				forImageNavigation: false,
				selectedClientEmails: selectedClientEmails,
				initialUploadDate: initialUploadDate,
				finalUploadDate: finalUploadDate,
				dbName: DBName.VDR,
				pageNumber: pageNumber,
				tags: [],
				projects: [],
				imagesPerPage: numberOfImagesInPage,
				labels: [],
				visualHashToSearch: null,
				pathPrefix: null,
				customQueryStr: JSON.stringify(customQueryJson),
				cacheRes: true,
			},
		});
		return Promise.resolve(res);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getShowroomImages(
	organizationIds: string[],
	pageNumber: number,
	numberOfImagesInPage: number,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<ApolloQueryResult<showroomImages>> {
	try {
		const res = await localClient.query<showroomImages, showroomImagesVariables>({
			query: gql`
				query showroomImages($pageNumber: Int!, $imagesPerPage: Int!, $organizationIds: [String]!) {
					showroomImages(
						pageNumber: $pageNumber
						imagesPerPage: $imagesPerPage
						organizationIds: $organizationIds
					) {
						images {
							url
							mediumUrl
							sourceUrl
							visualHash
							orgImageKey
						}
						totalPages
						totalImages
					}
				}
			`,
			variables: {
				organizationIds: organizationIds,
				pageNumber: pageNumber,
				imagesPerPage: numberOfImagesInPage,
			},
		});
		return Promise.resolve(res);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getUserImages(
	pageNumber: number,
	numberOfImagesInPage: number,
	localClient: ApolloClient<NormalizedCacheObject> = IframeClient
): Promise<ApolloQueryResult<getUserImagesInterface>> {
	try {
		const res = await localClient.query<getUserImagesInterface, getUserImagesVariables>({
			query: gql`
				query getUserImages($pageNumber: Int!, $imagesPerPage: Int!) {
					getUserImages(pageNumber: $pageNumber, imagesPerPage: $imagesPerPage) {
						images {
							url
							mediumUrl
							visualHash
						}
						totalPages
						totalImages
					}
				}
			`,
			variables: {
				pageNumber,
				imagesPerPage: numberOfImagesInPage,
			},
		});
		return Promise.resolve(res);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getStartImageOnboarding(
	visualHash: string,
	clientEmail: string,
	generated: boolean = false,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<startImageOnboarding> {
	try {
		const res = await localClient.query<startImageOnboarding, startImageOnboardingVariables>({
			query: gql`
				query startImageOnboarding($visualHash: String!, $clientEmail: String!, $generated: Boolean) {
					startImageOnboarding(visualHash: $visualHash, clientEmail: $clientEmail, generated: $generated) {
						onboardingStarted
						imageVhash
					}
				}
			`,
			variables: {
				visualHash: visualHash,
				clientEmail: clientEmail,
				generated: generated,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getOpenMondayTask(
	visualHash: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<openMondayTask> {
	try {
		const res = await localClient.query<openMondayTask, any>({
			query: gql`
				query openMondayTask($visualHash: String!) {
					openMondayTask(visualHash: $visualHash)
				}
			`,
			variables: {
				visualHash: visualHash,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getSendImageToDataloop(
	visualHash: string,
	clientEmail: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<sendImageToDataloop> {
	try {
		const res = await localClient.query<sendImageToDataloop, sendImageToDataloopVariables>({
			query: gql`
				query sendImageToDataloop($visualHash: String!, $clientEmail: String!) {
					sendImageToDataloop(visualHash: $visualHash, clientEmail: $clientEmail)
				}
			`,
			variables: {
				visualHash: visualHash,
				clientEmail: clientEmail,
			},
		});
		return Promise.resolve(res.data ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getAddOnboardingFieldsToImage(
	visualHash: string,
	clientEmail: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<any> {
	try {
		const res = await localClient.query<any, any>({
			query: gql`
				query addOnboardingFieldsToImage($visualHash: String!, $clientEmail: String!) {
					addOnboardingFieldsToImage(visualHash: $visualHash, clientEmail: $clientEmail)
				}
			`,
			variables: {
				visualHash: visualHash,
				clientEmail: clientEmail,
			},
		});
		return Promise.resolve(res.data.addOnboardingFieldsToImage ?? false);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getLabelsByPathPrefix(
	dbName: DBName,
	pathPrefix: string,
	query: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Label[]> {
	try {
		const res = await localClient.query<labelsByPathPrefix, labelsByPathPrefixVariables>({
			query: gql`
				query labelsByPathPrefix($dbName: DBName!, $pathPrefix: String!, $query: String) {
					labelsByPathPrefix(dbName: $dbName, pathPrefix: $pathPrefix, query: $query) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				pathPrefix: pathPrefix,
				query: query,
			},
		});
		return Promise.resolve(res.data.labelsByPathPrefix ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getLabelsByMongoId(
	dbName: DBName,
	mongoId: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Label[]> {
	try {
		const res = await localClient.query<labelsByMongoId, labelsByMongoIdVariables>({
			query: gql`
				query labelsByMongoId($dbName: DBName!, $mongoId: String!) {
					labelsByMongoId(dbName: $dbName, mongoId: $mongoId) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoId: mongoId,
			},
		});
		return Promise.resolve(res.data.labelsByMongoId ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getClientBillingRequestsPerAction(
	clientTokenList: string[],
	action: string,
	dbName: DBName,
	initialTimestamp: number,
	finalTimestamp: number,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<clientBillingRequestsPerAction_clientBillingRequestsPerAction> {
	try {
		const res = await localClient.query<clientBillingRequestsPerAction, clientBillingRequestsPerActionVariables>({
			query: gql`
				query clientBillingRequestsPerAction(
					$clientTokenList: [String]!
					$action: String!
					$dbName: DBName!
					$initialTimestamp: Float!
					$finalTimestamp: Float!
				) {
					clientBillingRequestsPerAction(
						clientTokenList: $clientTokenList
						action: $action
						dbName: $dbName
						initialTimestamp: $initialTimestamp
						finalTimestamp: $finalTimestamp
					) {
						numberOfRequests
					}
				}
			`,
			variables: {
				clientTokenList: clientTokenList,
				action: action,
				dbName: dbName,
				initialTimestamp,
				finalTimestamp,
			},
		});
		return Promise.resolve(res.data.clientBillingRequestsPerAction ?? 0);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getLabelsByMongoIdForMultipleImages(
	dbName: DBName,
	mongoIds: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Label[]> {
	try {
		const res = await localClient.query<any, any>({
			query: gql`
				query getLabelsByMongoIdForMultipleImages($dbName: DBName!, $mongoIds: [String!]!) {
					labelsByMongoIdForMultipleImages(dbName: $dbName, mongoIds: $mongoIds) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
			},
		});
		return Promise.resolve(res.data.labelsByMongoIdForMultipleImages ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export class getImagesReturnValues {
	images: Image[];
	totalPages: number;
	totalImages: number;
	queryStr: string;

	constructor(images: Image[], totalPages: number, totalImages: number, queryStr: string) {
		this.images = images;
		this.totalPages = totalPages;
		this.totalImages = totalImages;
		this.queryStr = queryStr;
	}
}

export const GET_IMAGES = gql`
	query images_with_tags(
		$imagesPerPageForImageNavigation: Int!
		$forImageNavigation: Boolean
		$selectedClientEmails: [String]!
		$initialUploadDate: Float!
		$finalUploadDate: Float!
		$dbName: DBName!
		$tags: [String!]!
		$projects: [String!]!
		$pageNumber: Int!
		$imagesPerPage: Int!
		$searchText: String
		$visualHashToSearch: String
		$labels: [String!]
		$pathPrefix: String
		$customQueryStr: String
		$cacheRes: Boolean
	) {
		images(
			imagesPerPageForImageNavigation: $imagesPerPageForImageNavigation
			forImageNavigation: $forImageNavigation
			selectedClientEmails: $selectedClientEmails
			initialUploadDate: $initialUploadDate
			finalUploadDate: $finalUploadDate
			dbName: $dbName
			tags: $tags
			projects: $projects
			pageNumber: $pageNumber
			imagesPerPage: $imagesPerPage
			searchText: $searchText
			visualHashToSearch: $visualHashToSearch
			labels: $labels
			pathPrefix: $pathPrefix
			customQueryStr: $customQueryStr
			mslString: "Both"
			validFacesString: "Both"
			cacheRes: $cacheRes
		) {
			images {
				mediumUrl
				width
				height
				visualHash
			}
			totalPages
			totalImages
			queryStr
		}
	}
`;

export async function getImageRepository(
	imagesPerPageForImageNavigation: number,
	selectedClientEmails: (string | null)[],
	initialUploadDate: number,
	finalUploadDate: number,
	validFacesString: ValidFacesOptions,
	mslString: string,
	selectedTags: string[],
	selectedProjects: string[],
	pageNumber: number,
	imagesPerPage: number,
	visualHashToSearch: string,
	dbName: DBName = DBName.VDR,
	forImageNavigation: boolean = false,
	pathPrefix: string | null = null,
	customQueryStr: string | null = null,
	selectedLabels: string[] = [],
	selectedText: string | null = null,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<getImagesReturnValues> {
	try {
		const res = await localClient.query<images, imagesVariables>({
			query: gql`
				query images(
					$imagesPerPageForImageNavigation: Int!
					$forImageNavigation: Boolean
					$selectedClientEmails: [String]!
					$initialUploadDate: Float!
					$finalUploadDate: Float!
					$validFacesString: String!
					$mslString: String!
					$dbName: DBName!
					$tags: [String!]!
					$projects: [String!]!
					$pageNumber: Int!
					$imagesPerPage: Int!
					$searchText: String
					$visualHashToSearch: String
					$labels: [String!]
					$pathPrefix: String
					$customQueryStr: String
				) {
					images(
						imagesPerPageForImageNavigation: $imagesPerPageForImageNavigation
						forImageNavigation: $forImageNavigation
						selectedClientEmails: $selectedClientEmails
						initialUploadDate: $initialUploadDate
						finalUploadDate: $finalUploadDate
						validFacesString: $validFacesString
						mslString: $mslString
						dbName: $dbName
						tags: $tags
						projects: $projects
						pageNumber: $pageNumber
						imagesPerPage: $imagesPerPage
						searchText: $searchText
						visualHashToSearch: $visualHashToSearch
						labels: $labels
						pathPrefix: $pathPrefix
						customQueryStr: $customQueryStr
					) {
						images {
							url
							smallUrl
							mediumUrl
							mongoId
							width
							height
							displayName
							visualHash
							imageLabels
						}
						totalPages
						totalImages
						queryStr
					}
				}
			`,
			variables: {
				imagesPerPageForImageNavigation: imagesPerPageForImageNavigation,
				forImageNavigation: forImageNavigation,
				selectedClientEmails: selectedClientEmails,
				initialUploadDate: initialUploadDate,
				finalUploadDate: finalUploadDate,
				validFacesString: validFacesString,
				mslString: mslString,
				dbName: dbName,
				tags: selectedTags,
				projects: selectedProjects,
				pageNumber: pageNumber,
				imagesPerPage: imagesPerPage,
				labels: selectedLabels,
				visualHashToSearch: visualHashToSearch,
				pathPrefix: pathPrefix,
				customQueryStr: customQueryStr,
				searchText: selectedText,
			},
		});
		const returnValues: getImagesReturnValues = new getImagesReturnValues(
			res.data.images?.images ?? [],
			res.data.images?.totalPages ?? 0,
			res.data.images?.totalImages ?? 0,
			res.data.images?.queryStr ?? ""
		);
		return Promise.resolve(returnValues);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getExportUploadedVhashesToCsv(
	successfullyUploadedVhashes: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<any> {
	try {
		const res = await localClient.query<any, any>({
			query: gql`
				query exportUploadedVhashesToCsv($successfullyUploadedVhashes: [String!]!) {
					exportUploadedVhashesToCsv(successfullyUploadedVhashes: $successfullyUploadedVhashes) {
						csvFile
					}
				}
			`,
			variables: {
				successfullyUploadedVhashes: successfullyUploadedVhashes,
			},
		});
		return Promise.resolve(res.data.exportUploadedVhashesToCsv);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getExportImagesToCsv(
	selectedClientEmails: (string | null)[],
	initialUploadDate: number,
	finalUploadDate: number,
	validFacesString: ValidFacesOptions,
	mslString: string,
	selectedTags: string[],
	selectedProjects: string[],
	pageNumber: number,
	visualHashToSearch: string,
	dbName: DBName = DBName.VDR,
	pathPrefix: string | null = null,
	customQueryStr: string | null = null,
	selectedLabels: string[] = [],
	selectedText: string | null = null,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<exportImagesToCsv_exportImagesToCsv | null> {
	try {
		const res = await localClient.query<exportImagesToCsv, exportImagesToCsvVariables>({
			query: gql`
				query exportImagesToCsv(
					$selectedClientEmails: [String]!
					$initialUploadDate: Float!
					$finalUploadDate: Float!
					$validFacesString: String!
					$mslString: String!
					$dbName: DBName!
					$tags: [String!]!
					$projects: [String!]!
					$pageNumber: Int!
					$searchText: String
					$visualHashToSearch: String
					$labels: [String!]
					$pathPrefix: String
					$customQueryStr: String
				) {
					exportImagesToCsv(
						selectedClientEmails: $selectedClientEmails
						initialUploadDate: $initialUploadDate
						finalUploadDate: $finalUploadDate
						validFacesString: $validFacesString
						mslString: $mslString
						dbName: $dbName
						tags: $tags
						projects: $projects
						pageNumber: $pageNumber
						searchText: $searchText
						visualHashToSearch: $visualHashToSearch
						labels: $labels
						pathPrefix: $pathPrefix
						customQueryStr: $customQueryStr
					) {
						csvFile
					}
				}
			`,
			variables: {
				selectedClientEmails: selectedClientEmails,
				initialUploadDate: initialUploadDate,
				finalUploadDate: finalUploadDate,
				validFacesString: validFacesString,
				mslString: mslString,
				dbName: dbName,
				tags: selectedTags,
				projects: selectedProjects,
				pageNumber: pageNumber,
				labels: selectedLabels,
				visualHashToSearch: visualHashToSearch,
				pathPrefix: pathPrefix,
				customQueryStr: customQueryStr,
				searchText: selectedText,
			},
		});
		return Promise.resolve(res.data.exportImagesToCsv);
	} catch (error) {
		return Promise.reject(error);
	}
}

// export async function userHasCredits(
// 	creditType: string,
// 	localClient: ApolloClient<NormalizedCacheObject> = client
// ): Promise<boolean> {
// 	try {
// 		const res = await localClient.query<userHasCreditsInterface, userHasCreditsVariables>({
// 			query: gql`
// 				query userHasCredits($creditType: String!) {
// 					userHasCredits(creditType: $creditType)
// 				}
// 			`,
// 			variables: {
// 				creditType,
// 			},
// 		});
// 		return Promise.resolve(res.data?.userHasCredits);
// 	} catch (error) {
// 		return Promise.reject(error);
// 	}
// }

export async function getSubscriptionConfig(
	subscriptionType: SubscriptionTypes,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<subscriptionConfig_subscriptionConfig> {
	try {
		const res = await localClient.query<subscriptionConfigInterface, subscriptionConfigVariables>({
			query: gql`
				query subscriptionConfig($subscriptionType: String!) {
					subscriptionConfig(subscriptionType: $subscriptionType) {
						peopleCustomizations
						videos
					}
				}
			`,
			variables: {
				subscriptionType,
			},
		});
		return Promise.resolve(res?.data?.subscriptionConfig);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getApiSubscriptionConfig(
	apiSubscriptionType: ApiSubscriptionTypes,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<apiSubscriptionConfig_apiSubscriptionConfig> {
	try {
		const res = await localClient.query<apiSubscriptionConfigInterface, apiSubscriptionConfigVariables>({
			query: gql`
				query apiSubscriptionConfig($apiSubscriptionType: String!) {
					apiSubscriptionConfig(apiSubscriptionType: $apiSubscriptionType) {
						productId
						storeId
						contractYearlyId
						contractMonthlyId
					}
				}
			`,
			variables: {
				apiSubscriptionType,
			},
		});
		return Promise.resolve(res.data.apiSubscriptionConfig);
	} catch (error) {
		return Promise.reject(error);
	}
}

async function sendPaymentBuyNowUrlRequest(
	subscriptionType: ApiSubscriptionTypes | SubscriptionTypes,
	subscriptionPeriod: SubscriptionPeriods,
	isApi: boolean,
	orgUid: string,
	couponCode: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		const res = await localClient.query<getPaymentBuyNowUrlInterface, getPaymentBuyNowUrlVariables | any>({
			query: gql`
				query getPaymentBuyNowUrl(
					$subscriptionType: String!
					$subscriptionPeriod: String!
					$isApi: Boolean!
					$orgUid: String!
					$couponCode: String!
				) {
					getPaymentBuyNowUrl(
						subscriptionType: $subscriptionType
						subscriptionPeriod: $subscriptionPeriod
						isApi: $isApi
						orgUid: $orgUid
						couponCode: $couponCode
					)
				}
			`,
			variables: {
				subscriptionType,
				subscriptionPeriod,
				isApi,
				orgUid,
				couponCode,
			},
		});
		return Promise.resolve(res?.data?.getPaymentBuyNowUrl);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getPaymentBuyNowUrl(
	subscriptionType: ApiSubscriptionTypes | SubscriptionTypes,
	subscriptionPeriod: SubscriptionPeriods,
	isApi: boolean,
	orgUid: string,
	couponCode: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		let paymentLink = await sendPaymentBuyNowUrlRequest(
			subscriptionType,
			subscriptionPeriod,
			isApi,
			orgUid,
			couponCode,
			localClient
		);
		return Promise.resolve(paymentLink);
	} catch (error: any) {
		if (error.networkError && error.networkError.statusCode === 401) {
			try {
				let paymentLink = await sendPaymentBuyNowUrlRequest(
					subscriptionType,
					subscriptionPeriod,
					isApi,
					orgUid,
					couponCode,
					localClient
				);
				return Promise.resolve(paymentLink);
			} catch (error) {
				return Promise.resolve("NO_LINK");
			}
		}
		return Promise.reject(error);
	}
}

export async function getPaymentInfo(
	orgUid?: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<getPaymentInfo_getPaymentInfo | null> {
	try {
		const res = await localClient.query<getPaymentInfoInterface, getPaymentInfoVariables>({
			query: gql`
				query getPaymentInfo($orgUid: String) {
					getPaymentInfo(orgUid: $orgUid) {
						cardLastFourDigits
						cardType
						cardSubType
						expirationMonth
						expirationYear
					}
				}
			`,
			variables: {
				orgUid,
			},
		});
		return Promise.resolve(res.data.getPaymentInfo);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getFolders(
	dbName: DBName = DBName.DATASETS,
	pathPrefix: string = "",
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Folder[]> {
	try {
		const res = await localClient.query<folders, foldersVariables>({
			query: gql`
				query folders($dbName: DBName!, $pathPrefix: String!) {
					folders(dbName: $dbName, pathPrefix: $pathPrefix) {
						fullPath
						folderName
					}
				}
			`,
			variables: {
				dbName: dbName,
				pathPrefix: pathPrefix,
			},
		});
		return Promise.resolve(res.data.folders ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getGCPInstanceMetadata(
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<InstanceMetadata[]> {
	try {
		const res = await localClient.query<gcpInstanceMetadata>({
			query: gql`
				query gcpInstanceMetadata {
					gcpInstanceMetadata {
						platformName
						name
						zone
						status
						vcpuNum
						lastStartTimestampUnix
						instanceId
						instanceType
						gpuType
					}
				}
			`,
		});
		return Promise.resolve(res.data.gcpInstanceMetadata ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getAWSInstanceMetadata(
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<InstanceMetadata[]> {
	try {
		const res = await localClient.query<awsInstanceMetadata>({
			query: gql`
				query awsInstanceMetadata {
					awsInstanceMetadata {
						platformName
						name
						zone
						status
						vcpuNum
						lastStartTimestampUnix
						instanceId
						instanceType
						gpuType
					}
				}
			`,
		});
		return Promise.resolve(res.data.awsInstanceMetadata ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getPostUrls(
	imageNames: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<PresignedPostUrl[]> {
	try {
		const res = await localClient.query<getPostUrlsForUpload, getPostUrlsForUploadVariables>({
			query: gql`
				query getPostUrlsForUpload($imageNames: [String!]!) {
					getPostUrlsForUpload(imageNames: $imageNames) {
						url
						fields
					}
				}
			`,
			variables: {
				imageNames: imageNames,
			},
		});
		return Promise.resolve(res.data.getPostUrlsForUpload ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getOrganization(
	orgUid: string,
	iframeId?: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<getOrganization_getOrganization | null> {
	try {
		const res = await localClient.query<getOrganizationResult, getOrganizationVariables>({
			query: gql`
				query getOrganization($orgUid: String!, $iframeId: String) {
					getOrganization(orgUid: $orgUid, iframeId: $iframeId) {
						apiKeys {
							apiToken
							created
							status
							keyType
						}
						uid
						name
						logoUrl
						galleryId
					}
				}
			`,
			variables: {
				orgUid,
				iframeId,
			},
		});
		return Promise.resolve(res.data.getOrganization);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function prepareImageForExport(
	path: string,
	orgId: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string | null> {
	try {
		const filename = new URL(path).pathname.split("/").pop() ?? path;
		const aesCipher = new AESCipher(orgId);
		const encryptedPath = aesCipher.encrypt(filename);
		const res = await localClient.query<prepareImageForExportResult, prepareImageForExportVariables>({
			query: gql`
				query prepareImageForExport($path: String!) {
					prepareImageForExport(path: $path)
				}
			`,
			variables: {
				path: encryptedPath,
			},
		});
		return Promise.resolve(res.data.prepareImageForExport);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getTailoredModels(
	orgId: string,
	iframeId?: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<getTailoredModels_getTailoredModels[] | null> {
	try {
		const res = await localClient.query<getTailoredModelsResult, getTailoredModelsVariables>({
			query: gql`
				query getTailoredModels($orgId: String!) {
					getTailoredModels(orgId: $orgId) {
						id
						name
						status
						description
						endpointModelName
					}
				}
			`,
			variables: {
				orgId,
			},
		});
		return Promise.resolve(res.data.getTailoredModels);
	} catch (error) {
		return Promise.reject(error);
	}
}

// export const GET_ETHNICITIES_LIST = gql`
// 	query EthnictyList {
// 		metaData {
// 			ethnicities {
// 				name
// 				displayName
// 				isDefault
// 			}
// 		}
// 	}
// `;
// export const GET_SEMANTICS_LIST = gql`
// 	query senamticsList {
// 		metaData {
// 			semantics {
// 				maxLayer
// 				maxName
// 				maxValue
// 				minLayer
// 				minName
// 				minValue
// 				name
// 				pca
// 				value
// 			}
// 		}
// 	}
// `;

export const IMAGE_DETAILS = gql`
	query ImageEditorData($visualHash: String) {
		vdrObject(visualHash: $visualHash) {
			url
			vdrAwsFaceDetection {
				faces {
					ethnicityValidFacesIndex
					gender {
						value
					}
					faceAttributes {
						value
						name
					}
					faceSentiments {
						name
					}
					ageRange {
						low
						high
					}
				}
			}
			width
			height
		}
	}
`;
// removed from IMAGE_DETAILS:
// metaData {
// 	pipelineVersion
// 	settings {
// 		name
// 		isOn
// 		description
// 		value
// 	}
// }

// export const TOOL_CONFIG = gql`
// 	query ToolConfig($facesData: [FaceData!]!, $isTesting: Boolean) {
// 		toolConfig(facesData: $facesData, isTesting: $isTesting) {
// 			objectTypesData {
// 				objectType
// 				data {
// 					level1Id
// 					level1Name
// 					data {
// 						level2Id
// 						level2Name
// 						faceThumbnails {
// 							faceIndex
// 							thumbnails {
// 								title
// 								baseImageUrl
// 								imageUrl
// 								ethnicity
// 								ethnicityValue
// 								semantics {
// 									value
// 									name
// 								}
// 							}
// 						}
// 						sliders {
// 							value
// 							name
// 							minName
// 							maxName
// 							minValue
// 							maxValue
// 							pca
// 							minLayer
// 							maxLayer
// 							reversed
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// export const GET_SETTINGS_LIST = gql`
// 	query getSettingsList {
// 		metaData {
// 			settings {
// 				name
// 				isOn
// 				description
// 				value
// 			}
// 		}
// 	}
// `;

export const GET_ASK_ALAN_SCORES = gql`
	query GetAskAlanScores(
		$ethnicity: String!
		$objectType: String!
		$crowdScoring: Boolean!
		$automaticScoring: Boolean!
		$rects: [Rect]
		$visualHash: String!
	) {
		getAskAlanScores(
			visualHash: $visualHash
			objectType: $objectType
			crowdScoring: $crowdScoring
			automaticScoring: $automaticScoring
			rects: $rects
			ethnicity: $ethnicity
		) {
			sliderId
			score
		}
	}
`;

export const VALID_VISUAL_HASH = gql`
	query ValidVisualHash($visualHash: String!) {
		validVisualHash(visualHash: $visualHash)
	}
`;

export const GET_USERS = gql`
	query users($orgUid: String, $query: String) {
		users(orgUid: $orgUid, query: $query) {
			email
			role
			profilePicture
			userName
			uid
			company
			organizationsObjs {
				uid
				name
			}
			userRole
			manuallyManaged
			subscription {
				webSubscriptionType
				webSubscriptionPeriod
				bsWebShopperId
				bsWebSubscriptionId
			}
		}
	}
`;

export const GET_IFRAME_CONFIG = gql`
	query getIframeConfig($iframeId: String!) {
		getIframeConfig(iframeId: $iframeId) {
			uid
			authUsername
			authPassword
			organization
			allowedDomains
			enabledTgModels
			allowedLanguages {
				english
				japanese
			}
			enabledLanguages {
				english
				japanese
			}
			allowedPages {
				gallery
				playground
				assets
				campaign
			}
			enabledPages {
				gallery
				playground
				assets
				campaign
			}
			allowedFeatures {
				expression
				diversity
				appearance
				camera_movement_video
				logo
				fine_tuning
				mood
				remove
				blur
				paint
				replace
				background
				remove_object
				text_editor
				crop
				psd_download
				super_resolution
				expand_background
				image_style
				campaign_create
				auto_obj_identification
			}
			name
			enabledFeatures {
				expression
				diversity
				appearance
				camera_movement_video
				logo
				fine_tuning
				mood
				remove
				blur
				paint
				replace
				background
				remove_object
				text_editor
				crop
				psd_download
				super_resolution
				expand_background
				image_style
				campaign_create
				auto_obj_identification
			}
			description
			updatedDate
			target
			saveDestination
			uploadDestination
			whiteLabel
			enableDownload
			usePublicGallery
			sourceDomain
			customStyle {
				logo
				primaryColor
			}
			disableUpload
			hideStockImages
			embeddedIframe
			disableDownloadPsd
			disableSave
			disableSavePsd
			saveLabel
			watermarkPath
			disableDownloadImage
			enableCloseButton
			editorSelectedTab
			defaultGenAspectRatio
			genModels {
				bria2_3_fast
				bria2_2_hd
				bria2_3
			}
			status
		}
	}
`;

export const VALIDATE_COUPON_CODE = gql`
	query validateCouponCode($couponCode: String!) {
		validateCouponCode(couponCode: $couponCode) {
			isValid
			amount
			cType
		}
	}
`;

export const GET_ALL_ORGANIZATIONS = gql`
	query allOrganizations($query: String) {
		allOrganizations(query: $query) {
			apiKeys {
				apiToken
				created
				status
				keyType
			}
			apiSubscription {
				bsApiSubscriptionId
				bsApiShopperId
				apiSubscriptionPeriod
				apiSubscriptionStartDate
				apiSubscriptionStatus
				apiSubscriptionType
			}
			uid
			name
			logoUrl
			galleryId
		}
	}
`;

export const GET_MEDIA_LAYOUTS = gql`
	query getMediaLayouts($media: String!, $brandId: String) {
		getMediaLayouts(media: $media, brandId: $brandId) {
			layouts {
				id
				target
			}
		}
	}
`;

export const GET_CONTRIBUTORS = gql`
	query getContributors($imageUrl: String!, $threshold: Float, $maskUrl: String) {
		getContributors(imageUrl: $imageUrl, threshold: $threshold, maskUrl: $maskUrl) {
			contributors {
				providerName
				images
			}
		}
	}
`;

export const SEND_ACCESS_MODEL_EMAIL = gql`
	query sendAccessModelEmail($modelName: String!) {
		sendAccessModelEmail(modelName: $modelName)
	}
`;

export const SEND_MODEL_WAITLIST_EMAIL = gql`
	query sendModelWaitlistEmail($modelName: String!) {
		sendModelWaitlistEmail(modelName: $modelName)
	}
`;

export const GET_USER_BRANDS = gql`
	query getUserBrands($includeGalleries: [String!], $includeUserGallery: Boolean!) {
		getUserBrands(includeGalleries: $includeGalleries, includeUserGallery: $includeUserGallery) {
			id
			name
			header1 {
				style
				font
				size
				color
			}
			header2 {
				style
				font
				size
				color
			}
			logo
			colorPallet
			gardian
			mood {
				moodParameters {
					light {
						brightness
						contrast
					}
					color {
						vibrance
						saturation
					}
					colorMixer {
						color
						hue
						saturation
						luminance
					}
				}
			}
		}
	}
`;

export const GET_NUMBER_OF_ASSETS_PER_ORGANIZATION = gql`
	query getNumberOfAssetsPerOrganization($orgId: String!) {
		getNumberOfAssetsPerOrganization(orgId: $orgId)
	}
`;

export const EXPORT_TO_EXCEL_USERS = gql`
	query exportToExcelUsers($orgUid: String!) {
		exportToExcelUsers(orgUid: $orgUid)
	}
`;

export const GET_ORGANIZATION_FONTS = gql`
	query getOrganizationFonts($orgId: String!) {
		getOrganizationFonts(orgId: $orgId) {
			id
			name
			orgId
			src
		}
	}
`;

export const GET_ORGANIZATION_CHANNELS = gql`
	query getOrganizationChannels($orgId: String!) {
		getOrganizationChannels(orgId: $orgId) {
			id
			name
			orgId
		}
	}
`;

export const GET_ORGANIZATION_PLACEMENTS = gql`
	query getOrganizationPlacements($orgId: String!) {
		getOrganizationPlacements(orgId: $orgId) {
			id
			name
			width
			height
			channelId
		}
	}
`;

export const GET_ORGANIZATION_BRAND_PRESETS = gql`
	query getOrganizationBrandPresets($orgId: String!) {
		getOrganizationBrandPresets(orgId: $orgId) {
			id
			name
			primaryMessage
			secondaryMessage
			primaryFontId
			secondaryFontId
			logoId
			colorPalleteId
			status
		}
	}
`;

export const GET_COLOR_PALLET = gql`
	query getColorPallet($id: Int!) {
		getColorPallet(id: $id) {
			id
			colors
		}
	}
`;

export const GET_LOGO = gql`
	query getLogo($id: Int!) {
		getLogo(id: $id) {
			id
			name
			src
		}
	}
`;

export const GET_ORGANIZATION_PLACEMENTS_BY_CHANNEL_IDS = gql`
	query getOrganizationPlacementsByChannelIds($channelIds: [Int!]) {
		getOrganizationPlacementsByChannelIds(channelIds: $channelIds) {
			id
			name
			width
			height
			channelId
		}
	}
`;

export const GET_RANDOM_TEMPLATE = gql`
	query getRandomTemplate($placementsIds: [Int!], $orgId: String!) {
		getRandomTemplate(placementsIds: $placementsIds, orgId: $orgId) {
			id
		}
	}
`;

export const GET_LAYOUTS = gql`
	query getLayouts($placementsIds: [Int!], $templateId: Int!) {
		getLayouts(placementsIds: $placementsIds, templateId: $templateId) {
			id
		}
	}
`;

export const GET_ORGANIZATION_TEMPLATES = gql`
	query getOrganizationTemplates($orgId: String!) {
		getOrganizationTemplates(orgId: $orgId) {
			id
			name
			orgId
		}
	}
`;

export const GET_ORGANIZATION_TEMPLATES_BY_CHANNEL_IDS = gql`
	query getOrganizationTemplatesByChannelIds($channelIds: [Int!], $orgId: String!) {
		getOrganizationTemplatesByChannelIds(channelIds: $channelIds, orgId: $orgId) {
			id
			name
			orgId
		}
	}
`;

export const GET_ORGANIZATION_CHANNELS_BY_TEMPLATE_ID = gql`
	query getOrganizationChannelsByTemplateId($templateId: Int!, $orgId: String!) {
		getOrganizationChannelsByTemplateId(templateId: $templateId, orgId: $orgId) {
			id
			name
			orgId
		}
	}
`;

export const GET_ORGANIZATION_CHANNELS_BY_TEMPLATES_IDS = gql`
	query getOrganizationChannelsByTemplatesIds($templatesIds: [Int!]) {
		getOrganizationChannelsByTemplatesIds(templatesIds: $templatesIds) {
			id
			name
			orgId
		}
	}
`;

export const GET_BRAND_PRESET = gql`
	query getBrandPreset($id: Int!) {
		getBrandPreset(id: $id) {
			id
			name
			primaryMessage
			secondaryMessage
			primaryFontId
			secondaryFontId
			colorPalleteId
			logoId
			status
		}
	}
`;
