import { usePromise } from "./usePromise";
import { useMultiPromise } from "./useMultiPromises";
import { getUserBrands_getUserBrands } from "./../GraphQL/types/getUserBrands";
import { useContext, useEffect, useState } from "react";
import Context from "../Context/Context";
import BriaAPI from "../sdk/resources/briaAPI";
import { ImageEditorContext } from "../Pages/Tool/ImageEditor";
import { GENERAL_ORG_ID } from "../Constants";
import { ShouldClearWatermark } from "../Helpers/clearWatermark";

export const useGeneratedMoodImgs = (
	vhash: string,
	imgUrl: string,
	config?: any,
	disableApply = false
) => {
	const { getPlaygroundUserMoods } = useContext(ImageEditorContext);
	const context = useContext(Context);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const { user } = context;
	const [loading, setLoading] = useState(false);
	const [processedMoods, setProcessedMoods] = useState<any[]>([]);
	const organizationUid = user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;

	const [applyMoodToImgs, imgsAfterMoodApply = []] = useMultiPromise();

	const mapToDropdownOptions = (option: getUserBrands_getUserBrands) => ({
		...option,
		title: option.name,
	});

	const fetchMoodsReq = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const userMoods = await getPlaygroundUserMoods();
				const mappedOptions = userMoods.map(mapToDropdownOptions);
				resolve(mappedOptions);
			} catch (error) {
				reject(error);
			}
		});
	};

	const generateUrlBaseOnHash = (moodName: string) => {
		return `https://labs-assets.bria.ai/predefined_moods_results/${moodName}_${vhash}.JPEG`;
	};

	const handleApplyMoodValuesCall = async (
		moodParameters: any,
		moodName: string
	): Promise<any | undefined> => {
		if (disableApply) return generateUrlBaseOnHash(moodName);

		const instance = BriaAPI.getInstance(vhash);
		try {
			const res =
				(await (instance as any)._applyMood(
					vhash,
					instance.getLastApiCallSid(),
					{
						...moodParameters,
					},
					shouldClearWatermark() ? organizationUid : ""
				)) || {};
			const {
				data: { image_res, sid },
			} = res;
			// if there's no selected moods auto-select xmp generated thumbnail
			return { imgRes: image_res || "", sid: sid || "" };
		} catch (error) {}
	};

	const [fetchMoods, moods = []] = usePromise(fetchMoodsReq);

	const MergeWithMoods = (
		resObj: { imgRes: string; sid: string },
		index: number,
		passedMoods: any[]
	) => ({
		...passedMoods[index],
		imageRes: resObj.imgRes,
		sid: resObj.sid,
		moodParameters: passedMoods[index]?.mood?.moodParameters ?? {},
	});

	const applyMoodsToSuggestions = (_moods: any[]) => {
		const moodImgsPromiseArray = _moods.map((moodObj: any) =>
			handleApplyMoodValuesCall(
				moodObj?.mood?.moodParameters ?? {},
				moodObj.name
			)
		);
		applyMoodToImgs(moodImgsPromiseArray).then((objs: any[]) => {
			const afterMergeWithMoods = objs.map((obj, index) =>
				MergeWithMoods(obj, index, _moods)
			);
			setProcessedMoods(afterMergeWithMoods);
			setLoading(false);
			(window as any).isFetchingMoods = false;
		});
	};

	useEffect(() => {
		// on component removal stop window loading
		return () => {
			(window as any).isFetchingMoods = false;
		};
	}, []);

	useEffect(() => {
		if (!user?.uid || !vhash || !imgUrl) return;
		setLoading(true);
		if ((window as any).isFetchingMoods) return; // this line is added to prevent douple fetches that's because of marketing msg douple render
		(window as any).isFetchingMoods = true;
		fetchMoods().then(applyMoodsToSuggestions);
	}, [vhash]);

	return [processedMoods, loading];
};
