import {
	Box,
	Link,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { ApiSubscriptionTypes } from "../../Constants/SubscriptionConstants";
import BriaButton, { BUTTONTYPES } from "../../Components/UI/BriaButton";
import ToolHeaderMarketing from "../../Components/Tools/ToolHeaderMarketing";
import { getNextApiRecommendedSubscription } from "../../Helpers/subscription";
import ToolHeader from "../../Components/Tools/ToolHeader";
import Check from "../../assets/Check.svg";
import Vector from "../../assets/Vector 187.svg";
import Info from "../../assets/svgs/info-circle.svg";
import { Trans, useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import QAList from "./QAList";
import StartupProgramTeaser from "./StartupProgramTeaser";
import { getPlatformHost } from "../../Config/Env";
import ConsumptionFeeInfoTable from "./ConsumptionFeeInfoTable";
import LinksConst from "../../Constants/LinksConst";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import PricingQuote from "./PricingQuote";
import BriaConfirmationDialog from "../../Components/BriaConfirmationDialog";

interface IProps {
	isMarketingFlag: boolean;
}

function ApiPricingComponent(props: IProps) {
	// const context = useContext(Context);
	// const queryParams = new URLSearchParams(window.location.search);
	// const { isGuestUser } = IsGuestUser();
	const { t } = useTranslation("translation", { keyPrefix: "pricingPage" });
	// const selectedOrganization = context.selectedOrganization;
	let currentPlan: ApiSubscriptionTypes | undefined = undefined;
	// if (
	// 	!isGuestUser() &&
	// 	(selectedOrganization?.apiSubscription?.apiSubscriptionStatus === ApiSubscriptionStatuses.active ||
	// 		selectedOrganization?.apiSubscription?.apiSubscriptionType === ApiSubscriptionTypes.starter) &&
	// 	Object.values(ApiSubscriptionTypes).includes(
	// 		selectedOrganization?.apiSubscription?.apiSubscriptionType as ApiSubscriptionTypes
	// 	)
	// ) {
	// 	currentPlan = selectedOrganization?.apiSubscription?.apiSubscriptionType as ApiSubscriptionTypes;
	// }
	const recommendedPlan = getNextApiRecommendedSubscription(currentPlan ?? ApiSubscriptionTypes.basic);
	const classes = useStyles({
		currentPlan,
	});

	const [selectedPlan, setSelectedPlan] = useState<ApiSubscriptionTypes>(ApiSubscriptionTypes.none);
	// const [shouldOpenAgreeOnTermsDialog, setShouldOpenAgreeOnTermsDialog] = useState<boolean>(false);
	// const [shouldOpenTermsDialog, setShouldOpenTermsDialog] = useState<boolean>(false);
	// const [shouldOpenCreateOrgDialog, setShouldOpenCreateOrgDialog] = useState<boolean>(false);
	// const [shouldOpenSelectOrgDialog, setShouldOpenSelectOrgDialog] = useState<boolean>(false);
	// const [buyNowDialogConfig, setBuyNowDialogConfig] = useState<BuyNowDialogConfig | undefined>();
	// const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
	// const [shouldOpenBuyNowDialog, setShouldOpenBuyNowDialog] = useState<boolean>(false);
	const [openQuete, setOpenQuete] = useState(false);
	const [openQuoteConfirmation, setOpenQuoteConfirmation] = useState(false);

	// const handleSelectBasic = () => {
	// 	if (context.allOrganizations.length === 0) {
	// 		setShouldOpenCreateOrgDialog(true);
	// 	} else if (context.allOrganizations.length > 1) {
	// 		setShouldOpenSelectOrgDialog(true);
	// 	} else {
	// 		setShouldOpenAgreeOnTermsDialog(true);
	// 	}
	// };

	// useEffect(() => {
	// 	if (!isGuestUser() && !context.isOrganizationsLoading) {
	// 		if (context.authDialogSource === "Select Plan") {
	// 			context.setAuthDialogSource("");
	// 			context.setAuthDialogState(false);
	// 			handleSelectBasic();
	// 		}
	// 	}
	// }, [context.user, context.user?.updated, context.isOrganizationsLoading]);

	function getTableContent() {
		return (
			<Table aria-label="subscriptions table" stickyHeader={true}>
				{getTableHeadContent()}
				{getTableBodyContent()}
			</Table>
		);
	}

	const getTableHeadContent = () => {
		return (
			<TableHead>
				<TableRow className={classes.firstRow}>
					<TableCell
						className={clsx(
							classes.tableCell,
							classes.noBottomBorder,
							classes.textAlignLeft,
							classes.firstColumn
						)}
					>
						<Typography className={classes.popupHeader}>{t("title")}</Typography>
					</TableCell>

					<TableCell
						className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}
					></TableCell>
					<TableCell
						className={clsx(classes.tableCell, classes.noBottomBorder, classes.headerRow, {
							[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
						})}
					>
						{t("basic.header")}
						<br />
						<Typography className={classes.planDesc}>{t("basic.subHeader")}</Typography>
					</TableCell>
					<TableCell
						className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}
					></TableCell>
					<TableCell
						className={clsx(
							classes.tableCell,
							classes.noBottomBorder,
							classes.headerRow,

							{
								[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
								[classes.recommendedPlanfirstRow]: recommendedPlan === ApiSubscriptionTypes.pro,
							}
						)}
					>
						{t("pro.header")}
						<br />
						<Typography className={classes.planDesc}>{t("pro.subHeader")}</Typography>
					</TableCell>
					<TableCell
						className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}
					></TableCell>
					<TableCell
						className={clsx(classes.tableCell, classes.noBottomBorder, classes.headerRow, {
							[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
						})}
					>
						{t("ultimate.header")}
						<br />
						<Typography className={classes.planDesc}>{t("ultimate.subHeader")}</Typography>
					</TableCell>
				</TableRow>
			</TableHead>
		);
	};

	const handleSelectPlan = (plan: ApiSubscriptionTypes) => {
		setSelectedPlan(plan);
		if (isDowngrading(plan)) {
			window.open("https://bria.ai/contact-us-app/", "_blank");
		} else {
			if (plan === ApiSubscriptionTypes.basic) {
				// if (isGuestUser()) {
				// 	context.setAuthDialogSource("Select Plan");
				// 	context.setAuthDialogState(true);
				// } else {
				Analytics.logEvent(ANALYTICS_EVENTS.BASIC_API_SUBSCRIPTION_START);
				// handleSelectBasic();
				window.open(`${getPlatformHost()}register/?pricing=true&selectedPlan=starter`, "_blank");
				// }
			} else if (plan === ApiSubscriptionTypes.pro) {
				Analytics.logEvent(ANALYTICS_EVENTS.PRO_API_SUBSCRIPTION_START);
				setOpenQuete(true);
				// window.open(`${getPlatformHost()}register/?pricing=true&selectedPlan=pro`, "_blank");
			} else if (plan === ApiSubscriptionTypes.ultimate) {
				Analytics.logEvent(ANALYTICS_EVENTS.ULTIMATE_API_SUBSCRIPTION_START);
				setOpenQuete(true);
			}
		}
	};

	const getPlanRank = (plan: ApiSubscriptionTypes) =>
		plan === ApiSubscriptionTypes.starter
			? 0
			: plan === ApiSubscriptionTypes.basic
			? 1
			: plan === ApiSubscriptionTypes.pro
			? 2
			: 3;

	const isDowngrading = (plan: ApiSubscriptionTypes) => currentPlan && getPlanRank(plan) < getPlanRank(currentPlan);

	const getPlanButton = (plan: ApiSubscriptionTypes) => (
		<BriaButton
			className={classes.button}
			// disabled={
			// 	plan === currentPlan ||
			// 	context.isOrganizationsLoading ||
			// 	(plan === ApiSubscriptionTypes.basic && loadingPayment)
			// }
			onClick={() => handleSelectPlan(plan)}
			buttonType={BUTTONTYPES.PRIMARYPURPLE}
		>
			{t(`${plan}.CTA`)}
			{/*{plan === currentPlan*/}
			{/*	? t("yourCurrentPlan")*/}
			{/*	: isDowngrading(plan)*/}
			{/*	? t("contactUs")*/}
			{/*	: plan === ApiSubscriptionTypes.basic && loadingPayment*/}
			{/*	? "Loading"*/}
			{/*	: t(`${plan}.CTA`)}*/}
		</BriaButton>
	);

	const RowTooltip = ({ description }: { description: string | React.ReactNode }) => (
		<Tooltip
			interactive={true}
			enterDelay={100}
			leaveDelay={50}
			placement="top-end"
			classes={{
				tooltip: classes.tooltipStyle,
			}}
			PopperProps={{
				style: {
					boxShadow: "none",
					border: "none",
				},
			}}
			title={<Typography className={classes.tooltipDesc}>{description}</Typography>}
		>
			<img className={classes.tooltipIconFirstCol} src={Info} alt="" />
		</Tooltip>
	);

	const getTableBodyContent = () => {
		const TABLE_BODY_ROWS = [
			<TableRow>
				<TableCell
					className={clsx(
						classes.tableCell,
						classes.noBottomBorder,
						classes.textAlignLeft,
						classes.firstColumn
					)}
				></TableCell>

				<TableCell className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}></TableCell>
				{/* </>
                                    )} */}
				<TableCell
					className={clsx(classes.tableCell, classes.noBottomBorder, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					{getPlanButton(ApiSubscriptionTypes.basic)}
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, classes.noBottomBorder, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					{getPlanButton(ApiSubscriptionTypes.pro)}
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.noBottomBorder, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, classes.noBottomBorder, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					{getPlanButton(ApiSubscriptionTypes.ultimate)}
				</TableCell>
			</TableRow>,

			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.platformFee.name")}
					<RowTooltip description={t("categories.platformFee.description")} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					{/*<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>*/}
					{/*	{t("pro.platformFee")}*/}
					{/*</Typography>*/}
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.includedUsage.name")}
					<RowTooltip description={t("categories.includedUsage.description")} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>
						<Trans i18nKey={"pricingPage.basic.includedUsage"} />
					</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("pro.includedUsage")}
					</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Vector} alt={"vector"} />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.additionalUsage.name")}
					<RowTooltip description={t("categories.additionalUsage.description")} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("basic.additionalUsage")}
						{/*<ConsumptionFeeTooltipInfoTable plan={ApiSubscriptionTypes.basic} />*/}
					</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("pro.additionalUsage")}
						{/*<ConsumptionFeeTooltipInfoTable plan={ApiSubscriptionTypes.pro} />*/}
					</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<Typography className={classes.infoWrapper} style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("ultimate.additionalUsage")}
					</Typography>
				</TableCell>
			</TableRow>,

			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.legal.name")}
					<RowTooltip
						description={
							<Typography className={clsx(classes.description, classes.tooltipDesc)}>
								<Trans
									i18nKey={"pricingPage.categories.legal.description"}
									components={[
										<Link
											style={{ cursor: "pointer" }}
											onClick={() => {
												window.open(
													`${LinksConst.BRIA_WEBSITE}terms-and-conditions/`,
													"_blank"
												);
											}}
										/>,
									]}
								/>
							</Typography>
						}
					/>
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.aiAccess.name")}
					<RowTooltip description={t("categories.aiAccess.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.licensedModels.name")}
					<RowTooltip description={t("categories.licensedModels.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.trainCustomModels.name")}
					<RowTooltip description={t("categories.trainCustomModels.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("pro.trainCustomModels")}</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("ultimate.trainCustomModels")}
					</Typography>
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.customPipelines.name")}
					<RowTooltip description={t("categories.customPipelines.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>
						{t("ultimate.customPipelines")}
					</Typography>
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.hostCustomModels.name")}
					<RowTooltip description={t("categories.hostCustomModels.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Vector} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.integration.name")}
					<RowTooltip description={t("categories.integration.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} alt="" />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.iframe.name")}
					<RowTooltip description={t("categories.iframe.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Check} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("pro.iframe")}</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("ultimate.iframe")}</Typography>
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.sso.name")}
					<RowTooltip description={t("categories.sso.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<img src={Vector} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<img src={Vector} />
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<img src={Check} />
				</TableCell>
			</TableRow>,
			<TableRow>
				<TableCell className={clsx(classes.tableCell, classes.textAlignLeft, classes.firstColumn)}>
					{t("categories.support.name")}
					<RowTooltip description={t("categories.support.description")} />
				</TableCell>

				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.basic,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("basic.support")}</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.pro,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("pro.support")}</Typography>
				</TableCell>
				<TableCell className={clsx(classes.tableCell, classes.emptyCell)}></TableCell>
				<TableCell
					className={clsx(classes.tableCell, {
						[classes.recommendedPlan]: recommendedPlan === ApiSubscriptionTypes.ultimate,
					})}
				>
					<Typography style={{ fontWeight: 600, fontSize: "14px" }}>{t("ultimate.support")}</Typography>
				</TableCell>
			</TableRow>,
		];
		return <TableBody>{TABLE_BODY_ROWS.map((row) => row)}</TableBody>;
	};

	// const openBuyNowDialog = () => {
	// 	setBuyNowDialogConfig({
	// 		subscriptionPeriod: SubscriptionPeriods.monthly,
	// 		subscriptionType: selectedPlan,
	// 		isApi: true,
	// 		orgUid: selectedOrganization?.uid ?? "",
	// 		couponCode: "NO_CODE",
	// 	});
	// 	setShouldOpenBuyNowDialog(true);
	// };

	const ConsumptionFeeTooltipInfoTable = ({ plan }: { plan: ApiSubscriptionTypes }) => {
		return (
			<Tooltip
				interactive={true}
				enterDelay={100}
				leaveDelay={50}
				placement="bottom"
				classes={{
					tooltip: classes.tooltipStyle,
				}}
				PopperProps={{
					style: {
						boxShadow: "none",
						border: "none",
					},
				}}
				title={<ConsumptionFeeInfoTable plan={plan} />}
			>
				<img className={classes.tooltipIcon} src={Info} alt="" />
			</Tooltip>
		);
	};

	return (
		<>
			<Box className={classes.fullWidth}>
				{/*<BriaConfirmationDialog*/}
				{/*	width="90%"*/}
				{/*	height="90%"*/}
				{/*	messageTextTerms="messageTextTerms"*/}
				{/*	promtWrapperTerms="promtWrapperTerms"*/}
				{/*	description={*/}
				{/*		<embed*/}
				{/*			src={*/}
				{/*				selectedPlan === ApiSubscriptionTypes.starter ||*/}
				{/*				selectedPlan === ApiSubscriptionTypes.basic*/}
				{/*					? "https://labs-assets.bria.ai/Split-terms-and-conditions-in-Web-API-subscriptions/Bria AI Limited General Terms and Conditions (March 2023.pdf?#scrollbar=0&toolbar=0&navpanes=0&view=FitH".replace(*/}
				{/*							/ /g,*/}
				{/*							"%20"*/}
				{/*					  )*/}
				{/*					: "https://labs-assets.bria.ai/Split-terms-and-conditions-in-Web-API-subscriptions/Bria AI Customer General Terms and Conditions (March 2023).pdf?#scrollbar=0&toolbar=0&navpanes=0&view=FitH".replace(*/}
				{/*							/ /g,*/}
				{/*							"%20"*/}
				{/*					  )*/}
				{/*			}*/}
				{/*			type="application/pdf"*/}
				{/*			width="100%"*/}
				{/*			height="100%"*/}
				{/*		/>*/}
				{/*	}*/}
				{/*	buttonText={"Close"}*/}
				{/*	onButtonClick={async () => {*/}
				{/*		setShouldOpenTermsDialog(false);*/}
				{/*	}}*/}
				{/*	onCloseModal={() => {*/}
				{/*		setShouldOpenTermsDialog(false);*/}
				{/*		setShouldOpenAgreeOnTermsDialog(true);*/}
				{/*	}}*/}
				{/*	shouldOpen={shouldOpenTermsDialog}*/}
				{/*/>*/}
				{/*<BriaConfirmationDialog*/}
				{/*	width={"505px"}*/}
				{/*	height={"300px"}*/}
				{/*	buttonExtraStyle={{ bottom: 30 }}*/}
				{/*	title={*/}
				{/*		<span*/}
				{/*			style={{*/}
				{/*				color: "#1A0638",*/}
				{/*				fontWeight: 600,*/}
				{/*				fontSize: 24,*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			Before we can move forward...*/}
				{/*		</span>*/}
				{/*	}*/}
				{/*	description={*/}
				{/*		<>*/}
				{/*			<Typography style={{ fontSize: 16, fontWeight: 500, color: "#5B5B5B" }}>*/}
				{/*				Please agree to{" "}*/}
				{/*				<a*/}
				{/*					href="#"*/}
				{/*					onClick={() => {*/}
				{/*						setShouldOpenTermsDialog(true);*/}
				{/*					}}*/}
				{/*				>*/}
				{/*					BRIA’s terms of service.*/}
				{/*				</a>*/}
				{/*			</Typography>*/}
				{/*			{(selectedPlan === ApiSubscriptionTypes.starter ||*/}
				{/*				selectedPlan === ApiSubscriptionTypes.basic) && (*/}
				{/*				<Typography style={{ marginTop: 20, fontSize: 16, fontWeight: 500, color: "#5B5B5B" }}>*/}
				{/*					To validate your payment method, Bria will charge your credit card $1 and then*/}
				{/*					automatically refund it within a few days.*/}
				{/*				</Typography>*/}
				{/*			)}*/}
				{/*		</>*/}
				{/*	}*/}
				{/*	buttonText={"Yes, I agree"}*/}
				{/*	onButtonClick={async () => {*/}
				{/*		setShouldOpenAgreeOnTermsDialog(false);*/}
				{/*		openBuyNowDialog();*/}
				{/*	}}*/}
				{/*	onCloseModal={() => {*/}
				{/*		setShouldOpenAgreeOnTermsDialog(false);*/}
				{/*	}}*/}
				{/*	shouldOpen={shouldOpenAgreeOnTermsDialog}*/}
				{/*/>*/}
				{/*<SelectOrganizationDialog*/}
				{/*	disablePremiumOrganizations={true}*/}
				{/*	onCloseModal={async (selectedOrg: allOrganizations_allOrganizations | undefined) => {*/}
				{/*		setShouldOpenSelectOrgDialog(false);*/}
				{/*		if (selectedOrg) {*/}
				{/*			context.setSelectedOrganization(selectedOrg);*/}
				{/*			await createHubspotContact({*/}
				{/*				organization_name: selectedOrg.name,*/}
				{/*				business_email: context.user?.email,*/}
				{/*				bria_selected_api_subscription_plan: selectedPlan,*/}
				{/*			});*/}
				{/*			setShouldOpenAgreeOnTermsDialog(true);*/}
				{/*		}*/}
				{/*	}}*/}
				{/*	shouldOpen={shouldOpenSelectOrgDialog}*/}
				{/*/>*/}
				{/*<CreateOrganizationDialog*/}
				{/*	onCloseModal={async (createdOrg: allOrganizations_allOrganizations | undefined) => {*/}
				{/*		setShouldOpenCreateOrgDialog(false);*/}
				{/*		if (createdOrg) {*/}
				{/*			context.setSelectedOrganization(createdOrg);*/}
				{/*			await createHubspotContact({*/}
				{/*				organization_name: createdOrg.name,*/}
				{/*				business_email: context.user?.email,*/}
				{/*				bria_selected_api_subscription_plan: selectedPlan,*/}
				{/*				bria_api_token_created: true,*/}
				{/*			});*/}
				{/*			setShouldOpenAgreeOnTermsDialog(true);*/}
				{/*		}*/}
				{/*	}}*/}
				{/*	shouldOpen={shouldOpenCreateOrgDialog}*/}
				{/*/>*/}
				{/*{buyNowDialogConfig && (*/}
				{/*	<BuyNowDialog*/}
				{/*		config={buyNowDialogConfig}*/}
				{/*		shouldOpen={shouldOpenBuyNowDialog}*/}
				{/*		onCloseModal={() => {*/}
				{/*			setLoadingPayment(true);*/}
				{/*			listenForOrgChanges(selectedOrganization?.uid ?? "", (record: any) => {*/}
				{/*				if (record && record?.api_subscription?.api_subscription_type === selectedPlan) {*/}
				{/*					window.location.assign(*/}
				{/*						decodeURIComponent(queryParams.get("source") ?? getPlatformHost())*/}
				{/*					);*/}
				{/*				}*/}
				{/*			});*/}
				{/*			setShouldOpenBuyNowDialog(false);*/}
				{/*			setBuyNowDialogConfig(undefined);*/}
				{/*		}}*/}
				{/*	/>*/}
				{/*)}*/}
				{openQuete && (
					<PricingQuote
						open={openQuete}
						plan={selectedPlan}
						onClose={(submitted: boolean) => {
							setOpenQuete(false);
							if (submitted) {
								setOpenQuoteConfirmation(true);
							}
						}}
					/>
				)}
				{openQuoteConfirmation && (
					<BriaConfirmationDialog
						buttonExtraStyle={{ paddingBottom: 12 }}
						width={"505px"}
						height={"217px"}
						title={t("requestQuoteConfirmation.requestSubmitted")}
						description={
							<Typography>{t("requestQuoteConfirmation.thankYouForRequestingAccess")}</Typography>
						}
						buttonText={t("requestQuoteConfirmation.gotIt")}
						onCloseModal={() => setOpenQuoteConfirmation(false)}
						onButtonClick={() => setOpenQuoteConfirmation(false)}
						shouldOpen={openQuoteConfirmation}
					/>
				)}
				{props.isMarketingFlag ? <ToolHeader isApiPricing={true} /> : <ToolHeaderMarketing />}
				<Box className={classes.wholeWrapper}>
					{/* <Sidebar
                                            px="14px"
                                            isDark={true}
                                            dotColor="#D80067"
                                            style={{ background: "#fff" }}
                                        /> */}
					<Box className={classes.plansWrapper}>
						<Box className={classes.plansSubWrapper}>
							<TableContainer
								style={{ overflowX: "visible" }}
								className={classes.tableContainer}
								component={Paper}
							>
								{getTableContent()}
							</TableContainer>
						</Box>
						{/* <Box className={clsx(classes.footer)}>
                                                <Box className={clsx(classes.footerWrapper)}>
                                                    <Box className={clsx(classes.footerContent)}>
                                                        <Typography
                                                            className={clsx(classes.footerTitle)}
                                                        >
                                                            API Subscription Plans can be offered
                                                            according to the number of users, not
                                                            only per API call.
                                                        </Typography>
                                                        <Typography
                                                            className={clsx(
                                                                classes.footerDescription
                                                            )}
                                                        >
                                                            For more info please contact us
                                                        </Typography>
                                                    </Box>
                                                    <Box className={clsx(classes.footerButton)}>
                                                        <BriaButton
                                                            buttonType={BUTTONTYPES.SECONDARY}
                                                            onClick={() => {
                                                                window.open(
                                                                    LinksConst.CONTACT_US_FORM_LINK,
                                                                    "_blank"
                                                                );
                                                            }}
                                                        >
                                                            Contact Us
                                                        </BriaButton>
                                                    </Box>
                                                    <Box
                                                        className={clsx(
                                                            classes.footerImagesContainer
                                                        )}
                                                    >
                                                        <img
                                                            className={clsx(
                                                                classes.footerImage,
                                                                classes.footerImage1
                                                            )}
                                                            src={apiPlansFooterImage1}
                                                        ></img>
                                                        <img
                                                            className={clsx(
                                                                classes.footerImage,
                                                                classes.footerImage2
                                                            )}
                                                            src={apiPlansFooterImage2}
                                                        ></img>
                                                        <img
                                                            className={clsx(
                                                                classes.footerImage,
                                                                classes.footerImage3
                                                            )}
                                                            src={apiPlansFooterImage3}
                                                        ></img>
                                                    </Box>
                                                </Box>
                                            </Box> */}
					</Box>
				</Box>
				<StartupProgramTeaser />
				<QAList />
			</Box>
		</>
	);
}

interface StyleProps {
	currentPlan: ApiSubscriptionTypes | undefined;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	fullWidth: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	wholeWrapper: {
		background:
			"linear-gradient(180deg, rgba(83, 0, 201, 0.05) 5%, rgba(255, 255, 255, 1) 20%, rgba(41, 0, 99, 0) 95%)",

		display: "flex",
		flex: 1,
		overflowY: "hidden",
	},
	plansWrapper: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	plansSubWrapper: {
		padding: "25px 100px 60px 100px",
		flex: 1,
	},
	title: {
		color: "#495057",
		fontSize: "2.5vw",
		fontWeight: 600,
		marginLeft: 8,
		padding: theme.spacing(3, 8, 2, 8),
	},
	popupHeader: {
		lineHeight: "normal !important",
		maxHeight: "92px",
		color: "#1A0638",
		width: "100%",
		fontWeight: 700,
		fontSize: "40px",
		maxWidth: "409px",
		"& span": {
			fontSize: 36,
			fontWeight: 400,
		},
	},
	popupSubHeader: {
		color: "#495057",
		width: "100%",
		fontSize: "2em", //24
		fontWeight: 400,
	},
	tableContainer: {
		background: "transparent",
		color: "#495057",
		boxShadow: "none",
		paddingBottom: 20,
		marginTop: 0,
		marginBottom: -20,
	},

	tableCell: {
		whiteSpace: "pre-line",
		minWidth: "15.5vw", //230
		maxWidth: "17vw", //230
		color: "#1A0638;",
		padding: "20px 8px",
		textAlign: "center",
		fontSize: "14px",
		borderBottom: "0.8px solid #A4A4A480",
		lineHeight: "14px",
		fontWeight: 500,
		"& .MuiBadge-badge": {
			top: 8,
			right: -4,
			color: "#D80067",
		},
		"& a": {
			color: "#D80067",
		},
	},
	headerRow: {
		fontWeight: 600,
		fontSize: 24,
		paddingBottom: "4px !important",
	},
	noBottomBorder: {
		border: "none",
	},
	priceCell: {
		fontWeight: 600,
		fontSize: "1.5em", //24
		"& span": {
			fontWeight: "normal",
			fontSize: 14,
		},
	},
	textAlignLeft: {
		textAlign: "left",
	},
	planDesc: {
		minWidth: "14vw",
		color: "#1A0638",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "24px",
		paddingTop: "16px",
		whiteSpace: "pre-line",
	},
	proKitPrice: {
		textDecorationLine: "line-through",
		display: "inline",
		fontWeight: 600,
		fontSize: "14px",
	},
	description: {
		color: "#495057",
		fontFamily: "Montserrat",
		fontSize: "14px",
		fontWeight: 400,
	},
	// recommendedPlanfirstRow: {
	// boxShadow: "0px -10px 5px rgba(0, 0, 0 , 0.01)",
	// borderRadius: "10px",
	// },
	// recommendedPlan: {
	// backgroundColor: "#FFFFFF",
	// color: "#1A0638",
	// position: "relative",
	// "&:before": {
	// 	boxShadow: "-15px 0 15px -15px rgba(164, 164, 164, 0.25) inset",
	// 	content: '" "',
	// 	height: "100%",
	// 	top: 0,
	// 	left: -15,
	// 	position: "absolute",
	// 	width: 15,
	// },
	// "&:after": {
	// 	boxShadow: "15px 0 15px -15px rgba(164, 164, 164, 0.25) inset",
	// 	content: '" "',
	// 	height: "100%",
	// 	position: "absolute",
	// 	top: 0,
	// 	right: -15,
	// 	width: 15,
	// },
	// },
	firstRow: {
		"& $tableCell": {
			borderTopRightRadius: 10,
			borderTopLeftRadius: 10,
			verticalAlign: "baseline",
		},
		// "& $recommendedPlan": {
		// boxShadow: "0px -4px 5px rgb(0 0 0 / 1%)",
		// },
	},
	lastRow: {
		"& $tableCell": {
			borderBottomRightRadius: 10,
			borderBottomLeftRadius: 10,
		},
		// "& $recommendedPlan": {
		// 	boxShadow: "0px 6px 5px rgb(0 0 0 / 10%)",
		// },
	},
	emptyCell: {
		width: 0,
		minWidth: 0,
		maxWidth: 0,
	},
	longEmptyCell: {
		width: 10,
		minWidth: 10,
		maxWidth: 10,
	},
	firstColumn: {
		width: "40%",
		paddingLeft: 10,
	},
	button: {
		padding: 6,
		fontSize: 14,
		fontWeight: 600,
		width: 145,
		"&:disabled": {
			borderColor: "#37057D",
			width: 165,
		},
		"&:hover": {
			opacity: 0.5,
		},
	},
	switchButton: {
		backgroundColor: theme.palette.primary.main,
		"& .MuiSwitch-track": {
			opacity: 0,
			transition: "none",
		},
	},
	selectedPricePeriod: {
		fontWeight: 700,
	},
	smallSizeCell: {
		fontSize: 12,
	},
	fullWidthRow: {
		paddingBottom: 30,
	},
	flexBreak: {
		flexBasis: "100%",
		height: 0,
	},
	footer: {
		width: "100%",
		backgroundColor: "white",
		height: 115,
	},
	footerWrapper: {
		height: "100%",
		display: "flex",
		flexDirection: "row",
		padding: "0 50px",
	},
	footerContent: {
		width: "30%",
		margin: "auto 0",
		marginRight: 50,
	},
	footerButton: {
		margin: "auto 0",
		"& button": {
			width: 150,
			height: 40,
		},
	},
	footerImagesContainer: {
		position: "relative",
		flex: 1,
	},
	footerImage: {
		position: "absolute",
		border: "5px solid rgba(208, 210, 211, 60%)",
		height: "100%",
		"& img": {
			height: "100%",
		},
	},
	footerImage1: {
		width: 189,
		height: 118,
		bottom: "-47%",
		right: 400,
		boxShadow: "-15px 15px 20px 0px rgb(164 164 164)",
	},
	footerImage2: {
		width: 212,
		height: 107,
		top: "-30%",
		right: 160,
		// boxShadow: "-15px -5px 20px 0px rgb(164 164 164)",
		boxShadow:
			"0px 0px 0px 0px white, 0px 0px 0px 0px white, -15px -5px 20px 0px rgb(164 164 164 / 60%), -15px 20px 15px 0px rgb(164 164 164 / 60%)",
	},
	footerImage3: {
		right: 0,
		top: "18%",
		boxShadow: "-15px 15px 20px 0px rgb(164 164 164)",
		width: 131,
		height: 76,
	},
	footerTitle: {
		fontSize: 16,
		fontWeight: 600,
		marginBottom: 10,
	},
	footerDescription: {
		fontSize: 12,
		fontWeight: 400,
	},
	tabs: {
		display: "flex",
		gap: 10,
		"& span": { fontWeight: 800, fontSize: 20 },
		whiteSpace: "nowrap",
	},
	currentPlan: {
		borderColor: "#37057D !important",
		color: "white !important",
		backgroundColor: "#5300C9 !important",
		opacity: 0.25,
		lineHeight: "16px",
		padding: "10px 16px",
		borderRadius: "8px",
		fontWeight: 600,
	},
	infoIcon: {
		marginLeft: "4px",
	},
	infoWrapper: {
		display: "flex",
		justifyContent: "center",
		color: "#1A0638",
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "normal",
	},
	tooltipStyle: {
		backgroundColor: "white",
		margin: "0px",
		padding: "16px 32px",
		minWidth: " 408px",
		boxShadow: " 0px 8px 16px 6px rgba(164, 164, 164, 0.25)",
		borderRadius: "10px",
	},
	tooltipIcon: {
		marginLeft: "4px",
	},
	tooltipIconFirstCol: {
		float: "right",
	},
	tooltipDesc: {
		color: "#1A0638",
		fontSize: "16px",
		fontWeight: 500,
	},
}));

export default ApiPricingComponent;
