import { child, get, getDatabase, ref, update } from "firebase/database";
import { STATIC_ACCOUNT_EMAIL } from "../Constants";
import Subscription from "./Subscription";
import { UserOrganization } from "./UserOrganization";

class User {
	userName: string;
	uid: string;
	profilePicture: string;
	role: string;
	emailVerified: boolean;
	organizations: UserOrganization[];
	rawOrganizations: UserOrganization[];
	company: string;
	userRole: string;
	getInfo: boolean;
	email: string;
	subscription: Subscription;
	settings?: any;
	updated?: any;
	manuallyManaged?: boolean;

	constructor(
		userName: string,
		uid: string,
		profilePicture: string,
		role: string,
		emailVerified: boolean,
		organizations: UserOrganization[] = [],
		rawOrganizations: UserOrganization[] = [],
		company: string,
		userRole: string,
		getInfo: boolean,
		email: string,
		subscription: Subscription,
		settings?: any,
		updated?: any,
		manuallyManaged?: boolean
	) {
		this.uid = uid;
		this.userName = userName;
		this.profilePicture = profilePicture;
		this.role = role;
		this.emailVerified = true; //emailVerified;
		this.organizations = organizations;
		this.rawOrganizations = rawOrganizations;
		this.company = company;
		this.userRole = userRole;
		this.getInfo = getInfo;
		this.email = email;
		this.subscription = subscription;
		this.settings = settings;
		this.updated = updated;
		this.manuallyManaged = manuallyManaged;
	}

	isEmailVerified(): boolean {
		return this.emailVerified;
	}

	haveViewPermitions() {
		return this.role !== "non";
	}

	isAdmin() {
		return this.role === "admin";
	}

	isViewer() {
		return this.role === "view";
	}

	isExternal() {
		return this.role === "external";
	}

	getSettings() {
		return new Promise((res, rej) => {
			const dbRef = ref(getDatabase());
			get(child(dbRef, `users/${this.uid}/settings/`))
				.then((snapshot) => {
					if (snapshot.exists()) {
						res(snapshot.val());
					} else {
						res({});
					}
				})
				.catch((error) => {
					rej(error);
				});
		});
	}

	async updateThumbnailsSettings(value: string) {
		if (this.email !== STATIC_ACCOUNT_EMAIL) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, { ...(prevSettings as {}), style: value });
		}
	}

	async updateShowIncreaseResolutionPopUpSettings(newDontShowIncreaseResPopUp: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				dontShowIncreaseResPopUp: newDontShowIncreaseResPopUp,
			});
		} else {
			localStorage.setItem("dontShowIncreaseResPopUp", `${newDontShowIncreaseResPopUp}`);
		}
	}

	async getShowIncreaseResolutionPopUpSettings(isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.dontShowIncreaseResPopUp ?? false;
		} else {
			return localStorage.getItem("dontShowIncreaseResPopUp") === "true";
		}
	}

	async updateShowResolutionBeforeDownloadDialogSettings(newValue: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				dontShowResolutionBeforeDownloadDialog: newValue,
			});
		} else {
			localStorage.setItem("dontShowResolutionBeforeDownloadDialog", `${newValue}`);
		}
	}

	getShowResolutionBeforeDownloadDialogSettings(isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.dontShowResolutionBeforeDownloadDialog ?? false;
		} else {
			return localStorage.getItem("dontShowResolutionBeforeDownloadDialog") === "true";
		}
	}

	async updateHideGalleryInfoBoxSettings(newValue: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				hideGalleryInfoBox: newValue,
			});
		} else {
			localStorage.setItem("hideGalleryInfoBox", `${newValue}`);
		}
	}

	async updateHideAutomaticInfoBoxSettings(newValue: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				hideAutomaticObjectsInfoBox: newValue,
			});
		} else {
			localStorage.setItem("hideAutomaticObjectsInfoBox", `${newValue}`);
		}
	}

	async updateHidePlaygroundInfoBoxSettings(newValue: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				hidePlaygroundInfoBox: newValue,
			});
		} else {
			localStorage.setItem("hidePlaygroundInfoBox", `${newValue}`);
		}
	}

	async updateHidePlaygroundObjectsInfoBoxSettings(newValue: boolean, isIframeUser: boolean) {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				hidePlaygroundObjectsInfoBox: newValue,
			});
		} else {
			localStorage.setItem("hidePlaygroundObjectsInfoBox", `${newValue}`);
		}
	}

	getHideGalleryInfoBoxSettings(isIframeUser: boolean): boolean {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.hideGalleryInfoBox ?? false;
		} else {
			return localStorage.getItem("hideGalleryInfoBox") === "true";
		}
	}

	async updatePromotionPopupVisabilityToUserSettings() {
		if (this.email !== STATIC_ACCOUNT_EMAIL) {
			const db = getDatabase();
			const prevSettings = await this.getSettings();
			const settingsRef = ref(db, `users/${this.uid}/settings/`);
			update(settingsRef, {
				...(prevSettings as {}),
				hidePromotionPopUp: true,
			});
		} else {
			localStorage.setItem("hidePromotionPopUp", "true");
		}
	}

	getPromotionPopupVisabilityToUserSettings(): boolean {
		if (this.email !== STATIC_ACCOUNT_EMAIL) {
			return this.settings?.hidePromotionPopUp ?? false;
		} else {
			return localStorage.getItem("hidePromotionPopUp") === "true";
		}
	}

	getHideAutomaticObjectsInfoBoxSettings(isIframeUser: boolean): boolean {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.hideAutomaticObjectsInfoBox ?? false;
		} else {
			return localStorage.getItem("hideAutomaticObjectsInfoBox") === "true";
		}
	}

	getHidePlaygroundInfoBoxSettings(isIframeUser: boolean): boolean {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.hidePlaygroundInfoBox ?? false;
		} else {
			return localStorage.getItem("hidePlaygroundInfoBox") === "true";
		}
	}

	getHidePlaygroundObjectsInfoBoxSettings(isIframeUser: boolean): boolean {
		if (this.email !== STATIC_ACCOUNT_EMAIL && !isIframeUser) {
			return this.settings?.hidePlaygroundObjectsInfoBox ?? false;
		} else {
			return localStorage.getItem("hidePlaygroundObjectsInfoBox") === "true";
		}
	}

	async updateBrand(config: { selected_brand: string }) {
		const db = getDatabase();
		const prevSettings = (await this.getSettings()) || {};
		const settingsRef = ref(db, `users/${this.uid}/settings/`);
		update(settingsRef, {
			...(prevSettings as {}),
			selected_brand: config.selected_brand,
		});
	}

	async updateState(key: string, value: any) {
		const db = getDatabase();
		const prevSettings = (await this.getSettings()) || {};
		const settingsRef = ref(db, `users/${this.uid}/settings/`);
		update(settingsRef, {
			...(prevSettings as {}),
			[key]: value,
		});
	}
}

export default User;
