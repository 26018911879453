import React, { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import {
	FormControl,
	Grid,
	InputLabel,
	makeStyles,
	Menu,
	MenuItem,
	Slider,
	TextField,
	Theme,
	Typography,
	useTheme,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CustomizedTooltip from "./UI/Tooltip";
import { ReactComponent as ArrowDown } from "../assets/svgs/arrow-dropdown.svg";
import clsx from "clsx";
import Context from "../Context/Context";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import Analytics, { ANALYTICS_EVENTS } from "../Models/Analytics";
import LoadingAnimation from "./Common/LoadingAnimation/LoadingAnimation";
import Personalizer from "./Tools/Personalizer";
import { ThumbnailImage, UncropValues } from "../Models/ToolsConfig";
import BriaAPI from "../sdk/resources/briaAPI";
import BriaConfirmationDialog from "./BriaConfirmationDialog";
import { useTranslation } from "react-i18next";
import { croppingAreaHasTransparency } from "./Tools/utilities";

interface StyleProps {
	expanded: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: (props) => ({
		display: props.expanded ? "block" : "flex",
		justifyContent: "flex-start",
		height: "100%",
		flexDirection: "column",
		paddingTop: 30,
	}),
	title: {
		fontWeight: "bold",
		color: "#495057",
		fontSize: "2.5rem",
	},
	hide: {
		display: "none",
	},
	button: {
		textTransform: "none",
		color: "rgba(73, 80, 87, 1)",
		"&:hover": {
			opacity: 0.5,
			background: "white",
		},
		"&:focus": {
			color: "#D80067",
		},
		"&:disabled": {
			color: "#121232 !important",
			opacity: 0.3,
		},

		fontSize: "1.25rem",
	},
	controls: {
		width: "100%",
		height: "3.5vh",
		alignItems: "center",
		background: "unset",
		outline: "none",
		"& .MuiSlider-thumb": {
			color: "#FFFFFF",
			boxShadow: " 0px 4px 8px rgba(44, 39, 56, 0.2)",
			width: "18px",
			height: "18px",
			marginTop: "-8px",
		},
		"& .MuiSlider-rail": {
			color: "#D9D9D9",
		},
		"& .MuiSlider-track": {
			color: theme.palette.cards.main,
		},
	},

	slider: {
		padding: "22px 0px",
	},
	menuItemRoot: {
		padding: "18px 12px",
		fontSize: 14,
		color: "#5B5B5B",
		lineHeight: "12px",
	},
	menuItemSelected: {
		backgroundColor: "rgba(83, 0, 201, 0.05) !important",
	},
	heightStyle: {
		marginRight: "0px !important",
	},
	customInput: {
		marginRight: "18px",

		"& .MuiOutlinedInput-root": {
			borderRadius: "8px",
			width: "174px",
			height: "34px",
			background: " rgba(233, 236, 239, 0.1)",
			border: "1px solid #B3B3B9",
			"&:hover": {
				border: "1px solid #1A0638",
			},
			"&.Mui-focused fieldset": {
				border: `3px solid ${theme.palette.primary.dark}`,
			},
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none",
		},
		"& .MuiInputBase-input": {
			width: "100%",
			height: "24px",
			borderBottom: "none",
			padding: "0 0 0 16px",
		},
		"&:hover": {
			background: " rgba(233, 236, 239, 0.1)",
			borderBottom: "none",
		},
		"& .MuiInput-underline:after": {
			background: " rgba(233, 236, 239, 0.1)",
			borderColor: "unset",
			border: "none",
		},
		"& .MuiInput-underline:before": {
			background: " rgba(233, 236, 239, 0.1)",
			borderColor: "unset",
			border: "none",
		},
	},
	customTextStyle: {
		color: "#1A0638",
		fontWeight: 600,
		fontSize: 14,
		lineHeight: "17px",
	},
	cutsomLable: {
		textAlign: "right",
		fontSize: "16px",
		color: "#5B5B5B",
		margin: "0 4px 0 0px",
		fontWeight: 500,
	},
	btnStyle: {
		width: 404,
		height: 40,
		marginTop: -9,
		border: "1px solid rgba(91, 91, 91, 0.5)",
		borderRadius: 8,
		display: "flex",
		justifyContent: "space-between",
		padding: "9px 14px 9px",
		color: "#5B5B5B",
		fontSize: 14,
		textTransform: "capitalize",
		"&:hover": {
			border: "1px solid #1A0638",
		},
		"&:focus": {
			border: `3px solid ${theme.palette.primary.dark}`,
		},
	},
	separator: {
		borderBottom: "1px solid #E7E7E7",
		marginBottom: 40,
		width: "100%",
		marginTop: 40,
	},
	sizeBtn: {
		border: "1px dashed rgba(26, 6, 56, 0.6);",
		borderRadius: "4px",
		background: "unset",

		"&:disabled": {
			border: "1px dashed rgba(73, 80, 87, 0.2)",
			color: "rgba(73, 80, 87, 0.2)",
		},
		"&:hover": {
			backgroundColor: "white !important",
		},
	},
	sizeBtnActive: {
		color: "#96054A",
		background: "rgba(231, 231, 231, 0.2)",
		border: "1px dashed rgba(26, 6, 56, 0.6)",
		borderRadius: "4px",
	},
	sizeBtnText: {
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "17px",
		textAlign: "center",
		color: "rgba(91, 91, 91, 0.85)",
	},
	tooltip: {
		padding: " 14px 8px !important",
		width: " 148px",
		alignItems: "center",
		height: " 82px",
		background: "#000000 !important",
		boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.15)",
		borderRadius: " 4px !important",
		textAlign: "center",
	},
	resetButtonsContainer: {
		display: "flex",
		justifyContent: "flex-end",
		width: "100%",
		marginTop: 30,
	},
	activeNumber: { color: "#1A0638" },
	activeText: { color: "#1A0638", fontWeight: 600 },
	DisabledText: { color: " rgba(73, 80, 87, 0.2)" },
	zommTextContainer: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
	},
	textStyle: {
		fontSize: 10,
		fontWeight: 400,
	},
	iconItem: {
		marginRight: 11,
		width: 13.33,
		height: 13.33,
	},
	switchLable: {
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		textShadow: "0px 0px 0px #495057",
	},
	buttonsContainer: {
		marginTop: 28,
		marginBottom: "4px",
		display: "flex",
		justifyContent: "flex-end",
	},
	buttonStyle: {
		width: 113,
		height: 34,
	},
}));

interface IProps {
	imageTransparencyPercentage: number;
	naturalSize: { width: number; height: number };
	cropImageFunction: (w: number, h: number, isCustom: boolean) => void;
	resetUncrop: () => void;
	resetCrop: () => void;
	imageRef: RefObject<HTMLImageElement>;
	setCropTabSeleced: (value: boolean) => void;
	cropZoom: number;
	setCropZoom: Dispatch<SetStateAction<number>>;
	setCropName: (value: string) => void;
	platformArray: number;
	activeCropPreset: string;
	setActiveCropPreset: (value: string) => void;
	activeCropSizebtn: number;
	setActiveCropSizeBtn: (value: number) => void;
	imageVH: string;
	setCroppedAreaPixels: (value: any) => void;
	resetPointsOfInterests: () => void;
	setRotation: (value: number) => void;
	setCroppedAreaPercentage: (value: any) => void;
	draftCroppedAreaPercentage: any;
	draftCroppedAreaPixels: any;
	draftRotation: number;
	croppedAreaPixels: any;
	croppedArea: any;
	processing: boolean;
	uncropLoading: boolean;
	uncropThumbsLoading: boolean;
	selectedPoint: any;
	visual_hash: string;
	setUncropThumbsLoading: (value: boolean) => void;
	onUncropThumbnailClick: (thumbnailImage: ThumbnailImage) => Promise<void>;
	onUncrop: (
		canvas_size: [number, number],
		original_image_size: [number, number],
		original_image_location: [number, number],
		num_results: number,
		append?: boolean
	) => Promise<void>;
	originalImage: string;
	uncropValues: UncropValues | undefined;
	uncropThumbnails: ThumbnailImage[];
	OnloadError: boolean;
	originalImageDimensions: { width: number; height: number };
	croppedImage: any;
	draftCropName: string;
	setDraftCropName: (value: string) => void;
	setDraftCroppedArea: (value: any) => void;
	minZoom: number;
	maxZoom: number;
	isUncropApplied: boolean;
	createCroppedImage: (isPreview: boolean, passedCroppedAreaPixels?: any, passedRotation?: any) => Promise<void>;
	setGeneralLoading: (loading: boolean) => void;
	applyCrop: () => void;
	callUncropApi: (number: number, append?: boolean) => void;
	isEngineOverloaded: boolean;
	setIsEngineOverloaded: (value: boolean) => void;
	thumbnailConfig: any;
}

const CropImage: React.FC<IProps> = ({
	imageTransparencyPercentage,
	isUncropApplied,
	naturalSize,
	minZoom,
	maxZoom,
	imageRef,
	cropImageFunction,
	resetUncrop,
	resetCrop,
	setCropTabSeleced,
	imageVH,
	cropZoom,
	setCropZoom,
	setCropName,
	platformArray,
	activeCropPreset,
	setActiveCropPreset,
	activeCropSizebtn,
	setActiveCropSizeBtn,
	setCroppedAreaPixels,
	setCroppedAreaPercentage,
	setRotation,
	resetPointsOfInterests,
	draftCroppedAreaPercentage,
	draftCroppedAreaPixels,
	draftRotation,
	originalImageDimensions,

	processing,
	setUncropThumbsLoading,
	uncropLoading,
	uncropThumbsLoading,
	selectedPoint,
	visual_hash,
	onUncropThumbnailClick,
	onUncrop,
	originalImage,
	uncropValues,
	uncropThumbnails,
	OnloadError = false,
	croppedAreaPixels,
	croppedArea,
	croppedImage,
	draftCropName,
	setDraftCropName,
	setDraftCroppedArea,
	createCroppedImage,
	setGeneralLoading,
	applyCrop,
	callUncropApi,
	isEngineOverloaded,
	setIsEngineOverloaded,
	thumbnailConfig,
}) => {
	const classes = useStyles({
		expanded: uncropLoading || uncropThumbnails.length !== 0,
	});
	const [isGenerateClicked, setIsGenerateClicked] = useState(false);
	const [hoveredBox, setHoveredBox] = useState(-1);
	const zoomSliderStepSize = 0.2;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedWidth, setSelectedWidth] = useState<number>(-1);
	const [selectedHeight, setSelectedHeigt] = useState<number>(-1);
	const [customWidth, setCustomWidth] = useState<number>(0);
	const [customHeight, setCustomHeight] = useState<number>(0);
	const context = useContext(Context);
	const [constrainProportions, setConstrainProportions] = useState<boolean>(false);
	const [selectedPlatform, setSelectedPlatform] = useState<any>(
		platformArray === 1 ? null : context.cropConfig[platformArray]
	);
	const theme = useTheme();
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const [originalImgae, setOriginalImgae] = useState<any>({
		width: 0,
		height: 0,
	});
	const { t } = useTranslation();
	const initialContainerRef = useRef<null | HTMLDivElement>(null);
	const uncropContainerRef = useRef<null | HTMLDivElement>(null);

	const [retryingImageAbortController, setRetryingImageAbortController] = useState<AbortController>(
		new AbortController()
	);
	const [isLoadMoreClicked, setIsLoadMoreClicked] = useState<boolean>(false);
	const [showResetConfirmationDialog, setShowResetConfirmationDialog] = useState<boolean>(false);

	const [resetConfirmationDialogHandler, setResetConfirmationDialogHandler] = useState(() => async () => {});

	// function setCropIimageValues() {  // commented-out for wai-1966
	// 	if (imageUrl) {
	// 		getImageMeta(imageUrl).then((img: any) => {
	// 			setOriginalImgae({
	// 				width: img.naturalWidth,
	// 				height: img.naturalHeight,
	// 			});
	// 			if (platformArray === 1) {
	// 				setCustomHeight(img.naturalHeight);
	// 				setCustomWidth(img.naturalWidth);
	// 				setcropZoomCallBack(1);
	// 				setDraftCropName("Custom Size");
	// 			} else {
	// 				setCropName("");
	// 				setSelectedHeigt(img.naturalHeight);
	// 				setSelectedWidth(img.naturalWidth);
	// 			}
	// 			cropImageFunction(img.naturalWidth, img.naturalHeight, false);
	// 			setcropZoomCallBack(1);
	// 		});
	// 	}
	// }

	const applyUncrop = async () => {
		setUncropThumbsLoading(true);
		await applyCrop();
		setIsGenerateClicked(true);
	};

	useEffect(() => {
		if (isGenerateClicked) {
			setIsGenerateClicked(false);
			callUncropFn();
		}
	}, [isGenerateClicked]);

	const resetCropState = (resetPreset: boolean = true) => {
		resetCrop();
		// wai-1966
		// if (resetPreset) {
		// 	cropImageFunction(
		// 		originalImageDimensions.width,
		// 		originalImageDimensions.height,
		// 		false
		// 	);
		// 	//use this on localhost
		// 	// getImageMeta(passedImageUrl).then((image: any) => {
		// 	// 	cropImageFunction(image.width, image.height, false);
		// 	// });
		// }

		// setCropZoom(1);
		// setDraftRotation(0);
		// setDraftCroppedAreaPercentage({
		// 	x: 0,
		// 	y: 0,
		// 	width: 0,
		// 	height: 0,
		// });
		// setDraftCroppedAreaPixels({
		// 	x: 0,
		// 	y: 0,
		// 	width: 0,
		// 	height: 0,
		// });

		// setCroppedImage(null);
		// if (resetPreset) {
		// 	setCropName("");
		// 	setActiveCropSizeBtn(-1);
		// }
	};

	useEffect(() => {
		// 	setCropIimageValues(); // commented-out for wai-1966
		if (platformArray < 1 && context.cropConfig[platformArray])
			setActiveCropPreset(context.cropConfig[platformArray].keys().next().value);
		// 	setActiveCropSizeBtn(-1);  // commented-out for wai-1966
		setCropTabSeleced(true);
	}, [context.cropConfig]);

	// const setnewMinZoom = (width: number, height: number) => {
	// 	//choose considation based on vertical or hrozantal based on crop w/h
	// 	//set the min based on ratio
	// 	let ratio = Math.max(
	// 		height / originalImgae.height,
	// 		width / originalImgae.width
	// 	);
	// 	if (ratio) setMinZoom(ratio);
	// 	else setMinZoom(1);
	// };

	const activeButton = (value: string) => {
		if (value !== activeCropPreset) {
			setActiveCropPreset(value);
			setActiveCropSizeBtn(-1);
		}
	};

	const onChangeCustomWidth = (value: number) => {
		if (constrainProportions) {
			let newheight = (value * customHeight) / customWidth;

			setCustomHeight(newheight);

			setCustomWidth(value);
			setSelectedHeigt(newheight);
			setSelectedWidth(value);
			// setnewMinZoom(value, newheight);
			cropImageFunction(value, newheight, true);
		} else {
			setcropZoomCallBack(1);
			setCustomWidth(value);
			setSelectedWidth(value);

			// setnewMinZoom(value, customHeight);
			cropImageFunction(value, customHeight, true);
		}
	};
	const onChangeCustomHeight = (value: number) => {
		if (constrainProportions) {
			let newWidth = (value * customWidth) / customHeight;
			setcropZoomCallBack(1);
			setCustomWidth(newWidth);
			setCustomHeight(value);
			setSelectedWidth(newWidth);
			setSelectedHeigt(value);

			// setnewMinZoom(newWidth, value);
			cropImageFunction(newWidth, value, true);
		} else {
			setcropZoomCallBack(1);
			setCustomHeight(value);
			setSelectedHeigt(value);

			// setnewMinZoom(customWidth, value);
			cropImageFunction(customWidth, value, true);
		}
	};

	// const setCustomOriginalSize = () => {
	// 	setCustomWidth(originalImgae.width);
	// 	setCustomHeight(originalImgae.height);
	// 	cropImageFunction(originalImgae.width, originalImgae.height, true);
	// 	// setnewMinZoom(originalImgae.width, originalImgae.height);
	// 	setcropZoomCallBack(1);
	// };

	function setcropZoomCallBack(value: number) {
		if (cropZoom !== value) {
			setCropZoom(value);
			cropImageFunction(selectedWidth, selectedHeight, false);
		}
	}

	// useEffect(() => {
	//reset all values
	// setCropIimageValues(); // commented out because when clicking original uncrop thumb, it resets customWidth and customHeight
	// }, [imageUrl]);

	const callLocalUncropApi = async (num_results: number, append?: boolean) => {
		setIsLoadMoreClicked(append ?? false);
		setIsEngineOverloaded(false);
		callUncropApi(num_results, append);
	};

	const callUncropFn = async () => {
		setTimeout(() => {
			uncropContainerRef.current?.scrollIntoView({
				behavior: "smooth",
			});
		}, 200);
		retryingImageAbortController?.abort();
		setRetryingImageAbortController(new AbortController());
		await callLocalUncropApi(thumbnailConfig[3]);
		uncropContainerRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	useEffect(() => {
		initialContainerRef.current?.scrollIntoView(true);
	}, [visual_hash]);

	const croppingAreaHasTransparencyBool = croppingAreaHasTransparency(
		croppedArea,
		draftCroppedAreaPixels,
		naturalSize
	);
	const disableUncrop =
		(imageTransparencyPercentage === 0 && !croppingAreaHasTransparencyBool) ||
		!croppingAreaHasTransparencyBool ||
		processing ||
		uncropLoading ||
		OnloadError;

	return (
		<Box className={classes.root} ref={initialContainerRef}>
			<BriaConfirmationDialog
				title={"Are you sure?"}
				description={<Typography style={{ marginBottom: 100 }}>This will reset the current result</Typography>}
				width="600px"
				buttonExtraStyle={{ width: 340, bottom: 30 }}
				buttonText={"Yes"}
				showCancelButton={true}
				cancelButtonText={"Cancel"}
				onButtonClick={async () => {
					await resetConfirmationDialogHandler();
					setShowResetConfirmationDialog(false);
				}}
				onCloseModal={() => {
					setShowResetConfirmationDialog(false);
				}}
				shouldOpen={showResetConfirmationDialog}
			/>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				style={{
					width: " 100%",
					display: "flex",
					flexDirection: "column",
					paddingRight: 20,
				}}
			>
				{selectedPlatform === null ? (
					<>
						<Typography className={classes.customTextStyle}>{t("customSize")}</Typography>
						<Grid
							xs={12}
							item
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="baseline"
							style={{ margin: "28px 0 40px 0 " }}
						>
							<InputLabel className={classes.cutsomLable}>w</InputLabel>
							<TextField
								className={classes.customInput}
								id="filled-number"
								value={(Number(customWidth) | 0).toString()}
								type="number"
								variant="outlined"
								// InputProps={{
								// 	inputProps: {
								// 		min: 1,
								// 		max: originalImgae.width,
								// 	},
								// }}
								onChange={(e) => {
									// Number(e.target.value) <=
									// originalImgae.width
									// 	?
									onChangeCustomWidth(Number(e.target.value) < 1 ? 1 : Number(e.target.value));
									// : setCustomWidth(
									// 		Number(originalImgae.width)
									//   );
								}}
								size="small"
								InputLabelProps={{
									shrink: true,
								}}
							>
								pt
							</TextField>

							<InputLabel className={classes.cutsomLable}>h</InputLabel>
							<TextField
								id="outlined-number"
								className={clsx(classes.customInput, classes.heightStyle)}
								value={(Number(customHeight) | 0).toString()}
								variant="outlined"
								type="number"
								// InputProps={{
								// 	inputProps: {
								// 		min: 0,
								// 		max: originalImgae.height,
								// 	},
								// }}
								onChange={(e) => {
									// Number(e.target.value) <=
									// originalImgae.height
									// 	?
									onChangeCustomHeight(Number(e.target.value) < 1 ? 1 : Number(e.target.value));
									// : setCustomHeight(
									// 		Number(originalImgae.height)
									//   );
								}}
								size="small"
							/>
							{/* <Button
								style={{
									background: "unset",
									border: "1px solid #D80067",
									borderRadius: " 4px",
									width: 140,
									height: 32,
									color: "#D80067",
									fontWeight: 600,
									textTransform: "none",
									marginLeft: "3%",
								}}
								onClick={() => setCustomOriginalSize()}
							>
								Original Size
							</Button> */}
						</Grid>
						<Grid xs={12}>
							{/* <FormControlLabel
								control={
									<Checkbox
										value={constrainProportions}
										onChange={(e) =>
											setConstrainProportions(
												!constrainProportions
											)
										}
									/>
								}
								label="Constrain proportions"
							/> */}
						</Grid>
					</>
				) : (
					<>
						<FormControl fullWidth>
							{/* <InputLabel id="demo-simple-select-label">
								Output Media
							</InputLabel> */}
							<Button className={classes.btnStyle} onClick={handleClick}>
								{activeCropPreset ? activeCropPreset : "Output Media"}
								<ArrowDown />
							</Button>
							<Menu
								// labelId="demo-simple-select-label"
								id="demo-simple-select"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
								// onChange={handleChange}
								elevation={0}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								PaperProps={{
									style: {
										width: 404,
										borderRadius: 8,
										boxShadow: "0px 8px 16px rgba(26, 6, 56, 0.1)",
									},
								}}
							>
								{context.cropConfig.map((selectedPlat: any, index) => {
									return [...selectedPlat.keys()].map((paltform) => (
										<MenuItem
											classes={{
												root: classes.menuItemRoot,
												selected: classes.menuItemSelected,
											}}
											selected={paltform === activeCropPreset}
											onClick={() => {
												activeButton(paltform);
												handleClose();
											}}
											value={paltform}
										>
											{paltform}
										</MenuItem>
									));
								})}
							</Menu>
						</FormControl>
						{/* <Grid xs={11} item style={{ marginTop: "28px" }}> */}
						{/* {[...selectedPlatform.keys()].map((paltform) => (
								<Button
									variant="text"
									key={paltform}
									disableRipple={true}
									focusRipple={activeCropPreset === paltform}
									//color="info"
									onClick={() => activeButton(paltform)}
									style={{
										color:
											activeCropPreset === paltform
												? "#D80067"
												: "rgba(73, 80, 87, 1)",
										fontWeight:
											activeCropPreset === paltform
												? "bold"
												: "unset",
									}}
									className={classes.button}
								>
									{paltform}
								</Button>
							))} */}
						{/* </Grid> */}
						<Grid
							item
							container
							justifyContent="flex-start"
							alignItems="baseline"
							style={{ marginTop: 25 }}
						>
							{context.cropConfig.map((p: any) => {
								return p.get(activeCropPreset)?.map((e: any) => (
									<CustomizedTooltip
										arrowColor="#000000"
										arrow
										classes={{
											tooltip: classes.tooltip,
										}}
										multiLine={true}
										title={
											// e.w > originalImgae.width ||
											// e.h > originalImgae.height ? (
											// 	<>
											// 		<div>
											// 			Image resolution is
											// 			too small to use for
											// 			this format.
											// 		</div>
											// 	</>
											// ) : (
											""
											// )
										}
										placement="top"
									>
										<Grid
											xs={4}
											item
											container
											justifyContent="flex-start"
											alignItems="center"
											direction="column"
											style={{ marginBottom: 30 }}
										>
											<Box
												style={{
													maxWidth: "fit-content",
													marginBottom: 10,
												}}
											>
												<Button
													onMouseEnter={() => setHoveredBox(e.index)}
													onMouseLeave={() => setHoveredBox(-1)}
													focusRipple={activeCropSizebtn === e.index}
													disableTouchRipple
													style={{
														minWidth: "fit-content",
														width: e.resize.w ?? 0,
														height: e.resize.h ?? 0,
													}}
													className={
														activeCropSizebtn === e.index
															? classes.sizeBtnActive
															: classes.sizeBtn
													}
													// disabled={
													// 	e.w >
													// 		originalImgae.width ||
													// 	e.h >
													// 		originalImgae.height
													// }
													onClick={() => {
														const handler = async () => {
															resetUncrop();
															if (croppedImage) {
																resetCropState(false);
															}

															// if (
															// 	e.w <=
															// 		originalImgae.width &&
															// 	e.h <=
															// 		originalImgae.height
															// ) {
															setcropZoomCallBack(1);

															setSelectedWidth(e.w);
															setSelectedHeigt(e.h);
															setDraftCropName(activeCropPreset + " " + e.name);
															// setnewMinZoom(e.w, e.h);
															cropImageFunction(e.w, e.h, false);
															// }
															setActiveCropSizeBtn(e.index);
														};
														setResetConfirmationDialogHandler(() => handler);
														if (!!croppedImage) {
															setShowResetConfirmationDialog(true);
														} else {
															handler();
														}
													}}
												></Button>
											</Box>

											<Typography
												className={clsx({
													[classes.sizeBtnText]: true,
													[classes.activeText]:
														e.index === activeCropSizebtn || hoveredBox === e.index,
													// [classes.DisabledText]:
													// 	e.w >
													// 		originalImgae.width ||
													// 	e.h >
													// 		originalImgae.height,
												})}
											>
												{e.name}
											</Typography>
											<Typography
												className={clsx({
													[classes.sizeBtnText]: true,
													[classes.activeNumber]:
														e.index === activeCropSizebtn || hoveredBox === e.index,
													// [classes.DisabledText]:
													// 	e.w >
													// 		originalImgae.width ||
													// 	e.h >
													// 		originalImgae.height,
												})}
											>
												{e.w} x {e.h}
											</Typography>
										</Grid>
									</CustomizedTooltip>
								));
							})}
						</Grid>
					</>
				)}
				<Grid
					xs={12}
					item
					container
					justifyContent="center"
					alignItems="center"
					style={{
						// borderTop: " 1px solid #E9ECEF",
						height: 180,
						// paddingRight: 20,
					}}
				>
					<Grid
						xs={12}
						item
						container
						justifyContent="flex-start"
						alignItems="flex-start"
						style={{
							// marginTop: 40,
							marginBottom: 12,
						}}
					>
						<Typography
							style={{
								fontWeight: 600,
								lineHeight: "17px",
								alignItems: "center",
								color: "#1A0638",
								fontSize: 14,
							}}
						>
							{t("zoomImage")}
						</Typography>
					</Grid>
					<Grid xs={12} item container justifyContent="flex-start" alignItems="flex-end" style={{}}>
						{/* <Grid
							item
							style={{
								margin: "0 0.3rem 0 0",
								width: 16,
								height: "3vh",
							}}
						>
							<svg
								style={{ cursor: "pointer" }}
								onClick={() => {
									setCropZoom((prevCropZoom: number) => {
										return (
											prevCropZoom - zoomSliderStepSize
										);
									});
								}}
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4.26849 7.99792H11.7352M8.00182 15.4646C3.8781 15.4646 0.535156 12.1216 0.535156 7.99792C0.535156 3.87419 3.8781 0.53125 8.00182 0.53125C12.1255 0.53125 15.4685 3.87419 15.4685 7.99792C15.4685 12.1216 12.1255 15.4646 8.00182 15.4646Z"
									stroke="#8800FF"
								/>
							</svg>
						</Grid> */}

						<Grid item className={classes.controls}>
							<>
								<Slider
									disabled={!!croppedImage}
									defaultValue={1}
									value={cropZoom}
									onChange={(event: React.ChangeEvent<{}>, value: number) => {
										setCropZoom(value);
									}}
									//valueLabelDisplay="auto"
									sx={{ width: "200px", ml: "20px" }}
									step={zoomSliderStepSize}
									max={maxZoom}
									min={minZoom}
									align="center"
								/>
							</>
						</Grid>
						{/* <Grid
							item
							style={{
								margin: "0 0 0 .8rem",
								width: 16,
								height: "3vh",
							}}
						>
							<svg
								style={{ cursor: "pointer" }}
								onClick={() => {
									setCropZoom((prevCropZoom: number) => {
										return (
											prevCropZoom + zoomSliderStepSize
										);
									});
								}}
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.00182 4.26458V11.7312M4.26849 7.99792H11.7352M8.00182 15.4646C3.8781 15.4646 0.535156 12.1216 0.535156 7.99792C0.535156 3.87419 3.8781 0.53125 8.00182 0.53125C12.1255 0.53125 15.4685 3.87419 15.4685 7.99792C15.4685 12.1216 12.1255 15.4646 8.00182 15.4646Z"
									stroke="#8800FF"
								/>
							</svg>
						</Grid> */}
					</Grid>
					<Box className={classes.zommTextContainer}>
						<Typography className={classes.textStyle}>{t("zoomOut")}</Typography>
						<Typography className={classes.textStyle}>{t("zoomIn")}</Typography>
					</Box>
					{/* <Box className={classes.separator} /> */}
					<Box className={classes.resetButtonsContainer}>
						<BriaButton
							style={{
								width: context.iframeConfig && context.iframeConfig?.enableTranslation ? 90 : 52,
								height: 34,
							}}
							buttonType={BUTTONTYPES.PRIMARYSMALL}
							onClick={async () => {
								const handler = async () => {
									setGeneralLoading(true);

									Analytics.logEvent(ANALYTICS_EVENTS.CROP_BUTTON_CLICK, {
										image_vh: imageVH,
									});
									resetUncrop();

									if (croppedImage) {
										resetCropState(false);
										initialContainerRef.current?.scrollIntoView({
											behavior: "smooth",
										});
									} else {
										await applyCrop();
									}
									setGeneralLoading(false);
								};
								setResetConfirmationDialogHandler(() => handler);
								if (!!croppedImage) {
									setShowResetConfirmationDialog(true);
								} else {
									await handler();
								}
							}}
						>
							{croppedImage ? t("reset") : t("crop")}
						</BriaButton>
					</Box>
				</Grid>
				{/* </Grid> */}
				{/* </Grid>
					 </Grid>
				</Grid>
				 </Grid>
		</div> */}
				<Box className={classes.separator} />

				<>
					<div ref={uncropContainerRef} style={{ width: "100%" }}>
						<span className={classes.switchLable}>{t("fillCropArea")}</span>
						<Box className={classes.buttonsContainer}>
							{/* <CustomizedTooltip
								arrow
								multiLine={true}
								title="Press Crop button before generating"
								placement="top"
								disable={!disableUncrop}
							> */}
							<span data-tip-disable={false}>
								<BriaButton
									id="GenrateBtn"
									disabled={disableUncrop}
									buttonType={BUTTONTYPES.PRIMARYSMALL}
									className={classes.buttonStyle}
									onClick={async (e) => {
										await applyUncrop();
									}}
								>
									{t("generate")}
								</BriaButton>
							</span>
							{/* </CustomizedTooltip> */}
							{/*<BriaButton*/}
							{/*	disabled={!uncropValues || disableUncrop}*/}
							{/*	buttonType={BUTTONTYPES.SECONDARYMEDIUM}*/}
							{/*	className={classes.buttonStyle}*/}
							{/*	onClick={() => {*/}
							{/*		resetUncrop();*/}
							{/*	}}*/}
							{/*>*/}
							{/*	{t("transparent")}*/}
							{/*</BriaButton>*/}
						</Box>
					</div>
					{(uncropThumbsLoading || uncropLoading) && !isLoadMoreClicked && (
						<Box
							mt={"25px"}
							height="20vh"
							width="100%"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<LoadingAnimation
								color="#ededed"
								loadingStringsPosition="top"
								progressBarTime={15}
								loading={true}
								showLoadingStrings={false}
								loadingStringsTimeout={5000}
								loadingStrings={[]}
							/>
						</Box>
					)}
					{(!uncropLoading || (uncropLoading && isLoadMoreClicked)) && (
						<Box
							style={{
								width: "100%",
								height: 0,
								minHeight:
									((!uncropThumbsLoading && !uncropLoading) || isLoadMoreClicked) &&
									uncropThumbnails.length > 0
										? 500
										: 0,
								visibility:
									((!uncropThumbsLoading && !uncropLoading) || isLoadMoreClicked) &&
									uncropThumbnails.length > 0
										? "visible"
										: "hidden",
							}}
							className={classes.thumbnail}
						>
							<Personalizer
								selectedBackgroundReplaceSeed={BriaAPI.getInstance(visual_hash).getSelectedUncropSeed()}
								isEngineOverloaded={isEngineOverloaded}
								onLoadMoreClick={async () => {
									await callLocalUncropApi(4, true);
								}}
								setReplaceBgThumbsLoading={setUncropThumbsLoading}
								retryingImageAbortController={retryingImageAbortController}
								isReplaceBackground={true}
								selectedPoint={selectedPoint}
								visualHash={visual_hash}
								originalImage={originalImage}
								imageRef={imageRef}
								key="uncrop"
								feature="uncrop"
								personalizerKey="suggestion"
								personalizerValues={{}}
								disabled={processing}
								isSuggestion={true}
								sliders={null}
								user={context.user}
								systemView={context.systemView}
								onChangePersonalizer={async (sliders, key) => {}}
								drawerAnchor={"left"}
								objectType={selectedPoint?.objectType}
								isLoadingExpression={false}
								thumbnailHeight={thumbnailConfig[1]}
								thumbnailWidth={thumbnailConfig[0]}
								thumbnailsPerCol={thumbnailConfig[2]}
								thumbnailsCount={12}
								thumbnailImages={uncropThumbnails}
								onAddSemanticCombination={async () => {
									return "";
								}}
								onReplaceBgThumbnailClick={async (thumbnailImage: ThumbnailImage) => {
									await onUncropThumbnailClick(thumbnailImage);
								}}
							/>
						</Box>
					)}
				</>
			</Grid>
		</Box>
	);
};

export default CropImage;

// const TabDisabledTip = () => {
// 	return (
// 		<CustomizedTooltip
// 			title={
// 				<>
// 					<b className="title">Selection</b>
// 					<div>
// 						Image resolution is too small to use for This format.
// 					</div>
// 				</>
// 			}
// 			placement="right"
// 			multiLine={true}
// 		>
// 			<></>
// 		</CustomizedTooltip>
// 	);
// };
