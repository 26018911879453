import { useFormik } from "formik";
import * as Yup from "yup";
import { MutationFunctionOptions } from "@apollo/client";
import { ChangeEthnicityVariables } from "../../GraphQL/types/ChangeEthnicity";
import { useContext, useState } from "react";
import BriaAPI from "../../sdk/resources/briaAPI";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import Context from "../../Context/Context";
import { sendPostMessage } from "../../Helpers/iframe";
import {
	IframeApplyChangePostMessageTypes,
	IframePostMessageTypes,
} from "../../Constants/IframeConstants";
import { GENERAL_ORG_ID } from "../../Constants";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";

const useLocalizer = () => {
	const context = useContext(Context);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const [changeEthnicityMutationLoading, setChangeEthnicityMutationLoading] =
		useState<boolean>(false);

	const changeEthnicityMutation = async (
		faceId: string,
		options: MutationFunctionOptions<ChangeEthnicityVariables>
	) => {
		if (!options.variables) {
			throw new Error("variables not provided");
		}

		setChangeEthnicityMutationLoading(true);
		const bria = BriaAPI.getInstance(options.variables.visualHash);

		const actions = bria.mapPipelineInputToAPI(
			options.variables as ChangeEthnicityVariables
		);
		const apiPipelineSettings = bria.mapPipelineSettingsToApi(
			options.variables.pipelineSettings
		);
		try {
			const res = await bria.callApi(
				ApiActions.FACE_MANIPULATIONS,
				{
					changes: [
						{
							id: faceId,
							actions,
							change_skin: true,
						},
					],
					image_url: options.variables.inputImage,
					apiPipelineSettings,
				},
				shouldClearWatermark(),
				organizationUid
			);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.Diversity,
					action: actions.diversity,
				},
				options.variables.visualHash,
				context.iframeConfig
			);
			setChangeEthnicityMutationLoading(false);
			return {
				data: {
					changeEthnicity: {
						newImage: {
							url: res.data.image_res,
							__typename: "NewImage",
						},
						layersUrl: res.data.layers_url,
						__typename: "ChangeEthnicity",
					},
				},
			};
		} catch (e) {
			console.log(e);
			setChangeEthnicityMutationLoading(false);
			throw e;
		}
	};
	// const [
	// 	changeEthnicityMutation,
	// 	{ loading: changeEthnicityMutationLoading },
	// ] =
	// 	useMutation<ChangeEthnicity, ChangeEthnicityVariables>(
	// 		CHANGE_ETHNICITY
	// 	);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			ethnicity: "original",
			ethnicityValue: 0,
		},
		validationSchema: Yup.object({
			ethnicity: Yup.string().nullable(),
		}),
		onSubmit: () => {
			// handleChangeEthnicity();
		},
	});

	return {
		selectedEthnicity: formik.values.ethnicity,
		selectedEthnicityValue: formik.values.ethnicityValue,
		changeEthnicityMutationLoading,
		changeEthnicityHandler: (ethnicity: string, ethnicityValue: number) => {
			formik.setFieldValue("ethnicity", ethnicity);
			formik.setFieldValue("ethnicityValue", ethnicityValue);
		},
		resetEthnicity: formik.resetForm,
		changeEthnicityMutation,
	};
};

export default useLocalizer;
