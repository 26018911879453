import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { CircularProgress, makeStyles, TextField, Theme } from "@material-ui/core";
import Personalizer from "../Tools/Personalizer";
import Context from "../../Context/Context";
import BriaButton, { BUTTONTYPES } from "./BriaButton";
import { ThumbnailImage } from "../../Models/ToolsConfig";
import Box from "@material-ui/core/Box";
import BriaAPI from "../../sdk/resources/briaAPI";
import ShowAt from "../Common/Wrappers/ShowAt";
import SurpriseMeButton from "../shared/SurpriseButton";
import { PresentersStylePrompts } from "../../Constants/RandomTextGenerator";
import { DISABLE_ENTER_LANGUAGES } from "../../Constants";
import i18n from "i18next";

interface IProps {
	presentersStyleThumbnails: ThumbnailImage[];
	presentersStyleLoading: boolean;
	processing: boolean;
	selectedPoint: any;
	visual_hash: string;
	imageRef: RefObject<HTMLImageElement>;
	onPresentersStyleThumbnailClick: (thumbnailImage: ThumbnailImage) => void;
	onDoPresentersStyle: (bg_prompt?: string) => Promise<void>;
	originalImage: string;
	OnloadError: boolean;
	presentersStyleText: { text: string; force?: boolean | undefined };
}

const PresentersStyle: React.FC<IProps> = ({
	onDoPresentersStyle,
	presentersStyleThumbnails,
	presentersStyleLoading,
	processing,
	selectedPoint,
	visual_hash,
	imageRef,
	onPresentersStyleThumbnailClick,
	presentersStyleText,
	originalImage,
	OnloadError = false,
}) => {
	const [retryingImageAbortController, setRetryingImageAbortController] = useState<AbortController>(
		new AbortController()
	);
	const [thumbnailConfig, setThumbnailsConfig] = useState<number[]>([0, 0, 0, 11]);
	const [textValue, setTextValue] = useState<string>(presentersStyleText.text ? presentersStyleText.text : "");

	const context = useContext(Context);
	const thumbnailsCount = 2;
	const initialContainerRef = useRef<null | HTMLDivElement>(null);
	const replaceContainerRef = useRef<null | HTMLDivElement>(null);
	const classes = useStyles({
		expanded: presentersStyleLoading || presentersStyleThumbnails.length !== 0,
	});

	const callRecastModel = async () => {
		await onDoPresentersStyle(textValue);
	};

	const setThumbnailImages = async () => {
		retryingImageAbortController?.abort();
		setRetryingImageAbortController(new AbortController());
		await callRecastModel();
		replaceContainerRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};
	const handleChange = ({ target: { value } }: any) => {
		setTextValue(value.replace("\n", "") ?? "");
	};

	useEffect(() => {
		initialContainerRef.current?.scrollIntoView(true);
	}, [visual_hash]);

	useEffect(() => {
		if (imageRef?.current) {
			setThumbnailsConfig(
				imageRef.current.height > imageRef.current.width ? [130, 200, 3, 11] : [150, 130, 2, 11]
			);
		}
	}, [imageRef, imageRef?.current?.clientHeight, imageRef?.current?.clientWidth]);

	const handleKeyUp = (event: any) => {
		event.preventDefault();

		if (!DISABLE_ENTER_LANGUAGES.includes(i18n.language) && event.key === "Enter" && textValue.trim()) {
			setThumbnailImages();
		}
	};
	const inputProps = {
		disableUnderline: true,
		style: { fontSize: 14, fontWeight: 500, lineHeight: "21px" },
	};
	return (
		<Box className={classes.root}>
			<ShowAt showAlways>
				<>
					<div ref={replaceContainerRef}>
						<span className={classes.switchLable}>Change presenter style</span>
						<TextField
							autoFocus
							value={textValue}
							fullWidth
							onChange={handleChange}
							placeholder="Describe the style of the presenter (e.g., superhero costume, astronaut)"
							multiline
							className={classes.textField}
							rows={3}
							maxRows={3}
							onKeyUp={handleKeyUp}
							disabled={processing || OnloadError}
							InputProps={inputProps}
						/>
						<Box
							style={{
								marginBottom: "4px",
								marginTop: "16px",
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
							}}
						>
							<SurpriseMeButton
								disabled={processing || presentersStyleLoading || OnloadError}
								list={PresentersStylePrompts}
								onClick={(text: any) => setTextValue(text)}
							/>
							<BriaButton
								id="GenrateBtn"
								disabled={!textValue || processing || presentersStyleLoading || OnloadError}
								buttonType={BUTTONTYPES.PRIMARYSMALL}
								onClick={(e) => {
									setThumbnailImages();
								}}
								style={{
									width: 113,
								}}
							>
								Change Style
							</BriaButton>
						</Box>
					</div>
					{!presentersStyleLoading ? (
						<Box
							style={{
								width: "100%",
								height: "",
								// minHeight: 500,
							}}
							className={classes.thumbnail}
						>
							<Personalizer
								selectedBackgroundReplaceSeed={BriaAPI.getInstance(
									visual_hash
								).getSelectedPresentersStyleSeed()}
								retryingImageAbortController={retryingImageAbortController}
								isReplaceBackground={true}
								selectedPoint={selectedPoint}
								visualHash={visual_hash}
								originalImage={originalImage}
								imageRef={imageRef}
								key="presenter-style"
								feature="presenter-style"
								personalizerKey="suggestion"
								personalizerValues={{}}
								disabled={processing}
								isSuggestion={true}
								sliders={null}
								user={context.user}
								systemView={context.systemView}
								onChangePersonalizer={async (sliders, key) => {}}
								drawerAnchor={"left"}
								objectType={selectedPoint?.objectType}
								isLoadingExpression={false}
								thumbnailHeight={thumbnailConfig[1]}
								thumbnailWidth={thumbnailConfig[0]}
								thumbnailsPerCol={thumbnailConfig[2]}
								thumbnailsCount={thumbnailsCount}
								thumbnailImages={presentersStyleThumbnails}
								onAddSemanticCombination={async () => {
									return "";
								}}
								onReplaceBgThumbnailClick={(thumbnailImage: ThumbnailImage) => {
									onPresentersStyleThumbnailClick(thumbnailImage);
								}}
							/>
						</Box>
					) : (
						<Box height="20vh" display="flex" justifyContent="center" alignItems="center">
							<CircularProgress />
						</Box>
					)}
				</>
			</ShowAt>
		</Box>
	);
};

interface StyleProps {
	expanded: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	root: (props) => ({
		display: props.expanded ? "block" : "flex",
		justifyContent: "flex-start",
		height: "100%",

		flexDirection: "column",
		width: 404,
	}),
	textField: {
		"& .MuiInput-underline, ": {
			"&::placeholder": {
				opacity: 1,
			},
		},

		"& .MuiInputBase-root": {
			borderRadius: 8,
			padding: "19px 7px 84px 17px !important",
			// border: "1px solid rgba(91, 91, 91, 0.5) !important",
			boxShadow: "0 0 0 1px rgba(91, 91, 91, 0.5)",
			"&:hover": {
				// border: " 1px solid #1A0638 !important",
				boxShadow: "0 0 0 1px #1A0638",
			},
			"&.Mui-focused": {
				// border: " 3px solid #5300C9 !important",
				boxShadow: `0 0 0 3px ${theme.palette.primary.main} !important`,
			},
		},
		"& .MuiInput-underline:before ": {
			border: "none !important",
		},

		"& .MuiInputBase-input": {
			color: "#5B5B5B !important",
		},
		marginTop: "8px",
	},
	switchLable: {
		color: "#1A0638",
		fontSize: 14,
		fontWeight: 600,
		textShadow: "0px 0px 0px #495057",
	},
}));
export default PresentersStyle;
