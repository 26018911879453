import { useState } from "react";
import LoadingOverlay from "./Components/Common/LoadingOverlay";
import AppContextProvider from "./Context/AppContextProvider";
import { BriaThemeProvider } from "./Context/BriaThemeProvider";
import AppContext from "./Context/Context";
import I18nProvider from "./Context/I18nProvider";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { isDisableLoaderRoute, isLightThemeRoute } from "./Components/Tools/utilities";
import TagManager from "react-gtm-module";
import { isInt, isProd } from "./Config/Env";

const tagManagerArgs = {
	gtmId: "GTM-MDN2KMG7",
};
TagManager.initialize(tagManagerArgs);

const App = () => {
	const [forceLightTheme, setForceLightTheme] = useState<boolean>(isLightThemeRoute());
	const [disableLoaderRoute, setDisableLoaderRoute] = useState<boolean>(isDisableLoaderRoute());
	return (
		<I18nProvider>
			<AppContextProvider>
				<AppContext.Consumer>
					{(context) => {
						return disableLoaderRoute || context.authenticationWasVerified ? (
							<BriaThemeProvider context={context} forceLightMode={forceLightTheme}>
								<Routes
									setIsLightThemeRoute={setForceLightTheme}
									setDisableLoaderRoute={setDisableLoaderRoute}
								/>
							</BriaThemeProvider>
						) : (
							<BriaThemeProvider context={context} forceLightMode={forceLightTheme}>
								<LoadingOverlay />
							</BriaThemeProvider>
						);
					}}
				</AppContext.Consumer>
			</AppContextProvider>
		</I18nProvider>
	);
};

export default isProd || isInt ? Sentry.withProfiler(App) : App;
