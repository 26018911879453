import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useLocalizer from "../../Components/Tools/useLocalizer";
import usePersonalizer from "../../Components/Tools/usePersonalizer";
import { GET_USER_BRANDS, IMAGE_DETAILS, VALID_VISUAL_HASH } from "../../GraphQL/queries";
import {
	ImageEditorData,
	ImageEditorData_vdrObject_vdrAwsFaceDetection_faces,
	ImageEditorDataVariables,
} from "../../GraphQL/types/imageEditorData";
import { useUndoRedu } from "../../hooks/useUndoRedo";
// @ts-ignore
import * as JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import {
	BriaObjectType,
	EditorTabIframeMap,
	getImageMeta,
	getImagePixelsData,
	SelectedPointInterface,
	ToolsBackGroundBlurMode,
	ToolsBackGroundMode,
	ToolsViewerMode,
} from "../../Components/Tools/utilities";
import useGrafanaMetric from "../../hooks/useGrafanaMetrics";
import { useExtremeExpression } from "../../Components/Tools/useExtremeExpressions";
import { ThumbnailImage, ToolConfigTabsTypes, ToolsSlider, UncropValues } from "../../Models/ToolsConfig";
import { useToolConfig } from "../../hooks/useToolConfig";
import Context from "../../Context/Context";
import useAskAlan from "../../hooks/useAskAlan";
import { getAuth } from "firebase/auth";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import BriaAPI, { ApiCall } from "../../sdk/resources/briaAPI";
import RouterConstants from "../../Constants/RouterConstants";
import Scene, { AddSemanticCombinationVariables, LeanImageInfo, ThumbnailData } from "../../sdk/resources/element";
import useObjectRemover from "../../Components/Tools/useObjectRemover";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import { IsIframe, sendPostMessage } from "../../Helpers/iframe";
import { IframeApplyChangePostMessageTypes, IframePostMessageTypes } from "../../Constants/IframeConstants";
import { IsAdminOrTesting } from "../../Helpers/adminOrViewer";
import { consumeUserCredits, translateText } from "../../GraphQL/mutations";
import { SubscriptionCreditsTypes } from "../../Constants/SubscriptionConstants";
import { clothColors } from ".././../Constants/StaticLists";
import useErrorPopup from "../../hooks/useErrorPopup";
import { getUserBrands, getUserBrands_getUserBrands, getUserBrandsVariables } from "../../GraphQL/types/getUserBrands";
import client from "../../GraphQL/client";
import { GENERAL_ORG_ID } from "../../Constants";
import { CropPlatformConstants } from "../../Constants/CropPlatformConstants";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";
import { captureException } from "@sentry/react";
import { get, getDatabase, ref } from "firebase/database";
import app from "../../Config/Firebase";
import { DEFAULT_BRUSH } from "../../Constants/defaultBrush";
import { resize } from "../../Components/Tools/CropImgeTool/Crop";
import { pullUntilFileIsAvailableFromBackend } from "../../Helpers/images";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

interface ParamTypes {
	visual_hash: string;
}

export interface CameraVideoActions {
	zoom?: {
		type: string;
		focus_area: string;
		video_depth: string;
		boomerang_loop?: boolean;
		pase: string;
	};
	hover?: {
		start_area: string;
		end_area: string;
		video_depth: string;
		boomerang_loop?: boolean;
		pase: string;
		duration?: number;
	};
	smart_zoom?: {
		boomerang_loop?: boolean;
		pase: string;
		duration?: number;
	};
}

export const useImageEditor = () => {
	const { t } = useTranslation();
	const { visual_hash } = useParams<ParamTypes>();
	const history = useHistory();
	const location = useLocation();
	const passedLocationState = (location.state as any) || {};
	const { isUserAdminOrTesting } = IsAdminOrTesting();
	const { shouldClearWatermark } = ShouldClearWatermark();
	const { isIframe } = IsIframe();

	const { data } = useQuery(VALID_VISUAL_HASH, {
		variables: {
			visualHash: visual_hash,
		},
	});

	if (data?.validVisualHash === false) {
		history.push(RouterConstants.PAGE_NOT_FOUND.path);
	}

	const metrics = useGrafanaMetric(visual_hash);
	const imageRef = useRef<HTMLImageElement>(null);
	const { user, systemView, iframeConfig } = useContext(Context);

	const askAlanScoreRef = useRef<string[]>([]);
	const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
	const [replaceBgSeed, setReplaceBgSeed] = useState<number>();
	// const [uncropSeed, setUncropSeed] = useState<number>();
	const [playgroundUserMoods, setPlaygroundUserMoods] = useState<getUserBrands_getUserBrands[]>([]);

	const { canUndo, canRedo, set, reset, redo, undo } = useUndoRedu();
	const [selectedPoint, setSelectedPoint] = useState<SelectedPointInterface | any | null>(null);
	const [isLoadingImageLeanInfo, setIsLoadingImageLeanInfo] = useState<boolean>(true);
	const [isLoadingPersonInfo, setIsLoadingPersonInfo] = useState<boolean>(true);
	const [isLoadingObjectsInfo, setIsLoadingObjectsInfo] = useState<boolean>(true);
	const [isLoadingBackgroundInfo, setIsLoadingBackgroundInfo] = useState<boolean>(true);
	const [isLoadingCropConfig, setIsLoadingCropConfig] = useState<number>(0);
	const [bgActionLoading, setBgActionLoading] = useState<boolean>(false);
	const [increaseResolutionLoading, setIncreaseResolutionLoading] = useState<boolean>(false);
	const [showResolutionBeforeDownloadDialog, setShowResolutionBeforeDownloadDialog] = useState<boolean>(false);
	const [hideImageUrlLoader, setHideImageUrlLoader] = useState(false);
	const [clickedIncreaseResButton, setClickedIncreaseResButton] = useState<number | null>(null);
	const [replaceBgLoading, setReplaceBgLoading] = useState<boolean>(false);
	const [replaceBgThumbsLoading, setReplaceBgThumbsLoading] = useState<boolean>(false);
	const [uncropThumbsLoading, setUncropThumbsLoading] = useState<boolean>(false);
	const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);
	const [generalLoading, setGeneralLoading] = useState<boolean>(false);
	const [presentersStyleLoading, setPresentersStyleLoading] = useState<boolean>(false);
	const [uncropLoading, setUncropLoading] = useState<boolean>(false);
	const useErrorPopupVar: any = useErrorPopup();
	const [originalImage, setOriginalImage] = useState<string>("");
	const [originalImageDimensions, setOriginalImageDimensions] = useState<{
		width: number;
		height: number;
	}>({ width: 0, height: 0 });
	const [originalImageWithWatermark, setOriginalImageWithWatermark] = useState<string>("");
	const [selectedImageUrl, setSelectedImageUrlState] = useState<string>(passedLocationState.imageUrl ?? "");
	const [imageTransparencyPercentage, setImageTransparencyPercentage] = useState<number>(0);
	const [textOnImage, setTextOnImage] = useState<string>("");
	const [fashionImageUrl, setFashionImageUrl] = useState<string>("");
	const [backgroundString, setBackgroundString] = useState<ToolsBackGroundMode>(ToolsBackGroundMode.withBg);
	const [backgroundBlur, setBackgroundBlur] = useState<ToolsBackGroundBlurMode>(ToolsBackGroundBlurMode.noBlur);
	const [selectedImageUrlWithoutExpression, setSelectedImageUrlWithoutExpression] = useState<string>("");
	const [selectedVideo, setSelectedVideo] = useState<string | null | undefined>(null);

	const [imgUrlHistory, setImageUrlHistory] = useState<string[]>([]);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const editorSelectedTab = searchParams.get("selectedTab") ?? iframeConfig?.editorSelectedTab ?? undefined;

	const setSelectedImageUrl = (url: string) => {
		setSelectedImageUrlState(url);
		setSelectedImageUrlWithoutExpression(url);
		setImageUrlHistory([...imgUrlHistory, url]);
	};

	const getPlaygroundUserMoods = async () => {
		if (playgroundUserMoods.length > 0) {
			return playgroundUserMoods;
		}
		const userRes = await client.query<getUserBrands, getUserBrandsVariables>({
			query: GET_USER_BRANDS,
			variables: {
				includeGalleries: [],
				includeUserGallery: true,
			},
		});
		let getUserBrands = userRes.data.getUserBrands ?? [];
		if (getUserBrands?.length === 0) {
			const predefinedRes = await client.query<getUserBrands, getUserBrandsVariables>({
				query: GET_USER_BRANDS,
				variables: {
					includeGalleries: ["159"],
					includeUserGallery: false,
				},
			});
			getUserBrands = predefinedRes.data.getUserBrands ?? [];
		}
		setPlaygroundUserMoods(getUserBrands);
		return getUserBrands;
	};

	const getResetToOriginalFunc = (orgImage: string, oldApiCallsMap: any) => {
		const resetToOriginal = async () => {
			const client = BriaAPI.getInstance(visual_hash);
			await client.cancelFashionProcesses();
			setSelectedImageUrl(orgImage);
			client.setApiCallsMap(oldApiCallsMap);
		};

		return resetToOriginal;
	};

	const [resetToOriginal, setResetToOriginal] = useState<Function | null>(null);

	const [objectsTooltipPosition, setObjectsTooltipPosition] = useState<{ x: number; y: number } | undefined>({
		x: 0,
		y: 0,
	});
	const [showObjectsFollowUpTooltip, setShowObjectsFollowUpTooltip] = useState<boolean>(false);
	const [imageElement, setImageElement] = useState<HTMLImageElement>();
	const [objectsPanopticImage, setObjectsPanopticImage] = useState<ImageData>();
	const [mainObjectData, setMainObjectData] = useState<ImageData>();

	const [objectsMasks, setObjectsMasks] = useState<HTMLImageElement[]>([]);
	const [isLoadingObjectsMasks, setisLoadingObjectsMasks] = useState<boolean>(false);
	const [leanImageInfo, setLeanImageInfo] = useState<LeanImageInfo | null>(null);
	const [personImageInfo, setPersonImageInfo] = useState<Scene[] | null>(null);
	const [objectsImageInfo, setObjectsImageInfo] = useState<Scene[] | null>(null);
	const [backgroundImageInfo, setBackgroundImageInfo] = useState<Scene | null>(null);
	const [backgroundOracle, setBackgroundOracle] = useState<any>(null);
	const [layersURL, setLayersURL] = useState<string | null>(null);
	const [disableRemoveBGSwitch, setDisableRemoveBGSwitch] = useState<boolean>(false);
	const [pipelineSettings, setPipelineSettings] = useState<any[]>([]);
	const [expandBackgroundTabSelected, setExpandBackgroundTabSelected] = useState(false);
	const [scaleDownFactor, setScaleDownFactor] = useState<number>(1);

	const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(false);

	const [videoStatus, setVideoStatus] = useState<"Progress" | "Ready" | null>(null);

	const [cameraMotionVideo, setCameraMotionVideo] = useState<string>("");
	const [operationPlayGroundError, setOperationPlayGroundError] = useState<boolean>(false);
	const [preCameraMovementActionsConfig, setPreCameraMovementActionsConfig] =
		useState<{ [s: string]: any } | null>(null);
	const [isCameraMovementTabSelected, setIsCameraMovementTabSelected] = useState<boolean>(false);
	const [isReplaceBackgroundTabSelected, setIsReplaceBackgroundTabSelected] = useState<boolean>(false);

	const [isTextEditorTabSelected, setIsTextEditorTabSelected] = useState<boolean>(false);

	const [isLogoTabSelected, setIsLogoTabSelected] = useState<boolean>(false);
	const [isObjectsTabSelected, setIsObjectsTabSelected] = useState<boolean>(false);
	const [isObjectsAutomaticTabSelected, setIsObjectsAutomaticTabSelected] = useState<boolean>(false);

	const [isObjectsManualBrushTabSelected, setIsObjectsManualBrushTabSelected] = useState<boolean>(false);

	const [resetManualBrushTab, setResetManualBrushTab] = useState<boolean | null>(null);
	const theme = useTheme();
	const [lockMasks, setLockMasks] = useState(false);
	const objectsTabCanvasRef = useRef<HTMLCanvasElement | null>(null);
	const [brushConfig, setBrushConfig] = useState({
		globalCompositeOperation: DEFAULT_BRUSH.globalCompositeOperation,
		strokeStyle: isIframe()
			? theme.palette.primary.dark === "#000000" || theme.palette.primary.dark === "#000"
				? "#0A0A0A"
				: theme.palette.primary.dark
			: DEFAULT_BRUSH.strokeStyle,
		lineWidth: DEFAULT_BRUSH.lineWidth,
		brushColorOpacity: DEFAULT_BRUSH.brushColorOpacity,
	});
	const [isFashionMenTabSelected, setIsFashionMenTabSelected] = useState<boolean>(false);
	const setIsFashionSelected = async (isFashionMenTabSelected: boolean, wasFashionSelected: boolean) => {
		setIsFashionMenTabSelected(isFashionMenTabSelected);
		handleTabChangeForFashion(isFashionMenTabSelected, wasFashionSelected);
	};
	const [showCropSavePopUp, setShowCropSavePopUp] = useState(false);

	const [onResetActions, setOnResetActions] = useState<VoidFunction[]>([]);

	const [openCreateVideoPopup, setOpenCreateVideoPopup] = useState<boolean>(false);

	const [openVideoReadyPopup, setOpenVideoReadyPopup] = useState<boolean>(false);

	const [isImageMode, setIsImageMode] = useState<boolean>(true);
	const [objectOnHover, setObjectOnHover] = useState<string>("");

	const [numberOfChanges, setNumberOfChanges] = useState<number>(0);
	const { setAskAlanScoreMutation } = useAskAlan();

	const {
		selectedEthnicity,
		selectedEthnicityValue,
		changeEthnicityMutationLoading,
		changeEthnicityHandler,
		resetEthnicity,
		changeEthnicityMutation,
	} = useLocalizer();

	const downloadBtnProps = {
		disableDownloadButton,
		setDisableDownloadButton,
	};

	const updateResetActions = (newAction: VoidFunction, wholeReset?: boolean) => {
		setOnResetActions(wholeReset ? [newAction] : [...onResetActions, newAction]);
	};

	const executeResetActions = () => {
		onResetActions.forEach((action: VoidFunction) => {
			action();
		});
	};

	const {
		removeObjectMutationLoading,
		removeObjectMutation,
		setremoveObjectMutationLoading,
		removeObjectMutationByCoordinates,
	} = useObjectRemover();

	const [facesConfigurations, setFacesConfigurations] = useState<{
		[key: string]: {
			ethnicity: string;
			ethnicityValue: number;
			selectedExpressionName: string;
			selectedExpressionValue: number;
			semantics: { [key: string]: any };
		};
	}>({});

	const {
		loading: imageDetailsLoading,
		data: imageDetails,
		error: imageDetailsError,
		refetch,
	} = useQuery<ImageEditorData, ImageEditorDataVariables>(IMAGE_DETAILS, {
		variables: {
			visualHash: visual_hash,
		},
	});

	const facesData: ImageEditorData_vdrObject_vdrAwsFaceDetection_faces[] =
		imageDetails?.vdrObject?.vdrAwsFaceDetection?.faces ?? [];
	const [devInfo, setDevInfo] = useState<Scene[] | null>(null);
	const [faceDevInfo, setFaceDevInfo] = useState<Scene[] | undefined>();
	const { toolsLoading, toolsConfigTabs, toolsConfigSliders, toolsConfigThumbnails, toolsConfigSuggestions } =
		useToolConfig(systemView, selectedPoint, facesData[selectedPoint?.index ?? 0]);
	const [disableObjectsTab, setDisableObjectsTab] = useState<boolean>(false);
	const [isUncropApplied, setIsUncropApplied] = useState<boolean>(false);
	const [replaceBgText, setReplaceBgText] = useState<{
		prefix?: string;
		text: string;
		force?: boolean;
		fast: boolean;
	}>({ text: "", prefix: "", fast: true });
	const [presentersStyleText, setPresentersStyleText] = useState<{
		text: string;
		force?: boolean;
	}>({ text: "" });
	const [replaceBgThumbnails, setReplaceBgThumbnails] = useState<ThumbnailImage[]>([]);
	const [presentersStyleThumbnails, setPresentersStyleThumbnails] = useState<ThumbnailImage[]>([]);
	const [uncropThumbnails, setUncropThumbnails] = useState<ThumbnailImage[]>([]);
	const [expandUncropThumbnails, setExpandUncropThumbnails] = useState<ThumbnailImage[]>([]);
	const [showCropSaveFirstTime, setShowCropSaveFirstTime] = useState(true);
	const [uncropValues, setUncropValues] = useState<UncropValues>();
	const [cropZoom, setCropZoom] = useState<number>(1);
	const [draftZoom, setDraftZoom] = useState<number>(1);
	const [cropName, setCropName] = useState<string>();
	const [draftCropName, setDraftCropName] = useState("");
	const [previewImage, setPreviewImage] = useState<any>();
	const [draftCroppedArea, setDraftCroppedArea] = useState<any>(undefined);
	const [croppedArea, setCroppedArea] = useState<any>({
		width: 500,
		height: 500,
		iscustom: false,
	});
	const selectedTabDefault = 0;

	const [selectedTab, setSelectedTab] = React.useState<number>(selectedTabDefault);
	const [activeCropPreset, setActiveCropPreset] = useState("");
	const [activeCropSizebtn, setActiveCropSizeBtn] = useState(-1);
	const [rotation, setRotation] = useState<number>(0);
	const [cropImageUrl, setCropImageUrl] = useState<any>();
	const [cropOnlyImageUrl, setCropOnlyImageUrl] = useState<any>();
	const [cropWidth, setCropWidth] = useState<number>(0);
	const [cropHeight, setCropHeight] = useState<number>(0);
	const [zoomPersantage, setZoomPersantage] = useState<number>(100);
	const [naturalSize, setNaturalSize] = useState({ width: 0, height: 0 });
	const [minZoom, setMinZoom] = useState(0.1);
	const [maxZoom, setMaxZoom] = useState(10);
	const [ethicalError, setEthicalError] = useState<boolean>(false);

	const [draftCroppedAreaPixels, setDraftCroppedAreaPixels] = useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});
	const [draftCroppedAreaPercentage, setDraftCroppedAreaPercentage] = useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});
	const [draftRotation, setDraftRotation] = useState<number>(0);

	const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});
	const [croppedAreaPercentage, setCroppedAreaPercentage] = useState<any>({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});
	const [CropTabselected, setCropTabSeleced] = useState<boolean>(false);
	const [canCompare, setCanCompare] = useState<boolean>(!CropTabselected);
	const [croppedImage, setCroppedImage] = useState<any>(null);

	const cropImageFunction = (w: number, h: number, isCustom: boolean) => {
		setCroppedArea({
			width: Number(w),
			height: Number(h),
			iscustom: isCustom,
		});
	};

	function usePrevious(value: any) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const prevLocation = usePrevious(location.pathname.split("/")[0]);

	useEffect(() => {
		if (cropImageUrl) {
			getImageMeta(cropImageUrl).then((image) => {
				setImageElement(image);
			});
		}
	}, [cropImageUrl]);

	useEffect(() => {
		if (operationPlayGroundError) {
			useErrorPopupVar.showErrorPopup();
			setOperationPlayGroundError(false);
		}
	}, [operationPlayGroundError]);

	useEffect(() => {
		setCanCompare(!CropTabselected);
	}, [CropTabselected]);

	useEffect(() => {
		if (toolsLoading === false) {
			sendPostMessage(IframePostMessageTypes.InitialLoadComplete, {}, visual_hash, context.iframeConfig);
		}
	}, [toolsLoading]);

	useEffect(() => {
		const client = BriaAPI.getInstance(visual_hash);

		function fetchInfo() {
			if (passedLeanImageInfo) {
				setLeanImageInfo(passedLeanImageInfo);
				setIsLoadingImageLeanInfo(false);
			} else {
				setIsLoadingImageLeanInfo(true);
				client
					.getImageLeanInfo(60000)
					.then((res) => {
						setLeanImageInfo(res.data);
					})
					.catch(() => {})
					.finally(() => {
						setIsLoadingImageLeanInfo(false);
					});
			}

			if (passedPersonImageInfo) {
				setPersonImageInfo(passedPersonImageInfo);
				setIsLoadingPersonInfo(false);
			} else {
				setIsLoadingPersonInfo(true);
				client
					.getPersonInfo(60000)
					.then((res) => {
						setPersonImageInfo(res.data.scene);
					})
					.catch(() => {})
					.finally(() => {
						setIsLoadingPersonInfo(false);
					});
			}

			if (passedObjectsImageInfo) {
				setObjectsImageInfo(passedObjectsImageInfo);
				setIsLoadingObjectsInfo(false);
			} else {
				setIsLoadingObjectsInfo(true);
				client
					.getObjectsInfo(60000)
					.then((res) => {
						setObjectsImageInfo(res.data);
					})
					.catch(() => {})
					.finally(() => {
						setIsLoadingObjectsInfo(false);
					});
			}

			if (passedBackgroundImageInfo) {
				setBackgroundImageInfo(passedBackgroundImageInfo);
				setIsLoadingBackgroundInfo(false);
			} else {
				setIsLoadingBackgroundInfo(true);
				client
					.getBackgroundInfo(60000)
					.then((res) => {
						setBackgroundImageInfo(res.data);
					})
					.catch(() => {})
					.finally(() => {
						setIsLoadingBackgroundInfo(false);
					});
			}
		}

		if (getPrevStateFromWindow("prevVhash") !== visual_hash) {
			BriaAPI.resetInstance(visual_hash);
			// setDisableObjectsTab(false);
			setIsUncropApplied(false);
			setReplaceBgText({ text: "", fast: true });
			setPresentersStyleText({ text: "" });
			setReplaceBgThumbnails([]);
			setPresentersStyleThumbnails([]);
		}
		updatePrevStateAtWindow({ prevVhash: visual_hash });
		fetchInfo();
		if (prevLocation === location.pathname.split("/")[0]) {
			handleResetActions();
			if (imageDetails?.vdrObject?.url) {
				setOriginalImage(imageDetails?.vdrObject?.url);
				if (!shouldClearWatermark()) {
					callMoodToAddWatermark();
				} else {
					setSelectedImageUrl(imageDetails?.vdrObject?.url); //to cause proper rerender in case of upload image while in the tool
				}
				setOriginalImageDimensionsState();
			}
		}
		setisLoadingObjectsMasks(true);
		client
			.getObjectsMasks()
			.then((res) => {
				const zip = new JSZip();
				pullUntilFileIsAvailableFromBackend(res.data.objects_masks, "SAM-zip").then(() => {
					JSZipUtils.getBinaryContent(res.data.objects_masks, function (err: any, data: any) {
						if (err) {
							throw err; // or handle err
						}
						zip.loadAsync(data).then((zip) => {
							const promises: Promise<HTMLImageElement>[] = new Array(Object.keys(zip.files).length - 1);
							zip.forEach((relativePath, file) => {
								if (file.name.includes("panoptic")) {
									file.async("base64").then((base64) => {
										getImageMeta(`data:image/png;base64,${base64}`).then(
											(image: HTMLImageElement) => {
												setObjectsPanopticImage(
													getImagePixelsData(image, image.width, image.height).imagePixelsData
												);
											}
										);
									});
								} else {
									const fileNameSuffix = file.name.split("_")[1];
									const fileIndex = parseInt(fileNameSuffix.split(".")[0]) - 1;
									promises[fileIndex] = new Promise<HTMLImageElement>((resolve, reject) => {
										file.async("base64").then((base64) => {
											resolve(getImageMeta(`data:image/png;base64,${base64}`));
										});
									});
								}
							});

							Promise.all(promises).then((res) => {
								setObjectsMasks(res);
							});
						});
					});
				});
			})
			.catch((e) => {
				console.log(e);
				// setOperationPlayGroundError(true);
				captureException(e);
				useErrorPopupVar?.showErrorPopup(t("objectsCouldNotRetrieved"));
			})
			.finally(() => {
				setisLoadingObjectsMasks(false);
			});
	}, [visual_hash, location.pathname.split("/")[0]]);

	useEffect(() => {
		setReplaceBgSeed(Math.floor(Math.random() * 2147483647));
	}, [replaceBgText]);

	// useEffect(() => {
	// 	setUncropSeed(Math.floor(Math.random() * 2147483647));
	// }, [uncropValues]);

	useEffect(() => {
		if (isUserAdminOrTesting()) {
			const client = BriaAPI.getInstance(visual_hash);
			client.getDevInfo().then((res) => {
				setDevInfo(res.data.scene);
			});
		}
	}, [visual_hash]);

	useEffect(() => {
		if (devInfo && selectedPoint) {
			setFaceDevInfo(devInfo?.filter((obj) => obj.parent_id === selectedPoint.id));
		}
	}, [selectedPoint, devInfo]);

	const {
		createExtremeExpressionMutation,
		setExtremeExpression,
		resetExtremeExpression: resetExtremeExpressionHandler,
		selectedExpressionName,
		selectedExpressionValue,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		getValidExpressions,
		validExpressions,
		isLoadingExpression,
	} = useExtremeExpression(personImageInfo);

	const resetExtremeExpression = () => {
		resetExtremeExpressionHandler();
		toolsConfigSliders.forEach((slider) => {
			if (
				slider.level1Id === ToolConfigTabsTypes.Emotions &&
				slider.level2Id === ToolConfigTabsTypes.Expression &&
				personalizerValues[slider.id] !== slider.defaultInitialValue
			) {
				changePersonalizerSlider(slider.id, slider.defaultInitialValue);
			}
		});
	};

	function onChangeShowResolutionBeforeDownloadDialog(newShowResolutionBeforeDownloadDialog: boolean) {
		setShowResolutionBeforeDownloadDialog(newShowResolutionBeforeDownloadDialog);
		setClickedIncreaseResButton(0);
	}

	async function onChangeClickedIncreaseResolutionButton(newClickedIncreaseResolutionButton: number | null) {
		await increaseResolution(newClickedIncreaseResolutionButton ?? 0);
		setClickedIncreaseResButton(newClickedIncreaseResolutionButton);
	}

	function onObjectHover(objectId: string) {
		setObjectOnHover(objectId);
	}

	function clearObjectHover() {
		setObjectOnHover("");
	}

	const resetFashionSliders = () => {
		toolsConfigSliders.forEach(async (slider) => {
			if (
				slider.level2Id === ToolConfigTabsTypes.FashionMen &&
				personalizerValues[slider.id] !== slider.defaultInitialValue
			) {
				await changePersonalizerSlider(slider.id, slider.defaultInitialValue);
			}
		});
	};

	useEffect(() => {
		setDisableDownloadButton(
			// !selectedImageUrl ||
			// 	selectedImageUrl === originalImage ||
			isCameraMovementTabSelected && !!videoStatus && videoStatus !== "Ready"
		);
	}, [selectedImageUrl, isCameraMovementTabSelected, videoStatus]);

	const context = useContext(Context);
	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;

	async function callMoodToAddWatermark() {
		const client = BriaAPI.getInstance(visual_hash);
		const response = await client._applyMoodOnImage(visual_hash, selectedImageUrl, {});
		const newSelectedImageUrl = response ? response.data?.image_res : originalImage;

		setSelectedImageUrl(newSelectedImageUrl);
		setOriginalImageWithWatermark(newSelectedImageUrl);
	}

	useEffect(() => {
		context.uploadImagePurpose &&
			setSelectedPoint({
				...selectedPoint,
				objectType: context.uploadImagePurpose,
			});
		context.updateUploadImagePurpose("");
		fetchCropConfig();
	}, []);

	async function fetchCropConfig() {
		try {
			if (!context.cropConfig || context.cropConfig.length === 0) {
				var firebaseDatabase = getDatabase(app);
				const res = await get(ref(firebaseDatabase, "crop_config/"));
				context.setCropConfig(CropPlatformConstants(res.val()));
			}
			setIsLoadingCropConfig(1);
		} catch (e) {
			setIsLoadingCropConfig(-1);
			captureException(e);
		}
	}

	async function fetchCameraVideo(actions: CameraVideoActions) {
		// const hasCredits = await checkIfUserHasCredits(history, SubscriptionCreditsTypes.VIDEO, isIframe(), context);
		// if (!hasCredits) return;

		const client = BriaAPI.getInstance(visual_hash);
		setVideoStatus("Progress");
		await client
			.video([visual_hash], selectedImageUrl || originalImage, actions)
			.then(async (res) => {
				sendPostMessage(
					IframePostMessageTypes.ApplyChange,
					{
						type: IframeApplyChangePostMessageTypes.CameraMotion,
						action: actions,
					},
					visual_hash,
					context.iframeConfig
				);
				setVideoStatus("Ready");
				setCameraMotionVideo(res.data.video_response);
				setDisableDownloadButton(false);
				if (res.data.video_response) {
					await consumeUserCredits(SubscriptionCreditsTypes.VIDEO);
				}
			})
			.catch(() => {
				setVideoStatus(null);
				useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			});
	}

	const resetCameraMotionVideo = () => {
		setCameraMotionVideo("");
		setVideoStatus(null);
	};

	const updateUndoRedo = (newState: any = {}) => {
		set({
			undo,
			redo,
			...newState,
		});
	};

	useEffect(() => {
		if (location.search && location.search.includes("from=email")) {
			Analytics.logEvent(ANALYTICS_EVENTS.UPLOADED_IMAGE_OPEN, {
				from: "email",
			});
		}
	}, [visual_hash]);

	const updatePrevStateAtWindow = (newState: any) => {
		(window as any).imageEditorInfo = {
			...(window as any).imageEditorInfo,
			...newState,
		};
	};

	const getPrevStateFromWindow = (key: string) => (window as any)?.imageEditorInfo?.[key];

	// useEffect(() => {
	// 	if (imageDetails) {
	// 		setPipelineSettings(imageDetails.metaData?.settings ?? []);
	// 	}
	// }, [imageDetails]);

	const setOriginalImageDimensionsState = () => {
		if (imageDetails?.vdrObject?.url) {
			getImageMeta(imageDetails?.vdrObject?.url).then((image: any) => {
				setOriginalImageDimensions({
					width: image.width,
					height: image.height,
				});
			});
		}
	};

	useEffect(() => {
		if (imageDetails?.vdrObject?.url) {
			setOriginalImage(imageDetails?.vdrObject?.url);
			if (!shouldClearWatermark()) {
				callMoodToAddWatermark();
			} else {
				setSelectedImageUrl(imageDetails?.vdrObject?.url); //to cause proper rerender in case of upload image while in the tool
			}
			setOriginalImageDimensionsState();
			if (!!passedLocationState?.replaceBgText || !!passedLocationState?.prefixText) {
				setReplaceBgText({
					prefix: passedLocationState?.prefixText,
					text: passedLocationState?.replaceBgText,
					force: true,
					fast: true,
				});
			}
		}
	}, [imageDetails?.vdrObject?.url]);

	useEffect(() => {
		if ((imageRef?.current?.naturalHeight ?? 0) > 0) {
			setScaleDownFactor((imageDetails?.vdrObject?.height ?? 1) / (imageRef?.current?.naturalHeight || 1));
		}
	}, [imageRef?.current?.naturalHeight]);

	useEffect(() => {
		context.updateObjectsToRemoveArray([]);
		setObjectsToRemoveArray([]);
		setShouldResetCheckboxes(true);
		refetch();
	}, [visual_hash, refetch]);

	useEffect(() => {
		const prevStateFromWindow = getPrevStateFromWindow("prevSysView");
		if (prevStateFromWindow && prevStateFromWindow !== systemView) handleResetActions();
		updatePrevStateAtWindow({ prevSysView: systemView });
	}, [systemView]);

	const currentSliders: ToolsSlider[] = [];
	const showBG = async () => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setBgActionLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const response = await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.REMOVE_BG
			);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.RemoveBackground,
					action: { remove: false },
				},
				visual_hash,
				context.iframeConfig
			);
			setSelectedImageUrl(response.data.image_res);
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
					setBackgroundString(ToolsBackGroundMode.noBg);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(response.data.image_res);
					setBackgroundString(ToolsBackGroundMode.withBg);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			setBgActionLoading(false);
		}
	};

	const increaseResolution = async (desiredIncrease: number) => {
		try {
			setIncreaseResolutionLoading(true);
			const oldImageURL = selectedImageUrl;
			const client = BriaAPI.getInstance(visual_hash);
			const prevInstance = client.clone();

			let newSelectedImageUrl = originalImage;
			if (desiredIncrease !== 0) {
				const res = await client.callNonSidCalls(
					client.getLastApiCallSid(),
					organizationUid,
					true || shouldClearWatermark(), // remove "true" to enable watermark
					undefined,
					true
				);

				const response = await client.increaseResolutionImage(
					res?.data?.sid ?? client.getLastApiCallSid(),
					desiredIncrease,
					true || shouldClearWatermark(), // remove "true" to enable watermark
					organizationUid
				);

				if (!response?.data?.image_res) {
					useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
				}

				newSelectedImageUrl = response ? response.data?.image_res : originalImage;
			} else {
				newSelectedImageUrl = (await client.getLastImageUrl()) ?? originalImage;
			}
			setSelectedImageUrl(newSelectedImageUrl);

			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.IncreaseResolution,
					value: desiredIncrease,
				},
				visual_hash,
				context.iframeConfig
			);

			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
					setClickedIncreaseResButton(clickedIncreaseResButton);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(newSelectedImageUrl);
					setClickedIncreaseResButton(desiredIncrease);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		} finally {
			setIncreaseResolutionLoading(false);
			Analytics.logToolsEvent(ANALYTICS_EVENTS.INCREASE_RESOLUTION, {
				source: "increase_resolution_buttons",
				changeName: "increase_resolution",
				value: desiredIncrease,
			});
		}
	};

	const removeBG = async () => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setBgActionLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const { data } = await client.callApi(ApiActions.REMOVE_BG, {}, shouldClearWatermark(), organizationUid);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.RemoveBackground,
					action: { remove: true },
				},
				visual_hash,
				context.iframeConfig
			);
			setSelectedImageUrl(data.image_res);

			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
					setBackgroundString(ToolsBackGroundMode.withBg);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(data.image_res);
					setBackgroundString(ToolsBackGroundMode.noBg);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			setBgActionLoading(false);
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.REMOVE_BG, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "remove_background",
				value: 0,
			});
		}
	};

	const applySketchImageStyle = async () => {
		try {
			setIsImageLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const { data } = await client.callApi(ApiActions.IMAGE_STYLE, {}, shouldClearWatermark(), organizationUid);
			if (!data.image_res) {
				throw `Error while calling apply_style API: ${data}`;
			}
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.ImageStyle,
					action: { image_style: "sketch" },
				},
				visual_hash,
				context.iframeConfig
			);
			setSelectedImageUrl(data.image_res);
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(data.image_res);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			setShowCropSaveFirstTime(true);
			setIsImageLoading(false);
		}
	};

	const unBlurBG = async () => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setBgActionLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const response = await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.BLUR_BG
			);

			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.BlurBackground,
					action: { blur: false },
				},
				visual_hash,
				context.iframeConfig
			);
			setSelectedImageUrl(response.data.image_res);
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
					setBackgroundBlur(ToolsBackGroundBlurMode.Blur);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(response.data.image_res);
					setBackgroundBlur(ToolsBackGroundBlurMode.noBlur);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			setBgActionLoading(false);
		}
	};
	const blurBG = async () => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setBgActionLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.REMOVE_BG
			);
			const { data } = await client.callApi(ApiActions.BLUR_BG, {}, shouldClearWatermark(), organizationUid);
			sendPostMessage(
				IframePostMessageTypes.ApplyChange,
				{
					type: IframeApplyChangePostMessageTypes.BlurBackground,
					action: { blur: true },
				},
				visual_hash,
				context.iframeConfig
			);
			setSelectedImageUrl(data.image_res);
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
					setBackgroundBlur(ToolsBackGroundBlurMode.noBlur);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(data.image_res);
					setBackgroundBlur(ToolsBackGroundBlurMode.Blur);
				},
			});
		} catch (e) {
			console.log(e);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			setBgActionLoading(false);
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.BLUR_BG, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "Blur_background",
				value: 0,
			});
		}
	};
	const increaseNumberOfChanges = () => {
		setNumberOfChanges(numberOfChanges + 1);
	};

	useEffect(() => {
		localStorage.setItem("numberOfChanges", numberOfChanges.toString());
	}, [numberOfChanges]);

	const {
		personalizerValues,
		addObjectTypeSemanticsMutationLoading,
		addSemanticsMutationLoading,
		toDisplayThumbnails,
		setSemanticsValues,
		resetSemantics,
		changePersonalizerSlider,
		addSemanticsMutation,
		addSemanticCombinationMutation,
		addObjectTypeSemanticsMutation,
		setPersonalizerInitialValues,
		setAddSemanticsMutationLoading,
	} = usePersonalizer(toolsConfigSliders);

	const [passedLeanImageInfo, setPassedLeanImageInfo] = useState<LeanImageInfo | null>(
		passedLocationState?.leanImageInfo
	);
	const [passedPersonImageInfo, setPassedPersonImageInfo] = useState<Scene[] | null>(
		passedLocationState?.personImageInfo
	);
	const [passedObjectsImageInfo, setPassedObjectsImageInfo] = useState<Scene[] | null>(
		passedLocationState?.objectsImageInfo
	);
	const [passedBackgroundImageInfo, setPassedBackgroundImageInfo] = useState<Scene | null>(
		passedLocationState?.backgroundImageInfo
	);
	const [passedApiCallsMap, setPassedApiCalls] = useState<Map<string, ApiCall> | null>(
		passedLocationState?.apiCallsMap
	);

	const getPipelineVariables = (isFashionTabSelected: boolean = false) => {
		const currentSemantics = toolsConfigSliders
			.filter(
				(slider) =>
					slider.defaultInitialValue !== personalizerValues[slider.id] &&
					slider.level1Id === "personalizer" &&
					isFashionTabSelected === (slider.level2Id === "fashion_men") &&
					slider.id !== ToolConfigTabsTypes.UPPER_CLOTHES
			)
			.map(({ maxLayer, maxName, maxValue, minLayer, minName, minValue, name, pca, reversed }) => ({
				maxLayer,
				maxName,
				maxValue,
				minLayer,
				minName,
				minValue,
				name,
				pca,
				reversed,
				value: personalizerValues[name],
			}));

		if (selectedPoint?.rect) {
			return {
				ethnicity: selectedEthnicity,
				ethnicityValue: selectedEthnicityValue,
				inputImage: selectedImageUrl,
				pipelineSettings: pipelineSettings,
				rects: [selectedPoint?.rect],
				semantics: currentSemantics,
				uid: "38f7e0b0-195c-4140-8e35-6569f08b04c5",
				visualHash: visual_hash,
				objectType: selectedPoint.objectType,
			};
		}
		return {
			ethnicity: selectedEthnicity,
			ethnicityValue: selectedEthnicityValue,
			inputImage: selectedImageUrl,
			pipelineSettings: pipelineSettings,
			rects: [],
			semantics: currentSemantics,
			uid: "38f7e0b0-195c-4140-8e35-6569f08b04c5",
			visualHash: visual_hash,
			objectType: "",
		};
	};

	const [shouldResetCheckboxes, setShouldResetCheckboxes] = useState<boolean>(false);
	const [objectsToRemoveArray, setObjectsToRemoveArray] = useState<string[]>(context.objectsToRemoveArray);

	const onShowObject = async (objectId: string) => {
		setremoveObjectMutationLoading(true);
		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		const oldImageURL = selectedImageUrl;
		const client = BriaAPI.getInstance(visual_hash);
		const prevInstance = client.clone();

		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			metrics.setStartTime(performance.now());
			const res = await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.REMOVE_OBJECT,
				objectId
			);
			const newArray = objectsToRemoveArray.filter((thisObjectId: string) => thisObjectId !== objectId);
			setObjectsToRemoveArray(newArray);
			context.updateObjectsToRemoveArray(newArray);
			setSelectedImageUrl(res?.data.image_res ?? "");
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(res?.data?.changeEthnicity?.newImage?.url ?? "");
				},
			});
			return true;
		} catch (e) {
			captureException(e);
			changeEthnicityHandler(previousselectedEthnicity, previousselectedEthnicityValue);
			return false;
		} finally {
			increaseNumberOfChanges();
			Analytics.logToolsEvent(ANALYTICS_EVENTS.REMOVE_OBJECT, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "object",
				value: 0,
			});
			setremoveObjectMutationLoading(false);
		}
	};
	const onRestoreAllObjects = async () => {
		setShouldResetCheckboxes(true);
		setGeneralLoading(true);
		const client = BriaAPI.getInstance(visual_hash);
		let imageRes = selectedImageUrl;
		if (
			client.getApiCallsMap().has(ApiActions?.REMOVE_OBJECT) ||
			client.getApiCallsMap().has(ApiActions?.REMOVE_BRUSH_OBJECT) ||
			client.getApiCallsMap().has(ApiActions?.REMOVE_AUTO_OBJECT)
		) {
			const apiCalls = client?.getApiCallsStack();
			client?.resetCalls();
			let res;
			let isFaceManipuLiationsCalled = false;
			for (let i = 0; i < apiCalls?.length; i++) {
				let isFaceManipuLiationsAction = apiCalls[i]?.action === ApiActions?.FACE_MANIPULATIONS;
				if (
					[
						ApiActions?.REMOVE_OBJECT,
						ApiActions?.REMOVE_BRUSH_OBJECT,
						ApiActions?.REMOVE_AUTO_OBJECT,
					]?.includes(apiCalls[i]?.action)
				) {
					continue;
				}
				if (isFaceManipuLiationsAction && isFaceManipuLiationsCalled) continue;
				else if (isFaceManipuLiationsAction && !isFaceManipuLiationsCalled) isFaceManipuLiationsCalled = true;
				res = await client?.callApi(
					apiCalls[i]?.action,
					apiCalls[i]?.input,
					apiCalls[i]?.shouldClearWatermark,
					apiCalls[i]?.orgUid,
					apiCalls[i]?.ignoreEmpty,
					apiCalls[i]?.disableRequest
				);
				res.data.image_res && (await pullUntilFileIsAvailableFromBackend(res.data.image_res, "-"));
			}
			setSelectedImageUrl(res?.data.image_res ?? "");
			imageRes = res?.data.image_res ?? "";
		}
		setGeneralLoading(false);
		return imageRes;
	};
	const onRemoveObject = async (objectId: string) => {
		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		const oldImageURL = selectedImageUrl;
		const client = BriaAPI.getInstance(visual_hash);
		const prevInstance = client.clone();

		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			metrics.setStartTime(performance.now());
			const res = await removeObjectMutation(objectId, {
				variables: {
					...getPipelineVariables(isFashionMenTabSelected),
				},
			});
			setObjectsToRemoveArray([objectId, ...objectsToRemoveArray]);
			context.updateObjectsToRemoveArray([objectId, ...objectsToRemoveArray]);
			setSelectedImageUrl(res?.image_res ?? "");
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					setSelectedImageUrl(oldImageURL);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setSelectedImageUrl(res?.image_res ?? "");
				},
			});
			return true;
		} catch (e) {
			captureException(e);
			changeEthnicityHandler(previousselectedEthnicity, previousselectedEthnicityValue);
			return false;
		} finally {
			increaseNumberOfChanges();
			Analytics.logToolsEvent(ANALYTICS_EVENTS.REMOVE_OBJECT, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "object",
				value: 0,
			});
		}
	};

	const onRemoveObjectByCoordinates = async (positivePoint: any, imageSize: any) => {
		const oldImageURL = selectedImageUrl;
		const client = BriaAPI.getInstance(visual_hash);
		const prevInstance = client.clone();
		try {
			const res = await removeObjectMutationByCoordinates({
				variables: {
					changes: [
						{
							positive_points: positivePoint,
							actions: { remove: true },
							size: imageSize,
						},
					],
					visualHash: visual_hash,
				},
			});
			if (res?.image_res) {
				setSelectedImageUrl(res?.image_res);
				set({
					undo: async () => {
						BriaAPI.setInstance(prevInstance);
						setSelectedImageUrl(oldImageURL);
					},
					redo: () => {
						BriaAPI.setInstance(client.clone());
						setSelectedImageUrl(res?.image_res ?? "");
					},
				});
				return true;
			} else {
				return false;
			}
		} catch (e) {
			captureException(e);
			return false;
		}
	};

	const onChangeEthnicity = async (ethnicity: string, value: number) => {
		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			await changeEthnicityHandler(ethnicity, value);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION,
			// 	isIframe(),
			// 	context
			// );
			// if (!hasCredits) throw new Error("No enough credits");

			const oldImageURL = selectedImageUrl;
			const client = BriaAPI.getInstance(visual_hash);
			const prevInstance = client.clone();

			metrics.setStartTime(performance.now());
			const res = await changeEthnicityMutation(selectedPoint?.id ?? "", {
				variables: {
					...getPipelineVariables(),
					ethnicity: ethnicity,
					ethnicityValue: value,
				},
			});
			setLayersURL(res?.data?.changeEthnicity?.layersUrl ?? "");
			setSelectedImageUrl(res?.data?.changeEthnicity?.newImage?.url ?? "");
			askAlanScoreRef.current = [
				getAuth().currentUser?.email ?? user?.userName ?? "Invalid Email",
				res?.data?.changeEthnicity?.newImage?.url ?? "",
				`ethnicity_change_${ethnicity}`,
				visual_hash,
				value.toString(),
				`${selectedPoint?.index}`,
			];

			metrics.addNewMetric("change_ethnicity_api_time");
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					changeEthnicityHandler(previousselectedEthnicity, previousselectedEthnicityValue);
					setSelectedImageUrl(oldImageURL);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					changeEthnicityHandler(ethnicity, value);
					setSelectedImageUrl(res?.data?.changeEthnicity?.newImage?.url ?? "");
				},
			});
			if (res?.data?.changeEthnicity?.newImage?.url) {
				await consumeUserCredits(SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION);
			}
		} catch (e) {
			changeEthnicityHandler(previousselectedEthnicity, previousselectedEthnicityValue);
			captureException(e);
		} finally {
			increaseNumberOfChanges();
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.CHANGE_ETHNICITY, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: ethnicity,
				value,
			});
		}
	};

	const slidersMapper = ({
		maxLayer,
		maxName,
		maxValue,
		minLayer,
		minName,
		minValue,
		name,
		pca,
		reversed,
		value,
	}: ToolsSlider) => ({
		maxLayer,
		maxName,
		maxValue,
		minLayer,
		minName,
		minValue,
		name,
		pca,
		reversed,
		value,
	});

	const isHairChange = (text: string) => {
		return text.startsWith("dark_hair") || text.startsWith("light_hair");
	};

	const resetHairChanges = async (field: string) => {
		if (isHairChange(field)) {
			const newPersonalizerValues = JSON.parse(JSON.stringify(personalizerValues));
			Object.keys(newPersonalizerValues).forEach((key) => {
				if (isHairChange(key)) {
					const slider = toolsConfigSliders.find(({ id }) => id === key);
					if (slider) {
						newPersonalizerValues[key] = slider.defaultInitialValue;
					}
				}
			});
			await setSemanticsValues(newPersonalizerValues);
		}
	};

	const handleTabChangeForFashion = async (isFashionSelected: boolean, wasFashionSelected: boolean) => {
		setIsImageLoading(false);

		if (!isFashionSelected && !wasFashionSelected) {
			return;
		}

		if (!isFashionSelected && wasFashionSelected) {
			if (resetToOriginal) {
				await resetToOriginal();
			}
			return;
		}

		const oldApiCallsMap = BriaAPI.getInstance(visual_hash).getApiCallsMap();
		let resert = getResetToOriginalFunc(selectedImageUrl, oldApiCallsMap);
		setResetToOriginal(() => resert);

		resetFashionSliders();
		if (!fashionImageUrl) {
			await setIsImageLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const fashion_info = await client.getFashionInfo();
			const imageUrl = fashion_info.data.aligned_image_url || "";
			setFashionImageUrl(imageUrl);
			setSelectedImageUrl(imageUrl);
			setIsImageLoading(false);
		} else {
			setSelectedImageUrl(fashionImageUrl);
		}
	};

	const onChangePersonalizer = async (
		field: string,
		value: number,
		actionType: ApiActions = ApiActions.FACE_MANIPULATIONS
	) => {
		const previousValue = personalizerValues[field];
		const oldImageURL = selectedImageUrl;
		const client = BriaAPI.getInstance(visual_hash);
		const prevInstance = client.clone();
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			await changePersonalizerSlider(field, value);
			// if (actionType === ApiActions.FACE_MANIPULATIONS) {
			// 	const hasCredits = await checkIfUserHasCredits(
			// 		history,
			// 		SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION,
			// 		isIframe(),
			// 		context
			// 	);
			// 	if (!hasCredits) throw new Error("No enough credits");
			// }
			const pipelineVariables = getPipelineVariables();
			if (actionType === ApiActions.UPPER_CLOTHES && faceDevInfo) {
				const sceneObj = faceDevInfo.find((e) => e.type === ToolConfigTabsTypes.UPPER_CLOTHES);
				const changes = [
					{
						id: sceneObj?.parent_id,
						actions: [
							{
								rgb_value: clothColors.personlizerUpperClothRgp[Math.round(value)],
								rect: sceneObj?.rect,
							},
						],
					},
				];
				const input = {
					changes,
					apiPipelineSettings: pipelineVariables.pipelineSettings,
				};
				setAddSemanticsMutationLoading(true);
				const res = await BriaAPI.getInstance(visual_hash).callApi(
					ApiActions.UPPER_CLOTHES,
					input,
					shouldClearWatermark(),
					organizationUid
				);
				setSelectedImageUrl(res.data.image_res);
				setAddSemanticsMutationLoading(false);
				set({
					undo: () => {
						BriaAPI.setInstance(prevInstance);
						changePersonalizerSlider(field, previousValue);
						setSelectedImageUrl(oldImageURL);
					},
					redo: () => {
						BriaAPI.setInstance(client.clone());
						changePersonalizerSlider(field, value);
						setSelectedImageUrl(res.data.image_res);
					},
				});
			} else {
				await resetHairChanges(field);
				let semantic = pipelineVariables.semantics?.find((slider) => slider.name === field);

				if (isHairChange(field)) {
					pipelineVariables.semantics = pipelineVariables.semantics.filter(({ name }) => !isHairChange(name));
				}

				if (semantic) {
					semantic.value = value;
				} else {
					let fullSlider = toolsConfigSliders.find((slider) => slider.id === field);

					if (fullSlider) {
						let subset = slidersMapper(fullSlider);

						pipelineVariables.semantics.push({
							...subset,
							value,
						});
					}
				}

				if (selectedPoint?.objectType === BriaObjectType.human) {
					const sliderConfig = toolsConfigSliders.find((el) => el.name === field);
					const isExpression =
						sliderConfig &&
						sliderConfig.level1Id === ToolConfigTabsTypes.Personalizer &&
						sliderConfig.level2Id === ToolConfigTabsTypes.Expression;

					isExpression && resetExtremeExpression();
					metrics.setStartTime(performance.now());

					const res = await addSemanticsMutation(
						selectedPoint?.id ?? "",
						{
							variables: pipelineVariables,
						},
						actionType
					);
					if (!isExpression) {
						sendPostMessage(
							IframePostMessageTypes.ApplyChange,
							{
								type: field,
								action: {
									key: field,
									value: value,
								},
							},
							visual_hash,
							context.iframeConfig
						);
					}
					setLayersURL(res?.data?.addSemantics?.layersUrl ?? "");

					if (isExpression) {
						setSelectedImageUrlState(res?.data?.addSemantics?.newImage?.url ?? "");
					} else if ((actionType == ApiActions.FASHION_MEN) == isFashionMenTabSelected) {
						setSelectedImageUrl(res?.data?.addSemantics?.newImage?.url ?? "");
					}
					metrics.addNewMetric("add_semantics_api_time");
					askAlanScoreRef.current = [
						getAuth().currentUser?.email ?? user?.userName ?? "Invalid Email",
						res?.data?.addSemantics?.newImage?.url ?? "",
						pipelineVariables.semantics.map((el) => el.name).join("_"),
						visual_hash,
						value.toString(),
						`${selectedPoint?.index}`,
					];
					set({
						undo: () => {
							BriaAPI.setInstance(prevInstance);
							changePersonalizerSlider(field, previousValue);
							setSelectedImageUrl(oldImageURL);
						},
						redo: () => {
							BriaAPI.setInstance(client.clone());
							changePersonalizerSlider(field, value);
							setSelectedImageUrl(res?.data?.addSemantics?.newImage?.url ?? "");
						},
					});
					if (actionType === ApiActions.FACE_MANIPULATIONS && res?.data?.addSemantics?.newImage?.url) {
						await consumeUserCredits(SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION);
					}
				} else {
					pipelineVariables.rects = [];
					pipelineVariables.inputImage = "";
					const res = await addObjectTypeSemanticsMutation({
						variables: pipelineVariables,
					});
					setLayersURL(res?.data?.objectTypeSemantics?.layersUrl ?? "");
					setSelectedImageUrl(res?.data?.objectTypeSemantics?.newImage?.url ?? "");
					set({
						undo: () => {
							BriaAPI.setInstance(prevInstance);
							changePersonalizerSlider(field, previousValue);
							setSelectedImageUrl(oldImageURL);
						},
						redo: () => {
							BriaAPI.setInstance(client.clone());
							changePersonalizerSlider(field, previousValue);
							setSelectedImageUrl(res?.data?.objectTypeSemantics?.newImage?.url ?? "");
						},
					});
				}
			}
		} catch (e) {
			console.log(e);
			changePersonalizerSlider(field, previousValue);
			setOperationPlayGroundError(true);
			captureException(e);
		} finally {
			increaseNumberOfChanges();
			await Analytics.logEvent(ANALYTICS_EVENTS.CHANGE_APPEARANCE, {
				source: context.preferences?.style,
				personalizer: field,
				value,
			});
		}
	};

	const onThumbnailClick = async ({
		semanticsList,
		ethnicity = selectedEthnicity,
		ethnicityValue = selectedEthnicityValue,
	}: {
		semanticsList: ToolsSlider[];
		ethnicity: string;
		ethnicityValue: number;
	}) => {
		clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
		// const hasCredits = await checkIfUserHasCredits(
		// 	history,
		// 	SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION,
		// 	isIframe(),
		// 	context
		// );
		// if (!hasCredits) return;

		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		const previousSematicsValues = JSON.parse(JSON.stringify(personalizerValues));
		const oldImageURL = selectedImageUrl;
		const client = BriaAPI.getInstance(visual_hash);
		const prevInstance = client.clone();
		const currentSemantics = semanticsList.map((el) => slidersMapper(el));
		const pipelineVariables = getPipelineVariables();
		pipelineVariables.ethnicity = ethnicity;
		pipelineVariables.ethnicityValue = ethnicityValue;
		pipelineVariables.semantics = currentSemantics;

		const newPersonaolizerValues: { [key: string]: number } = {};
		Object.keys(personalizerValues).forEach((key) => {
			newPersonaolizerValues[key] =
				currentSemantics.find((el) => el.name === key)?.value ??
				toolsConfigSliders.find((el) => el.name === key)?.defaultInitialValue ??
				personalizerValues[key];
		});
		await changeEthnicityHandler(ethnicity, ethnicityValue);
		await setSemanticsValues(newPersonaolizerValues);
		metrics.setStartTime(performance.now());
		const res = await addSemanticsMutation(selectedPoint?.id ?? "", {
			variables: pipelineVariables,
		});
		metrics.addNewMetric("add_semantics_api_time");
		setLayersURL(res?.data?.addSemantics?.layersUrl ?? "");
		setSelectedImageUrl(res?.data?.addSemantics?.newImage?.url ?? "");
		set({
			undo: () => {
				BriaAPI.setInstance(prevInstance);
				changeEthnicityHandler(previousselectedEthnicity, previousselectedEthnicityValue);
				setSemanticsValues(newPersonaolizerValues);
				setSelectedImageUrl(oldImageURL);
			},
			redo: () => {
				BriaAPI.setInstance(client.clone());
				changeEthnicityHandler(ethnicity, ethnicityValue);
				setSemanticsValues(previousSematicsValues);
				setSelectedImageUrl(res?.data?.addSemantics?.newImage?.url ?? "");
			},
		});
		if (res?.data?.addSemantics?.newImage?.url) {
			await consumeUserCredits(SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION);
		}
	};

	const createExtremeExpression = async (emotion: string, value: number) => {
		const previousSelectedExpressionName = selectedExpressionName;
		const previousSelectedExpressionValue = selectedExpressionValue;
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setExtremeExpression(emotion, value);

			await changePersonalizerSlider(
				previousSelectedExpressionName,
				previousSelectedExpressionName === emotion ? previousSelectedExpressionValue : 0
			);
			await changePersonalizerSlider(emotion, value);
			// const hasCredits = await checkIfUserHasCredits(
			// 	history,
			// 	SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION,
			// 	isIframe(),
			// 	context
			// );
			// if (!hasCredits) throw new Error("No enough credits");

			const oldImageURL = selectedImageUrl;
			const client = BriaAPI.getInstance(visual_hash);
			const prevInstance = client.clone();

			const pipelineVariables = await getPipelineVariables();
			pipelineVariables.semantics = pipelineVariables.semantics.filter((sematic) => {
				const slider = toolsConfigSliders.find((el) => el.name === sematic.name);
				if (
					!!slider &&
					slider.level1Id === ToolConfigTabsTypes.Personalizer &&
					slider.level2Id === ToolConfigTabsTypes.Expression
				) {
					changePersonalizerSlider(slider.id, slider.defaultInitialValue);
					sematic.value = slider.defaultInitialValue;
					return false;
				}
				return true;
			});

			const res = await createExtremeExpressionMutation(selectedPoint?.id ?? "", {
				variables: {
					...pipelineVariables,
					inputImage: selectedImageUrlWithoutExpression,
					sliderScale: value,
					emotion: emotion,
				},
			});
			setLayersURL(res?.data?.addExtremeExpression?.layersUrl ?? "");
			setSelectedImageUrlState(res?.data?.addExtremeExpression?.newImage?.url ?? "");
			askAlanScoreRef.current = [
				getAuth().currentUser?.email ?? user?.userName ?? "Invalid Email",
				res?.data?.addExtremeExpression?.newImage?.url ?? "",
				`expression_change_${emotion}`,
				visual_hash,
				value.toString(),
				`${selectedPoint?.index}`,
			];
			set({
				undo: () => {
					BriaAPI.setInstance(prevInstance);
					changePersonalizerSlider(previousSelectedExpressionName, previousSelectedExpressionValue);
					changePersonalizerSlider(
						emotion,
						previousSelectedExpressionName === emotion ? previousSelectedExpressionValue : 0
					);
					setExtremeExpression(previousSelectedExpressionName, previousSelectedExpressionValue);
					setSelectedImageUrlState(oldImageURL);
				},
				redo: () => {
					BriaAPI.setInstance(client.clone());
					setExtremeExpression(emotion, value);
					changePersonalizerSlider(emotion, value);
					changePersonalizerSlider(previousSelectedExpressionName, 0);
					setSelectedImageUrlState(res?.data?.addExtremeExpression?.newImage?.url ?? "");
				},
			});
			if (res?.data?.addExtremeExpression?.newImage?.url) {
				await consumeUserCredits(SubscriptionCreditsTypes.PEOPLE_CUSTOMIZATION);
			}
		} catch (e) {
			captureException(e);
			changePersonalizerSlider(previousSelectedExpressionName, previousSelectedExpressionValue);
			changePersonalizerSlider(
				emotion,
				previousSelectedExpressionName === emotion ? previousSelectedExpressionValue : 0
			);
			setExtremeExpression(previousSelectedExpressionName, previousSelectedExpressionValue);
			setOperationPlayGroundError(true);
		} finally {
			await Analytics.logEvent(ANALYTICS_EVENTS.CHANGE_EXPRESSION, {
				source: context.preferences?.style,
				expression: emotion,
				value,
			});
		}
	};

	const resetLastExtremeExpression = () => {
		setSelectedImageUrlState(selectedImageUrlWithoutExpression);
		resetExtremeExpression();
		changePersonalizerSlider(selectedExpressionName, 0);
	};

	function resetCrop() {
		setCropZoom(1);
		setDraftZoom(1);
		setIsUncropApplied(false);
		setUncropThumbnails([]);
		setExpandUncropThumbnails([]);
		setUncropLoading(false);
		setUncropThumbsLoading(false);
		setUncropValues(undefined);
		setCropName(undefined);
		setDraftCropName("");
		setPreviewImage(undefined);
		setDraftCroppedArea(undefined);
		setCropOnlyImageUrl(undefined);
		setCroppedArea({
			width: 500,
			height: 500,
			iscustom: false,
		});
		// setActiveCropPreset("");
		setActiveCropSizeBtn(-1);
		setRotation(0);
		setCropWidth(0);
		setCropHeight(0);
		setZoomPersantage(100);
		// setNaturalSize({ width: 0, height: 0 });
		setCroppedAreaPixels({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		});
		setCroppedAreaPercentage({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		});
		//cropImageFunction(originalImageDimensions.width, originalImageDimensions.height, false);
		setCroppedImage(null);
	}

	async function handleResetActions(isFashionMenTabSelected: boolean = false) {
		resetPointsOfInterests();
		setClickedIncreaseResButton(0);
		context.updateObjectsToRemoveArray([]);
		setObjectsToRemoveArray([]);
		setShouldResetCheckboxes(true);
		setPersonalizerInitialValues(toolsConfigSliders);
		reset();
		resetEthnicity();
		resetSemantics();
		resetExtremeExpression();
		setFacesConfigurations({});
		resetFashionSliders();
		if (isFashionMenTabSelected) {
			setSelectedImageUrl(fashionImageUrl);
		} else {
			setSelectedImageUrl(originalImage);
		}
		setLayersURL(null);
		setBackgroundString(ToolsBackGroundMode.withBg);
		setBackgroundBlur(ToolsBackGroundBlurMode.noBlur);
		BriaAPI.resetInstance(visual_hash);
		Analytics.logEvent(ANALYTICS_EVENTS.RESET);
		executeResetActions();
		setReplaceBgText({ text: " ", fast: true });
		setReplaceBgText({ text: "", fast: true });
		setPresentersStyleText({ text: " " });
		setPresentersStyleText({ text: "" });
		setReplaceBgThumbnails([]);
		setPresentersStyleThumbnails([]);
		// setDisableObjectsTab(false);
		setIsUncropApplied(false);
		setUncropValues(undefined);
		setUncropThumbnails([]);
		setExpandUncropThumbnails([]);
		resetCrop();
		setResetManualBrushTab(resetManualBrushTab !== null ? !resetManualBrushTab : true);
	}

	const resetUncrop = async () => {
		if (isUncropApplied) {
			const client = BriaAPI.getInstance(visual_hash);
			const res = await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.UNCROP
			);
			setSelectedImageUrl(res?.data?.image_res);
			setUncropValues(undefined);
			setUncropThumbnails([]);
			setExpandUncropThumbnails([]);
			// setDisableObjectsTab(false);
			setIsUncropApplied(false);
		}
	};

	const handleApplyMoodValuesCall = async (imgUrl: string, instance: any): Promise<string | undefined> => {
		const appliedMood = (window as any).SELECTED_MOOD_KEY?.mood?.moodParameters;

		if (!appliedMood) return imgUrl;
		try {
			const res =
				(await (instance as any).callApi(
					ApiActions.APPLY_MOOD,
					{
						changes: {
							...appliedMood,
						},
					},
					shouldClearWatermark(),
					organizationUid,
					true,
					false
				)) || {};
			const {
				data: { image_res },
			} = res;
			return image_res || "";
		} catch (error) {}
	};

	const onReplaceBgThumbnailClick = async (thumbnailImage: ThumbnailImage) => {
		try {
			setHideImageUrlLoader(true);
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setBgActionLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const newSid = thumbnailImage.sid;
			const newSeed = thumbnailImage.seed;
			let newImageUrl = thumbnailImage.imageUrl;
			client.setSelectedBackgroundReplaceSeed(newSeed);

			await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.REMOVE_BG
			);

			let translatedPrompt: string | undefined | null = replaceBgText.prefix
				? replaceBgText.prefix + " " + replaceBgText.text
				: replaceBgText.text;
			if (translatedPrompt && isIframe() && iframeConfig?.enableTranslation) {
				translatedPrompt = await translateText(i18n.language, "en", translatedPrompt);
			}

			client.pushApiCall(
				ApiActions.REPLACE_BG,
				{
					imageUrl: newImageUrl,
					changes: {
						bg_prompt: translatedPrompt,
						num_results: 1,
						fast: replaceBgText.fast,
					},
				},
				{
					data: {
						result: [newImageUrl, newSeed, newSid],
						image_res: newImageUrl,
						sid: newSid,
					},
				},
				shouldClearWatermark(),
				organizationUid
			);

			const res = await client.callNonSidCalls(newSid, organizationUid, shouldClearWatermark(), undefined, true);
			newImageUrl = res?.data.image_res ?? newImageUrl;

			setSelectedImageUrl(newImageUrl);

			if (thumbnailImage.key !== "original-image") {
				// setDisableObjectsTab(true);
				sendPostMessage(
					IframePostMessageTypes.ApplyChange,
					{
						type: IframeApplyChangePostMessageTypes.ReplaceBackground,
						action: {},
					},
					visual_hash,
					context.iframeConfig
				);
			} else {
				// setDisableObjectsTab(false);
			}

			set({
				undo: () => {
					setSelectedImageUrl(oldImageURL);
					BriaAPI.setInstance(prevInstance);
					// setDisableObjectsTab(prevInstance.getApiCallsMap().has(ApiActions.REPLACE_BG));
				},
				redo: () => {
					setSelectedImageUrl(newImageUrl);
					BriaAPI.setInstance(client.clone());
					// setDisableObjectsTab(client.getApiCallsMap().has(ApiActions.REPLACE_BG));
				},
			});
		} catch (err) {
			console.log(err);
			captureException(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		} finally {
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.REPLACE_BG_THUMBNAIL_CLICK, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "Replace_background_thumbnail_click",
				value: 0,
			});
			setBgActionLoading(false);
			setHideImageUrlLoader(false);
		}
	};

	const onImageReplaceBackground = async (
		fast: boolean,
		bg_prompt?: string,
		prefix?: string,
		num_results?: number,
		append?: boolean
	): Promise<void> => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			!append && setReplaceBgLoading(true);
			if (!append && bg_prompt !== undefined) {
				setReplaceBgThumbnails([]);
				setReplaceBgText({ text: bg_prompt, prefix, fast });
			}
			let briaApi = BriaAPI.getInstance(visual_hash).clone();
			const origImg = originalImageWithWatermark !== "" ? originalImageWithWatermark : originalImage;

			let sId = briaApi.getLastApiCallSid(ApiActions.REPLACE_BG);
			let response = await briaApi.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.REMOVE_BG
			);
			if (response) {
				sId = response?.data.sid;
			}

			let translatedPrompt: string | undefined | null = prefix ? prefix + " " + bg_prompt : bg_prompt;
			if (translatedPrompt && isIframe() && iframeConfig?.enableTranslation) {
				translatedPrompt = await translateText(i18n.language, "en", translatedPrompt);
			}

			response = await briaApi._replaceImageBG(
				sId,
				{
					bg_prompt: translatedPrompt,
					num_results: num_results,
					allowSolidBg: !append,
					fast,
				},
				append ? undefined : replaceBgSeed,
				shouldClearWatermark() ? organizationUid : ""
			);
			if (
				response &&
				response?.data?.code == 400 &&
				response?.data?.description == "Query doesn't stand with Bria's ethic rules"
			) {
				setEthicalError(true);
			} else {
				setEthicalError(false);
			}
			let replacebgThumbs: ThumbnailImage[] = append
				? replaceBgThumbnails
				: [
						{
							imageUrl: selectedImageUrl !== "" ? selectedImageUrl : origImg,
							key: "original-image",
							seed: briaApi.getSelectedBackgroundReplaceSeed(),
							sid: null,
						},
				  ];
			replacebgThumbs = [
				...replacebgThumbs,
				...response.data.result.map((thumb: any, index: number) => {
					return {
						imageUrl: thumb[0] ? thumb[0] : selectedImageUrl ?? origImg,
						key: thumb[1] ?? "",
						seed: thumb[1] ?? "",
						sid: thumb[2],
					};
				}),
			];
			setReplaceBgThumbnails(replacebgThumbs);
			if (replacebgThumbs.length > 1) {
				setReplaceBgThumbsLoading(true);
			}
		} catch (err: any) {
			captureException(err);
			console.log(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			if (!append && err.response && err.response.status === 512) {
				setReplaceBgThumbnails([]);
				setEthicalError(false);
				setReplaceBgThumbsLoading(false);
				context.setdisplayHighLoadDialog(true);
				throw err;
			}
		} finally {
			setReplaceBgLoading(false);
			Analytics.logToolsEvent(ANALYTICS_EVENTS.REPLACE_BG, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "Replace_background",
				value: 0,
			});
		}
	};

	const onUncropThumbnailClick = async (thumbnailImage: ThumbnailImage) => {
		try {
			setHideImageUrlLoader(true);
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setUncropLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const newSid = thumbnailImage.sid;
			const newSeed = thumbnailImage.seed;
			let newImageUrl = thumbnailImage.imageUrl;
			client.setSelectedUncropSeed(newSeed);

			await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.UNCROP
			);

			client.pushApiCall(
				ApiActions.UNCROP,
				{
					imageUrl: newImageUrl,
					changes: {
						canvas_size: uncropValues?.canvas_size,
						original_image_size: uncropValues?.original_image_size,
						original_image_location: uncropValues?.original_image_location,
						// num_results: 1,
					},
				},
				{
					data: {
						sid: newSid,
						image_res: newImageUrl,
						seed: newSeed,
					},
				},
				shouldClearWatermark(),
				organizationUid
			);

			const res = await client.callNonSidCalls(newSid, organizationUid, shouldClearWatermark(), undefined, true);
			newImageUrl = res?.data.image_res ?? newImageUrl;

			if (thumbnailImage.key !== "original-image") {
				// setDisableObjectsTab(true);
				setIsUncropApplied(true);
				sendPostMessage(
					IframePostMessageTypes.ApplyChange,
					{
						type: IframeApplyChangePostMessageTypes.Uncrop,
						action: {},
					},
					visual_hash,
					context.iframeConfig
				);
				setSelectedImageUrl(newImageUrl);
			} else {
				setSelectedImageUrl(newImageUrl);
				// setDisableObjectsTab(false);
				setIsUncropApplied(false);
			}

			set({
				undo: () => {
					setSelectedImageUrl(oldImageURL);
					BriaAPI.setInstance(prevInstance);
					// setDisableObjectsTab(prevInstance.getApiCallsMap().has(ApiActions.UNCROP));
					setIsUncropApplied(prevInstance.getApiCallsMap().has(ApiActions.UNCROP));
				},
				redo: () => {
					setSelectedImageUrl(newImageUrl);
					BriaAPI.setInstance(client.clone());
					// setDisableObjectsTab(client.getApiCallsMap().has(ApiActions.UNCROP));
					setIsUncropApplied(client.getApiCallsMap().has(ApiActions.UNCROP));
				},
			});
		} catch (err) {
			console.log(err);
		} finally {
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.UNCROP_THUMBNAIL_CLICK, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "uncrop_thumbnail_click",
				value: 0,
			});
		}
		setUncropLoading(false);
		setHideImageUrlLoader(false);
	};

	const onUncrop = async (
		canvas_size: [number, number],
		original_image_size: [number, number],
		original_image_location: [number, number],
		num_results: number,
		append?: boolean,
		prompt?: string,
		expandImageTab?: boolean
	): Promise<void> => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setUncropLoading(!!!append);
			if (!append) {
				setUncropThumbnails([]);
				setExpandUncropThumbnails([]);
				setUncropValues({
					canvas_size,
					original_image_size,
					original_image_location,
				});
			}
			let briaApi = BriaAPI.getInstance(visual_hash).clone();
			const origImg = originalImageWithWatermark !== "" ? originalImageWithWatermark : originalImage;
			const prevUncropThumbnails = expandImageTab ? expandUncropThumbnails : uncropThumbnails;
			if (append) {
				const emptyImgsForSkeleton: ThumbnailImage[] = Array.from({ length: num_results }, () => ({
					imageUrl: "",
					key: "",
					seed: undefined,
					sid: null,
				}));

				prompt
					? setExpandUncropThumbnails([...uncropThumbnails, ...emptyImgsForSkeleton])
					: setUncropThumbnails([...uncropThumbnails, ...emptyImgsForSkeleton]);
			}
			let sId = briaApi.getLastApiCallSid(ApiActions.UNCROP);
			let response = await briaApi.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.UNCROP
			);
			if (response) {
				sId = response?.data.sid;
			}
			let newThumbs = [];
			// if (prompt) {
			// 	const uncropResponse = await briaApi.promptGenerate(prompt ?? "", num_results);
			// 	newThumbs = uncropResponse.data.results.map((res) => {
			// 		return {
			// 			imageUrl: res.synthetic_url,
			// 		};
			// 	});
			// } else {

			let translatedPrompt: string | undefined | null = prompt;
			if (translatedPrompt && isIframe() && iframeConfig?.enableTranslation) {
				translatedPrompt = await translateText(i18n.language, "en", translatedPrompt);
			}
			const uncropApiCalls = Array.from({ length: num_results }, async () => {
				const uncropResponse = await briaApi._uncrop(
					sId,
					{
						canvas_size,
						original_image_size,
						original_image_location,
						prompt: translatedPrompt,
						// num_results: num_results,
					},

					Math.floor(Math.random() * 2147483647), //to avoid duplicate results //uncropSeed
					shouldClearWatermark() ? organizationUid : ""
				);

				return {
					imageUrl: uncropResponse?.data?.image_res
						? uncropResponse?.data?.image_res
						: selectedImageUrl ?? origImg,
					key: `${uncropResponse?.data?.seed}` ?? "",
					seed: uncropResponse?.data?.seed ?? "",
					sid: uncropResponse?.data?.sid,
				} as ThumbnailImage;
			});
			newThumbs = await Promise.all(uncropApiCalls);
			// }
			let uncropThumbs: ThumbnailImage[] = append
				? prevUncropThumbnails
				: [
						{
							imageUrl: selectedImageUrl !== "" ? selectedImageUrl : origImg,
							key: "original-image",
							seed: briaApi.getSelectedUncropSeed(),
							sid: null,
						},
				  ];

			uncropThumbs = [...uncropThumbs, ...newThumbs];
			if (prompt || expandImageTab) {
				setExpandUncropThumbnails(uncropThumbs);
			} else {
				setUncropThumbnails(uncropThumbs);
			}

			if (uncropThumbs.length > 1) {
				setUncropThumbsLoading(true);
			}
		} catch (err: any) {
			captureException(err);
			console.log(err);
			if (!append && err.response && err.response.status === 512) {
				setUncropThumbnails([]);
				setExpandUncropThumbnails([]);
				setUncropThumbsLoading(false);
				context.setdisplayHighLoadDialog(true);
				throw err;
			}
		} finally {
			setUncropLoading(false);
			Analytics.logToolsEvent(ANALYTICS_EVENTS.UNCROP, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "uncrop",
				value: 0,
			});
		}
	};

	const onPresentersStyleThumbnailClick = async (thumbnailImage: ThumbnailImage) => {
		try {
			setHideImageUrlLoader(true);
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setPresentersStyleLoading(true);
			const client = BriaAPI.getInstance(visual_hash);
			const oldImageURL = selectedImageUrl;
			const prevInstance = client.clone();

			const newSid = thumbnailImage.sid;
			const newSeed = thumbnailImage.seed;
			let newImageUrl = thumbnailImage.imageUrl;
			client.setSelectedPresentersStyleSeed(newSeed);

			await client.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.RECAST_MODEL
			);

			let translatedPrompt: string | undefined | null = presentersStyleText.text;
			if (translatedPrompt && isIframe() && iframeConfig?.enableTranslation) {
				translatedPrompt = await translateText(i18n.language, "en", translatedPrompt);
			}

			client.pushApiCall(
				ApiActions.RECAST_MODEL,
				{
					imageUrl: newImageUrl,
					changes: {
						bg_prompt: translatedPrompt,
						num_results: 1,
					},
				},
				{
					data: {
						result: [newImageUrl, newSeed, newSid],
						image_res: newImageUrl,
						sid: newSid,
					},
				},
				shouldClearWatermark(),
				organizationUid
			);

			const res = await client.callNonSidCalls(newSid, organizationUid, shouldClearWatermark(), undefined, true);
			newImageUrl = res?.data.image_res ?? newImageUrl;

			setSelectedImageUrl(newImageUrl);

			if (thumbnailImage.key !== "original-image") {
				sendPostMessage(
					IframePostMessageTypes.ApplyChange,
					{
						type: IframeApplyChangePostMessageTypes.PresentersStyle,
						action: {},
					},
					visual_hash,
					context.iframeConfig
				);
			}

			set({
				undo: () => {
					setSelectedImageUrl(oldImageURL);
					BriaAPI.setInstance(prevInstance);
				},
				redo: () => {
					setSelectedImageUrl(newImageUrl);
					BriaAPI.setInstance(client.clone());
				},
			});
		} catch (err) {
			console.log(err);
			captureException(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		} finally {
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.PRESENTERS_STYLE_THUMBNAIL_CLICK, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "Presenters_style_thumbnail_click",
				value: 0,
			});
			setPresentersStyleLoading(false);
			setHideImageUrlLoader(false);
		}
	};

	const onDoPresentersStyle = async (prompt?: string): Promise<void> => {
		try {
			clickedIncreaseResButton && onChangeShowResolutionBeforeDownloadDialog(true);
			setPresentersStyleLoading(true);
			if (prompt !== undefined) {
				setPresentersStyleThumbnails([]);
				setPresentersStyleText({ text: prompt });
			}
			let briaApi = BriaAPI.getInstance(visual_hash).clone();
			const origImg = originalImageWithWatermark !== "" ? originalImageWithWatermark : originalImage;

			let sId = briaApi.getLastApiCallSid(ApiActions.RECAST_MODEL);
			let response = await briaApi.recallStackAfterLastAction(
				shouldClearWatermark() ? originalImage : originalImageWithWatermark,
				ApiActions.RECAST_MODEL
			);
			if (response) {
				sId = response?.data.sid;
			}

			let translatedPrompt: string | undefined | null = prompt;
			if (translatedPrompt && isIframe() && iframeConfig?.enableTranslation) {
				translatedPrompt = await translateText(i18n.language, "en", translatedPrompt);
			}

			response = await briaApi._recast_model(
				sId,
				{
					prompt: translatedPrompt,
				},
				shouldClearWatermark() ? organizationUid : ""
			);
			if (response?.data?.image_res) {
				let presentersStyleThumbs: ThumbnailImage[] = [
					{
						imageUrl: originalImage,
						key: "original-image",
						seed: 0,
						sid: null,
					},
					{
						imageUrl: response.data.image_res,
						key: "resulted-image",
						seed: 1,
						sid: response.data.sid,
					},
				];
				setPresentersStyleThumbnails(presentersStyleThumbs);
			} else {
				useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			}
		} catch (err: any) {
			console.log(err);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			captureException(err);
			if (err.response && err.response.status === 512) {
				setPresentersStyleThumbnails([]);
				context.setdisplayHighLoadDialog(true);
				throw err;
			}
		} finally {
			setPresentersStyleLoading(false);
			Analytics.logToolsEvent(ANALYTICS_EVENTS.PRESENTERS_STYLE, {
				source: context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: "presenters_style",
				value: 0,
			});
		}
	};

	useEffect(() => {
		if (!uncropThumbsLoading) {
			if (uncropThumbnails.length === 4) {
				// auto select the first uncrop thumbnail
				onUncropThumbnailClick(uncropThumbnails[1]);
			} else if (expandUncropThumbnails.length === 4) {
				// auto select the first uncrop thumbnail
				onUncropThumbnailClick(expandUncropThumbnails[1]);
			}

			// auto select the first uncrop thumbnail
		}
	}, [uncropThumbsLoading]);

	const onAddSemanticCombination = async (
		thumbnail: ThumbnailData,
		original: boolean = false,
		controller: any
	): Promise<string> => {
		const currentSemantics = !original
			? toolsConfigSliders
					.filter(
						(slider) =>
							slider.defaultInitialValue !== personalizerValues[slider.id] &&
							personalizerValues[slider.id] !== undefined &&
							slider.level1Id === ToolConfigTabsTypes.Personalizer
					)
					.filter(
						(slider) =>
							thumbnail.semantics.length === 0 ||
							thumbnail.semantics.find(({ name }: any) => name !== slider.id)
					)
					.map((slider) => ({
						...slider,
						value: personalizerValues[slider.id],
					}))
			: [];

		const currentThumbnails: ThumbnailData = {
			ethnicity: thumbnail.ethnicity,
			ethnicityValue: thumbnail.ethnicityValue || 0,
			thumbnailIndex: 0,
			semantics: [...currentSemantics, ...thumbnail.semantics]
				.map((el) => slidersMapper(el as ToolsSlider))
				.filter((el) => el.value !== undefined || el.value !== null),
		};

		const pipelineSettings: AddSemanticCombinationVariables = {
			...getPipelineVariables(),
			scaleDownFactor: scaleDownFactor,
			thumbnails: [currentThumbnails],
			entityId: selectedPoint?.id,
		};

		try {
			if (selectedPoint?.objectType !== BriaObjectType.human) {
				return "";
			}

			const res = await addSemanticCombinationMutation({
				variables: pipelineSettings,
				...controller,
			});
			let entries: string[] = Object.values(JSON.parse(res?.data?.thumbnail_images ?? "{}")) || [];
			let arr: { url: string }[] = entries.map((e) => {
				return {
					url: e,
				};
			});

			return arr[0].url;
		} catch (err) {
			console.log(err);
		}

		return "";
	};

	const handleChangePipelineSettings = (index: number, isOn: boolean, value: any = null) => {
		let newSettingsList = [...pipelineSettings];
		newSettingsList[index].isOn = isOn;
		newSettingsList[index].value = value;
		setPipelineSettings([...newSettingsList]);
	};

	const onFullscreenChange = (value: boolean) => {
		setFullscreenMode(value);
	};

	useEffect(() => {
		if (selectedVideo) {
			setDisableDownloadButton(false);
		}
	}, [selectedVideo]);

	useEffect(() => {
		if (selectedPoint?.id && facesConfigurations[selectedPoint.id] === undefined) {
			setPersonalizerInitialValues(toolsConfigSliders);
		}
	}, [toolsConfigSliders, selectedPoint?.id]);

	const toggleBackground = async (fireRequest: boolean = true) => {
		// const hasCredits = await checkIfUserHasCredits(
		// 	history,
		// 	SubscriptionCreditsTypes.REMOVE_BG,
		// 	isIframe(),
		// 	context
		// );
		// if (!hasCredits) throw new Error("No enough credits");

		setBackgroundString(
			backgroundString === ToolsBackGroundMode.noBg ? ToolsBackGroundMode.withBg : ToolsBackGroundMode.noBg
		);
		if (fireRequest) {
			if (backgroundString === ToolsBackGroundMode.noBg) {
				showBG();
			} else {
				removeBG();
			}
		}
	};
	const toggleBackgroundBlur = async (fireRequest: boolean = true) => {
		setBackgroundBlur(
			backgroundBlur === ToolsBackGroundBlurMode.Blur
				? ToolsBackGroundBlurMode.noBlur
				: ToolsBackGroundBlurMode.Blur
		);
		if (fireRequest) {
			if (backgroundBlur === ToolsBackGroundBlurMode.Blur) {
				unBlurBG();
			} else {
				blurBG();
			}
		}
	};

	const onSetSelectedPoint = (point: SelectedPointInterface | null) => {
		if (selectedPoint?.id !== point?.id || selectedPoint?.objectType !== point?.objectType) {
			setSelectedImageUrlWithoutExpression(selectedImageUrl);
			setSelectedPoint(point);
			setReplaceBgText({ text: "", prefix: "", fast: true });
			setReplaceBgThumbnails([]);
			if (selectedPoint === null || point === null) return;
			//save old configuration
			const faceConfig = {
				...facesConfigurations,
				[selectedPoint.id]: {
					ethnicity: selectedEthnicity,
					ethnicityValue: selectedEthnicityValue,
					semantics: personalizerValues,
					selectedExpressionName: selectedExpressionName,
					selectedExpressionValue: selectedExpressionValue,
				},
			};
			if (facesConfigurations[point.id]) {
				changeEthnicityHandler(
					facesConfigurations[point.id].ethnicity,
					facesConfigurations[point.id].ethnicityValue
				);
				setExtremeExpression(
					facesConfigurations[point.id].selectedExpressionName,
					facesConfigurations[point.id].selectedExpressionValue
				);
				setSemanticsValues(facesConfigurations[point.id].semantics);
			} else {
				resetEthnicity();
				resetSemantics();
			}

			setFacesConfigurations(faceConfig);
		}
	};

	async function onUndo() {
		undo();
	}

	async function onRedo() {
		redo();
	}

	const setImageScore = (score: string) => {
		setAskAlanScoreMutation({
			variables: {
				list: [...askAlanScoreRef.current, score],
			},
		});
		askAlanScoreRef.current = [];
	};

	const loading = imageDetailsLoading;
	const proccessing =
		isImageLoading ||
		removeObjectMutationLoading ||
		changeEthnicityMutationLoading ||
		addSemanticsMutationLoading ||
		addObjectTypeSemanticsMutationLoading ||
		createExtremeExpressionMutationLoading ||
		((replaceBgLoading || replaceBgThumbsLoading) && replaceBgThumbnails.length <= 6) ||
		uncropLoading ||
		uncropThumbsLoading ||
		bgActionLoading ||
		increaseResolutionLoading ||
		presentersStyleLoading ||
		generalLoading;

	function getProcessingOperation() {
		let operation = "";
		if (bgActionLoading) {
			operation = "bgAction";
		} else if (replaceBgLoading || replaceBgThumbsLoading) {
			operation = "replaceBg";
		} else if (presentersStyleLoading) {
			operation = "presentersStyle";
		} else if (removeObjectMutationLoading) {
			operation = "removeObj";
		} else if (increaseResolutionLoading) {
			operation = "increaseRes";
		} else if (changeEthnicityMutationLoading) {
			operation = "changeEthnicity";
		} else if (addSemanticsMutationLoading) {
			operation = "addSemantics";
		} else if (createExtremeExpressionMutationLoading) {
			operation = "expressions";
		}
		return operation;
	}

	const imageEditError = null;

	// Select tab
	const [disabledTabs, setDisabledTabs] = useState<{ [key in ToolConfigTabsTypes]?: boolean }>({});
	const [expressionsSliders, setExpressionsSliders] = useState<ToolsSlider[]>([]);
	const [accessoriesToRemove, setAccessoriesToRemove] = useState<any[]>([]);
	const [objectsList, setObjectsList] = useState<any[]>([]);

	useEffect(() => {
		const validExpressionsLowerCase = validExpressions.map((exp: string) => exp.toLowerCase());

		const expressionsSlidersArr = [
			...toolsConfigSliders.filter(
				(el) =>
					el.level2Id === ToolConfigTabsTypes.Expression && el.level1Id === ToolConfigTabsTypes.Personalizer
			),
			...toolsConfigSliders.filter(
				(el) =>
					el.level2Id === ToolConfigTabsTypes.Expression &&
					el.level1Id === ToolConfigTabsTypes.Emotions &&
					validExpressionsLowerCase.includes(el.id.toLowerCase())
			),
		];
		setExpressionsSliders(expressionsSlidersArr);

		// Disabled tabs
		const disabledTabsObj: { [key in ToolConfigTabsTypes]?: boolean } = {};
		disabledTabsObj[ToolConfigTabsTypes.Appearance] = !toolsConfigSliders.filter(
			(el) => el.level2Id === ToolConfigTabsTypes.Appearance
		).length;
		disabledTabsObj[ToolConfigTabsTypes.Expression] = !expressionsSlidersArr.length;
		disabledTabsObj[ToolConfigTabsTypes.Objects] = disableObjectsTab;
		disabledTabsObj[ToolConfigTabsTypes.ManualObjBrush] = disableObjectsTab;
		disabledTabsObj[ToolConfigTabsTypes.AutoObjIdentification] = disableObjectsTab;
		setDisabledTabs(disabledTabsObj);
	}, [toolsConfigSliders, selectedPoint, validExpressions]);

	useEffect(() => {
		if ((selectedImageUrl || originalImage) && leanImageInfo) {
			getImageMeta(selectedImageUrl || originalImage).then((image: any) => {
				const canvas = document.createElement("canvas");
				canvas.width = image.width;
				canvas.height = image.height;
				const ctx = canvas.getContext("2d");
				if (ctx) {
					const widthRatio = image.width / (leanImageInfo?.org_width ?? 1);
					const heightRatio = image.height / (leanImageInfo?.org_height ?? 1);
					ctx.drawImage(image, 0, 0);

					// image transparency check
					const imgData = ctx.getImageData(0, 0, image.width, image.height).data;
					let transparentCount = 0;
					for (let i = 0, n = imgData.length; i < n; i += 4) {
						transparentCount += imgData[i + 3] == 0 ? 1 : 0;
					}
					const imageTransparencyPrecent = transparentCount / (image.width * image.height);
					setImageTransparencyPercentage(imageTransparencyPrecent);

					// objects transparency check
					const filteredPointOfInterests = pointOfInterests.filter((obj) => {
						if (
							obj.objectType === BriaObjectType.objects &&
							!objectsToRemoveArray.some((x) => x === obj.id)
						) {
							let objWidth = obj.rect.w * widthRatio;
							let objHeight = obj.rect.h * heightRatio;
							let objX = obj.rect.x * widthRatio;
							let objY = obj.rect.y * heightRatio;

							const imgData = ctx.getImageData(objX, objY, objWidth, objHeight).data;
							let transparentCount = 0;
							for (let i = 0, n = imgData.length; i < n; i += 4) {
								transparentCount += imgData[i + 3] == 0 ? 1 : 0;
							}
							const transparencyPrecent = transparentCount / (objWidth * objHeight);
							return transparencyPrecent < 0.8;
						} else {
							return true;
						}
					});
					setPointOfInterests(filteredPointOfInterests);
				}
			});
		}
	}, [selectedImageUrl]);

	useEffect(() => {
		setBackgroundOracle(backgroundImageInfo?.oracle ?? undefined);
	}, [backgroundImageInfo]);

	const [pointOfInterests, setPointOfInterests] = useState<any[]>([]);

	useEffect(() => {
		setObjectsList(pointOfInterests?.filter((pt) => pt.objectType === BriaObjectType.objects));
	}, [pointOfInterests]);
	useEffect(() => {
		if (selectedPoint && selectedPoint?.objectType === BriaObjectType.human) {
			getValidExpressions(pointOfInterests.find((pt: any) => pt.objectType === BriaObjectType.human));
		}
	}, [pointOfInterests, selectedPoint?.id]);
	useEffect(() => {
		disabledTabs[ToolConfigTabsTypes.Accessories] = !accessoriesToRemove.length;
		disabledTabs[ToolConfigTabsTypes.Objects] = !objectsList.length;
	}, [accessoriesToRemove, objectsList]);

	const resetPointsOfInterests = () => {
		if (leanImageInfo && imageDetails) {
			const facesList =
				personImageInfo?.map((el, index) => {
					const pointId: string = el.id ?? index;

					let reducedToSliderScore: any = null;
					if (el.oracle) {
						reducedToSliderScore = Object.values(el.oracle).reduce((prev: any, cur: any) => {
							return {
								...prev,
								...cur,
							};
						}, []);
						reducedToSliderScore = Object.entries(reducedToSliderScore).map(([key, value]) => {
							return {
								sliderId: key,
								id: key,
								score: value,
							};
						});
					}
					return {
						id: pointId,
						objectType: BriaObjectType.human,
						originalImageHeight: leanImageInfo?.org_height,
						originalImageWidth: leanImageInfo?.org_width,
						resizedImageHeight: imageDetails?.vdrObject?.height,
						resizedImageWidth: imageDetails?.vdrObject?.width,
						oracle: reducedToSliderScore,
						rect: el.rect,
					};
				}) ?? [];

			const sceneList = [
				{
					id: "image_background",
					objectType: BriaObjectType.imageBackground,
					originalImageHeight: leanImageInfo?.org_height,
					originalImageWidth: leanImageInfo?.org_width,
					resizedImageHeight: imageDetails?.vdrObject?.height,
					resizedImageWidth: imageDetails?.vdrObject?.width,
					rect: {
						x: -100,
						y: -100,
						h: -100,
						w: -100,
					},
					oracle: backgroundImageInfo?.oracle ?? undefined,
				},
			];
			const graphicsList = [
				{
					id: "graphics",
					objectType: BriaObjectType.graphics,
					originalImageHeight: leanImageInfo?.org_height,
					originalImageWidth: leanImageInfo?.org_width,
					resizedImageHeight: imageDetails?.vdrObject?.height,
					resizedImageWidth: imageDetails?.vdrObject?.width,
					rect: {
						x: -100,
						y: -100,
						h: -100,
						w: -100,
					},
				},
			];

			const removables = objectsImageInfo?.filter(
				(element: Scene) => element.actions.remove && element.actions.remove[0] === true
			);
			setAccessoriesToRemove(
				removables?.filter((element: Scene) => element.labels && element.labels.includes("accessories")) ?? []
			);
			const objList =
				removables?.map((scene: Scene) => {
					return {
						id: scene.id,
						label: scene.type,
						objectType: BriaObjectType.objects,
						originalImageHeight: leanImageInfo?.org_height,
						originalImageWidth: leanImageInfo?.org_width,
						resizedImageHeight: imageDetails?.vdrObject?.height,
						resizedImageWidth: imageDetails?.vdrObject?.width,
						rect: scene.rect,
						oracle: scene.oracle,
					};
				}) || [];
			objList.sort((a, b) =>
				a.rect.h * a.rect.w < b.rect.h * b.rect.w ? 1 : a.rect.h * a.rect.w > b.rect.h * b.rect.w ? -1 : 0
			);
			setObjectsList(objList);

			setPointOfInterests([...sceneList, ...graphicsList, ...objList, ...facesList]);
		}
	};

	useEffect(() => {
		resetPointsOfInterests();
	}, [personImageInfo, objectsImageInfo, backgroundImageInfo, imageDetails, leanImageInfo]);

	useEffect(() => {
		const hasPassedCalls =
			passedApiCallsMap &&
			(passedApiCallsMap.has(ApiActions.FACE_MANIPULATIONS) || passedApiCallsMap.has(ApiActions.APPLY_MOOD));
		if (
			pointOfInterests &&
			selectedPoint === null &&
			(!hasPassedCalls ||
				(personImageInfo && objectsImageInfo && backgroundImageInfo && imageDetails && hasPassedCalls))
		) {
			let filteredPointOfInterests: any[] = [];
			const hasHuman = pointOfInterests.filter((el) => el.objectType === BriaObjectType.human).length > 0;

			if (!!passedLocationState?.selectedTab) {
				filteredPointOfInterests = pointOfInterests.filter(
					(el) => el.objectType === passedLocationState?.selectedTab
				);
			} else {
				if (hasHuman && hasPassedCalls) {
					filteredPointOfInterests = pointOfInterests.filter((el) => el.objectType === BriaObjectType.human);
				} else {
					filteredPointOfInterests = pointOfInterests.filter(
						(el) => el.objectType === BriaObjectType.imageBackground
					);
				}
			}
			if (filteredPointOfInterests.length >= 1) {
				setSelectedPoint(filteredPointOfInterests[0]);
			} else if (!!!passedLocationState?.selectedTab) {
				if (
					editorSelectedTab &&
					EditorTabIframeMap[editorSelectedTab as keyof typeof EditorTabIframeMap] in BriaObjectType
				) {
					setSelectedPoint({
						...selectedPoint,
						objectType:
							BriaObjectType[
								EditorTabIframeMap[
									editorSelectedTab as keyof typeof EditorTabIframeMap
								] as keyof typeof BriaObjectType
							],
					});
				} else {
					setSelectedPoint({
						...selectedPoint,
						objectType: BriaObjectType.imageBackground,
					});
				}
			}
		}
	}, [pointOfInterests, personImageInfo, objectsImageInfo, backgroundImageInfo, imageDetails]);

	useEffect(() => {
		if (
			!!(
				toolsConfigSliders.length > 0 &&
				validExpressions.length > 0 &&
				passedApiCallsMap &&
				(passedApiCallsMap.has(ApiActions.FACE_MANIPULATIONS) || passedApiCallsMap.has(ApiActions.APPLY_MOOD))
			)
		) {
			const briaApi = BriaAPI.getInstance(visual_hash);
			if (passedApiCallsMap.has(ApiActions.FACE_MANIPULATIONS)) {
				const faceManipulationValue = passedApiCallsMap.get(ApiActions.FACE_MANIPULATIONS) as ApiCall;
				if (faceManipulationValue?.input.changes.length > 0) {
					const change = faceManipulationValue?.input.changes[0]; // take only the first face
					// if we need to support multiple faces, we have to setFaceConfigurations for each face
					const targetPoint = pointOfInterests.find((pt) => pt.id === change.id);
					setSelectedPoint(targetPoint);
					setTimeout(async () => {
						for (const [key, action] of Object.entries(change.actions) as any[]) {
							if (key === ToolConfigTabsTypes.Expression) {
								setExtremeExpression(action.key, action.value);
								changePersonalizerSlider(action.key, action.value);
							} else if (key === ToolConfigTabsTypes.Diversity) {
								changeEthnicityHandler(action.key, action.value);
							} else {
								changePersonalizerSlider(action.key, action.value);
							}
						}
					}, 100);
				}

				briaApi.pushApiCall(
					ApiActions.FACE_MANIPULATIONS,
					faceManipulationValue.input,
					faceManipulationValue.response,
					shouldClearWatermark(),
					organizationUid
				);
				const newSelectedImageUrl = faceManipulationValue.response
					? faceManipulationValue.response.data.image_res
					: originalImage;
				if (newSelectedImageUrl !== selectedImageUrl) {
					setSelectedImageUrl(newSelectedImageUrl);
				}
			}
			if (passedApiCallsMap.has(ApiActions.APPLY_MOOD)) {
				const appliedMoodValue = passedApiCallsMap.get(ApiActions.APPLY_MOOD) as ApiCall;
				briaApi.pushApiCall(
					ApiActions.APPLY_MOOD,
					appliedMoodValue.input,
					appliedMoodValue.response,
					shouldClearWatermark(),
					organizationUid
				);
				const newSelectedImageUrl = appliedMoodValue.response
					? appliedMoodValue.response.data.image_res
					: originalImage;
				if (newSelectedImageUrl !== selectedImageUrl) {
					setSelectedImageUrl(newSelectedImageUrl);
				}
			}
			setPassedApiCalls(null);
		}
	}, [passedApiCallsMap, toolsConfigSliders, validExpressions]);

	const createCroppedImage = async (
		isPreview: boolean,
		passedCroppedAreaPixels?: any,
		passedRotation?: any,
		cropFlag?: any
	) => {
		if (selectedImageUrl) {
			try {
				const res = await resize(
					selectedImageUrl,
					isPreview ? draftCroppedAreaPixels : passedCroppedAreaPixels,
					isPreview ? draftRotation : passedRotation,
					croppedArea.width,
					croppedArea.height
				);

				if (isPreview) {
					setPreviewImage(res);
				} else {
					setCroppedImage(res);
					setCropImageUrl(res);
					if (cropFlag) {
						setCropOnlyImageUrl(res);
					}
				}
			} catch (e) {
				console.error(e);
				captureException(e);
			}
		}
	};

	return {
		imageRef,
		loading,
		lockMasks,
		setLockMasks,
		proccessing,
		selectedVideo,
		setSelectedVideo,
		accessoriesToRemove,
		objectsList,
		pointOfInterests,
		visual_hash,
		imageDetailsError,
		disableDownloadButton,
		selectedImageUrl: selectedImageUrl || originalImage,
		setSelectedImageUrl: setSelectedImageUrlState,
		originalImage: originalImage,
		originalImageWithWatermark,
		backgroundString,
		toggleBackground,
		increaseResolution,
		backgroundBlur,
		toggleBackgroundBlur,
		imageDetails,
		imageEditError,
		selectedPoint,
		showCropSaveFirstTime,
		setShowCropSaveFirstTime,
		setSelectedPoint: onSetSelectedPoint,
		personalizerValues,
		onChangePersonalizer,
		onAddSemanticCombination,
		onImageReplaceBackground,
		onReplaceBgThumbnailClick,
		onUncrop,
		onUncropThumbnailClick,
		uncropLoading,
		uncropThumbnails,
		expandUncropThumbnails,
		uncropThumbsLoading,
		setUncropThumbsLoading,
		hideImageUrlLoader,
		replaceBgText,
		replaceBgThumbnails,
		toDisplayThumbnails,
		onThumbnailClick,
		setSemanticsValues,
		onObjectHover,
		clearObjectHover,
		objectOnHover,
		layersURL,
		resetPointsOfInterests,

		//Tools Configurations
		toolsLoading,
		toolsConfigTabs,
		toolsConfigSliders,
		toolsConfigThumbnails,
		toolsConfigSuggestions,
		currentTabs: [],
		showCropSavePopUp,
		setShowCropSavePopUp,
		toolConfigurations: [],
		currentSliders,

		// Undo/Redo
		canUndo,
		canRedo,
		onRedo,
		onUndo,
		undoRedoSetter: updateUndoRedo,

		canCompare,
		setCropOnlyImageUrl,
		// Ethnicicy
		selectedEthnicity,
		selectedEthnicityValue,
		onRemoveObject,
		onRemoveObjectByCoordinates,
		onShowObject,
		onChangeEthnicity,
		handleResetActions,
		updateResetActions,

		//Extreme Expressions
		createExtremeExpression,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		selectedExpressionValue,
		selectedExpressionName,
		resetExtremeExpression,
		resetLastExtremeExpression,
		validExpressions,
		isLoadingExpression,

		fetchCameraVideo,
		cameraMotionVideo,
		isCameraMovementTabSelected,
		setIsCameraMovementTabSelected,
		isTextEditorTabSelected,
		setIsTextEditorTabSelected,
		isLogoTabSelected,
		setIsLogoTabSelected,
		isObjectsTabSelected,
		setIsObjectsTabSelected,
		resetManualBrushTab,
		setResetManualBrushTab,
		isObjectsAutomaticTabSelected,
		setIsObjectsAutomaticTabSelected,
		isObjectsManualBrushTabSelected,
		setIsObjectsManualBrushTabSelected,
		objectsTabCanvasRef,
		brushConfig,
		setBrushConfig,
		setIsReplaceBackgroundTabSelected,
		isReplaceBackgroundTabSelected,
		isFashionMenTabSelected,
		setIsFashionSelected,
		imageDetailsLoading,

		//pipeline
		pipelineSettings,
		handleChangePipelineSettings,

		generatingImageMetric: metrics.generatingImageMetric,

		fullscreenMode,
		onFullscreenChange,
		mainObjectData,
		openCreateVideoPopup,
		setOpenCreateVideoPopup,
		openVideoReadyPopup,
		setOpenVideoReadyPopup,
		setImageScore,
		disableRemoveBGSwitch,
		setMainObjectData,
		isUncropApplied,
		setIsUncropApplied,
		isImageMode,
		setIsImageMode,
		onChangeClickedIncreaseResolutionButton,
		clickedIncreaseResButton,
		onChangeShowResolutionBeforeDownloadDialog,
		showResolutionBeforeDownloadDialog,
		setShowResolutionBeforeDownloadDialog,
		videoStatus,
		isImageLoading,
		setIsImageLoading,
		changePersonalizerSlider,
		resetCameraMotionVideo,
		preCameraMovementActionsConfig,
		setPreCameraMovementActionsConfig,
		setGeneralLoading,
		downloadBtnProps,
		disabledTabs,
		expressionsSliders,
		faceDevInfo,
		setTextOnImage,
		textOnImage,
		replaceBgLoading,
		getPlaygroundUserMoods,
		isLoadingPersonInfo,
		isLoadingObjectsInfo: isLoadingObjectsInfo || isLoadingObjectsMasks,
		isLoadingCropConfig,
		backgroundOracle,

		// playground error catch on all operations
		operationPlayGroundError,
		setOperationPlayGroundError,
		removeObjectMutationLoading,
		shouldResetCheckboxes,
		setShouldResetCheckboxes,
		disableObjectsTab,
		setPointOfInterests,
		imageTransparencyPercentage,
		originalImageDimensions,
		presentersStyleLoading,
		setPresentersStyleLoading,
		getProcessingOperation,
		setReplaceBgThumbsLoading,
		replaceBgThumbsLoading,
		leanImageInfo,
		personImageInfo,
		objectsImageInfo,
		backgroundImageInfo,
		uncropValues,
		resetUncrop,
		resetCrop,
		cropZoom,
		setCropZoom,
		draftZoom,
		setDraftZoom,
		cropName,
		setCropName,
		draftCropName,
		setDraftCropName,
		previewImage,
		setPreviewImage,
		draftCroppedArea,
		setDraftCroppedArea,
		croppedArea,
		setCroppedArea,
		selectedTab,
		ethicalError,
		setEthicalError,
		setSelectedTab,
		activeCropPreset,
		cropOnlyImageUrl,
		setActiveCropPreset,
		activeCropSizebtn,
		setActiveCropSizeBtn,
		rotation,
		setRotation,
		cropImageUrl,
		setCropImageUrl,
		cropWidth,
		setCropWidth,
		cropHeight,
		setCropHeight,
		zoomPersantage,
		setZoomPersantage,
		naturalSize,
		setNaturalSize,
		croppedAreaPixels,
		setCroppedAreaPixels,
		croppedAreaPercentage,
		setCroppedAreaPercentage,
		CropTabselected,
		setCropTabSeleced,
		croppedImage,
		setCroppedImage,
		cropImageFunction,
		minZoom,
		maxZoom,
		draftCroppedAreaPixels,
		setDraftCroppedAreaPixels,
		draftCroppedAreaPercentage,
		setDraftCroppedAreaPercentage,
		draftRotation,
		setDraftRotation,
		createCroppedImage,
		onPresentersStyleThumbnailClick,
		onDoPresentersStyle,
		presentersStyleThumbnails,
		presentersStyleText,
		expandBackgroundTabSelected,
		setExpandBackgroundTabSelected,
		objectsPanopticImage,
		objectsMasks,
		isLoadingObjectsMasks,
		imageElement,
		showObjectsFollowUpTooltip,
		setShowObjectsFollowUpTooltip,
		objectsTooltipPosition,
		setObjectsTooltipPosition,
		onRestoreAllObjects,
		applySketchImageStyle,
	};
};
