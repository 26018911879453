import { useContext } from "react";
import { IframePostMessageTypes } from "../Constants/IframeConstants";
import Context from "../Context/Context";
import { IframeConfig } from "../sdk/resources/element";
import { isProd } from "../Config/Env";

export function sendPostMessage(
	type: IframePostMessageTypes,
	message: any,
	vhash: string,
	iframeConfig: IframeConfig | null
) {
	if (iframeConfig) {
		const messageJson = {
			type,
			message,
			inputData: {
				userId: iframeConfig.userId,
				sessionId: iframeConfig.sessionId,
				vhash: vhash,
				enableDownload: iframeConfig.enableDownload,
				enabledFeatures: iframeConfig.enabledFeaturesIds,
				usageText: iframeConfig.usageText,
			},
		};

		if (!isProd) {
			console.log("Iframe postMessage sent", messageJson);
		}

		if (iframeConfig.sourceDomain) {
			window.parent.postMessage(messageJson, iframeConfig.sourceDomain);
		} else {
			try {
				iframeConfig.allowedDomains.forEach((targetOrigin, index) => {
					window.parent.postMessage(messageJson, targetOrigin);
				});
			} catch (e) {}
		}
	}
}

export const IsIframe = () => {
	const context = useContext(Context);
	const isIframe = () => {
		return !!context.iframeConfig;
	};

	return { isIframe };
};
