import { ChangeEvent, useContext, useEffect, useState } from "react";
import { convertS3UriToHttpsUrl, getBase64 } from "../Components/UI/CreateCampaign/utils";
import { uploadTempImageToS3 } from "../GraphQL/mutations";
import BriaAPI from "../sdk/resources/briaAPI";
import { getColorPallet, getColorPalletVariables } from "../GraphQL/types/getColorPallet";
import { GET_BRAND_PRESET, GET_COLOR_PALLET, GET_LOGO } from "../GraphQL/queries";
import { getLogo, getLogoVariables } from "../GraphQL/types/getLogo";
import client from "../GraphQL/client";
import Context from "../Context/Context";
import { uploadImageViaApi } from "../Components/Tools/utilities";
import { IframeDestinationEnum } from "../types/graphql-global-types";
import { IsIframe } from "../Helpers/iframe";
import { getBrandPreset, getBrandPresetVariables } from "../GraphQL/types/getBrandPreset";
function useCampaigngeneration({
	setCampaignAssets,
	handleCampaignChange,
	setImagesReady,
	setShouldOpenKeepReplaceBackground,
	setImageWithoutBackground,
	setImageWithBackground,
	campaign,
	setSelectedFontPrimaryName,
	setSelectedFontSecondaryName,
}: any) {
	const { isIframe } = IsIframe();
	const context = useContext(Context);
	const fontOptions = context.orgFonts;
	const handleAssetUpload = async (fileKey: any, e: any) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}
		const file = e.target.files[0];
		e.target.value = null;
		let fileUrl = URL.createObjectURL(file);
		setCampaignAssets((prevAssets: any) => ({
			...prevAssets,
			[fileKey]: URL.createObjectURL(file),
		}));

		const base64_string = (await getBase64(file)) as string;
		handleCampaignChange(fileKey, { filename: file.name, base64_string });
		if (fileKey === "mainAsset") {
			setImagesReady(false);
			setShouldOpenKeepReplaceBackground(true);

			const res = await uploadTempImageToS3(file.name, base64_string, "campaign_generation");
			if (res && res.ok == true && res.url) {
				try {
					const visualHash = await updateVisualHash(file);
					if (visualHash) {
						context.setCurrentCampainVhash(visualHash);
						const client = BriaAPI.getInstance(visualHash);
						const { data } = await client._removeImageBG("", "");
						if (data?.image_res) {
							setImageWithoutBackground(data.image_res);
						}
					} else {
						console.log("error in getting visualHash");
						setImagesReady(true);
					}
				} catch (e) {
					console.log("error in getting visualHash");
					setImagesReady(true);
				}
			}
			setImageWithBackground(fileUrl);
			setImagesReady(true);
		}
	};

	const getBrandDetails = async () => {
		let selectedBrand = context?.selectedDropdownValue;
		if (!selectedBrand?.id) {
			const defaultBrand: any = await client.query<getBrandPreset, getBrandPresetVariables>({
				query: GET_BRAND_PRESET,
				variables: {
					id: 6,
				},
			});
			selectedBrand = defaultBrand?.data?.getBrandPreset;
		}
		let colorData = null;
		if (selectedBrand?.colorPalleteId) {
			colorData = await client.query<getColorPallet, getColorPalletVariables>({
				query: GET_COLOR_PALLET,
				variables: {
					id: parseInt(selectedBrand?.colorPalleteId),
				},
			});
		}

		let logoData = null;
		if (selectedBrand?.logoId) {
			logoData = await client.query<getLogo, getLogoVariables>({
				query: GET_LOGO,
				variables: {
					id: parseInt(selectedBrand?.logoId),
				},
			});
		}

		if (colorData && colorData.data && colorData.data.getColorPallet) {
			const selectedBrandColorPallet = colorData.data.getColorPallet.colors.replace(/[\[\]']+/g, "").split(", ");
			const selectedBrandColors = selectedBrandColorPallet.slice(0, 5);
			selectedBrandColors.forEach((color, index) => {
				campaign.colors[index] = color;
			});
			handleCampaignChange("bgColor", campaign.colors[0]);
		}

		if (logoData && logoData.data && logoData.data.getLogo) {
			const httpUrl = convertS3UriToHttpsUrl(logoData.data.getLogo.src);
			handleCampaignChange("logo", logoData.data.getLogo.src);
			setCampaignAssets((prevAssets: any) => ({ ...prevAssets, logo: httpUrl }));
		}

		if (selectedBrand) {
			const header1Updates = {
				size:
					(selectedBrand &&
						selectedBrand?.primaryMessage &&
						JSON.parse(selectedBrand?.primaryMessage?.replace(/'/g, '"'))?.size) ||
					"",
				color:
					(selectedBrand &&
						selectedBrand?.primaryMessage &&
						JSON.parse(selectedBrand?.primaryMessage?.replace(/'/g, '"'))?.color) ||
					"",
				font: findFontNameById(selectedBrand?.primaryFontId)?.src || "",
			};

			handleCampaignChange("header1", { ...campaign.header1, ...header1Updates });
			setSelectedFontPrimaryName(findFontNameById(selectedBrand?.primaryFontId)?.label);

			const header2Updates = {
				size:
					(selectedBrand?.secondaryMessage &&
						JSON.parse(selectedBrand?.secondaryMessage?.replace(/'/g, '"'))?.size) ||
					"",
				color:
					(selectedBrand?.secondaryMessage &&
						JSON.parse(selectedBrand?.secondaryMessage?.replace(/'/g, '"'))?.color) ||
					"",
				font: findFontNameById(selectedBrand?.secondaryFontId)?.src || "",
			};

			handleCampaignChange("header2", { ...campaign.header1, ...header2Updates });
			setSelectedFontSecondaryName(findFontNameById(selectedBrand?.secondaryFontId)?.label);
		}
	};
	const findFontNameById = (id: any) => {
		for (const group of fontOptions) {
			const option = group?.options?.find((option: any) => option.id === id);
			if (option) {
				return option;
			}
		}
		return null;
	};

	async function updateVisualHash(file: File) {
		try {
			let resp = null;
			let res = null;
			if (isIframe()) {
				const orgId =
					context.iframeConfig?.saveDestination === IframeDestinationEnum.ORGANIZATION
						? context.iframeConfig.organization
						: undefined;
				const addToUserAssets = context.iframeConfig?.saveDestination === IframeDestinationEnum.USER;
				resp = await uploadImageViaApi(file, orgId, addToUserAssets);
				res = resp.data as any;
			} else {
				resp = await uploadImageViaApi(file, undefined, true);
				res = resp.data as any;
			}

			if (res && res.visualId && res.status) {
				handleCampaignChange("productVisualHash", res.visualId);
				return res.visualId;
			}
		} catch (err) {
			console.log("error while uploading image");
			return null;
		}
		return null;
	}

	return { handleAssetUpload: handleAssetUpload, getBrandDetails: getBrandDetails };
}

export default useCampaigngeneration;
