import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import Context from "../../../Context/Context";
import { Box, makeStyles, Theme } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "../../UI/BriaButton";
import clsx from "clsx";
import { uploadLogoToS3 } from "../../../GraphQL/mutations";
import FileUpload from "./FileUpload";
import { ApiActions } from "../../../sdk/resources/briaAPIConstants";
import ImgViewer from "../Brand/BrandPage/ImgViewer/ImgViewer";
import Divider from "@material-ui/core/Divider";
import { Skeleton } from "@material-ui/lab";
import BriaAPI from "../../../sdk/resources/briaAPI";
import { GENERAL_ORG_ID } from "../../../Constants";
import { ShouldClearWatermark } from "../../../Helpers/clearWatermark";
import { getImageMeta } from "../utilities";
import useErrorPopup from "../../../hooks/useErrorPopup";

type IProps = {};

function EmbeddedLogoTab(props: IProps) {
	const classes = useStyles();
	const { t } = useTranslation();
	const context = useContext(Context);
	const { shouldClearWatermark } = ShouldClearWatermark();
	const useErrorPopupVar: any = useErrorPopup();

	const [logo, setLogo] = useState<any>("https://labs-assets.bria.ai/embedded_logo/bria_logo.png");
	const [uploading, setUploading] = useState<boolean>(false);
	const [firstBrandedImage, setFirstBrandedImage] = useState<any>(null);
	const [brandedImages, setBrandedImages] = useState<any[]>([]);
	const [startExtraCalls, setStartExtraCalls] = useState<boolean>(false);

	const bodyParams = [
		[0.05, 15],
		[0.1, 7.5],
		[0.1, 15],
		[0.15, 7.5],
		[0.15, 15],
	];

	const getPasteLogoParameters = (logoPath: string, min_roi: number, scale_text: number) => {
		return {
			changes: {
				vhash: window.location.href.split("/")[4],
				mask_rect: `[${Math.floor(context.shapeEditingItems[0].x)},${Math.floor(
					context.shapeEditingItems[0].y
				)},${Math.floor(context.shapeEditingItems[0].width)},${Math.floor(
					context.shapeEditingItems[0].height
				)}]`,
				logo_url: logoPath,
				min_roi: min_roi,
				scale_text: scale_text,
			},
		};
	};

	const pastParallelLogo = async (client: any, logoPath: string, organizationUid: string) => {
		setBrandedImages([]);
		await (async () => {
			for (let i = 0; i < bodyParams.length; i++) {
				try {
					let response = await client.callApi(
						ApiActions.PASTE_LOGO,
						getPasteLogoParameters(logoPath, bodyParams[i][0], bodyParams[i][1]),
						shouldClearWatermark(),
						organizationUid
					);
					setBrandedImages((brandedImages) => [...brandedImages, response?.data?.image_res]);
				} catch (e) {
					console.log("Server Error: consecutive 6 parallel calls: ", e);
				}
			}
		})();
	};

	const onDrop = async (file: any) => {
		setUploading(true);
		const logoResp = await uploadLogoToS3(file?.name, file?.base64);

		if (logoResp && logoResp.ok && logoResp.url) {
			setUploading(false);
			setLogo(logoResp.url);
			const img: any = await getImageMeta(logoResp.url);
			context.setEmbeddedLogoAspectRatio(img.naturalWidth / img.naturalHeight);
		}
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.firstRow}>
				<Box className={classes.logoActions}>
					<FileUpload
						isLogoTab={true}
						file={logo?.length && logo}
						loading={uploading}
						hideFileOnDrop={true}
						onDrop={onDrop}
						dragText="Drop it like it’s hot!"
						renderFile={(file: any) => {
							return (
								<>
									<img src={file} alt="file" className={classes.logoImg} />
								</>
							);
						}}
					/>
					<Box>
						{!uploading && !startExtraCalls && (
							<BriaButton
								buttonType={BUTTONTYPES.OUTLINED}
								className={clsx(classes.uploadButton, classes.embedButton)}
								onClick={async () => {
									setFirstBrandedImage(null);
									setBrandedImages([]);
									let logoPath: any = null;
									const client = BriaAPI.getInstance(window.location.href.split("/")[4]);
									const organizationUid =
										context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
									if (logo) {
										logoPath = `${logo.substring(logo.indexOf("users/"))}`;
										if (logoPath === logo) {
											logoPath = "users/embedded_logo/bria_logo.png";
										}
									} else {
										console.log("Please try again, The logo isn't being uploaded");
										return;
									}
									setStartExtraCalls(true);
									try {
										const response = await client.callApi(
											ApiActions.PASTE_LOGO,
											getPasteLogoParameters(logoPath, 0.05, 7.5),
											shouldClearWatermark(),
											organizationUid
										);
										if (response?.data?.image_res) {
											setFirstBrandedImage(response?.data?.image_res);
										} else {
											useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
										}
									} catch (e) {
										console.log("Server Error: Single call: ", e);
										useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
									}
									await pastParallelLogo(client, logoPath, organizationUid);
									setStartExtraCalls(false);
								}}
							>
								{t("EmbedLogo")}
							</BriaButton>
						)}
					</Box>
				</Box>
				<Box
					style={{
						width: 160,
						height: 212,
						display: "flex",
						marginLeft: "4vw",
						alignSelf: "self-end",
					}}
				>
					<Box
						style={{
							maxWidth: "100%",
							maxHeight: "100%",
							display: "flex",
						}}
					>
						{firstBrandedImage && (
							<ImgViewer
								imageSrc={firstBrandedImage}
								enableCompareMode={false}
								hideImgTools={true}
								onClick={() => {
									context.setShapeResultedImage(firstBrandedImage);
								}}
							/>
						)}
					</Box>
				</Box>
			</Box>
			<Divider orientation="horizontal" className={classes.divider} />
			<Box display="flex" flexWrap="wrap" overflow="auto" height="15vw">
				{brandedImages.map((brandedImage) => (
					<Box
						display="flex"
						justifyContent="center"
						marginRight="10px"
						marginBottom="5px"
						width="8vw"
						height="160px"
					>
						<ImgViewer
							imageSrc={brandedImage}
							enableCompareMode={false}
							hideImgTools={true}
							onClick={() => {
								context.setShapeResultedImage(brandedImage);
							}}
						/>
					</Box>
				))}
				{startExtraCalls && (
					<>
						{[...Array(bodyParams.length - brandedImages.length)].map(() => (
							<Box display="flex" justifyContent="center" marginRight="10px" marginBottom="5px">
								<Skeleton
									variant="rect"
									animation="wave"
									width="8vw"
									height="160px"
									style={{ borderRadius: 4 }}
								/>
							</Box>
						))}
					</>
				)}
			</Box>
		</Box>
	);
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
	},
	firstRow: {
		display: "flex",
		flexDirection: "row",
	},
	logoActions: {
		display: "flex",
		width: 160,
		flexDirection: "column",
		justifyContent: "flex-end",
	},
	logoImg: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	embedButton: {
		width: 160,
		marginTop: 10,
	},
	divider: {
		marginTop: 10,
		marginBottom: 10,
	},
}));

export default EmbeddedLogoTab;
