import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import { Box, Checkbox, IconButton, Theme, Typography } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "./UI/BriaButton";
import CloseIcon from "../assets/icons/CloseIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
	title?: string | JSX.Element;
	description: JSX.Element;
	buttonText: string;
	buttonType?: BUTTONTYPES;
	cancelButtonText?: string;
	cancelButtonType?: BUTTONTYPES;
	onCloseModal: (dontShowAgainFlag: boolean) => void;
	onButtonClick?: (dontShowAgainFlag: boolean) => void;
	shouldOpen: boolean;
	height?: string;
	width?: string;
	buttonExtraStyle?: any;
	extraStyle?: any;
	showDontShowAgainCheckbox?: boolean;
	showCancelButton?: boolean;
	promtWrapperTerms?: string;
	messageTextTerms?: string;
}

export default function BriaConfirmationDialog(props: IProps) {
	const { t } = useTranslation();
	const classes = useStyles({
		height: props.height ? props.height : "",
		width: props.width ? props.width : "550px",
	});
	const [dontShowAgainFlag, setDontShowAgainFlag] = useState<boolean>(false);

	return (
		<>
			<Dialog
				classes={{
					paper: classes.paper,
				}}
				onClose={() => {
					props.onCloseModal(dontShowAgainFlag);
				}}
				open={props.shouldOpen}
				maxWidth={false}
				style={props.extraStyle}
			>
				<Box className={props.promtWrapperTerms ? classes.promtWrapperTerms : classes.promtWrapper}>
					<Box className={classes.closeBtnContainer}>
						<IconButton
							style={{ padding: 0 }}
							color="inherit"
							aria-label="close"
							onClick={() => {
								props.onCloseModal(dontShowAgainFlag);
							}}
						>
							<CloseIcon style={{ fontSize: 12 }} />
						</IconButton>
					</Box>
					{!!props.title && <Typography className={classes.beforeYouGoText}>{props.title}</Typography>}
					<Typography className={props.messageTextTerms ? classes.messageTextTerms : classes.messageText}>
						{props.description}
					</Typography>
				</Box>
				{props.showDontShowAgainCheckbox && (
					<Box className={classes.dontShowCheckbox}>
						<Checkbox
							color="primary"
							name="Object Remover"
							checked={dontShowAgainFlag}
							onChange={(event, checked) => {
								setDontShowAgainFlag(checked);
							}}
						/>
						<span
							onClick={() => {
								setDontShowAgainFlag(!dontShowAgainFlag);
							}}
						>
							{t("dontShowAgain")}
						</span>
					</Box>
				)}
				<Box className={classes.bottomCloseBtnContainer} style={props.buttonExtraStyle}>
					<Box
						className={classes.buttonsWrapper}
						style={{
							gap: props.showCancelButton ? "1rem" : "2rem",
						}}
					>
						<BriaButton
							className={classes.btnStyle}
							buttonType={props.buttonType ? props.buttonType : BUTTONTYPES.PRIMARY}
							onClick={() => {
								props.onButtonClick && props.onButtonClick(dontShowAgainFlag);
							}}
						>
							{props.buttonText}
						</BriaButton>
						{props.showCancelButton && (
							<BriaButton
								className={classes.btnStyle}
								buttonType={props.cancelButtonType ? props.cancelButtonType : BUTTONTYPES.SECONDARY}
								onClick={() => {
									props.onCloseModal(dontShowAgainFlag);
								}}
							>
								{props.cancelButtonText ?? "Cancel"}
							</BriaButton>
						)}
					</Box>
				</Box>
			</Dialog>
		</>
	);
}

interface StyleProps {
	height?: string;
	width?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	promtWrapper: {
		padding: "1.5rem",
		paddingTop: "2rem",
	},
	promtWrapperTerms: {
		padding: "1.5rem",
		paddingTop: "2rem",
		height: "90%",
	},
	messageTextTerms: {
		fontSize: "0.9rem",
		margin: "1rem 0",
		height: "100%",
	},
	beforeYouGoText: {
		fontSize: 24,
		fontWeight: 600,
		color: "#1A0638",
	},
	messageText: {
		fontSize: "16px",
		fontWeight: 400,
		color: "#5B5B5B",
		margin: "1rem 0",
	},
	paper: (props) => ({
		borderRadius: "10px !important",
		height: props.height,
		width: props.width,
	}),
	buttonsWrapper: {
		marginTop: "1.5rem",
		display: "flex",
		justifyContent: "flex-end",
		marginLeft: "auto",
	},
	rightBtns: {
		flex: 0.7,
	},
	btnStyle: {
		width: "100%",
		height: "100%",
		fontWeight: 600,
		fontSize: 16,
		borderRadius: 8,
	},
	bottomCloseBtnContainer: {
		position: "absolute",
		bottom: 10,
		right: 30,
	},
	closeBtnContainer: {
		position: "absolute",
		top: 10,
		right: 16,
	},
	dontShowCheckbox: {
		bottom: 30,
		left: "1.5rem",
		marginLeft: -9,
		position: "absolute",
		color: "rgba(73, 80, 87, 1)",
	},
}));
