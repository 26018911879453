import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import React, { useState } from "react";
import ColorInput from "./ColorInput/ColorInput";
import TitleTexts from "../TitleTexts/TitleTexts";
import FileUpload from "../FileUpload/FileUpload";
import { useContext } from "react";
import { BrandContext } from "../BrandPage/BrandPage";
import client from "../../../../GraphQL/client";
import { parseXmpFileContent } from "../../../../GraphQL/mutations";
import { parseXmpFileContent_parseXmpFileContent_mood } from "../../../../GraphQL/types/parseXmpFileContent";
import InlineAlert from "../../../Common/InlineAlert/InlineAlert";
import { useEffect } from "react";
import { apiMoodSchema, mapObjectBySchema } from "../../Mood/data/sliders";
import { invertObj } from "../../../../Helpers/mapper";
import { wait } from "../../../../Helpers/time";
import {
	GridImgProps,
	mainImgInfo,
	SELECTED_IMG_INFO,
} from "../BrandPage/ImgViewer/ImgViewer";
import BriaAPI from "../../../../sdk/resources/briaAPI";
import { ApiActions } from "../../../../sdk/resources/briaAPIConstants";
import { validateColorMixer } from "../Moods/SingleMood/SingleMood";
import Context from "../../../../Context/Context";
import { IsAdminOrTesting } from "../../../../Helpers/adminOrViewer";
import { IsIframe } from "../../../../Helpers/iframe";

const defaultColors = ["", "", "", "", ""];
export const XMP_FILE = "xmpFile";
export const EXTRA_SUGGESTION = "EXTRA_SUGGESTION";
export const SELECTED_PREDEFINED_MOOD = "SELECTED_PREDEFINED_MOOD";
export const SELECTED_PREDEFINED_MOOD_INFO = "SELECTED_PREDEFINED_MOOD_INFO";
export const SELECTED_PREDEFINED_MOOD_URL = "SELECTED_PREDEFINED_MOOD_URL";
export const XMP_FILE_IMAGE_RES = "XMP_FILE_IMAGE_RES";
export const XMP_FILE_INFO = "XMP_FILE_INFO";

export default function ColorPallete() {
	const classes = useStyles();
	const [lcoalFile, setLocalFile] = useState<any>(null);

	const {
		updateBrandState,
		defaultStepValue = defaultColors,
		generalState,
		brandState,
		updateGeneralState,
		setChangeId,
	} = useContext(BrandContext);
	const context = useContext(Context);

	const [colors, setColors] = useState(defaultStepValue ?? []);

	const selectedImg: GridImgProps =
		(generalState as any)?.[SELECTED_IMG_INFO] || mainImgInfo;

	const instance = BriaAPI.getInstance(selectedImg.vhash);

	const savedFile = (generalState as any)?.[XMP_FILE];

	const onColorChange = (
		newColor: string,
		index: number,
		remove?: boolean
	) => {
		const newColors = colors.map((_color: string, _index: number) => {
			if (_index === index) return remove ? "" : newColor;
			return _color;
		});
		setColors(newColors);
		updateBrandState(newColors);
	};

	useEffect(() => {
		let logoColors = (generalState as any)?.logoColorPallet;
		let logoColorsLength = logoColors?.length;
		if (logoColorsLength) {
			if (logoColorsLength < 5) {
				for (let index = 0; index < 5 - logoColorsLength; index++) {
					logoColors.push("");
				}
			}
			setColors(logoColors);
			updateBrandState(logoColors);
		}
	}, [(generalState as any)?.logoColorPallet]);

	const parseXmpContent = async (
		res: parseXmpFileContent_parseXmpFileContent_mood | undefined | null,
		fileInfo: any
	) => {
		const { moodParameters } = res || {};
		const mappedVersion = mapObjectBySchema(
			moodParameters,
			invertObj(apiMoodSchema)
		);

		updateBrandState(mappedVersion, "moodSliders");

		const xmpFileInfo = {
			id: XMP_FILE,
			moodId: XMP_FILE,
			name: fileInfo?.name,
			moodParameters: mappedVersion,
			// imageRes: xmpImageRes,
		};
		updateGeneralState(XMP_FILE, fileInfo?.name, {
			[XMP_FILE]: fileInfo?.name, // to store xmp info for color pallet step
			[XMP_FILE_INFO]: xmpFileInfo, // to store xmp info for color pallet step
			// [EXTRA_SUGGESTION]: [
			// 	// to append xmp file as predefined mood in moods stage
			// 	xmpFileInfo,
			// ],
			[SELECTED_PREDEFINED_MOOD]: XMP_FILE, // to default selected predefined mood to xmp file
		});
		// setChangeId((c: number) => c + 1);
	};

	const onXmpFileDrop = (fileInfo: any, file: any) => {
		var reader = new FileReader();
		setLocalFile(fileInfo);
		reader.readAsText(file);
		reader.onload = async function () {
			const res = await parseXmpFileContent(reader.result as any, client);
			parseXmpContent(res, fileInfo);
			// TODO: use parsed data here
		};
		reader.onerror = function (error) {
			console.error(error);
		};
	};

	return (
		<Box className={classes.colorPalleteRoot}>
			<TitleTexts
				passedClassName={classes.brandName}
				subText={`Creating ${(brandState as any)?.name}`}
			/>
			<TitleTexts
				passedClassName={classes.titleTexts}
				text={
					colors?.some((color) => color !== "")
						? colors.filter((color) => color !== "").length > 1
							? "Are these your brand colors?"
							: "Only one color?"
						: "What are your brand colors?"
				}
				subText="AI will be able to incorporate these colors in your photography style"
			/>
			<Box className={classes.colorInputsWrapper}>
				{colors.map((color: string, index: number) => (
					<ColorInput
						color={color}
						onColorChange={(color: string, remove?: boolean) =>
							onColorChange(color, index, remove)
						}
					/>
				))}
			</Box>
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	colorPalleteRoot: {
		maxWidth: 850,
		margin: "auto",
		textAlign: "center",
		fontSize: 24,
	},
	brandName: {
		color: "#A4A4A4",
		padding: "11vh 0 10vh",
		"& p": {
			fontWeight: 400,
			fontSize: "24px",
		},
	},
	titleTexts: {
		"& :first-child": {
			fontSize: "48px",
			fontWeight: 600,
			color: "#495057",
		},
		"& :nth-child(2)": {
			fontSize: "24px",
			fontWeight: 400,
			color: "#A4A4A4",
		},
	},
	colorInputsWrapper: {
		display: "flex",
		gap: 40,
		marginTop: "3rem",
		justifyContent: "center",
	},
	colorInput: {
		visibility: "hidden",
	},
	bottomText: {
		marginTop: "2rem",
	},
	uploadedFile: {
		color: "#D80067",
	},
}));
