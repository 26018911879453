import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ObjectRemover from "./ObjectRemover";
import { Box, Typography } from "@material-ui/core";
import BriaButton, { BUTTONTYPES } from "../UI/BriaButton";
import Context from "../../Context/Context";
import { ShouldClearWatermark } from "../../Helpers/clearWatermark";
import { uploadBrushedImageToS3 } from "../../GraphQL/mutations";
import { uuid4 } from "@sentry/utils";
import BriaAPI from "../../sdk/resources/briaAPI";
import { GENERAL_ORG_ID } from "../../Constants";
import { ApiActions } from "../../sdk/resources/briaAPIConstants";
import { ImageEditorContext } from "../../Pages/Tool/ImageEditor";
import { captureException } from "@sentry/react";
import BriaInfoBox from "../UI/BriaInfoBox";
import { IsIframe } from "../../Helpers/iframe";
import useErrorPopup from "../../hooks/useErrorPopup";
import { SystemViewTypes } from "../../Models/SystemView";
import BriaSwitch from "../UI/BriaSwitch";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import { getImageMeta, getImagePixelsData, hexToRgb } from "./utilities";
import { useTranslation } from "react-i18next";
import { ToolConfigTabsTypes } from "../../Models/ToolsConfig";

interface IProps {
	fullscreen: boolean | undefined;
	isImageLoading: boolean;
	imageElement?: HTMLImageElement;
	setIsImageLoading: React.Dispatch<SetStateAction<boolean>>;
	visualHash: string;
	brushConfig: any;
	setBrushConfig: any;
	resetManualBrushTab: boolean | null;
	setResetManualBrushTab: React.Dispatch<SetStateAction<boolean | null>>;
	setIsObjectsAutomaticTabSelected: React.Dispatch<SetStateAction<boolean>>;
	setIsObjectsManualBrushTabSelected: React.Dispatch<SetStateAction<boolean>>;
	objectsTabCanvasRef: any;
	imageUrl: string;
	setSelectedImageUrl: (imageUrl: string) => void;
	objectsList: any;
	onObjectHover: (id: string) => void;
	clearObjectHover: () => void;
	objectOnHover: string;
	setShouldResetCheckboxes: Dispatch<SetStateAction<boolean>>;
	shouldResetCheckboxes: boolean;
	loading: boolean;
	onRemoveObject: (objectId: string) => Promise<boolean>;
	onShowObject: (objectId: string) => Promise<boolean>;
	disableObjectsTab: boolean;
	pointOfInterests: any;
	selectedTab: string;
	parentDimenstions: { width: number; height: number } | undefined;
	objectsPanopticImage: ImageData | undefined;
	objectsMasks: HTMLImageElement[];
	canvasHook: any;
	onRemoveObjectByCoordinates: (positivePoint: any, imageSize: any) => Promise<boolean>;
	setShowObjectsFollowUpTooltip: (value: boolean) => void;
	setObjectsTooltipPosition: (value: { x: number; y: number } | undefined) => void;
	onRestoreAllObjects: any;
	lockMasks: boolean;
	setLockMasks: (value: boolean) => void;
	mainObjectData: any;
	setMainObjectData: (value: any) => void;
}

export default function ObjectsTab({
	fullscreen,
	imageElement,
	isImageLoading,
	setIsImageLoading,
	visualHash,
	brushConfig,
	setBrushConfig,
	resetManualBrushTab,
	setResetManualBrushTab,
	setIsObjectsAutomaticTabSelected,
	setIsObjectsManualBrushTabSelected,
	objectsTabCanvasRef,
	imageUrl,
	setSelectedImageUrl,
	objectsList,
	onObjectHover,
	clearObjectHover,
	objectOnHover,
	setShouldResetCheckboxes,
	shouldResetCheckboxes,
	loading,
	onRemoveObject,
	onShowObject,
	disableObjectsTab,
	pointOfInterests,
	selectedTab,
	parentDimenstions,
	onRestoreAllObjects,
	objectsPanopticImage,
	objectsMasks,
	canvasHook,
	onRemoveObjectByCoordinates,
	setShowObjectsFollowUpTooltip,
	setObjectsTooltipPosition,
	lockMasks,
	setLockMasks,
	mainObjectData,
	setMainObjectData,
}: IProps) {
	const context = useContext(Context);
	const { isIframe } = IsIframe();
	const client = BriaAPI.getInstance(visualHash);
	const prevInstance = client.clone();
	const brushOverlayCanvasRef = useRef<Path2D | HTMLCanvasElement | null>();
	const { shouldClearWatermark } = ShouldClearWatermark();
	const { actions, reDrawImage, initializeCanvas } = canvasHook(
		mainObjectData,
		lockMasks,
		brushConfig,
		imageUrl,
		objectsTabCanvasRef,
		parentDimenstions,
		brushOverlayCanvasRef,
		setShowObjectsFollowUpTooltip,
		setObjectsTooltipPosition,
		objectsPanopticImage,
		objectsMasks,
		(e: any, mask: HTMLImageElement | undefined) => {
			if (mask) {
				handleRemoveObjectsWithMaskUrl(mask.src);
			}
		},
		(
			e: any,
			mouseLocation: { x: number; y: number } | undefined,
			width: number | undefined,
			height: number | undefined
		) => {
			if (mouseLocation) {
				onRemoveObjectByCoordinates(
					[Math.floor(mouseLocation.x), Math.floor(mouseLocation.y)],
					[width, height]
				);
			}
		},
		imageElement
	);
	const { t } = useTranslation();
	const { undoRedoSetter } = useContext(ImageEditorContext);
	const classes = useStyles({
		brushConfig,
		isIframe: isIframe(),
	});
	const useErrorPopupVar: any = useErrorPopup();
	const [mainObjectMetaData, setMainObjectMetaData] = useState<any>();
	useEffect(() => {
		setIsObjectsManualBrushTabSelected(selectedTab === "manual-brush");
		setIsObjectsAutomaticTabSelected(selectedTab === "automatic");
		return () => {
			setIsObjectsManualBrushTabSelected(false);
			setIsObjectsAutomaticTabSelected(false);
		};
	}, []);

	useEffect(() => {
		if (resetManualBrushTab) {
			resetAllObjects(true);
		}
	}, [resetManualBrushTab]);

	useEffect(() => {
		const redrawAndSetSelectedImage = async () => {
			await reDrawImage(imageUrl);
		};
		imageUrl?.length > 0 && redrawAndSetSelectedImage();
	}, [imageUrl]);
	useEffect(() => {
		if (lockMasks && selectedTab === "manual-brush") {
			lockMainMask(true);
		}
	}, [imageElement]);
	const resetAllObjects = async (resetButton: boolean = false) => {
		setIsImageLoading(true);
		let res = await onRestoreAllObjects();
		await reDrawImage(res);
		setIsImageLoading(false);
		setResetManualBrushTab(null);
	};
	const handleRemoveObjectsCall = async (
		maskUrl: string | null | undefined,
		operation: string | null,
		maskSource: string
	) => {
		const response = await client.callApi(
			ApiActions.REMOVE_BRUSH_OBJECT,
			{
				changes: [
					{
						mask_url: maskUrl,
						mask_source: maskSource,
						actions: {
							remove: true,
						},
					},
				],
				operation: operation,
			},
			shouldClearWatermark(),
			context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID,
			true,
			false,
			false,
			context.systemView === SystemViewTypes.Admin
		);
		if (response?.data?.image_res) {
			const newInstance = client.clone();
			await reDrawImage(response.data.image_res);
			if (!lockMasks) {
				setIsImageLoading(false);
			}

			undoRedoSetter({
				undo: async () => {
					BriaAPI.setInstance(prevInstance);
					await reDrawImage(imageUrl);
					setSelectedImageUrl(imageUrl);
					setIsImageLoading(false);
				},
				redo: async () => {
					BriaAPI.setInstance(newInstance);
					await reDrawImage(response.data.image_res);
					setSelectedImageUrl(response.data.image_res);
					setIsImageLoading(false);
				},
			});
			setSelectedImageUrl(response.data.image_res);
		} else {
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
		}
	};

	const handleRemoveObjects = async (operation: string | null = null) => {
		setIsImageLoading(true);
		try {
			let base64Image = await actions.handleCanvasDownload();
			const uploadResp = await uploadBrushedImageToS3(uuid4(), base64Image);
			await handleRemoveObjectsCall(uploadResp?.url, operation, "manual");
			if (!lockMasks) {
				setIsImageLoading(false);
			}
		} catch (e) {
			setIsImageLoading(false);
			console.log("Server Error: Single call: ", e);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			captureException(e);
		}
	};

	const handleRemoveObjectsWithMaskUrl = async (maskUrl: string, operation: string | null = null) => {
		setIsImageLoading(true);
		try {
			await handleRemoveObjectsCall(maskUrl, operation, "generated");
			if (!lockMasks) {
				setIsImageLoading(false);
			}
		} catch (e) {
			setIsImageLoading(false);
			await reDrawImage(imageUrl);
			console.log("Server Error: Single call: ", e);
			useErrorPopupVar?.showErrorPopup(t("someActionsLimited"));
			captureException(e);
		}
	};
	const theme = useTheme();

	const organizationUid = context.user?.rawOrganizations[0]?.org_uid ?? GENERAL_ORG_ID;
	const maskColors = hexToRgb(theme.palette.primary.main);
	const canvasContext = useRef<CanvasRenderingContext2D | null>(null);
	const colorMainObject = async (uncolorFlag: boolean, image?: any) => {
		const maskImageData: any = mainObjectMetaData ? mainObjectMetaData : image;
		if (objectsTabCanvasRef.current) {
			canvasContext.current = objectsTabCanvasRef.current.getContext("2d");
		}
		if (imageElement && canvasContext.current) {
			const imageData = getImagePixelsData(imageElement, maskImageData.width, maskImageData.height);
			const maskData = getImagePixelsData(maskImageData, maskImageData.width, maskImageData.height);
			if (imageData.imagePixelsData && maskData.imagePixelsData) {
				for (let i = 0, len = imageData.imagePixelsData.data.length; i < len; i = i + 4) {
					if (maskData.imagePixelsData.data[i] !== 0 && !uncolorFlag) {
						imageData.imagePixelsData.data[i] = 216;
						imageData.imagePixelsData.data[i + 1] = 0;
						imageData.imagePixelsData.data[i + 2] = 103;
						imageData.imagePixelsData.data[i + 3] = 60;
					} else if (maskData.imagePixelsData.data[i] !== 0 && uncolorFlag) {
						imageData.imagePixelsData.data[i] = maskData.imagePixelsData.data[i];
						imageData.imagePixelsData.data[i + 1] = maskData.imagePixelsData.data[i + 1];
						imageData.imagePixelsData.data[i + 2] = maskData.imagePixelsData.data[i + 2];
						imageData.imagePixelsData.data[i + 3] = maskData.imagePixelsData.data[i + 3];
					}
				}

				if (imageData.ctx) {
					imageData.ctx.putImageData(imageData.imagePixelsData, 0, 0);
					await getImageMeta(imageData.ctx.canvas.toDataURL()).then((newImageElement) => {
						canvasContext.current?.drawImage(
							newImageElement,
							0,
							0,
							newImageElement.width,
							newImageElement.height,
							0,
							0,
							objectsTabCanvasRef.current.width,
							objectsTabCanvasRef.current.height
						);
					});
				}
				setIsImageLoading(false);
			}
		}
	};
	const lockMainMask = async (v) => {
		setIsImageLoading(true);

		if (v) {
			const client = BriaAPI.getInstance(visualHash);
			const { data } = await client._removeImageBG("", shouldClearWatermark() ? organizationUid : "");
			getImageMeta(data.image_res).then((image: HTMLImageElement) => {
				setMainObjectData(getImagePixelsData(image, image.width, image.height).imagePixelsData);
				setMainObjectMetaData(image);
				colorMainObject(false, image);
			});
		} else {
			colorMainObject(true);
		}

		setLockMasks(v);
	};
	return (
		<>
			<Box
				visibility={selectedTab === "automatic" ? "visible" : "hidden"}
				height={selectedTab === "automatic" ? "auto" : "0px"}
				style={{
					display: "flex",
					flexDirection: "column",
					margin: "2vh 0",
				}}
			>
				<Box mb="2vh">
					<BriaInfoBox
						showCloseIcon={true}
						styleType="info"
						condition={!fullscreen && !context.user?.getHideAutomaticObjectsInfoBoxSettings(isIframe())}
						iconColor={"#FFC107"}
						position={"relative"}
						text={t("removeObjectByClickingOnImage")}
						onButtonClick={() => {
							context.user?.updateHideAutomaticInfoBoxSettings(true, isIframe());
						}}
					/>
				</Box>
				{(!isIframe() ||
					(isIframe() &&
						context.iframeConfig?.enabledFeatures.some(
							(feature: string) =>
								[ToolConfigTabsTypes.AutoObjIdentification.toString()].indexOf(feature) !== -1
						))) && (
					<>
						{objectsList.length !== 0 && (
							<Typography className={classes.automaticTitle}>{t("objectsAutoDetected")}</Typography>
						)}
						{objectsList.map((object: any) => {
							return (
								<ObjectRemover
									loading={loading}
									onObjectHover={onObjectHover}
									clearObjectHover={clearObjectHover}
									objectOnHover={objectOnHover}
									setShouldResetCheckboxes={setShouldResetCheckboxes}
									shouldResetCheckboxes={shouldResetCheckboxes}
									label={object?.label}
									objectId={object?.id}
									oracle={object?.oracle}
									onRemoveObject={async (objectId: string, checked: boolean) => {
										if (checked) {
											return await onShowObject(objectId);
										} else {
											return await onRemoveObject(objectId);
										}
									}}
								/>
							);
						})}
					</>
				)}
			</Box>
			<Box
				className="App"
				visibility={selectedTab === "automatic" ? "hidden" : "visible"}
				height={selectedTab === "automatic" ? 0 : "auto"}
			>
				<Box
					style={{
						marginRight: 20,
					}}
				>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textSecondary"
						style={{
							fontWeight: 600,
							textAlign: "left",
							textTransform: "capitalize",
							color: "#2C2738",
							marginBottom: 24,
						}}
					>
						{t("brushSize")}
					</Typography>
					<ThumbnailSlider
						className={classes.slider}
						maxValue={100}
						minValue={0}
						name="lineWidth"
						value={brushConfig.lineWidth}
						sliderDefaultValue={brushConfig.lineWidth}
						aria-labelledby="input-slider"
						id={"input-slider"}
						leftSubtitle={t("minimum")}
						rightSubtitle={t("maximum")}
						handleValueChange={(value: number) => {
							setBrushConfig({
								...brushConfig,
								lineWidth: value > 0 ? value : 1,
							});
						}}
					/>
					{context.user?.isAdmin() && context.systemView === SystemViewTypes.Admin && (
						<Box className={classes.lockMaskContainer}>
							<Typography className={classes.lockMaskTextStyle}>Lock Mask</Typography>
							<BriaSwitch checked={lockMasks} onChange={async (e, v) => lockMainMask(v)} />
						</Box>
					)}
					<Box
						style={{
							margin: "8% 0 0 0",
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
						}}
					>
						<BriaButton
							buttonType={BUTTONTYPES.PRIMARYSMALL}
							className={classes.button}
							onClick={handleRemoveObjects}
							disabled={isImageLoading}
							style={context.iframeConfig?.enableTranslation ? { width: 145 } : {}}
						>
							{t("removeObjects")}
						</BriaButton>
						<BriaButton
							onClick={() => resetAllObjects(false)}
							buttonType={BUTTONTYPES.TEXTSMALL}
							className={classes.buttonLink}
							style={{
								opacity: isImageLoading ? 0.3 : 1,
								pointerEvents: isImageLoading ? "none" : "auto",
								...(context.iframeConfig?.enableTranslation ? { width: 210 } : {}),
							}}
						>
							{t("restoreAllObjects")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	buttonGroup: {
		width: "100%",
		marginBottom: "1vw",
		"& .MuiToggleButton-root.Mui-selected": {
			color: "#000",
			border: `1px solid ${theme.palette.primary.dark}`,
		},
	},
	buttonGroupItem: {
		color: theme.palette.primary.dark,
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(14),
		width: "45%",
	},
	slider: (props: any) => ({
		width: "100%",
		"& .MuiSlider-thumbColorPrimary": {
			color: "#FFF",
			boxShadow: "0px 4px 8px rgba(44, 39, 56, 0.2)",
		},
		"& .MuiSlider-track": {
			color: theme.palette.cards.main,
		},
		"& .MuiSlider-root": {
			color: theme.palette.cards.main,
		},
		"& .MuiSlider-thumb": {
			"&:active": {
				marginTop: "0",
				marginLeft: "0",
				transform: "translate(-50%,-50%)",
				position: "relative",
				width: props?.brushConfig?.lineWidth,
				height: props?.brushConfig?.lineWidth,
				color: props.isIframe ? theme.palette.primary.dark : props?.brushConfig?.strokeStyle,
				opacity: props?.brushConfig?.brushColorOpacity,
			},
		},
	}),

	button: {
		width: 139,
	},
	lockMaskContainer: {
		display: "flex",
		justifyContent: "space-between",
		borderTop: "1px solid #E7E7E7",
		borderBottom: "1px solid #E7E7E7",
		padding: "28px 0px 20px 0px",
		marginTop: 15,
	},
	lockMaskTextStyle: {
		fontWeight: 600,
		color: "#1A0638",
		fontSize: 14,
	},
	buttonLink: {
		width: 160,
		marginLeft: 8,
		textDecoration: "underline",
	},
	automaticTitle: {
		color: "#1A0638",
		fontSize: 14,
		marginBottom: 20,
		fontWeight: 600,
	},
})); //
