import {
	initializeAnalytics,
	logEvent,
	setAnalyticsCollectionEnabled,
	setUserId,
	setUserProperties,
} from "firebase/analytics";
import { getCurrentRoute } from "../Components/Tools/utilities";
import { isProd } from "../Config/Env";
import app from "../Config/Firebase";
import { STATIC_ACCOUNT_EMAIL } from "../Constants";
import { createHubspotContact } from "../GraphQL/mutations";
import { UserOrganization } from "./UserOrganization";

interface AnalyticsUser {
	userName: string;
	uid: string;
	email: string;
	role: string;
	company: string;
	organizations?: UserOrganization[];
	subscription?: any;
	manuallyManaged?: any;
	isSso?: boolean;
}

export enum HUBSPOT_EVENTS {
	ASSET_DOWNLOAD = "asset_download",
	ASSET_UPLOAD = "asset_upload",
	BRAND_EARLY_REGISTRATION = "brand_early_registration",
	FAIR_DIFFUSION_REQUEST = "fair_diffusion_request",
}

export enum ANALYTICS_EVENTS {
	CROP_BUTTON_CLICK = "CROP_BUTTON_CLICK",
	RESET_CROP_BUTTON_CLICK = "RESET_CROP_BUTTON_CLICK",
	SUCCESSFUL_BULK_UPLOAD = "SUCCESSFUL_BULK_UPLOAD",
	AT_LEAST_ONE_FAILED_BULK_UPLOAD = "AT_LEAST_ONE_FAILED_BULK_UPLOAD",
	HIGH_LOAD_DIALOG_OPEN = "HIGH_LOAD_DIALOG_OPEN",
	HIGH_LOAD_UPGRADE_BTN_CLICK = "HIGH_LOAD_UPGRADE_BTN_CLICK",
	UPGRADE_POPUP_UPGRADE_BTN_CLICK = "UPGRADE_POPUP_UPGRADE_BTN_CLICK",
	CREATE_BRAND = "CREATE_BRAND",
	HOME_SEARCH = "HOME_SEARCH",
	HOME_GENERATE = "HOME_GENERATE",
	GALLERY_GENERATE = "GALLERY_GENERATE",
	REGISTER = "REGISTER",
	LOGIN = "LOGIN",
	FAIR_DIFFUSION_SIGN_UP = "FAIR_DIFFUSION_SIGN_UP",
	INCREASE_RESOLUTION = "INCREASE_RESOLUTION",
	BRAND_MOOD_FINE_TUNING = "BRAND_MOOD_FINE_TUNING",
	BRAND_MOOD_THUMBNAIL_CLICK = "BRAND_MOOD_THUMBNAIL_CLICK",
	REMOVE_BG = "REMOVE_BG",
	BLUR_BG = "BLUR_BG",
	REPLACE_BG = "REPLACE_BG",
	PRESENTERS_STYLE = "PRESENTERS_STYLE",
	UNCROP = "UNCROP",
	REPLACE_BG_THUMBNAIL_CLICK = "REPLACE_BG_THUMBNAIL_CLICK",
	PRESENTERS_STYLE_THUMBNAIL_CLICK = "PRESENTERS_STYLE_THUMBNAIL_CLICK",
	UNCROP_THUMBNAIL_CLICK = "UNCROP_THUMBNAIL_CLICK",
	REMOVE_OBJECT = "REMOVE_OBJECT",
	CHANGE_ETHNICITY = "CHANGE_ETHNICITY",
	CHANGE_EXPRESSION = "CHANGE_EXPRESSION",
	CHANGE_APPEARANCE = "CHANGE_APPEARANCE",
	DOWNLOAD_IMAGE = "DOWNLOAD_IMAGE",
	GALLERY_DOWNLOAD_IMAGE = "GALLERY_DOWNLOAD_IMAGE",
	DOWNLOAD_PSD = "DOWNLOAD_PSD",
	SAVE_IMAGE = "SAVE_IMAGE",
	SAVE_IMAGE_AS_PSD = "SAVE_IMAGE_AS_PSD",
	DOWNLOAD_VIDEO = "DOWNLOAD_VIDEO",
	UNDO = "UNDO",
	REDO = "REDO",
	RESET = "RESET",
	SUGGESTION_CLICKED = "SUGGESTION_CLICKED",
	EARLY_BIRD_SIGN_UP = "EARLY_BIRD_SIGN_UP",
	BETA_SIGN_UP_BUTTON_CLICK = "BETA_SIGN_UP_BUTTON_CLICK",
	API_LANDING_PAGE_CTA_BUTTON_CLICK = "API_LANDING_PAGE_CTA_BUTTON_CLICK",
	LANDING_PAGE_CTA_BUTTON_CLICK = "LANDING_PAGE_CTA_BUTTON_CLICK",
	DEMO_APPLICATION_SUBMITTED = "DEMO_APPLICATION_SUBMITTED",
	DEMO_APPLICATION_DIALOG_OPENED = "DEMO_APPLICATION_DIALOG_OPENED",
	FEEDBACK_SUBMITTED = "FEEDBACK_SUBMITTED",
	FEEDBACK_DIALOG_OPENED = "FEEDBACK_DIALOG_OPENED",
	CANCELATION_POPUP_OPENED = "CANCELATION_POPUP_OPENED",
	IMAGE_UPLOAD_START = "IMAGE_UPLOAD_START",
	IMAGE_UPLOAD_COMPLETE = "IMAGE_UPLOAD_COMPLETE",
	IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED",
	UPLOADED_IMAGE_OPEN = "UPLOADED_IMAGE_OPEN",
	HOME_HEADER_CONTACT_SALES = "HOME_HEADER_CONTACT_SALES",
	HOME_HEADER_PRICING = "HOME_HEADER_PRICING",
	HOME_HEADER_API = "HOME_HEADER_API",
	HOME_BODY_GET_API = "HOME_BODY_GET_API",
	HOME_BODY_ENHANCE_VISUAL = "HOME_BODY_ENHANCE_VISUAL",
	HOME_BODY_REMOVE_OBJECTS = "HOME_BODY_REMOVE_OBJECTS",
	HOME_BODY_COVERT_IMAGE_TO_PSD = "HOME_BODY_COVERT_IMAGE_TO_PSD",
	HOME_BODY_EARLY_ACCESS = "HOME_BODY_EARLY_ACCESS",
	HOME_BODY_REMOVE_OBJECT = "HOME_BODY_REMOVE_OBJECT",
	PRO_SUBSCRIPTION_START = "PRO_SUBSCRIPTION_START",
	BUSINESS_SUBSCRIPTION_START = "BUSINESS_SUBSCRIPTION_START",
	PRICING_GENERATE = "PRICING_GENERATE",
	MARKETING_CONTACT_US = "MARKETING_CONTACT_US",
	MARKETING_PLAYGROUND = "MARKETING_PLAYGROUND",
	MARKETING_TERMS = "MARKETING_TERMS",
	MARKETING_FAIR_DIFFUSION = "MARKETING_FAIR_DIFFUSION",
	MARKETING_API = "MARKETING_API",
	MARKETING_MODELS = "MARKETING_MODELS",
	MARKETING_TECHNOLOGY = "MARKETING_TECHNOLOGY",
	MARKETING_ABOUTUS = "MARKETING_ABOUTUS",
	MARKETING_PRICING = "MARKETING_PRICING",
	STARTER_API_SUBSCRIPTION_START = "STARTER_API_SUBSCRIPTION_START",
	BASIC_API_SUBSCRIPTION_START = "BASIC_API_SUBSCRIPTION_START",
	PRO_API_SUBSCRIPTION_START = "PRO_API_SUBSCRIPTION_START",
	ULTIMATE_API_SUBSCRIPTION_START = "ULTIMATE_API_SUBSCRIPTION_START",
}

export enum PAGE_TYPES {
	IMAGE_EDITOR = "Image editor",
	GALLERY = "Gallery",
	IMAGE_EDITOR_MAIN_IMAGE = "Image editor - main image",
}

class Analytics {
	analytics = initializeAnalytics(app);
	user: AnalyticsUser | null | undefined;

	constructor() {
		setAnalyticsCollectionEnabled(this.analytics, true);
	}

	private async setHubspotUser(extraProperties: any = {}) {
		extraProperties["is_sso"] = this.user?.isSso;
		createHubspotContact(extraProperties); //removed await to improve site performance
		if (this.user) {
			const splittedUserName = this.user.userName.split(" ");
			let _hsq = ((window as any)._hsq = (window as any)._hsq || []);
			_hsq.push([
				"identify",
				{
					contact_source: `${isProd ? "prod" : "int"}_${this.user.isSso ? "web_app_sso" : "web_app"}`,
					email: this.user.email,
					firstname: splittedUserName[0],
					lastname: splittedUserName.filter((part, index) => index !== 0).join(" "),
					company: this.user.company,
					...extraProperties,
				},
			]);
		}
	}

	public async pushHubspotEvent(eventName: HUBSPOT_EVENTS, eventValue: any = true) {
		await createHubspotContact({ [eventName]: eventValue });
	}

	public async pushAnonymousHubspotEvent(
		eventName: HUBSPOT_EVENTS,
		eventValue: any = true,
		userEmail: string,
		userFirstName?: string,
		userLastName?: string,
		userCompany?: string,
		userRole?: string
	) {
		await createHubspotContact(
			{ [eventName]: eventValue },
			true,
			userEmail,
			userFirstName,
			userLastName,
			userCompany,
			userRole
		);
	}

	async setUser(currentUser: AnalyticsUser | null, isNewUser: boolean = false) {
		if (currentUser) {
			this.user = currentUser;
			if (currentUser.email !== STATIC_ACCOUNT_EMAIL) {
				this.setUserProperties();
				await this.setHubspotUser();
				if (isNewUser) {
					this.logEvent(ANALYTICS_EVENTS.REGISTER);
					(window as any).lintrk("track", {
						conversion_id: 13339457,
					});
				} else {
					this.logEvent(ANALYTICS_EVENTS.LOGIN);
				}
			}
			this.fireScreenView();
		}
	}

	private setUserProperties() {
		if (this.user) {
			setUserProperties(this.analytics, {
				role: this.user?.role,
				company: this.user?.company,
				user_domain: this.user?.email.split("@").pop(),
				user_id: this.user?.uid,
				briaUid: this.user?.uid,
				...(this.user?.organizations &&
					this.user?.organizations.length > 0 && {
						orgUid: this.user?.organizations[0],
					}),
				...(this.user?.subscription && {
					subscription_type: this.user?.subscription.web_subscription_type,
					subscription_period: this.user?.subscription.web_subscription_period,
				}),
				is_sso: this.user?.isSso,
			});
			setUserId(this.analytics, this.user?.uid);
		}
	}

	logEvent(eventName: ANALYTICS_EVENTS, eventParams?: { [key: string]: any }) {
		logEvent(this.analytics, eventName, eventParams);
	}

	logToolsEvent(
		eventName: ANALYTICS_EVENTS,
		{ changeName, value, source }: { changeName: string; value: number; source: String }
	) {
		logEvent(this.analytics, eventName, { changeName, value, source });
	}

	private getScreenName() {
		const currentRoute = getCurrentRoute();
		return currentRoute ? currentRoute.name : window.location.pathname;
	}

	fireScreenView() {
		const screenName = this.getScreenName();
		const props = {
			firebase_screen: screenName,
			page_title: screenName,
			screen_name: screenName,
			firebase_screen_class: screenName.replace(" ", "_"),
			screen_class: screenName.replace(" ", "_"),
		};
		logEvent(this.analytics, "screen_view", props);
		this.pushHubspotPageView();
	}

	private pushHubspotPageView(pathName?: string) {
		let _hsq = ((window as any)._hsq = (window as any)._hsq || []);
		_hsq.push(["setPath", pathName ?? window.location.pathname]);
		_hsq.push(["trackPageView"]);
	}
}

export default new Analytics();
