import { GenModels } from "../Components/Tools/utilities";

export const PUBLIC_GALLERY_ORG_ID = "d8c6299f-774a-4f3b-adb1-74f250e19407";
export const BRIA_ORG_ID = "96c241f8-dd6a-11eb-ba80-0242ac130004";
export const GENERAL_ORG_ID = "6f9007a7-cfd4-49b2-bf4b-be3a11e869a7";
export const MODIFIED_IMAGES_ORG_ID = "990c3b7a-03b2-41b5-bb77-e39760b7987c";
export const GENERATED_IMAGES_ORG_ID = "0f581472-68ef-41eb-b6e9-41b45cf051b7";
export const STATIC_ACCOUNT_EMAIL = "bria.guest@gmail.com";
export const STATIC_ACCOUNT_PASSWORD = "__Bria@@@@2022__";

export const DISABLE_ENTER_LANGUAGES = ["ja"];

export const GEN_MODEL_DROPDOWN_OPTIONS = [
	{
		key: "BRIA 2.3 Fast",
		value: GenModels.bria_2_3_fast,
		description: "bria2_3FastTooltipContent",
	},
	{
		key: "BRIA 2.3",
		value: GenModels.bria_2_3,
		description: "bria2_3TooltipContent",
	},
	{
		key: "BRIA 2.2 HD",
		value: GenModels.bria_2_2_hd,
		description: "bria2_2_hdTooltipContent",
	},
];

export const SAMSUNG_GEN_MODEL_DROPDOWN_OPTIONS = [
	{
		key: "BRIA 2.3",
		value: GenModels.bria_2_3,
		description: "",
	},
];

export const GEN_MODEL_ASPECT_RATIO_DROPDOWN_OPTIONS = [
	{
		key: "4:3",
		value: {
			normal: [1152, 896],
			hd: [1728, 1280],
		},
	},
	{
		key: "3:4",
		value: {
			normal: [896, 1152],
			hd: [1280, 1728],
		},
	},
	{
		key: "1:1",
		value: {
			normal: [1024, 1024],
			hd: [1536, 1536],
		},
	},
	{
		key: "16:9",
		value: {
			normal: [1344, 768],
			hd: [1920, 1088],
		},
	},
	{
		key: "9:16",
		value: {
			normal: [768, 1344],
			hd: [1088, 1920],
		},
	},
	{
		key: "5:4",
		value: {
			normal: [1088, 896],
			hd: [1664, 1344],
		},
	},
	{
		key: "4:5",
		value: {
			normal: [896, 1088],
			hd: [1344, 1664],
		},
	},
	{
		key: "3:2",
		value: {
			normal: [1216, 832],
			hd: [1856, 1280],
		},
	},
	{
		key: "2:3",
		value: {
			normal: [832, 1216],
			hd: [1280, 1856],
		},
	},
];

export enum TAILORED_GENERATION_MODEL_STATUS {
	CREATED = "CREATED",
	READY = "READY",
	UNAVAILABLE = "UNAVAILABLE",
	DELETED = "DELETED",
}
