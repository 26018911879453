import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from "react-compare-slider";
import { IsIframe } from "../../../../../../Helpers/iframe";
import { useIsDomMounted } from "../../../../../../hooks/useDomMounted";
import BriaAPI from "../../../../../../sdk/resources/briaAPI";
import { ApiActions } from "../../../../../../sdk/resources/briaAPIConstants";
import Image from "../../../../../Common/Img/Img";
import LoadingAnimation from "../../../../../Common/LoadingAnimation/LoadingAnimation";
import { defaultSlidersState } from "../../../../Mood/data/sliders";
import {
	SELECTED_PREDEFINED_MOOD,
	XMP_FILE,
} from "../../../ColorPallete/ColorPallete";
import { validateColorMixer } from "../../../Moods/SingleMood/SingleMood";
import { BrandContext } from "../../BrandPage";
import {
	allImgsHashes,
	GridImgProps,
	mainImgInfo,
	SELECTED_IMG_INFO,
} from "../ImgViewer";
import clsx from "clsx";

const LAST_UPDATE_CHANGE_ID = "LAST_UPDATE_CHANGE_ID";

const mergeImgsWithChangeId = (
	changeId: number,
	imgsSrc: string[] = allImgsHashes,
	imageRes: string
) =>
	imgsSrc.reduce(
		(prevHash, currHash, index) => ({
			...prevHash,
			[currHash]: { changeId, imageRes },
		}),
		{}
	);

export const handleMoodApplyCall = async (
	instance: BriaAPI,
	moodSliders: any = {},
	orgUid: string
): Promise<string | undefined> => {
	// const { isIframe } = IsIframe();
	return new Promise(async (resolve, reject) => {
		try {
			BriaAPI.resetInstance(instance.visualId);
			const res =
				(await (instance as any).callApi(
					ApiActions.APPLY_MOOD,
					{
						changes: {
							...defaultSlidersState,
							...moodSliders,
							light: {
								...defaultSlidersState.light,
								...moodSliders.light,
							},
							color: {
								...defaultSlidersState.color,
								...moodSliders.color,
							},
							color_mixer: validateColorMixer(
								moodSliders.color_mixer
							),
						},
					},
					// for brand creation flow, always clear the watermark
					true,
					orgUid
				)) || {};
			const {
				data: { image_res },
			} = res;
			resolve(image_res || "");
		} catch (error: any) {
			reject(error?.message);
		}
	});
};

interface IBrandImg {
	onClick: () => void;
	className: string;
	src: string;
	original?: string;
	hash: string;
	isCompareMode?: boolean;
	isGrid?: boolean;
	loading?: boolean;
	isBrandPage?: boolean;
}

export default function BrandImg({
	isCompareMode = true,
	hash,
	isGrid,
	src,
	original,
	loading,
	isBrandPage,
	...rest
}: IBrandImg) {
	const classes = useStyles();
	const { generalState } = useContext(BrandContext);
	const _generalState = generalState as any;
	const _window = window as any;

	const selectedImg: GridImgProps =
		_generalState?.[SELECTED_IMG_INFO] || mainImgInfo;
	const selectedPredefinedMood = _generalState?.[SELECTED_PREDEFINED_MOOD];

	const hideLoading = loading ? !_window.atFineTuning : true; // hide loading in case the selected mood is xmp and this img is selected as the main img

	return (
		<>
			{isCompareMode ? (
				<ReactCompareSlider
					className={clsx({
						[classes.imageLoadingState]: loading,
					})}
					style={{
						overflow: "hidden",
						maxWidth: "100%",
						height: "100%",
						objectFit: "contain",
						borderRadius: 10,
						border: "1px solid #ccc",
						backgroundImage: `url(/transparent.jpg)`,
						backgroundSize: "cover",
					}}
					handle={
						<ReactCompareSliderHandle
							buttonStyle={{
								WebkitBackdropFilter: "none",
								backdropFilter: "none",
								border: 0,
								boxShadow: "none",
								display: isCompareMode ? "grid" : "none",
							}}
							linesStyle={{ opacity: 0.5 }}
						/>
					}
					position={isCompareMode ? 50 : 0}
					itemOne={<img src={src} {...rest} />}
					itemTwo={<img src={original || src} {...rest} />}
				/>
			) : (
				<Box className={classes.singleImg}>
					<img src={src} {...rest} />
				</Box>
			)}
			{loading && (!hideLoading || isBrandPage) && (
				<Box className={classes.animationPosition}>
					<LoadingAnimation loading={loading} />
				</Box>
			)}
		</>
	);
}

const useStyles = makeStyles(() => ({
	singleImg: {
		position: "relative",
	},
	animationPosition: {
		position: "absolute",
		left: "50%",
		top: "50%",
		zIndex: 5,
		transform: "translate(-50%,-50%)",
	},
	imageLoadingState: {
		filter: "blur(4px)",
	},
}));
